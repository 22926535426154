import templateUrl from './metricsPickerCatalogResults.tpl.html';
import tooltipTemplateUrl from '../tooltips/metricTooltip.tpl.html';
import metricsResultsSummaryTemplateUrl from '../metricsCatalog/metricsResultsSummary.tpl.html';

export default {
    templateUrl,
    bindings: {
        results: '<',
        terms: '<',
        filters: '<',
        addFilter: '<',
        resultsChecked: '<',
        allowMultipleSelection: '<',
        onMetricClick: '<',
        onClickActionText: '<',
    },
    controller: [
        '_',
        'metricsCatalogSearchUtil',
        function (_, metricsCatalogSearchUtil) {
            const $ctrl = this;
            $ctrl.includedFilters = [];
            $ctrl.excludedFilters = [];

            // Lifecyle functions
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.isArchived = isArchived;
            $ctrl.selectAll = selectAll;
            $ctrl.selectNone = selectNone;
            $ctrl.onSelection = onSelection;
            $ctrl.tooltip = {
                showDescription: true,
                onClickActionText: null,
                getFilterString: getFilterString,
            };

            $ctrl.tooltipTemplateUrl = tooltipTemplateUrl;
            $ctrl.metricsResultsSummaryTemplateUrl = metricsResultsSummaryTemplateUrl;

            function $onInit() {
                metricsCatalogSearchUtil.addMetricResultMetadata($ctrl.results, $ctrl.terms);
                $ctrl.resultsCapped = metricsCatalogSearchUtil.areMetricResultsCapped(
                    $ctrl.results
                );
                processFilters();
                calculateSelected();
            }

            function $onChanges() {
                $ctrl.tooltip.onClickActionText = $ctrl.onClickActionText;
            }

            // determine whether the metric is an archived metrics
            // a metric is archived if isArchived is true
            function isArchived(metric) {
                return metric.isArchived;
            }

            function processFilters() {
                $ctrl.filters.forEach((filter) => {
                    const filterString = getFilterString(filter);
                    if (filter.NOT) {
                        $ctrl.excludedFilters.push(filterString.substring(1));
                    } else {
                        $ctrl.includedFilters.push(filterString);
                    }
                });
            }

            function getFilterString(filter) {
                return `${filter.NOT ? '!' : ''}${filter.alias || filter.name}:${
                    filter.currentValue || filter.value
                }`;
            }

            function calculateSelected() {
                const checked = _.pickBy($ctrl.resultsChecked);
                $ctrl.numChecked = _.size(checked);
            }

            function onSelection(selected) {
                if (selected) {
                    $ctrl.numChecked++;
                } else {
                    $ctrl.numChecked--;
                }
            }

            function selectAll() {
                $ctrl.results.forEach((metric, index) => ($ctrl.resultsChecked[index] = true));
                $ctrl.numChecked = $ctrl.results.length;
            }

            function selectNone() {
                $ctrl.results.forEach((metric, index) => ($ctrl.resultsChecked[index] = false));
                $ctrl.numChecked = 0;
            }
        },
    ],
};
