import { react2angular } from 'react2angular';
import { PageHeaderWrapper } from './PageHeaderWrapper';

angular
    .module('signalview.pageHeaderWrapper', [])
    .component(
        'pageHeaderWrapper',
        react2angular(PageHeaderWrapper, [
            'description',
            'navigation',
            'rightContent',
            'titleContent',
        ])
    );
