import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import ExclamationTriangle from '@splunk/react-icons/ExclamationTriangle';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledWarningIcon = styled(ExclamationTriangle)`
    height: 16px;
    width: auto;
    color: ${variables.statusColorMedium};
`;

export const WarningIcon: React.FC = () => {
    const themeKey = useLegacyThemeServiceKey();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledWarningIcon variant="filled" />
        </ThemeProvider>
    );
};
