export default [
    'suggestAPIService',
    'signalTypeService',
    function (suggestAPIService, signalTypeService) {
        const DEFAULT_RETENTION_WINDOW = 8 * 24 * 60 * 60 * 1000; // 8 days
        const AUTOSUGGEST_LIMIT = 100;

        return {
            getEnvironments,
            getEnvironmentSuggestions,
        };

        function getEnvironments() {
            return suggestAPIService
                .getSignalFlowSuggest({
                    property: signalTypeService.getAPMDimensions().environment,
                    partialInput: null,
                    limit: 100,
                    additionalFilters: [
                        {
                            property: 'sf_metric',
                            values: [signalTypeService.getAPMDimensions().env_search_metric],
                        },
                    ],
                    additionalReplaceOnlyFilters: [],
                    startTime: Date.now() - DEFAULT_RETENTION_WINDOW,
                    endTime: Date.now(),
                })
                .then((environments) => environments.sort());
        }

        function getEnvironmentSuggestions(searchTerm) {
            return suggestAPIService
                .getSignalFlowSuggest({
                    property: signalTypeService.getAPMDimensions().environment,
                    partialInput: searchTerm,
                    limit: AUTOSUGGEST_LIMIT,
                    additionalFilters: [
                        {
                            property: 'sf_metric',
                            values: [signalTypeService.getAPMDimensions().env_search_metric],
                        },
                    ],
                    additionalReplaceOnlyFilters: [],
                    startTime: Date.now() - DEFAULT_RETENTION_WINDOW,
                    endTime: Date.now(),
                })
                .then((environments) => environments.sort());
        }
    },
];
