import PropTypes from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import _ from 'lodash';

const className = new BEMHelper('information-checkbox');

export default function InformationCheckbox({
    label,
    isSelected,
    onCheckboxClick,
    additionalInfo,
    disabled = false,
}) {
    const additionalInfoList = _.map(additionalInfo, (infoStr, index) => {
        return <div key={index}>{infoStr}</div>;
    });

    return (
        <div {...className()}>
            <label>
                <input
                    type="checkbox"
                    name={label}
                    checked={isSelected}
                    onClick={onCheckboxClick}
                    disabled={disabled}
                    readOnly
                />
                <span {...className('input-label')}>{label}</span>
            </label>
            <div {...className('additional-information')}>{additionalInfoList}</div>
        </div>
    );
}

InformationCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    onCheckboxClick: PropTypes.func,
    additionalInfo: PropTypes.array,
};
