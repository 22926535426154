import PropTypes from 'prop-types';
import React, { useState, useEffect, useReducer } from 'react';
import BEMHelper from 'react-bem-helper';
import SavingStatus from './SavingStatus.js';
import InformationCheckbox from './InformationCheckbox.js';
import InformationDurationInput from './InformationDurationInput.js';
import OrganizationPreferenceService from './OrganizationPreferenceService';
import DefaultTokenExpirationConfiguration from './DefaultTokenExpirationConfiguration';
import UserAnalyticsService from '../../../../legacy/app/userAnalytics/UserAnalyticsService';
import { AngularInjector } from '../../../../common/AngularUtils';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../app/routing/ngRoute';
import * as Sitemaps from '../../../routing/Sitemaps';
import { PageHeaderWrapper } from '../../../header/PageHeaderWrapper';

const ORGANIZATION_SETTINGS_TITLE = 'General Organization Settings';
const TEAM_MANAGEMENT_TITLE = 'Team Management';
const RELATED_CONTENT_MANAGEMENT_TITLE = 'Related Content Management';

//buttons
const APPLY_CHANGES = 'Apply';
const CANCEL = 'Cancel';

//headings and labels
const TEAM_MANAGEMENT_INFORMATION = [
    'Enable enhanced team security by restricting users from joining any team.',
    'Team managers and administrators can add and remove users from a team.',
];
const TEAM_CHECKBOX_LABEL = 'Restrict Team Access';

const RC_CHECKBOX_LABEL = 'Activate Related Content for logs';
const RC_MANAGEMENT_INFORMATION = [
    'Activate Related Content searches for logs to trigger by default.',
];

const ALERT_AUTO_CLEAR_LABEL = 'Auto-Clear Alerts';
const ALERT_AUTO_CLEAR_INFORMATION = [
    'Clear active alerts by default if metric time series has not responded for the given duration.',
];

const ACCESS_TOKENS_LABEL = 'Token expiration duration default';
const ACCESS_TOKEN_INFORMATION =
    'Default token expiration settings for organization members during token creation and rotation process, maximum 18 years.';

// AI Assistant constants
const AI_ASSISTANT_MANAGEMENT_TITLE = 'AI Assistant Management';
const ENABLE_AI_ASSISTANT_LABEL = 'AI Assistant in Observability';
const ENABLE_AI_ASSISTANT_DATA_SHARING =
    'Allow my AI service data to be used for research and development purposes.';
const ENABLE_AI_ASSISTANT_INFORMATION = [
    'Check this box to activate AI Assistant for Observability for your organization.',
];
const ENABLE_DATA_SHARING_INFORMATION = [
    'Unchecking this box indicates that you DO NOT consent to the use of data consumed, processed, or generated by',
    'your use of the AI Assistant for Observability for the purpose of research and development.',
];

const className = new BEMHelper('organization-preferences');

function ChangeButtons({ onCancelClick, onApplyChangesClick, isValid }) {
    return (
        <div {...className('apply-change-button')}>
            <div {...className('button-spacing')}>
                <button onClick={onCancelClick} className="btn btn-default">
                    {CANCEL}
                </button>
            </div>
            <div {...className('button-spacing')}>
                <button
                    onClick={onApplyChangesClick}
                    className={'btn btn-primary'}
                    disabled={!isValid}
                >
                    {APPLY_CHANGES}
                </button>
            </div>
        </div>
    );
}

ChangeButtons.propTypes = {
    onCancelClick: PropTypes.func,
    onApplyChangesClick: PropTypes.func,
    isValid: PropTypes.bool,
};

const msInADay = 24 * 60 * 60 * 1000; // 1 day in milliseconds

function convertMsToDays(milliseconds) {
    return milliseconds / msInADay;
}

export default function OrganizationPreferences({ featureEnabled, editable }) {
    const [restrictTeamManagement, setRestrictTeamManagement] = useState(null);
    const [alertAutoClearDuration, setAlertAutoClearDuration] = useState(null);
    const [defaultTokenExpiration, setDefaultTokenExpiration] = useState('30');
    const [relatedContentLogsDisabled, setRelatedContentLogsDisabled] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const [isValidDefaultTokenExpiration, setValidDefaultTokenExpiration] = useState(true);
    const [isCancelled, cancel] = useReducer((c) => c + 1, 0);
    const [isLoading, setIsLoading] = useState(true);
    const [initialTeamManagementSetting, setInitialTeamManagementSetting] = useState(null);
    const [initialAlertAutoClearDuration, setInitialAlertAutoClearDuration] = useState(null);
    const [initialDefaultTokenExpiration, setInitialDefaultTokenExpiration] = useState('30');
    const [initialRelatedContentLogsDisabled, setInitialRelatedContentLogsDisabled] =
        useState(null);

    const [initialAiAssistant, setInitialAiAssistant] = useState(null);
    const [initialAiAssistantShareData, setInitialAiAssistantShareData] = useState(null);
    const [orgPreferenceId, setOrgPreferenceId] = useState(null);
    const [syncState, setSyncState] = useState(null);
    const [aiAssistant, setAiAssistant] = useState(null);
    const [aiAssistantShareData, setAiAssistantShareData] = useState(null);
    const rbacUtilsService = AngularInjector.instantiate('rbacUtilsService');

    const orgPreferenceService = OrganizationPreferenceService.useInstance();
    const userAnalyticsService = UserAnalyticsService.useInstance();

    const isUpdated = () => {
        if (
            restrictTeamManagement !== initialTeamManagementSetting ||
            alertAutoClearDuration !== initialAlertAutoClearDuration ||
            defaultTokenExpiration !== initialDefaultTokenExpiration ||
            relatedContentLogsDisabled !== initialRelatedContentLogsDisabled ||
            aiAssistant !== initialAiAssistant ||
            aiAssistantShareData !== initialAiAssistantShareData
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (featureEnabled('newRbacExperience')) {
            rbacUtilsService.checkCapabilityAndRedirect(Capability.READ_GENERAL_SETTINGS);
        } else if (!editable) {
            ngRoute.history?.replace('/NotFound');
        }
    }, [editable, featureEnabled, rbacUtilsService]);

    useEffect(() => {
        const fetchOrgData = async () => {
            const organizationSettings = await orgPreferenceService.get();
            setOrgPreferenceId(organizationSettings.sf_id);
            setInitialTeamManagementSetting(!!organizationSettings.sf_restrictTeamManagement);
            setRestrictTeamManagement(!!organizationSettings.sf_restrictTeamManagement);
            if (featureEnabled('logsRCIsEnabledOrDisabledByAdmin')) {
                setInitialRelatedContentLogsDisabled(
                    !!organizationSettings.sf_relatedContentLogsDisabled
                );
                setRelatedContentLogsDisabled(!!organizationSettings.sf_relatedContentLogsDisabled);
            }

            if (organizationSettings.sf_alertAutoClearDuration) {
                setInitialAlertAutoClearDuration(organizationSettings.sf_alertAutoClearDuration);
                setAlertAutoClearDuration(organizationSettings.sf_alertAutoClearDuration);
            }

            if (featureEnabled('tokenExpirationEnhancements')) {
                const defaultTime =
                    Math.floor(convertMsToDays(organizationSettings.sf_defaultTokenLifespanMs)) ||
                    30;
                setInitialDefaultTokenExpiration(defaultTime.toString());
                setDefaultTokenExpiration(defaultTime.toString());
            }

            if (featureEnabled('o11yAiAssistantAdminSettings')) {
                setAiAssistant(!!organizationSettings.sf_ai_assistant_enabled);
                setAiAssistantShareData(
                    !!organizationSettings.sf_ai_assistant_customer_data_usage_enabled
                );
                setInitialAiAssistant(!!organizationSettings.sf_ai_assistant_enabled);
                setInitialAiAssistantShareData(
                    !!organizationSettings.sf_ai_assistant_customer_data_usage_enabled
                );
            }

            setIsLoading(false);
        };

        fetchOrgData();
    }, [featureEnabled, orgPreferenceService]);

    function handleSubmit() {
        setSyncState('APPLYING');

        const patch = {};
        if (restrictTeamManagement !== initialTeamManagementSetting) {
            patch.restrictTeamManagement = restrictTeamManagement;
        }
        if (alertAutoClearDuration !== initialAlertAutoClearDuration) {
            patch.alertAutoClearDuration = alertAutoClearDuration;
        }

        if (defaultTokenExpiration !== initialDefaultTokenExpiration) {
            const timeInMs = parseInt(defaultTokenExpiration) * msInADay;
            patch.defaultTokenExpiration = timeInMs;
        }

        if (relatedContentLogsDisabled !== initialRelatedContentLogsDisabled) {
            patch.relatedContentLogsDisabled = relatedContentLogsDisabled;
        }

        if (aiAssistant !== initialAiAssistant) {
            patch.aiAssistant = aiAssistant;
        }

        if (aiAssistantShareData !== initialAiAssistantShareData) {
            patch.aiAssistantShareData = aiAssistantShareData;
        }

        return orgPreferenceService
            .update(orgPreferenceId, patch)
            .then(() => {
                setSyncState('COMPLETED');
                const eventAction = restrictTeamManagement
                    ? 'team-controls-toggle-on'
                    : 'team-controls-toggle-off';
                userAnalyticsService.event('general organization settings', eventAction);
                setInitialTeamManagementSetting(restrictTeamManagement);
                setInitialAlertAutoClearDuration(alertAutoClearDuration);

                if (featureEnabled('logsRCIsEnabledOrDisabledByAdmin')) {
                    setInitialRelatedContentLogsDisabled(relatedContentLogsDisabled);
                }

                if (featureEnabled('tokenExpirationEnhancements')) {
                    setInitialDefaultTokenExpiration(defaultTokenExpiration);
                }
                if (featureEnabled('o11yAiAssistantAdminSettings')) {
                    setInitialAiAssistant(aiAssistant);
                    setInitialAiAssistantShareData(aiAssistantShareData);
                }
            })
            .catch(() => {
                setSyncState('FAILED');
            })
            .finally(() => {
                setTimeout(function () {
                    setSyncState(null);
                }, 1000);
            });
    }

    function cancelChanges() {
        setRestrictTeamManagement(initialTeamManagementSetting);
        setAlertAutoClearDuration(initialAlertAutoClearDuration);
        setDefaultTokenExpiration(initialDefaultTokenExpiration);
        setRelatedContentLogsDisabled(initialRelatedContentLogsDisabled);
        setAiAssistant(initialAiAssistant);
        setAiAssistantShareData(initialAiAssistantShareData);
        cancel();
    }

    function onRestrictTeamManagementChanges() {
        setRestrictTeamManagement(!restrictTeamManagement);
    }

    function onRelatedContentLogsDisabledChanges() {
        setRelatedContentLogsDisabled(!relatedContentLogsDisabled);
    }

    function onAIAssistantChange() {
        const newAiAssistantVal = !aiAssistant;
        setAiAssistant(newAiAssistantVal);

        // turn off data sharing automatically if assistant is turned off.
        if (!newAiAssistantVal) {
            setAiAssistantShareData(false);
        }
    }

    function onAIAssistantDataSharingChange() {
        setAiAssistantShareData(!aiAssistantShareData);
    }

    return (
        <section
            {...className()}
            {...(featureEnabled('magneticPageHeader') && className('compact'))}
        >
            {isLoading && <div className="loading-shroud"></div>}
            {!isLoading && (
                <div {...className('content-container')}>
                    {featureEnabled('magneticPageHeader') ? (
                        <PageHeaderWrapper
                            navigation={[
                                {
                                    id: Sitemaps.OrganizationPreferences.map.id,
                                    label: Sitemaps.OrganizationPreferences.map.label,
                                },
                            ]}
                        />
                    ) : (
                        <h4 {...className('page-title')}>{ORGANIZATION_SETTINGS_TITLE}</h4>
                    )}
                    <div {...className('content')}>
                        <SavingStatus status={syncState} />
                        <div>
                            <h5 {...className('team-management-title')}>{TEAM_MANAGEMENT_TITLE}</h5>
                            <div {...className('team-management')}>
                                {featureEnabled('writepermissions') && (
                                    <InformationCheckbox
                                        label={TEAM_CHECKBOX_LABEL}
                                        isSelected={restrictTeamManagement}
                                        onCheckboxClick={onRestrictTeamManagementChanges}
                                        additionalInfo={TEAM_MANAGEMENT_INFORMATION}
                                    />
                                )}
                                <InformationDurationInput
                                    key={isCancelled}
                                    label={ALERT_AUTO_CLEAR_LABEL}
                                    value={alertAutoClearDuration}
                                    onChange={(duration, isValidDuration) => {
                                        setIsValid(isValidDuration);
                                        setAlertAutoClearDuration(duration);
                                    }}
                                    additionalInfo={ALERT_AUTO_CLEAR_INFORMATION}
                                />

                                {featureEnabled('tokenExpirationEnhancements') && (
                                    <DefaultTokenExpirationConfiguration
                                        label={ACCESS_TOKENS_LABEL}
                                        value={defaultTokenExpiration}
                                        onChange={(
                                            defaultExpirationTokenValue,
                                            isValidExpiryDate
                                        ) => {
                                            setValidDefaultTokenExpiration(isValidExpiryDate);
                                            setDefaultTokenExpiration(defaultExpirationTokenValue);
                                        }}
                                        additionalInfo={ACCESS_TOKEN_INFORMATION}
                                    />
                                )}
                            </div>
                        </div>

                        {/* AI Assistant */}
                        {featureEnabled('o11yAiAssistantAdminSettings') &&
                            featureEnabled('aiAssistant') && (
                                <div>
                                    <h5 {...className('ai-assistant-management-title')}>
                                        {AI_ASSISTANT_MANAGEMENT_TITLE}
                                    </h5>
                                    <div {...className('ai-assistant-management')}>
                                        <InformationCheckbox
                                            label={ENABLE_AI_ASSISTANT_LABEL}
                                            isSelected={aiAssistant}
                                            onCheckboxClick={onAIAssistantChange}
                                            additionalInfo={ENABLE_AI_ASSISTANT_INFORMATION}
                                        />
                                        <div>{aiAssistant}</div>
                                        <div {...className('ai-assistant-management')}>
                                            <InformationCheckbox
                                                disabled={!aiAssistant}
                                                label={ENABLE_AI_ASSISTANT_DATA_SHARING}
                                                isSelected={aiAssistantShareData}
                                                onCheckboxClick={onAIAssistantDataSharingChange}
                                                additionalInfo={ENABLE_DATA_SHARING_INFORMATION}
                                            />
                                        </div>
                                        <div>{aiAssistantShareData}</div>
                                    </div>
                                </div>
                            )}

                        {featureEnabled('logsRCIsEnabledOrDisabledByAdmin') && (
                            <div>
                                <h5 {...className('rc-management-title')}>
                                    {RELATED_CONTENT_MANAGEMENT_TITLE}
                                </h5>
                                <div {...className('rc-management')}>
                                    <InformationCheckbox
                                        label={RC_CHECKBOX_LABEL}
                                        isSelected={!relatedContentLogsDisabled}
                                        onCheckboxClick={onRelatedContentLogsDisabledChanges}
                                        additionalInfo={RC_MANAGEMENT_INFORMATION}
                                    />
                                </div>
                            </div>
                        )}

                        {isUpdated() && (
                            <ChangeButtons
                                isValid={isValid && isValidDefaultTokenExpiration}
                                onApplyChangesClick={handleSubmit}
                                onCancelClick={cancelChanges}
                            />
                        )}
                    </div>
                </div>
            )}
        </section>
    );
}

OrganizationPreferences.propTypes = {
    featureEnabled: PropTypes.func,
    currentUser: PropTypes.func,
    editable: PropTypes.bool,
};
