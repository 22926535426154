import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import MagnifierChartLine from '@splunk/react-icons/MagnifierChartLine';
import Button from '@splunk/react-ui/Button';
import Tooltip from '@splunk/react-ui/Tooltip';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledMagnifierIcon = styled(MagnifierChartLine)`
    width: 20px;
    height: 20px;
    color: ${variables.contentColorDefault};
`;

const StyledButton = styled(Button)`
    color: ${variables.contentColorDefault} !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        color: ${variables.contentColorActive} !important;
        svg {
            color: ${variables.contentColorActive};
        }
    }
`;

type ExternalLink = {
    type: 'StaticExternal';
    label: string;
    url: string;
};

type InternalLink = {
    type: 'StaticInternal';
    label: string;
    path: string;
};

type NavigatorLink = {
    type: 'NavigatorLink';
    label: string;
    // possibly other fields
};

type Link = ExternalLink | InternalLink | NavigatorLink;

type DrilldownButtonProps = {
    link: Link;
};

const resolveLink = (link: Link): string => {
    if (link.type === 'StaticInternal') return link.path;
    if (link.type === 'StaticExternal') return link.url;
    if (link.type === 'NavigatorLink') return ''; // use resolveURL function when it's ready

    const exhaustiveCheck: never = link;
    throw new Error(`Unhandled link type: ${exhaustiveCheck}`);
};

export const DrilldownButton = ({ link }: DrilldownButtonProps): React.JSX.Element => {
    const themeKey = useLegacyThemeServiceKey();

    const label = link.label;

    return (
        <ThemeProvider colorScheme={themeKey}>
            <Tooltip
                contentRelationship="label"
                content={<div className="drilldown-button-tooltip-label">{label}</div>}
                defaultPlacement="above"
            >
                <StyledButton
                    to={resolveLink(link)}
                    appearance="subtle"
                    openInNewContext={link.type === 'StaticExternal'}
                    icon={<StyledMagnifierIcon />}
                    label={label}
                />
            </Tooltip>
        </ThemeProvider>
    );
};
