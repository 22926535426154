import templateUrl from './objectCrossLink.tpl.html';

export default {
    templateUrl,
    bindings: {
        object: '<',
    },
    controller: [
        '$scope',
        'crossLinkUtils',
        'CROSS_LINK_TYPES',
        'PRODUCT_NAME',
        function ($scope, crossLinkUtils, CROSS_LINK_TYPES, PRODUCT_NAME) {
            const $ctrl = this;

            $ctrl.allowedTypes = [
                CROSS_LINK_TYPES.INTERNAL_LINK,
                CROSS_LINK_TYPES.EXTERNAL_LINK,
                CROSS_LINK_TYPES.SPLUNK_LINK,
                CROSS_LINK_TYPES.KIBANA_LINK,
                CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK,
                CROSS_LINK_TYPES.APPD_LINK,
            ];

            $ctrl.$onInit = $onInit;

            function $onInit() {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                if (!crossLinkUtils.objectIsCrossLinkable($ctrl.object)) {
                    return;
                } else {
                    $ctrl.isCrossLinkType = true;
                }

                const key = $ctrl.object.sf_key[0];

                $ctrl.crossLinkToEdit = {
                    propertyName: key,
                    propertyValue: $ctrl.object[key],
                };
            }
        },
    ],
};
