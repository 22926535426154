import { startCase } from 'lodash';

import dimensionsService from './dimensionsService';
import plotsService from './plotsService';

export const APM_IDENTIFIERS = ['ep_above_ft', 'd_above_ft', 'signal_baseline'];
export const APM_ALERT_TYPES = ['errors', 'latency', 'requests'];

export default class ChartInformationService {
    constructor(detectorPriorityService, valueFormatter) {
        this.detectorPriorityService = detectorPriorityService;
        this.valueFormatter = valueFormatter;
    }

    getChartInformation(event, incidentInfo, orgId, plotColorMap) {
        const incidentRelatedPlots = plotsService.getPlotsForIncident(event, incidentInfo);
        const triggerValue = plotsService.getTriggerValue(
            incidentRelatedPlots,
            this.valueFormatter.formatValue
        );

        return {
            swatchClass: this._getSwatchClass(event.metadata.sf_severity),
            title: this.buildChartTitle(event, incidentInfo, incidentRelatedPlots),
            plots: plotsService.getPlotsInformation(
                incidentRelatedPlots,
                event,
                this.valueFormatter.formatValue,
                plotColorMap
            ),
            condition: event.metadata.sf_detectOnCondition,
            triggerValue: triggerValue,
            dimensions: dimensionsService.getForPlots(incidentRelatedPlots, orgId),
        };
    }

    buildChartTitle(event, incidentInfo, relatedPlots) {
        if (event?.properties?.detector_type === 'rum') {
            return this.buildForRUMDetector(event, relatedPlots);
        }
        const isAPMTypeDetector =
            event?.properties?.detector_type === 'apm' &&
            APM_ALERT_TYPES.includes(event?.properties?.alert_type);
        // hasAPMIdentifiers can be removed after APM detectors migration
        const hasAPMIdentifiers =
            incidentInfo && APM_IDENTIFIERS.some((id) => id === incidentInfo.conditionIdentifier);
        if (isAPMTypeDetector || hasAPMIdentifiers) {
            return this.buildForAPMDetector(event, relatedPlots);
        }
        return this.buildForInfrastructureDetector(relatedPlots, incidentInfo);
    }

    buildForAPMDetector(event, relatedPlots) {
        const service = event.metadata.service;
        const value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        const threshold = plotsService.getThresholdValue(relatedPlots);

        return `APM Service Error Rate - ${service} (value: ${value}) > ${threshold}`;
    }

    buildForRUMDetector(event, relatedPlots) {
        const operation = startCase(event.metadata?.sf_operation);
        const value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        const threshold = plotsService.getThresholdValue(relatedPlots);
        return `RUM ${operation} Error Rate (value: ${value}) > ${threshold}`;
    }

    buildForInfrastructureDetector(relatedPlots, incidentInfo) {
        if (incidentInfo && incidentInfo.builtInFunction && incidentInfo.readableCondition) {
            return incidentInfo.readableCondition;
        }

        const metricName = plotsService.getTriggerPlotName(relatedPlots);
        let value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        if (value === '-') {
            value = 'N/A';
        }

        return metricName && value ? `${metricName} (value: ${value})` : '';
    }

    _getSwatchClass(severityName) {
        const severity = this.detectorPriorityService.getSeverityByDisplay(severityName);

        return this.detectorPriorityService.getSwatchClassBySeverity(severity);
    }
}
