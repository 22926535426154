import React, { useCallback } from 'react';
import Button from '@splunk/react-ui/Button';
import Tooltip from '@splunk/react-ui/Tooltip';
import { CopyIcon } from '../icons/CopyIcon';
import { AngularInjector } from '../../../common/AngularUtils';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

type CopyButtonProps = {
    onCopyClick: () => void;
};

export const CopyButton: React.FC<CopyButtonProps> = ({ onCopyClick }: CopyButtonProps) => {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const themeKey = useLegacyThemeServiceKey();

    const handleCopy = useCallback(() => {
        onCopyClick();
        userAnalytics.event('click', 'dashboard-chart-copy');
    }, [onCopyClick, userAnalytics]);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <Tooltip
                contentRelationship="label"
                content={<div className="add-to-clipboard-label">Add to clipboard</div>}
                defaultPlacement="above"
            >
                <Button
                    appearance="subtle"
                    data-test="dashboard-chart-copy"
                    icon={<CopyIcon />}
                    onClick={handleCopy}
                />
            </Tooltip>
        </ThemeProvider>
    );
};
