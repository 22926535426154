import React, { useCallback, useState } from 'react';
import Button from '@splunk/react-ui/Button';
import Tooltip from '@splunk/react-ui/Tooltip';
import { DotsThreeHorizontalIcon } from '../icons/DotsThreeHorizontalIcon';
import Dropdown from '@splunk/react-ui/Dropdown';
import Menu from '@splunk/react-ui/Menu';
import { AngularInjector } from '../../AngularUtils';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';
import {
    SPLUNK_APM_PRODUCT_NAME,
    SPLUNK_RUM_PRODUCT_NAME,
    SPLUNK_PROFILING_PRODUCT_NAME,
} from '../../../legacy/common/consts';

type OptionsButtonProps = {
    widget: { chartId: string };
    chartId: string;
    snapshot: { id: string };
    getOpenUrl: (chartMode: string) => string;
    getOpenTitle: (chartMode: string) => string;
    clipSize: number;
    onCopyClick: () => void;
    onShareClick: () => void;
    currentModel: { sf_uiModel: { chartMode: string } };
    hasCreateChartCapability: boolean;
    hasCreateShareableSnapshotCapability: boolean;
    onShowAdditionalInfoClick: () => void;
    onDownloadClick: () => void;
    onExportAsCsvClick: () => void;
    onExportEventAsJsonClick: () => void;
    troubleshootIndexParams: string;
    setTroubleshootIndexParams: () => void;
    profilingIndexParams: string;
    setProfilingIndexParams: () => void;
    rumIndexParams: string;
    setRumIndexParams: () => void;
    viewOnly: boolean;
    enterDeleteMode: () => void;
    rumEnabled: boolean;
    profilingTroubleshootingEnabled: boolean;
    apm2Enabled: boolean;
};

export const OptionsButton: React.FC<OptionsButtonProps> = ({
    widget,
    chartId,
    snapshot,
    getOpenUrl,
    getOpenTitle,
    clipSize,
    onCopyClick,
    onShareClick,
    currentModel,
    hasCreateChartCapability,
    hasCreateShareableSnapshotCapability,
    onShowAdditionalInfoClick,
    onDownloadClick,
    onExportAsCsvClick,
    onExportEventAsJsonClick,
    troubleshootIndexParams,
    setTroubleshootIndexParams,
    profilingIndexParams,
    setProfilingIndexParams,
    rumIndexParams,
    setRumIndexParams,
    viewOnly,
    enterDeleteMode,
    rumEnabled,
    profilingTroubleshootingEnabled,
    apm2Enabled,
}: OptionsButtonProps) => {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const themeKey = useLegacyThemeServiceKey();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleCopyClick = useCallback(() => {
        onCopyClick();
        userAnalytics.event('click', 'chart-options-chart-copy');
        setIsDropdownOpen(false);
    }, [userAnalytics, onCopyClick]);

    const handleShareClick = useCallback(() => {
        onShareClick();
        userAnalytics.event('click', 'chart-options-chart-share');
        setIsDropdownOpen(false);
    }, [userAnalytics, onShareClick]);

    const handleInfoClick = useCallback(() => {
        onShowAdditionalInfoClick();
        userAnalytics.event('click', 'chart-options-chart-info');
        setIsDropdownOpen(false);
    }, [userAnalytics, onShowAdditionalInfoClick]);

    const handleDownloadImageClick = useCallback(() => {
        onDownloadClick();
        userAnalytics.event('click', 'chart-options-download-chart-image');
        setIsDropdownOpen(false);
    }, [userAnalytics, onDownloadClick]);

    const handleExportAsCsvClick = useCallback(() => {
        onExportAsCsvClick();
        userAnalytics.event('click', 'chart-export-as-csv');
        setIsDropdownOpen(false);
    }, [userAnalytics, onExportAsCsvClick]);

    const handleExportEventClick = useCallback(() => {
        onExportEventAsJsonClick();
        userAnalytics.event('click', 'chart-event-export-as-json');
        setIsDropdownOpen(false);
    }, [userAnalytics, onExportEventAsJsonClick]);

    const handleDeleteClick = useCallback(() => {
        enterDeleteMode();
        userAnalytics.event('click', 'chart-options-chart-delete');
        setIsDropdownOpen(false);
    }, [userAnalytics, enterDeleteMode]);

    const shouldShowShareItem =
        hasCreateShareableSnapshotCapability &&
        (chartId || snapshot.id) &&
        currentModel.sf_uiModel.chartMode !== 'logs' &&
        currentModel.sf_uiModel.chartMode !== 'logsTimeSeries';
    const hasId = !!(chartId || snapshot.id);
    const shouldShowDownloadCsvItem =
        currentModel.sf_uiModel.chartMode === 'graph' ||
        currentModel.sf_uiModel.chartMode === 'table';
    const shouldShowExportEventItem = currentModel.sf_uiModel.chartMode === 'event';
    const shouldShowTroubleshoot =
        apm2Enabled &&
        currentModel.sf_uiModel.chartMode !== 'logs' &&
        currentModel.sf_uiModel.chartMode !== 'logsTimeSeries';
    const shouldShowProfiling =
        profilingTroubleshootingEnabled && currentModel.sf_uiModel.chartMode === 'graph';
    const shouldShowRum = rumEnabled && currentModel.sf_uiModel.chartMode !== 'text';

    return (
        <ThemeProvider colorScheme={themeKey}>
            <Dropdown
                defaultPlacement="below"
                open={isDropdownOpen}
                onRequestOpen={(): void => setIsDropdownOpen(true)}
                onRequestClose={(): void => setIsDropdownOpen(false)}
                toggle={
                    <Tooltip
                        contentRelationship="label"
                        content={<div className="chart-actions-label">Chart actions</div>}
                        defaultPlacement="above"
                    >
                        <Button
                            id={`chart-action-id-${widget.chartId}`}
                            appearance="subtle"
                            data-test="chart-actions"
                            icon={<DotsThreeHorizontalIcon />}
                            onClick={(): void => {
                                userAnalytics.event('click', 'chart-options-open-menu');
                                setIsDropdownOpen(false);
                            }}
                        />
                    </Tooltip>
                }
            >
                <Menu>
                    {hasId && (
                        <Menu.Item
                            onClick={(): void => {
                                userAnalytics.event('click', 'chart-options-chart-open');
                                setIsDropdownOpen(false);
                            }}
                            data-test="chart-options-chart-open"
                            to={`${getOpenUrl(currentModel.sf_uiModel.chartMode)}`}
                        >
                            {getOpenTitle(currentModel.sf_uiModel.chartMode)}
                        </Menu.Item>
                    )}
                    {hasCreateChartCapability && (
                        <Menu.Item onClick={handleCopyClick} data-test="chart-options-chart-copy">
                            {clipSize > 0 ? 'Add to clipboard' : 'Copy'}
                        </Menu.Item>
                    )}
                    {shouldShowShareItem && (
                        <Menu.Item onClick={handleShareClick} data-test="chart-options-chart-share">
                            Share
                        </Menu.Item>
                    )}
                    {hasId && (
                        <Menu.Item onClick={handleInfoClick} data-test="chart-options-chart-info">
                            Info
                        </Menu.Item>
                    )}
                    <Menu.Item
                        onClick={handleDownloadImageClick}
                        data-test="chart-options-download-chart-image"
                    >
                        Download chart as image
                    </Menu.Item>
                    {shouldShowDownloadCsvItem && (
                        <Menu.Item onClick={handleExportAsCsvClick} data-test="chart-export-as-csv">
                            Export chart as CSV
                        </Menu.Item>
                    )}
                    {shouldShowExportEventItem && (
                        <Menu.Item
                            onClick={handleExportEventClick}
                            data-test="chart-event-export-as-json"
                        >
                            Export events as JSON
                        </Menu.Item>
                    )}
                    {shouldShowTroubleshoot && (
                        <Menu.Item
                            to={`#/apm/troubleshooting?${troubleshootIndexParams}`}
                            data-test="apm2-chart-view-troubleshooting"
                            onClick={(): void => {
                                userAnalytics.event('click', 'apm2-chart-view-troubleshooting');
                                setIsDropdownOpen(false);
                            }}
                            onMouseEnter={setTroubleshootIndexParams}
                        >
                            {`Troubleshoot from this time window (${SPLUNK_APM_PRODUCT_NAME})`}
                        </Menu.Item>
                    )}
                    {shouldShowProfiling && (
                        <Menu.Item
                            to={`#/profiling?${profilingIndexParams}`}
                            data-test="profiling-chart-view"
                            onClick={(): void => {
                                userAnalytics.event('click', 'profiling-chart-view');
                                setIsDropdownOpen(false);
                            }}
                            onMouseEnter={setProfilingIndexParams}
                        >
                            {`Troubleshoot from this time window (${SPLUNK_PROFILING_PRODUCT_NAME})`}
                        </Menu.Item>
                    )}
                    {shouldShowRum && (
                        <Menu.Item
                            to={`#/rum/tagspotlight?${rumIndexParams}`}
                            data-test="rum-chart-view"
                            onClick={(): void => {
                                userAnalytics.event('click', 'rum-chart-view');
                                setIsDropdownOpen(false);
                            }}
                            onMouseEnter={setRumIndexParams}
                        >
                            {`Troubleshoot from this time window (${SPLUNK_RUM_PRODUCT_NAME})`}
                        </Menu.Item>
                    )}
                    {!viewOnly && (
                        <Menu.Item
                            onClick={handleDeleteClick}
                            data-test="chart-options-chart-delete"
                        >
                            Delete
                        </Menu.Item>
                    )}
                </Menu>
            </Dropdown>
        </ThemeProvider>
    );
};
