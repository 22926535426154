import { useState } from 'react';
import logger from './logger';
import type { auto } from 'angular';

const log = logger('AngularUtils');
let $injector: auto.IInjectorService;

// A list of Angular dependencies that are consumed by the Olly application.
// These need to be manually deleted from the list when they're no longer needed by Olly,
// so that the "code cleaner" scripts can correctly mark them for removal.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exposedInjectedModules = [
    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/packages/olly-services/src/hooks/useIsClusterNameUnique.ts#L17
     * ```
     * const [service] = useState(() => $injector.get<DimensionService>(DIMENSION_SERVICE_DI_KEY));
     * ```
     */
    // declare-used-dependency-to-linter::dimensionService
    'dimensionService',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/org-settings/utils/fetchOrganizationConfigBuckets.ts#L4
     * ```
     * const organizationDataService =
     *   SignalViewApp.getInjectedOrDefault<OrganizationDataService>('organizationDataService');
     * ```
     */
    // declare-used-dependency-to-linter::organizationDataService
    'organizationDataService',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/providers/GlobalState.tsx#L141
     * ```
     * const colorAccessibilityServiceFallback = {
     *   ...SignalViewApp.getInjectedOrDefault('colorAccessibilityService'),
     * } as ReturnType<ColorAccessibilityStore>;
     * ```
     */
    // declare-used-dependency-to-linter::colorAccessibilityService
    'colorAccessibilityService',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/detectors/common/WritePermissionsChecker.service.ts#L13
     * ```
     * ServiceProvider.provide(WritePermissionCheckerService, () =>
     *   SignalViewApp.getInjected<WritePermissionCheckerService>('writepermissionsPermissionsChecker')
     * );
     * ```
     */
    // declare-used-dependency-to-linter::writepermissionsPermissionsChecker
    'writepermissionsPermissionsChecker',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/detectors/smokey/SmokeyStateProvider.service.ts#L9
     * ```
     * ServiceProvider.provide(SmokeyStateProviderService, () =>
     *   SignalViewApp.getInjected<SmokeyStateProvider>('SmokeyStateProvider')
     * );
     * ```
     */
    // declare-used-dependency-to-linter::SmokeyStateProvider
    'SmokeyStateProvider',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/services/DashboardSelectorServiceInstance.ts#L7
     * ```
     * ServiceProvider.provide(DashboardSelectorServiceInstance, () =>
     *   SignalViewApp.getInjected<DashboardSelectorService>('dashboardSelectorService')
     * );
     * ```
     */
    // declare-used-dependency-to-linter::dashboardSelectorService
    'dashboardSelectorService',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/infra/components/visualization/HeatmapGraph.tsx#L36
     * ```
     * export const HeatmapGraph = angulardirective2react<HeatmapGraphProps>(
     *   'heatmapGraph',
     *   heatmapGraph,
     *   SignalViewApp.getInjector()
     * );
     * ```
     */
    // declare-used-dependency-to-linter::heatmapGraph
    'heatmapGraph',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/infra/components/Dashboard.tsx#L44
     * ```
     * export const Dashboard = angulardirective2react<DashboardProps>(
     *   'dashboard',
     *   dashboard,
     *   SignalViewApp.getInjector()
     * );
     * ```
     */
    // declare-used-dependency-to-linter::dashboard
    'dashboard',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/integrations/common/CredentialDisplay.tsx#L24
     * ```
     * export const CredentialDisplay = angulardirective2react<CredentialDisplayProps>(
     *   'credentialDisplay',
     *   credentialDisplay,
     *   SignalViewApp.getInjector()
     * );
     * ```
     */
    // declare-used-dependency-to-linter::credentialDisplay
    'credentialDisplay',

    /* from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/integrations/common/GoogleAuthIntegration.tsx#L16
     * ```
     * export const GoogleAuthIntegration = angulardirective2react<GoogleAuthIntegrationProps>(
     *   'googleAuthIntegration',
     *   googleAuthIntegration,
     *   SignalViewApp.getInjector()
     * );
     * ```
     */
    // declare-used-dependency-to-linter::googleAuthIntegration
    'googleAuthIntegration',

    /* remaining items all from: https://cd.splunkdev.com/observability/shared/olly/-/blob/main/app/src/providers/GlobalStateSignalViewBindings.tsx
     * of the form
     * ```
     * export const mutingService = SignalViewApp.getInjectedOrDefault<MutingService>(
     *   'mutingService',
     *   globalStateDefaults.mutingService
     * );
     * ```
     */
    // declare-used-dependency-to-linter::mutingService
    'mutingService',
    // declare-used-dependency-to-linter::teamLinkingService
    'teamLinkingService',
    // declare-used-dependency-to-linter::detectorSettingsModal
    'detectorSettingsModal',
    // declare-used-dependency-to-linter::deleteDetector
    'deleteDetector',
    // declare-used-dependency-to-linter::config
    'config',
    // declare-used-dependency-to-linter::navigatorModes
    'navigatorModes',
    // declare-used-dependency-to-linter::plotToSignalflowV2
    'plotToSignalflowV2',
    // declare-used-dependency-to-linter::chartDisplayUtils
    'chartDisplayUtils',
    // declare-used-dependency-to-linter::clusterMapConfig
    'clusterMapConfig',
    // declare-used-dependency-to-linter::customerSupportService
    'customerSupportService',
    // declare-used-dependency-to-linter::discoveryDashboardUtils
    'discoveryDashboardUtils',
    // declare-used-dependency-to-linter::dashboardUtil
    'dashboardUtil',
    // declare-used-dependency-to-linter::detectorUtils
    'detectorUtils',
    // declare-used-dependency-to-linter::aclDashboardCloner
    'aclDashboardCloner',
    // declare-used-dependency-to-linter::dashboardCloner
    'dashboardCloner',
    // declare-used-dependency-to-linter::createHeatmap
    'createHeatmap',
    // declare-used-dependency-to-linter::heatmapDataService
    'heatmapDataService',
    // declare-used-dependency-to-linter::getDataFacets
    'getDataFacets',
    // declare-used-dependency-to-linter::largeVolumeModalService
    'largeVolumeModalService',
    // declare-used-dependency-to-linter::timeZoneService
    'timeZoneService',
    // declare-used-dependency-to-linter::auth
    'auth',
];

interface FunctionInjection {
    new (...args: any[]): any;
    $inject: string[];
}

type ArrayInjection = (string | ((...args: any[]) => any))[];

export type AngularInjection = FunctionInjection | ArrayInjection | string;

export const AngularInjector = {
    get $injector(): auto.IInjectorService {
        return $injector;
    },
    set $injector(_$injector) {
        $injector = _$injector;

        // Hack to make Protractor E2E test suite to keep working even with detached angular framework
        if ($injector) {
            window.angular.element(document.body).data('$injector', $injector);
            window.angular.element(document.documentElement).data('$injector', $injector);
        }
    },

    isAngularInjection(klass: any): klass is AngularInjection {
        return (
            typeof klass === 'string' ||
            (klass instanceof Array && typeof klass[klass.length - 1] === 'function') ||
            (typeof klass === 'function' && klass.$inject instanceof Array)
        );
    },

    instantiate<T = any>(klass: AngularInjection, locals?: any, caller?: string): T {
        if (typeof klass === 'string') {
            return $injector.get<T>(klass, caller);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return $injector.instantiate<T>(klass, locals);
    },

    useInjectedClass<T = any>(klass: AngularInjection, locals?: any): T {
        // for official docs see https://docs.angularjs.org/api/auto/service/$injector#instantiate
        // this requires all our ES6 modules which rely on angular.js dependencies to be compatible with $injector
        const [instance] = useState(() => AngularInjector.instantiate<T>(klass, locals));
        return instance;
    },
};

let bootstrapped = false;
const angularStorage = document.createElement('div');
const angularApp = document.createElement('div');
angularApp.setAttribute('id', 'angularapp');
angularApp.setAttribute('style', 'height:100%; width:100%; position:relative');
// declare-used-dependency-to-linter::Main
angularApp.setAttribute('ng-controller', 'Main');
angularApp.append(document.createElement('ng-view'));

function catchSignalviewNotDefined(e: Error): void {
    const isOlly = (window as any).OLLY !== undefined;
    if (
        isOlly ||
        !(
            e.message.includes('signalviewModule is not defined') ||
            e.message.includes('Module "signalview" is not available')
        )
    ) {
        throw e;
    }
}

export function bootstrapAngular(): void {
    if (bootstrapped) {
        return;
    }

    try {
        bootstrapped = true;
        window.angular.bootstrap(angularApp, ['signalview'], { strictDi: true });
        log('SignalView App bootstrapped');
    } catch (e) {
        catchSignalviewNotDefined(e);
    }
}

export function mount(hostEl: HTMLElement): void {
    bootstrapAngular();
    hostEl.appendChild(angularApp);
}

export function unmount(): void {
    bootstrapAngular();
    angularStorage.appendChild(angularApp);
}
