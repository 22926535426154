import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import {
    RefreshChartSlot,
    ChartSlot,
    ChartSlotsMessages,
    RemoveChartFromDashboard,
    ExportChart,
    CHARTS_CHANNEL_KEY,
    UnknownChart,
    useMessageChannel,
    useMessageChannelReducer,
} from '@splunk/olly-services';
import { RemoveDashboardPrompt } from './RemoveDashboardPrompt';

interface Widget {
    chartId: string;
    sizeX: number;
    sizeY: number;
}

export interface DashboardConfig {
    groupId: string;
}

export type Chart = UnknownChart & {
    name: string;
};

type DashboardChartSlotProps = {
    widget: Widget;
    width: number;
    height: number;
    inView: boolean;
    chart: Chart;
    chartUtils: any;
    filterAlias: any;
    plotToSignalflowV2: any;
    allDashboardConfigs?: Promise<DashboardConfig[]>;
    onExportToImage: (id: string, isDashboardSlotChart: boolean) => void;
    onExportToCsv: (data: Array<Array<string | number>>, chartName: string) => void;
    onRemove: (widget: Widget) => void;
};

interface KeyValueFilter {
    key: string;
    value: any;
    applyIfExists: boolean;
    not: boolean;
}

interface PropertyFilter {
    property: string;
    propertyValue: string[] | string;
    applyIfExists: boolean;
    NOT: boolean;
}

const mapFilters = (filters: KeyValueFilter[]): PropertyFilter[] => {
    return filters.map((filter: KeyValueFilter) => ({
        property: filter.key,
        propertyValue: filter.value,
        applyIfExists: filter.applyIfExists,
        NOT: filter.not,
    }));
};

export const DashboardChartSlot = ({
    plotToSignalflowV2,
    widget,
    width,
    height,
    inView,
    chart,
    chartUtils,
    filterAlias,
    allDashboardConfigs,
    onExportToImage,
    onExportToCsv,
    onRemove,
}: DashboardChartSlotProps): JSX.Element => {
    const getFilter = useCallback(() => {
        const propertyFilters = mapFilters(chartUtils.getChartOverrides(filterAlias));
        return plotToSignalflowV2.filters(propertyFilters) || undefined;
    }, [filterAlias, plotToSignalflowV2, chartUtils]);

    const [channelMessage] = useMessageChannel<ChartSlotsMessages>(CHARTS_CHANNEL_KEY);
    const [showRemovePrompt, setShowRemovePrompt] = useState(false);
    const [filter, setFilter] = useState(getFilter);

    useMessageChannelReducer<ChartSlotsMessages['type']>(channelMessage)
        .on(RemoveChartFromDashboard, ({ id }) => {
            if (id === widget.chartId) {
                setShowRemovePrompt(true);
            }
        })
        .on(ExportChart, (payload) => {
            if (payload.id === widget.chartId) {
                if (payload.format === 'csv') {
                    onExportToCsv(payload.data, chart.name);
                } else if (payload.format === 'image') {
                    onExportToImage(payload.id, true);
                }
            }
        })
        .on(RefreshChartSlot, () => setFilter(getFilter()));

    return (
        <>
            <ChartSlot
                width={width}
                height={height}
                inView={inView}
                chart={chart}
                filter={filter}
            />
            <RemoveDashboardPrompt
                show={showRemovePrompt}
                chart={chart}
                allDashboardConfigs={allDashboardConfigs}
                onCancel={(): void => setShowRemovePrompt(false)}
                onRemove={(): void => onRemove(widget)}
            />
        </>
    );
};

DashboardChartSlot.propTypes = {
    widget: PropTypes.any.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    inView: PropTypes.bool.isRequired,
    chart: PropTypes.any.isRequired,
    chartUtils: PropTypes.any.isRequired,
    filterAlias: PropTypes.any.isRequired,
    plotToSignalflowV2: PropTypes.any.isRequired,
    allDashboardConfigs: PropTypes.any,
    onExportToCsv: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export const DashboardChartSlotComponent = react2angular(
    DashboardChartSlot,
    [
        'widget',
        'width',
        'height',
        'inView',
        'chart',
        'filterAlias',
        'allDashboardConfigs',
        'onExportToImage',
        'onExportToCsv',
        'onRemove',
    ],
    ['chartUtils', 'plotToSignalflowV2']
);
