import templateUrl from './detectorWizardModal.tpl.html';
import { getOrCreateChannel } from '@splunk/olly-services/lib/hooks/useMessageChannel/channelRegistry';
import {
    DETECTOR_WIZARD_CHANNEL,
    DetectorCreatedInLegacyDetectorWizard,
    LinkDetectorToChartDetectorWizard,
} from '@splunk/olly-services/lib/hooks/useDetectorWizard';

/**
 * Detector wizard modal shows the detector wizard in a modal.
 * The wizard is instantiated with the detector model, and rule that is to be edited.
 * The detector might be a new detector or an already existing detector.
 * Similary, rule might be a new rule or an already existing rule.
 */
angular.module('signalview.detector.wizard').factory('detectorWizardModal', [
    '$log',
    '$q',
    'sfxModal',
    '$location',
    'currentUser',
    'chartbuilderUtil',
    'DETECTOR_TYPES',
    'URL_PARAMETER_CONSTANTS',
    'detectorService',
    'detectorUtils',
    'featureEnabled',
    'title',
    'writepermissionsPermissionsChecker',
    'processNotificationRecipient',
    'apmChartToDetectorTranslator',
    'signalTypeService',
    'organizationService',
    function (
        $log,
        $q,
        sfxModal,
        $location,
        currentUser,
        chartbuilderUtil,
        DETECTOR_TYPES,
        URL_PARAMETER_CONSTANTS,
        detectorService,
        detectorUtils,
        featureEnabled,
        title,
        writepermissionsPermissionsChecker,
        processNotificationRecipient,
        apmChartToDetectorTranslator,
        signalTypeService,
        organizationService
    ) {
        const { APM_V2, INFRASTRUCTURE } = DETECTOR_TYPES;

        return {
            newDetector,
            newRule,
            editRule,
            newApmDetector,
        };

        // Show wizard with passed in parameters - detector model, rule for edit and resolution promise generator
        // also let wizard know if it's a new rule
        function edit(params) {
            return sfxModal.open({
                templateUrl,
                controller: [
                    '$scope',
                    'data',
                    'preferences',
                    'hasWritePermission',
                    'orgSettings',
                    function ($scope, data, preferences, hasWritePermission, orgSettings) {
                        const messageChannel = getOrCreateChannel(DETECTOR_WIZARD_CHANNEL);
                        $scope.done = function (detector) {
                            messageChannel.postMessage(
                                DetectorCreatedInLegacyDetectorWizard({ detectorId: detector.id })
                            );
                            $scope.$close(detector);
                        };
                        $scope.detector = data.detector;
                        $scope.rule = data.rule;
                        $scope.newRule = params.newRule;
                        $scope.hasWritePermission = hasWritePermission;
                        $scope.orgSettings = orgSettings;

                        if (featureEnabled('apm2')) {
                            $scope.detectorType = params.detectorType;
                            $scope.apmDetectorOptions = params.apmDetectorOptions;

                            // when creating a detector from a chart, we try to recommend a matching detector type
                            // if there were mixed metrics (e.g. APM1 and APM2), then we don't recommend
                            if (params.fromChart && !params.detectorType) {
                                $scope.detectorTypeRecommendationFailed = true;
                            }
                        }

                        // call this first before adding empty detector rule because unique keys are shared across rules and plots
                        // we don't want rule getting uniqueKey 1
                        chartbuilderUtil.createTransientIfNeeded($scope.detector.sf_uiModel);
                        if (!data.rule) {
                            if (!data.detector.sf_uiModel.rules.length || $scope.newRule) {
                                const rule = detectorUtils.addEmptyDetectorRule(
                                    data.detector.sf_uiModel
                                );
                                if (!data.detector.sf_id) {
                                    rule.name = data.detector.sf_detector;
                                }
                            }
                            const length = data.detector.sf_uiModel.rules.length;
                            $scope.rule = data.detector.sf_uiModel.rules[length - 1];
                        }
                        $scope.preferences = preferences;
                        $scope.getSignalResolutionPromise = params.signalResolution;
                        if (!$scope.getSignalResolutionPromise) {
                            const signalResolutionUtil = detectorUtils.signalResolution();
                            $scope.getSignalResolutionPromise = function (plotLabel) {
                                return signalResolutionUtil(
                                    plotLabel,
                                    $scope.detector.sf_uiModel.allPlots
                                );
                            };
                        }
                    },
                ],
                size: 'lg',
                windowClass: 'detector-wizard-modal',
                resolve: {
                    data: function () {
                        return currentUser.orgId().then(function (orgId) {
                            let detectorData;
                            if (params.detector) {
                                // simplest deep copy without references 3 levels down
                                detectorData = angular.fromJson(angular.toJson(params.detector));
                            }
                            if (!detectorData) {
                                detectorData = detectorService.newV1();
                                detectorData.sf_organizationID = orgId;
                                if (params.fromChart) {
                                    // copy-paste from detector/chartRouteData.js, that code will be removed soon
                                    detectorData.sf_uiModel.fromChart =
                                        params.fromChart.sf_id || '';
                                    detectorData.sf_uiModel.allPlots =
                                        params.fromChart.sf_uiModel.allPlots.filter(
                                            (plot) => plot.type !== 'event'
                                        );
                                    detectorData.sf_uiModel.currentUniqueKey =
                                        params.fromChart.sf_uiModel.currentUniqueKey;
                                    // Copy over the timezone from the chart config
                                    detectorData.sf_uiModel.chartconfig.timezone =
                                        params.fromChart.sf_uiModel.chartconfig.timezone;
                                }
                                if (params.detectorName) {
                                    detectorData.sf_detector = params.detectorName;
                                }
                            }
                            let rule;
                            if (params.rule) {
                                rule = angular.fromJson(angular.toJson(params.rule));
                                if (rule.notifications) {
                                    rule.notifications =
                                        processNotificationRecipient.filterDeprecatedNotifications(
                                            rule.notifications
                                        );
                                }
                            }
                            return {
                                detector: detectorData,
                                rule: rule,
                            };
                        });
                    },
                    preferences: function () {
                        return currentUser.preferences().catch(function (e) {
                            $log.error('Failed fetching preferences.', e);
                            return null;
                        });
                    },
                    orgSettings: organizationService.getOrgSettings(),
                    hasWritePermission: function () {
                        return writepermissionsPermissionsChecker
                            .hasWritePermissions(params.detector)
                            .catch(function (e) {
                                $log.error('Failed fetching permissions.', e);
                                return false;
                            });
                    },
                },
                backdrop: 'static',
                keyboard: false,
            }).result;
        }

        /**
         * Entry point for the detector creation flow.
         *
         * When coming from a chart through 'New Detector from Chart', a chart model will be given, and we must provide the
         * most appropriate creation experience based on that incoming chart. For this, we request a detector recommendation
         * from the backend and select the appropriate wizard flow based on that recommendation.
         *
         * If no recommendation was given, or if we can't get an entity-based detector and the chart isn't convertible, we
         * will fallback to a Signalflow-based editor experience by redirecting the user to /detector/v2/new.
         *
         * At the end of this method, the user is redirected to the created detector.
         *
         * @param chartModel The chart model (may be null when not creating a detector from a chart).
         * @param filterAlias The filter alias, only used for entity-based detector creation like APM detectors.
         * @param editable Whether the incoming chart was editable, allowing us to link to it.
         * @param convertible Whether the incoming chart was convertible to a UI model.
         */
        function newDetector(chartModel, filterAlias, editable, convertible) {
            const lastTitle = title.getTitle();
            title.updateTitle('New Detector');

            return (
                chartModel
                    ? getNewDetectorWizardPromise(chartModel, filterAlias, editable, convertible)
                    : createDetector()
            )
                .then(directToCreatedDetector)
                .catch(() => title.updateTitle(lastTitle));
        }

        function newApmDetector() {
            return createApmDetector().then(directToCreatedDetector);
        }

        function getNewDetectorWizardPromise(chartModel, filterAlias, editable, convertible) {
            return $q
                .when(
                    apmChartToDetectorTranslator.getDetectorRecommendation(
                        chartModel.sf_viewProgramText
                    )
                )
                .then((recommendation) => {
                    if (recommendation.detectorType === APM_V2 && featureEnabled('apm2')) {
                        return createApmDetector(
                            recommendation.apmMetricType,
                            chartModel,
                            filterAlias
                        );
                    } else if (convertible) {
                        if (INFRASTRUCTURE === recommendation.detectorType) {
                            return createInfraDetector(chartModel);
                        } else {
                            return createDetector(chartModel);
                        }
                    } else {
                        /*
                         * If the chart does not translate to an entity-based detector like APM detectors, or cannot be converted,
                         * fallback to redirecting the user to a SignalFlow-editor based detector creation.
                         */
                        redirectToEditor(chartModel, editable);
                    }
                })
                .then((detector) => {
                    const link = featureEnabled('linkingDetectors') && editable;
                    return link
                        ? linkAfterCreation($q.when(detector), chartModel, convertible)
                        : detector;
                });
        }

        /**
         * Link the detector returned by the given promise to the given chart that was used to create it.
         *
         * @param promise A promise to the detector being created.
         * @param chartModel The model of the incoming chart.
         * @param convertible Whether the chart program text is properly convertible or not
         * @returns A new promise to the detector, after the chart has been linked to it.
         */
        function linkAfterCreation(promise, chartModel, convertible) {
            return promise.then((detector) => {
                // If the chart is not convertible, attempting to generate the program text from the uiModel will fail.
                // So just get the chart from the API, update the program text directly, and attempt to save it (this
                // is what's done in linkDetectorToUnconvertableChart).
                if (!convertible) {
                    return detectorUtils
                        .linkDetectorToUnconvertableChart(
                            detector.id,
                            detector.name,
                            chartModel.sf_id
                        )
                        .then((success) =>
                            success
                                ? detector
                                : detectorUtils
                                      .showFailedDetectorLinkingWarning()
                                      .then(() => detector)
                        );
                }
                const messageChannel = getOrCreateChannel(DETECTOR_WIZARD_CHANNEL);
                messageChannel.postMessage(
                    LinkDetectorToChartDetectorWizard({
                        detectorId: detector.id,
                        chartId: chartModel.sf_id,
                    })
                );
                return Promise.resolve(detector);
            });
        }

        /**
         * Redirects the user to the Signalflow-editor based detector creation experience.
         *
         * If we came from a chart, we land here because the chart isn't convertible a UI model. We'll still link the chart to
         * this detector after it gets created (by giving a fromChart parameter to the editor).
         *
         * @param chart The chart model.
         * @param editable Whether the chart was editable, which tells us whether we'll be able to link to it or not.
         */
        function redirectToEditor(chart, editable) {
            const endpoint = '/detector/v2/new';
            const params = {
                signalflow: chart.sf_viewProgramText,
            };

            // if the chart has an ID, linking detectors is enabled, and the chart is editable by
            // the current user, then provide a chartId to link back to after creation is complete.
            if (chart.sf_id && featureEnabled('linkingDetectors') && editable) {
                params[URL_PARAMETER_CONSTANTS.fromChart] = chart.sf_id;
            }

            $location.path(endpoint).search(params);
        }

        // Redirect the user to the given detector (usually, immediately after its creation).
        function directToCreatedDetector(detector) {
            if (detector) {
                $location.url(`/detector/${detector.sf_id || detector.id}/edit`);
            }
        }

        // Show wizard with an existing detector with an existing rule for edit
        function editRule(detector, rule, signalResolution, newRule) {
            return edit({
                detector: detector,
                rule: rule,
                signalResolution: signalResolution,
                newRule: newRule,
            })
                .then(function (editedDetector) {
                    if (editedDetector) {
                        $log.info(
                            'Successfully edited rule ',
                            rule || '(new rule)',
                            ' in detector ',
                            editedDetector
                        );
                        return editedDetector;
                    }
                })
                .catch(function (msg) {
                    $log.warn('Rule edit was canceled.', msg);
                });
        }

        // Show wizard with an existing detector but a new rule for creation
        function newRule(detector, signalResolution) {
            return editRule(detector, null, signalResolution, true);
        }

        function getDefaultDetectorName(chartModel) {
            return chartModel ? chartModel.sf_chart + ' Detector' : '';
        }

        // create detector
        function createDetector(chart) {
            const defaultName = getDefaultDetectorName(chart);

            return detectorUtils.detectorNameModal(defaultName).then((name) =>
                edit({
                    newRule: true,
                    fromChart: chart,
                    detectorName: name,
                })
            );
        }

        // create infra type detector
        function createInfraDetector(chart) {
            const defaultName = getDefaultDetectorName(chart);

            return detectorUtils.detectorNameModal(defaultName).then((name) =>
                edit({
                    newRule: true,
                    fromChart: chart,
                    detectorName: name,
                    detectorType: INFRASTRUCTURE,
                })
            );
        }

        // create apm type detector
        function createApmDetector(apmMetricType, chart, filterAlias) {
            const signalTypeSelection = apmChartToDetectorTranslator.getSignalTypeSelection(
                filterAlias,
                apmMetricType
            );
            const environmentSelection = apmChartToDetectorTranslator.getEnvironmentSelection(
                filterAlias,
                chart
            );

            const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');

            const detectorName =
                detectorUtils.getDefaultApmDetectorName(apmMetricType, signalTypeSelection) ||
                getDefaultDetectorName(chart);
            const filters = apmChartToDetectorTranslator.getApmFiltersFromSourcesOverride();

            return detectorUtils.detectorNameModal(detectorName).then((name) => {
                const apmDetectorOptions = {
                    metricType: apmMetricType,
                    filters,
                    environmentSelection,
                };
                apmDetectorOptions.serviceEndpointSelection = signalTypeService.SERVICE_ENDPOINT[
                    'apmMetricGroup'
                ].includes(apmMetricType)
                    ? signalTypeSelection
                    : null;

                if (isApm2WorkflowsEnabled) {
                    apmDetectorOptions.businessWorkflowSelection = signalTypeService.WORKFLOW[
                        'apmMetricGroup'
                    ].includes(apmMetricType)
                        ? signalTypeSelection
                        : null;
                }

                const params = {
                    newRule: true,
                    detectorType: APM_V2,
                    detectorName: name,
                    apmDetectorOptions,
                    fromChart: chart,
                };
                return edit(params);
            });
        }
    },
]);
