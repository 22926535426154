import { useEffect, useState } from 'react';

import CrosslinkService from './crosslinkService';

// Replicates a combined logic of getCrossLinkAndSetHref and setHref in crossLinkAnchor.js as a custom hook
// so it can be reused in React components
export function useCrosslinkHref(dimensions, context) {
    const [href, setHref] = useState(null);
    const [title, setTitle] = useState(null);
    const [defaultNavigatorCode, setDefaultNavigatorCode] = useState(null);
    const [isCrossLinkInternal, setIsCrossLinkInternal] = useState(null);
    const crosslinkService = CrosslinkService.useInstance();

    useEffect(() => {
        if (dimensions) {
            const dimension = dimensions[0];
            const extendedContext = crosslinkService
                .getDataService()
                .getExtendedContext(dimensions, null, null, context);
            crosslinkService
                .getDataService()
                .getCrossLinkByTrigger(
                    dimension.propertyName,
                    dimension.propertyValue,
                    extendedContext
                )
                .then(({ link: crossLink, isInternal }) => {
                    setIsCrossLinkInternal(isInternal);
                    if (isInternal && crossLink && crossLink.defaultLink) {
                        const crosslinkUtils = crosslinkService.getUtils();

                        const redirectHref = crosslinkUtils.getRedirectHref(
                            crossLink,
                            dimension.propertyValue,
                            crossLink.defaultLink
                        );
                        setHref(redirectHref);

                        const linkTitle = crosslinkUtils.getCrossLinkTitle(crossLink.defaultLink);
                        const defaultNavigatorCode = crosslinkUtils.getDefaultNavigatorCrossLink(
                            crossLink.defaultLink
                        );
                        setDefaultNavigatorCode(defaultNavigatorCode);
                        setTitle(linkTitle);
                    } else if (!isInternal && crossLink) {
                        const url = crossLink.targets[0].url;
                        const title = crossLink.targets[0].name;

                        setHref(url);
                        setTitle(title);
                    }
                });
        }
    }, [crosslinkService, dimensions, context]);

    return { href, title, defaultNavigatorCode, isCrossLinkInternal };
}
