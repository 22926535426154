export const SPANS_METRIC = 'spans';
export const WORKFLOWS_METRIC = 'workflows';

export default [
    'APM_METRIC_TYPE',
    'featureEnabled',
    function (APM_METRIC_TYPE, featureEnabled) {
        const getAPMDimensions = function () {
            return {
                service: featureEnabled('detectorsApmOtelDimensions')
                    ? 'service.name'
                    : 'sf_service',
                operation: 'sf_operation',
                environment: featureEnabled('detectorsApmOtelDimensions')
                    ? 'deployment.environment'
                    : 'sf_environment',
                kind: 'sf_kind',
                error: 'sf_error',
                dimensionalized: 'sf_dimensionalized',
                workflow: 'sf_workflow',
                env_search_metric: 'spans',
            };
        };

        // metric
        const ERROR_RATE_METRIC = 'count';
        const LATENCY_METRIC = 'duration.ns';

        // service/endpoints
        const SERVICE_ENDPOINT = {
            name: 'SERVICE_ENDPOINT',
            errorRateMetric: `${SPANS_METRIC}.${ERROR_RATE_METRIC}`,
            latencyMetric: `${SPANS_METRIC}.${LATENCY_METRIC}`,
            histogramMetric: SPANS_METRIC,
            errorRateOption: {
                displayName: 'Service Error Rate',
                value: APM_METRIC_TYPE.SERVICE_ERRORS,
            },
            latencyOption: {
                displayName: 'Service Latency',
                value: APM_METRIC_TYPE.SERVICE_LATENCY,
            },
            percentileToMetrics: {
                99: new Set([`${SPANS_METRIC}.${LATENCY_METRIC}.p99`]),
                90: new Set([`${SPANS_METRIC}.${LATENCY_METRIC}.p90`]),
                50: new Set([`${SPANS_METRIC}.${LATENCY_METRIC}.median`]),
            },
            apmMetricGroup: [APM_METRIC_TYPE.SERVICE_ERRORS, APM_METRIC_TYPE.SERVICE_LATENCY],
            headingText: 'Service/Endpoint',
        };

        // business workflow
        const WORKFLOW = {
            name: 'WORKFLOW',
            errorRateMetric: `${WORKFLOWS_METRIC}.${ERROR_RATE_METRIC}`,
            latencyMetric: `${WORKFLOWS_METRIC}.${LATENCY_METRIC}`,
            histogramMetric: WORKFLOWS_METRIC,
            errorRateOption: {
                displayName: 'Workflow Error Rate',
                value: APM_METRIC_TYPE.WORKFLOW_ERROR_RATE,
            },
            latencyOption: {
                displayName: 'Workflow Duration',
                value: APM_METRIC_TYPE.WORKFLOW_LATENCY,
            },
            percentileToMetrics: {
                99: new Set([`${WORKFLOWS_METRIC}.${LATENCY_METRIC}.p99`]),
                90: new Set([`${WORKFLOWS_METRIC}.${LATENCY_METRIC}.p90`]),
                50: new Set([`${WORKFLOWS_METRIC}.${LATENCY_METRIC}.median`]),
            },
            apmMetricGroup: [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE, APM_METRIC_TYPE.WORKFLOW_LATENCY],
            headingText: 'Business Workflow',
        };

        const METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY = {
            [APM_METRIC_TYPE.SERVICE_ERRORS]: SERVICE_ENDPOINT,
            [APM_METRIC_TYPE.SERVICE_LATENCY]: SERVICE_ENDPOINT,
            [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE]: WORKFLOW,
            [APM_METRIC_TYPE.WORKFLOW_LATENCY]: WORKFLOW,
        };

        const METRIC_TYPE_TO_LABEL = {
            [APM_METRIC_TYPE.SERVICE_ERRORS]: SERVICE_ENDPOINT.errorRateOption.displayName,
            [APM_METRIC_TYPE.SERVICE_LATENCY]: SERVICE_ENDPOINT.latencyOption.displayName,
            [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE]: WORKFLOW.errorRateOption.displayName,
            [APM_METRIC_TYPE.WORKFLOW_LATENCY]: WORKFLOW.latencyOption.displayName,
        };

        const getSignalTypeEntityForMetricType = function (metricType) {
            if (
                !metricType ||
                typeof METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY[metricType] === 'undefined'
            ) {
                return SERVICE_ENDPOINT; // default to signal type: service/endpoint
            }

            return METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY[metricType];
        };

        return {
            getSignalTypeEntityForMetricType,
            getAPMDimensions,
            METRIC_TYPE_TO_LABEL,
            SERVICE_ENDPOINT,
            WORKFLOW,
        };
    },
];
