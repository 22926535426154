import React from 'react';
import {
    ReminderNotificationSettings as ReminderNotificationSettingsComponent,
    ReminderNotificationSettingsProps,
} from '@splunk/olly-common/ReminderNotification/ReminderNotificationSettings';
import { react2angular } from 'react2angular';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

export const ReminderNotificationSettings: React.FC<ReminderNotificationSettingsProps> = ({
    reminderNotification,
    onReminderNotificationChange,
}) => {
    const themeKey = useLegacyThemeServiceKey();
    return (
        <ThemeProvider colorScheme={themeKey}>
            <ReminderNotificationSettingsComponent
                reminderNotification={reminderNotification}
                onReminderNotificationChange={onReminderNotificationChange}
            ></ReminderNotificationSettingsComponent>
        </ThemeProvider>
    );
};

export const reminderNotificationSettings = react2angular(ReminderNotificationSettings, [
    'reminderNotification',
    'onReminderNotificationChange',
]);
