import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import DotsThreeHorizontal from '@splunk/react-icons/DotsThreeHorizontal';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledDotsThreeHorizontal = styled(DotsThreeHorizontal)`
    width: 20px;
    height: 20px;
    color: ${variables.contentColorDefault};
`;

export const DotsThreeHorizontalIcon: React.FC = () => {
    const themeKey = useLegacyThemeServiceKey();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledDotsThreeHorizontal />
        </ThemeProvider>
    );
};
