import templateUrl from './serviceEndpointSelector.tpl.html';
import { ENDPOINT_WILDCARD } from './serviceEndpointSelectorDataSource.js';

export default {
    templateUrl,
    bindings: {
        defaultSelection: '<',
        selectedDetectorType: '<',
        environment: '<',
        onSelect: '&',
        onRemove: '&',
        onFocus: '&',
        onBlur: '&',
        hideInferred: '<',
    },
    controller: [
        '_',
        '$element',
        '$timeout',
        'ServiceEndpointSelection',
        'serviceEndpointSelectorDataSource',
        function (
            _,
            $element,
            $timeout,
            ServiceEndpointSelection,
            serviceEndpointSelectorDataSource
        ) {
            const $ctrl = this;
            const SERVICE_ENDPOINT_SEPARATOR = ':';
            const SERVICE_TYPE = 'service';
            const ENDPOINT_TYPE = ($ctrl.endpointType = 'endpoint');
            const ON_BLUR_INPUT_TIMEOUT = 120;

            // initial variables
            $ctrl.endpointWildcard = ENDPOINT_WILDCARD;
            $ctrl.serviceToEndpointOptionsCache = new Map();
            $ctrl.serviceOptions = [];
            $ctrl.dropdownOptions = [];
            $ctrl.wildcardEntry = '';
            $ctrl.editMode = false;
            $ctrl.showDropdown = false;
            $ctrl.inMultiSelectContext = false;

            // lifecycle bindings
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            // blue pill related methods
            $ctrl.removeServiceEndpointSelection = removeServiceEndpointSelection;
            $ctrl.modifySelection = modifySelection;

            // input related methods
            $ctrl.isInputEmpty = isInputEmpty;
            $ctrl.onClickInput = onClickInput;
            $ctrl.onChangeInput = onChangeInput;
            $ctrl.onBlurInput = onBlurInput;
            $ctrl.getInputValues = getInputValues;

            // dropdown related methods
            $ctrl.optionFilter = optionFilter;
            $ctrl.selectOption = selectOption;
            $ctrl.onDropdownToggle = onDropdownToggle;
            $ctrl.disableEditMode = disableEditMode;

            function $onInit() {
                $ctrl.serviceEndpointSelection =
                    $ctrl.defaultSelection || new ServiceEndpointSelection();
                resetPlaceholder();
            }

            function $onChanges(changesObj) {
                const {
                    environment: environmentChange,
                    selectedDetectorType: selectedDetectorTypeChange,
                } = changesObj;

                if (environmentChange || selectedDetectorTypeChange) {
                    fetchAndUpdateServiceOptions();
                }
            }

            function removeServiceEndpointSelection() {
                $ctrl.onRemove({ serviceEndpointSelection: $ctrl.serviceEndpointSelection });
                $ctrl.serviceEndpointSelection.clear();
                resetInput();
                closeDropdown();
            }

            // blue pill related methods

            function modifySelection() {
                $ctrl.editMode = true;
                openDropdown();
            }

            // input related methods

            function isInputEmpty() {
                return !$ctrl.inputValue || $ctrl.inputValue === '';
            }

            function onClickInput() {
                if ($ctrl.serviceEndpointSelection.isEmpty()) {
                    $ctrl.placeholder = 'Select a service';
                }

                $ctrl.editMode = true;
                openDropdown();

                if ($ctrl.onFocus) $ctrl.onFocus();
            }

            function onChangeInput() {
                if (isInputEmpty()) {
                    handldInputIsCleared();
                    return;
                }

                if (!$ctrl.inputValue.includes(SERVICE_ENDPOINT_SEPARATOR)) {
                    updateServiceOptions();
                    return;
                }

                const hasPartialEndpoint = !$ctrl.inputValue.endsWith(SERVICE_ENDPOINT_SEPARATOR);
                if (hasPartialEndpoint) {
                    updateEndpointOptions();
                    return;
                }

                updateSelectionForCurrentInput();
            }

            function onBlurInput() {
                $timeout(() => {
                    if (!$ctrl.editMode && !$ctrl.showDropdown && $ctrl.onBlur) {
                        $ctrl.onBlur();
                    }
                }, ON_BLUR_INPUT_TIMEOUT);
            }

            function getInputValues() {
                return isInputEmpty()
                    ? ['', '']
                    : $ctrl.inputValue.split(SERVICE_ENDPOINT_SEPARATOR);
            }

            // dropdown related methods

            function optionFilter(option) {
                if (isInputEmpty()) return true;

                if (!$ctrl.inputValue.includes(SERVICE_ENDPOINT_SEPARATOR)) {
                    return option.value.toLowerCase().includes($ctrl.inputValue.toLowerCase());
                }

                const [, endpointInput] = getInputValues();
                return option.value.toLowerCase().includes(endpointInput.toLowerCase());
            }

            function selectOption(option, isMultiSelect) {
                if (isMultiSelect) $ctrl.inMultiSelectContext = true;

                switch (option.type) {
                    case SERVICE_TYPE:
                        selectService(option.value);
                        return;
                    case ENDPOINT_TYPE:
                        selectEndpoint(option.value);
                        return;
                }
            }

            function onDropdownToggle(open) {
                if (open) {
                    setInput();
                    setDropdownOptions();
                } else {
                    $ctrl.wildcardEntry = null;
                    $ctrl.inMultiSelectContext = false;
                    updateServiceEndpointSelection();
                }
            }

            function disableEditMode() {
                $ctrl.editMode = false;
                resetInput();
                closeDropdown();
            }

            // private methods

            function convertToOptions(list, type) {
                if (!angular.isArray(list)) return;

                return _.uniq(list).map((option) => {
                    return { type, value: option };
                });
            }

            function updateServiceEndpointSelection() {
                const selection = $ctrl.serviceEndpointSelection;

                if (selection.isComplete()) {
                    $ctrl.onSelect({ serviceEndpointSelection: selection });
                } else {
                    selection.clear();

                    if ($ctrl.onBlur) $ctrl.onBlur();
                }

                resetInput();
            }

            function selectService(selectedService) {
                $ctrl.serviceEndpointSelection.service = selectedService;
                $ctrl.inputValue = selectedService + SERVICE_ENDPOINT_SEPARATOR;
                setDropdownOptions();
            }

            function selectEndpoint(selectedEndpoint) {
                if (selectedEndpoint === ENDPOINT_WILDCARD) {
                    $ctrl.serviceEndpointSelection.endpoints = [ENDPOINT_WILDCARD];
                    disableEditMode();
                } else if (
                    !$ctrl.inMultiSelectContext &&
                    $ctrl.serviceEndpointSelection.endpoints.length <= 1
                ) {
                    $ctrl.serviceEndpointSelection.endpoints = [selectedEndpoint];
                    disableEditMode();
                } else {
                    $ctrl.serviceEndpointSelection.toggleEndpoint(selectedEndpoint);
                    if ($ctrl.serviceEndpointSelection.isEndpointSelected(ENDPOINT_WILDCARD)) {
                        $ctrl.serviceEndpointSelection.removeEndpoint(ENDPOINT_WILDCARD);
                    }
                    focusOnInputBox();
                }
            }

            function openDropdown() {
                setDropdownOptions();

                $timeout(() => {
                    focusOnInputBox();
                    $ctrl.showDropdown = true;
                }, 0);
            }

            function closeDropdown() {
                $ctrl.showDropdown = false;
            }

            function removeAllWildcardOptions() {
                if (!$ctrl.dropdownOptions) return;

                $ctrl.dropdownOptions = $ctrl.dropdownOptions.filter(
                    (option) => !isWildcardEntry(option.value)
                );
            }

            function addWildcardOptionToDropdownOptions(option) {
                if (!isWildcardEntry(option)) return;

                const isIncluded = $ctrl.dropdownOptions.some((o) => o.value === option);
                if (isIncluded) return;

                $ctrl.dropdownOptions.push({ type: ENDPOINT_TYPE, value: option });
            }

            function setDropdownOptions() {
                updateDropdownOptions();
                removeAllWildcardOptions();
                addWildcardOptionToDropdownOptions($ctrl.wildcardEntry);
                $ctrl.serviceEndpointSelection.endpoints.forEach((endpoint) =>
                    addWildcardOptionToDropdownOptions(endpoint)
                );
            }

            function setInput() {
                if ($ctrl.serviceEndpointSelection.isEmpty()) return;

                const selectedService = $ctrl.serviceEndpointSelection.service;
                $ctrl.inputValue = selectedService + SERVICE_ENDPOINT_SEPARATOR;
            }

            function resetInput() {
                $ctrl.inputValue = '';
                $ctrl.dropdownOptions = [];
                resetPlaceholder();
                $ctrl.editMode = false;
            }

            function isWildcardEntry(value) {
                if (!value || value === ENDPOINT_WILDCARD) return;

                const startOrEndWithWildcard =
                    value.startsWith(ENDPOINT_WILDCARD) || value.endsWith(ENDPOINT_WILDCARD);
                const hasOnlyOneWildcard = (value.match(/\*/g) || []).length === 1;

                return startOrEndWithWildcard && hasOnlyOneWildcard;
            }

            function handldInputIsCleared() {
                $ctrl.serviceEndpointSelection.clear();
                resetPlaceholder();
                setDropdownOptions();
            }

            function updateServiceOptions() {
                $ctrl.serviceEndpointSelection.clear();
                setDropdownOptions();
            }

            function updateEndpointOptions() {
                const [, endpointInput] = getInputValues();

                $ctrl.wildcardEntry = isWildcardEntry(endpointInput) ? endpointInput : null;

                setDropdownOptions();
            }

            function updateSelectionForCurrentInput() {
                const [serviceInput] = getInputValues();

                if (!$ctrl.serviceEndpointSelection.hasService()) {
                    $ctrl.serviceEndpointSelection.service = serviceInput;
                    setDropdownOptions();
                    return;
                }

                if (serviceInput !== $ctrl.serviceEndpointSelection.service) {
                    $ctrl.serviceEndpointSelection.clear();
                    $ctrl.inputValue = serviceInput;
                    setDropdownOptions();
                }
            }

            function resetPlaceholder() {
                $ctrl.placeholder = 'Select service and endpoint(s)';
            }

            function focusOnInputBox() {
                const inputBox = $element.find('.service-endpoint-selector-input')[0] || {};
                $timeout(() => {
                    inputBox.focus();
                }, 0);
            }

            function fetchAndUpdateServiceOptions() {
                $ctrl.loading = true;

                $ctrl.serviceToEndpointOptionsCache = new Map();
                serviceEndpointSelectorDataSource
                    .fetchServices($ctrl.hideInferred, $ctrl.environment)
                    .then((services) => {
                        $ctrl.serviceOptions = convertToOptions(services, SERVICE_TYPE);
                        setDropdownOptions();

                        $ctrl.loading = false;
                    });
            }

            function updateDropdownOptions() {
                if ($ctrl.serviceEndpointSelection.hasService()) {
                    const service = $ctrl.serviceEndpointSelection.service;

                    let optionsPromise;
                    $ctrl.loading = true;

                    if ($ctrl.serviceToEndpointOptionsCache.has(service)) {
                        optionsPromise = Promise.resolve(
                            $ctrl.serviceToEndpointOptionsCache.get(service)
                        );
                    } else {
                        optionsPromise = serviceEndpointSelectorDataSource
                            .fetchOperations($ctrl.environment, service)
                            .then((operations) => {
                                const endpointOptions = convertToOptions(operations, ENDPOINT_TYPE);

                                const service = $ctrl.serviceEndpointSelection.service;
                                $ctrl.serviceToEndpointOptionsCache.set(service, endpointOptions);

                                return endpointOptions;
                            });
                    }

                    optionsPromise.then((endpointOptions) => {
                        $ctrl.dropdownOptions = endpointOptions;
                        $ctrl.loading = false;
                    });
                } else {
                    $ctrl.dropdownOptions = $ctrl.serviceOptions;
                }
            }
        },
    ],
};
