import styled from 'styled-components';
import React, { useState } from 'react';
import Tooltip from '@splunk/react-ui/Tooltip';
import { AngularInjector } from '../../AngularUtils';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

type GlobalActionPasteChartsProps = {
    chartClips: Array<{ sf_chart?: string; name?: string }>;
    isDashboardGroupEmpty: boolean;
    isInDashboardPage: boolean;
    isReadOnly: boolean;
    onClick: () => void;
    pastingInProgress: boolean;
};

const ClipboardTooltipContent: React.FC<
    Pick<
        GlobalActionPasteChartsProps,
        'isDashboardGroupEmpty' | 'isInDashboardPage' | 'isReadOnly' | 'chartClips'
    >
> = ({ isDashboardGroupEmpty, isInDashboardPage, isReadOnly, chartClips }) => {
    if (isDashboardGroupEmpty) {
        return <div>Please create a dashboard before pasting charts</div>;
    }

    if (!isInDashboardPage) {
        return <div>Charts can be pasted when viewing a dashboard</div>;
    }

    if (isReadOnly) {
        return <div>Unable to paste into read-only dashboard</div>;
    }

    return (
        <div className="text-left">
            <div>Clipboard contains these charts:</div>
            <ul>
                {chartClips.map((chart, index) => (
                    <li key={`chart_${index}`}>{chart.sf_chart || chart.name || 'Untitled'}</li>
                ))}
            </ul>
            <div>Click to paste them into the current dashboard</div>
        </div>
    );
};

const StyledTooltip = styled(Tooltip)`
    width: 100%;
    height: 30px;
    margin-top: -5px;

    & > span {
        display: block;
        width: 100%;

        & > .sf-menu-item {
            padding: 8px 15px;

            &:hover,
            &:focus {
                font-weight: 600;
            }
        }
    }

    .sf-ui.dark & > span > .sf-menu-item {
        &:focus {
            background-color: #555;
        }
    }
`;

export const GlobalActionPasteCharts: React.FC<GlobalActionPasteChartsProps> = ({
    chartClips,
    isDashboardGroupEmpty,
    isInDashboardPage,
    isReadOnly,
    onClick,
    pastingInProgress,
}) => {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const themeKey = useLegacyThemeServiceKey();
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const clipsLength = chartClips.length;
    const disabled = isDashboardGroupEmpty || isReadOnly || !isInDashboardPage || !clipsLength;

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledTooltip
                contentRelationship="description"
                content={
                    <ClipboardTooltipContent
                        isDashboardGroupEmpty={isDashboardGroupEmpty}
                        isInDashboardPage={isInDashboardPage}
                        isReadOnly={isReadOnly}
                        chartClips={chartClips}
                    />
                }
                defaultPlacement="left"
                open={isTooltipOpen}
                onRequestOpen={(): void =>
                    clipsLength && !pastingInProgress ? setIsTooltipOpen(true) : undefined
                }
                onRequestClose={(): void => setIsTooltipOpen(false)}
            >
                <a
                    data-test="global-create-paste-charts"
                    onClick={(event): void => {
                        event.preventDefault();
                        userAnalytics.event('click', 'global-create-paste-charts');
                        onClick();
                    }}
                    href=""
                    className={`sf-menu-item ${disabled ? 'disabled' : ''}`}
                    tabIndex={disabled ? -1 : 0}
                >
                    {clipsLength > 0 && <span className="red-badge pull-right">{clipsLength}</span>}
                    <span>Paste charts</span>
                </a>
            </StyledTooltip>
        </ThemeProvider>
    );
};
