/* How to add a new sitemap:
 * 1. Create an object including every ID to be used in the sitemap
 * 2. Create the sitemap
 * 3. Export a const with the new data
 */
import { SiteMapEntry } from '@splunk/olly-common/Nav';

const homeTeamIds = { home: 'home', teamName: 'teamName', pageSetup: 'pageSetup' };

const organizationPreferencesIds = { organizationPreferences: 'organizationPreferences' };

const organizationUsageIds = { organizationUsage: 'organizationUsage' };

const organizationCrossLinkIds = { organizationCrossLink: 'organizationCrossLink' };

const dashboardIds = {
    chartName: 'chartName',
    dashboardGroup: 'dashboardGroup',
    dashboardName: 'dashboardName',
    dashboards: 'dashboards',
    newChart: 'newChart',
    newDashboard: 'newDashboard',
    teamName: 'teamName',
};

const metricMetadataIds = {
    metricMetadata: 'metricMetadata',
};

const metricFinderIds = { metricFinder: 'metricFinder' };

const monitoringIds = {
    monitoring: 'monitoring',
    alertLogs: 'alertLogs',
    alerts: 'alerts',
    bounces: 'bounces',
    detectors: 'detectors',
    editDetector: 'editDetector',
    mutingRules: 'mutingRules',
    templates: 'templates',
};

const dashboards: SiteMapEntry = {
    id: dashboardIds.dashboards,
    label: 'Dashboards',
    path: '/dashboards',
    children: [
        { id: dashboardIds.teamName, label: 'Team Name' },
        {
            id: dashboardIds.dashboardGroup,
            children: [
                {
                    id: dashboardIds.dashboardName,
                    children: [{ id: dashboardIds.chartName, label: 'New chart' }],
                },
            ],
        },
        { id: dashboardIds.newChart, label: 'New chart', isHistoricalPath: true },
        { id: dashboardIds.newDashboard, label: 'New dashboard', isHistoricalPath: true },
    ],
};

const homeTeam: SiteMapEntry = {
    id: homeTeamIds.home,
    label: 'Home',
    path: '/team',
    browserTitle: 'Splunk - Home',
    children: [
        {
            id: homeTeamIds.teamName,
            label: 'label',
            children: [
                {
                    id: homeTeamIds.pageSetup,
                    label: 'Team page setup',
                },
            ],
        },
    ],
};

const organizationPreferences: SiteMapEntry = {
    id: organizationPreferencesIds.organizationPreferences,
    label: 'General Settings',
    path: '/organization/current?selectedKeyValue=sf_section:settings',
};

const organizationUsage: SiteMapEntry = {
    id: organizationUsageIds.organizationUsage,
    label: 'Subscription Usage',
    path: '/organization/current?selectedKeyValue=sf_section:immusage',
};

const organizationCrossLink: SiteMapEntry = {
    id: organizationCrossLinkIds.organizationCrossLink,
    label: 'Global Data Links',
    path: '/organization/current?selectedKeyValue=sf_section:immusage',
};

const metricFinder: SiteMapEntry = {
    id: metricFinderIds.metricFinder,
    label: 'Metric finder',
    path: '/metrics',
};

const monitoring: SiteMapEntry = {
    id: monitoringIds.monitoring,
    label: 'Detectors & SLOs',
    path: '/alerts',
    children: [
        { id: monitoringIds.alerts, label: 'Active alerts' },
        {
            id: monitoringIds.detectors,
            label: 'Detectors',
            children: [{ id: monitoringIds.editDetector }],
        },
        { id: monitoringIds.templates, label: 'Templates' },
        { id: monitoringIds.mutingRules, label: 'Muting rules' },
        { id: monitoringIds.bounces, label: 'Bounces' },
    ],
};

const metricMetadata: SiteMapEntry = {
    id: metricMetadataIds.metricMetadata,
    label: 'Metric Metadata',
    isHistoricalPath: true,
};

export const Dashboards = { name: 'Dashboards', map: dashboards, IDs: dashboardIds };
export const MetricFinder = { name: 'MetricFinder', map: metricFinder, IDs: metricFinderIds };
export const Monitoring = { name: 'Monitoring', map: monitoring, IDs: monitoringIds };
export const MetricMetadata = {
    name: 'Metric Metadata',
    map: metricMetadata,
    IDs: metricMetadataIds,
};
export const HomeTeam = { name: 'Home Team', map: homeTeam, IDs: homeTeamIds };
export const OrganizationPreferences = {
    name: 'Organization Preferences',
    map: organizationPreferences,
    IDs: organizationPreferencesIds,
};
export const OrganizationUsage = {
    name: 'Organization Usage',
    map: organizationUsage,
    IDs: organizationUsageIds,
};
export const OrganizationCrossLink = {
    name: 'Organization Cross Link',
    map: organizationCrossLink,
    IDs: organizationCrossLinkIds,
};
