import React, { useState, useRef, useEffect } from 'react';
import { useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';
import useFetchAppDShowOnServices from './useFetchAppDShowOnServices';
import Magnifier from '@splunk/react-icons/Magnifier';
import * as Styled from './AppdShowOnOption.styles';

const AppdShowOnOption = ({
    preselectAppdService,
    appdServiceName,
    onUpdateAppdShowOnOption,
    onAppdShowonFocus,
    onAppdShowonBlur,
    definition,
}) => {
    const { isLoading, services } = useFetchAppDShowOnServices();
    const colorScheme = useLegacyThemeServiceKey();
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedOption, setSelectedOption] = useState(
        appdServiceName ? appdServiceName : 'Select an inferred service'
    );
    const dropdownRef = useRef(null);
    useEffect(() => {
        if (!preselectAppdService && appdServiceName) {
            onUpdateAppdShowOnOption(appdServiceName);
        }
    }, [appdServiceName, onUpdateAppdShowOnOption, preselectAppdService]);

    useEffect(() => {
        if (definition.propertyValue) {
            setSelectedOption(definition.propertyValue);
        }
    }, [definition]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onUpdateAppdShowOnOption(option);
    };

    const onSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const filteredOptions = services.filter(
        (option) => option.toLowerCase().includes(searchText.toLowerCase()) // Case-insensitive search
    );

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <Styled.StyledSelectorContainer isLoading={isLoading}>
            <div
                className="flex-neutral"
                style={{
                    position: 'relative',
                    display: 'inline-block',
                }}
            >
                <div
                    className="sfmenuright sf-list-menu-wrapper"
                    tabIndex="0"
                    onFocus={onAppdShowonFocus}
                    onBlur={onAppdShowonBlur}
                >
                    <Styled.StyledSelecter
                        colorScheme={colorScheme}
                        tabIndex="0"
                        isLoading={isLoading}
                        aria-haspopup="listbox"
                        aria-labelledby="show-on-dropdown"
                        className="clickable form-control dropdown-toggle cross-btn"
                        onClick={toggleDropdown}
                    >
                        {selectedOption}
                        <i className="icon-chevrondown sfx-form-row-icons sfx-form-right-icon"></i>
                    </Styled.StyledSelecter>
                </div>
            </div>
            {isOpen && (
                <Styled.StyledMenuWrapper ref={dropdownRef} colorScheme={colorScheme}>
                    {!isLoading && (
                        <>
                            <Styled.StyledSearchWrapper>
                                <Styled.StyledIconWrapper>
                                    <Magnifier />
                                </Styled.StyledIconWrapper>

                                <Styled.StyledSearchInput
                                    colorScheme={colorScheme}
                                    type="text"
                                    placeholder="Filter inferred service"
                                    value={searchText}
                                    onChange={(e) => onSearchChange(e)}
                                />
                            </Styled.StyledSearchWrapper>

                            <Styled.StyledMenu className="sf-menu-items smaller" role="menu">
                                {filteredOptions.length === 0 && (
                                    <div className="cross-options-style">
                                        No matching service found.
                                    </div>
                                )}
                                {filteredOptions.length > 0 &&
                                    filteredOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleOptionClick(option)}
                                            className="cross-options-style"
                                        >
                                            {option}
                                        </div>
                                    ))}
                            </Styled.StyledMenu>
                        </>
                    )}
                </Styled.StyledMenuWrapper>
            )}
            {isLoading && <div className="busy-spinner-dark"></div>}
        </Styled.StyledSelectorContainer>
    );
};

export default AppdShowOnOption;
