import { SPANS_METRIC } from '../../../app/detector/signalTypeService';

// it is assumed here that there will never be more than 10k results
// returned by a query for services or endpoints
const REASONABLE_INFINITY = 10000;

export const ENDPOINT_WILDCARD = '*';

serviceEndpointSelectorDataSourceFactory.$inject = [
    'traceAPIService',
    'suggestAPIService',
    'signalTypeService',
];
export default function serviceEndpointSelectorDataSourceFactory(
    traceAPIService,
    suggestAPIService,
    signalTypeService
) {
    return {
        fetchServices,
        fetchOperations,
    };

    function fetchServices(hideInferred, environment) {
        const query = buildQueryObject(
            signalTypeService.getAPMDimensions().service,
            buildFilters(environment)
        );
        return suggestAPIService.getSignalFlowSuggest(query);
    }

    function fetchOperations(environment, service) {
        const query = buildQueryObject(
            signalTypeService.getAPMDimensions().operation,
            buildFilters(environment, service)
        );
        return suggestAPIService
            .getSignalFlowSuggest(query)
            .then((response) => [ENDPOINT_WILDCARD, ...response]);
    }

    function buildQueryObject(property, filters) {
        return {
            property,
            limit: REASONABLE_INFINITY,
            additionalFilters: filters,
        };
    }

    function buildFilters(environment, service) {
        const filters = [
            { property: signalTypeService.getAPMDimensions().kind, values: ['SERVER', 'CONSUMER'] },
            { property: 'sf_metric', values: [SPANS_METRIC] },
        ];

        if (!!environment) {
            filters.push({
                property: signalTypeService.getAPMDimensions().environment,
                values: [environment],
            });
        }

        if (!!service) {
            filters.push({
                property: signalTypeService.getAPMDimensions().service,
                values: [service],
            });
        }

        return filters;
    }
}
