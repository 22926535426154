import React from 'react';
import { AuthStore } from '@splunk/olly-services/lib/services/Auth/AuthStore';
import { ApolloContextProvider } from './ApolloContextProvider';
import AppdShowOnOption from './AppdShowOnOption';
import { API_URL } from '../../common/consts';

const AppdShowOnWrapper = ({
    preselectAppdService,
    appdServiceName,
    onUpdateAppdShowOnOption,
    onAppdShowonFocus,
    onAppdShowonBlur,
    definition,
}) => {
    const auth = AuthStore(true, API_URL);
    return (
        <>
            <ApolloContextProvider auth={auth}>
                <AppdShowOnOption
                    preselectAppdService={preselectAppdService}
                    appdServiceName={appdServiceName}
                    onUpdateAppdShowOnOption={onUpdateAppdShowOnOption}
                    onAppdShowonFocus={onAppdShowonFocus}
                    onAppdShowonBlur={onAppdShowonBlur}
                    definition={definition}
                />
            </ApolloContextProvider>
        </>
    );
};
export default AppdShowOnWrapper;
