import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './detectorEditor.tpl.html';
import detectorInvalidRuleNamesTemplateUrl from './detectorInvalidRuleNames.tpl.html';
import detectorNameModalTemplateUrl from './signal/detectorNameModal.tpl.html';
import renderThrottleMessageDetectorTemplateUrl from '../charting/chartbuilder/renderThrottleMessageDetector.tpl.html';
import jobFetchCapsMessageTemplateUrl from '../charting/chartbuilder/jobFetchCapsMessage.tpl.html';
import prefilghtInfoPanelTemplateUrl from './preflightInfoPanel.tpl.html';
import detectorSettingsPanelTemplateUrl from './detectorSettingsPanel.tpl.html';
import detectorSaveTooltipTemplateUrl from '../tooltips/detectorSaveTooltip.tpl.html';
import { convertStringToMS, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { ngRoute } from '../../../app/routing/ngRoute';
import qs from 'query-string';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { NO_CAPABILITIES_TOOLTIP } from '../../common/data/rbac/common';
import { NO_PERMISSIONS_TOOLTIP } from '../../common/data/util/writepermissionsPermissionsChecker';

angular.module('signalview.detector').directive('detectorEditor', [
    function () {
        return {
            restrict: 'E',
            scope: {
                detector: '=',
                reloadMutings: '=',
                goBack: '=',
            },
            templateUrl,
            controller: [
                'featureEnabled',
                'detectorVersionService',
                'resolutionToChartRangeService',
                'detectorWizardModal',
                '$scope',
                '$log',
                '$http',
                '$timeout',
                'sfxModal',
                '$q',
                'analyticsService',
                'chartbuilderUtil',
                '$location',
                'urlOverridesService',
                'instrumentationService',
                '$window',
                'signalviewMetrics',
                'chartV2Service',
                'userAnalytics',
                'detectorUtils',
                'sidebarService',
                'programTextUtils',
                'timepickerUtils',
                'plotUtils',
                'alertMessageService',
                'alertTypeService',
                'CHART_DISPLAY_EVENTS',
                'DETECTOR_CHART_IDENTIFIERS',
                'ChartDisplayDebounceService',
                'hasCapability',
                'writepermissionsPermissionsChecker',
                'deleteDetector',
                'showSignalFlowModal',
                'detectorSettingsModal',
                'teamLinkingService',
                'mutingService',
                'API_URL',
                function (
                    featureEnabled,
                    detectorVersionService,
                    resolutionToChartRangeService,
                    detectorWizardModal,
                    $scope,
                    $log,
                    $http,
                    $timeout,
                    sfxModal,
                    $q,
                    analyticsService,
                    chartbuilderUtil,
                    $location,
                    urlOverridesService,
                    instrumentationService,
                    $window,
                    metrics,
                    chartV2Service,
                    userAnalytics,
                    detectorUtils,
                    sidebarService,
                    programTextUtils,
                    timepickerUtils,
                    plotUtils,
                    alertMessageService,
                    alertTypeService,
                    CHART_DISPLAY_EVENTS,
                    DETECTOR_CHART_IDENTIFIERS,
                    ChartDisplayDebounceService,
                    hasCapability,
                    writepermissionsPermissionsChecker,
                    deleteDetector,
                    showSignalFlowModal,
                    detectorSettingsModal,
                    teamLinkingService,
                    mutingService,
                    API_URL
                ) {
                    let teamsToLink = [];
                    let teamsToUnlink = [];
                    let periodicUpdater;
                    const UPDATE_INTERVAL = 10 * 1000;
                    const ALERTS_COUNT_EXPIRATION = 10 * 1000;
                    const ZERO_ID = 'AAAAAAAAAAA';
                    const jobFeedback = {};
                    jobFeedback[DETECTOR_CHART_IDENTIFIERS.MAIN] = [];
                    jobFeedback[DETECTOR_CHART_IDENTIFIERS.NATIVE] = [];

                    sidebarService.showSidebar = false;
                    $scope.detectorSaveTooltipTemplateUrl = detectorSaveTooltipTemplateUrl;
                    $scope.renderThrottleMessageDetectorTemplateUrl =
                        renderThrottleMessageDetectorTemplateUrl;
                    $scope.prefilghtInfoPanelTemplateUrl = prefilghtInfoPanelTemplateUrl;
                    $scope.jobFetchCapsMessageTemplateUrl = jobFetchCapsMessageTemplateUrl;
                    $scope.detectorSettingsPanelTemplateUrl = detectorSettingsPanelTemplateUrl;
                    $scope.isLocked = $scope.detector.sf_isDpmDetector;
                    $scope.cloneUrl = `#/detector${
                        $scope.detector.sf_modelVersion === 2 ? '/v2' : ''
                    }/new?fromDetector=${$scope.detector.sf_id}`;
                    $scope.detectorChartIdentifiers = DETECTOR_CHART_IDENTIFIERS;
                    $scope.jobFeedback = jobFeedback;

                    $scope.deleteModalState = {
                        isOpen: false,
                        onCancel: function () {
                            $timeout(function () {
                                $scope.deleteModalState.isOpen = false;
                            }, 0);
                        },
                    };

                    hasCapability(Capability.CREATE_DETECTOR).then(
                        (hasCreateDetectorCapability) =>
                            ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
                    );

                    const signalResolution = detectorUtils.signalResolution();

                    if (
                        $scope.detector &&
                        $scope.detector.sf_uiModel &&
                        $scope.detector.sf_uiModel.chartconfig &&
                        !angular.isDefined($scope.detector.sf_uiModel.chartconfig.pointDensity)
                    ) {
                        $scope.detector.sf_uiModel.chartconfig.pointDensity = '';
                    }
                    let model, uiModel;

                    function updateModel() {
                        model = $scope.model = $scope.detector;
                        uiModel = $scope.uiModel = $scope.model.sf_uiModel;
                    }

                    updateModel();

                    async function updateLocalPermissionState() {
                        $scope.hasDeleteDetectorCapability = await hasCapability(
                            Capability.DELETE_DETECTOR
                        );
                        $scope.hasUpdateDetectorCapability = await hasCapability(
                            Capability.UPDATE_DETECTOR
                        );
                        $scope.hasCreateMutingRuleCapability = await hasCapability(
                            Capability.CREATE_MUTING_RULE
                        );
                        $scope.hasWritePermission =
                            await writepermissionsPermissionsChecker.hasWritePermissions(model);
                    }

                    updateLocalPermissionState();

                    $scope.getDisableTooltipText = () => {
                        if (!$scope.hasUpdateDetectorCapability) {
                            return NO_CAPABILITIES_TOOLTIP;
                        } else if (!$scope.hasWritePermission) {
                            return NO_PERMISSIONS_TOOLTIP;
                        }
                    };

                    $scope.chartDisplayDebouncer = new ChartDisplayDebounceService();
                    $scope.chartDisplayDebouncer.setEnabled(!featureEnabled('detector wizard'));

                    const chartconfig = $scope.detector.sf_uiModel.chartconfig;

                    $scope.timezone = {
                        callback: (toSave) => {
                            $scope.detector.sf_timezone = toSave;
                            userAnalytics.event('timezone-changed', 'v1-detector-options' + toSave);
                        },
                    };
                    $scope.timezone.query = chartconfig.timezone || '';

                    const range = timepickerUtils.getChartConfigURLTimeParameters();

                    // doesn't make sense to use absolute time range in detector view.
                    delete chartconfig.absoluteEnd;
                    delete chartconfig.absoluteStart;

                    if (
                        !chartconfig.absoluteStart &&
                        !chartconfig.absoluteEnd &&
                        !chartconfig.range &&
                        !range
                    ) {
                        const labelResolutionKeys = Object.keys(
                            $scope.model.sf_labelResolutions || {}
                        );
                        if (labelResolutionKeys.length) {
                            const resolution =
                                $scope.model.sf_labelResolutions[labelResolutionKeys[0]];
                            chartconfig.range = convertStringToMS(
                                resolutionToChartRangeService(resolution)
                            );
                        } else {
                            chartconfig.range = -15 * 60 * 1000; // 15 minutes
                        }

                        chartconfig.rangeEnd = 0;
                    }

                    const appliedTimeConfig = angular.extend({}, chartconfig, range);

                    const startViewTime = urlOverridesService.getViewTime();
                    if (startViewTime && appliedTimeConfig.range) {
                        // if there's a view (pinned) time and big chart is doing relative time, we should update big chart to absolute time
                        const timeRange = Math.abs(appliedTimeConfig.range);
                        const half = Math.floor(timeRange / 2);
                        urlOverridesService.setGlobalTimePicker(
                            startViewTime - half,
                            Math.min(startViewTime + half, Date.now()),
                            false
                        );
                    }

                    $scope.initRange = appliedTimeConfig;

                    $scope.altMode = false;

                    $scope.$on(
                        'plot alt mode trigger',
                        function (evt, altModeDisplayedKey, altModeOn = false) {
                            $scope.altMode = altModeOn;
                            $scope.$broadcast(
                                'plot alt mode apply',
                                altModeDisplayedKey,
                                $scope.altMode
                            );
                        }
                    );

                    $scope.$on(CHART_DISPLAY_EVENTS.RENDER_STATS, function (evt, pl) {
                        $scope.renderStats = pl;
                    });

                    $scope.$on(CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS, function (evt, pl) {
                        $scope.jobFetchCaps = pl;
                    });

                    $scope.$on(
                        CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                        function (evt, chartWindowMisaligned, identifier) {
                            if (identifier === 'main') {
                                $scope.misalignedResolution = chartWindowMisaligned;
                            }
                        }
                    );

                    $scope.$on('setCalendarWindowCycleLength', function (evt, cycleName) {
                        const chartConfigTime = $scope.detector.sf_uiModel.chartconfig;
                        const optimizedCalendarWindow = detectorUtils.getOptimizedCalendarWindow(
                            cycleName,
                            chartConfigTime
                        );

                        if (optimizedCalendarWindow) {
                            $scope.detector.sf_uiModel.chartconfig.range =
                                optimizedCalendarWindow.optimalRangeInMs;
                            // Optimize the current chart window
                            $scope.$broadcast(
                                'setTimePicker',
                                optimizedCalendarWindow.optimalRange
                            );
                        }
                    });

                    const plots_tab = {
                        id: 'plots',
                        name: 'Signals',
                        disabled: $scope.isLocked,
                    };
                    $scope.rules_tab = {
                        id: 'rules',
                        name: 'Alert Rules',
                        showTooltip: false,
                        tooltipClass: 'alert-rule-tab-tooltip',
                    };
                    const options_tab = {
                        id: 'options',
                        name: 'Options',
                    };
                    const data_table_tab = {
                        id: 'data',
                        name: 'Data Table',
                    };
                    const events_tab = {
                        id: 'events',
                        name: 'Events',
                    };
                    const disabledWhenNoSignalTabs = [
                        $scope.rules_tab,
                        options_tab,
                        data_table_tab,
                        events_tab,
                    ];

                    function initializeSectionTabs() {
                        // check if it's an apm detector
                        const rules = safeLookup($scope, 'detector.sf_uiModel.rules');
                        if (detectorUtils.hasValidApmV2Rule(rules)) {
                            // for apm detector, the 'SIGNALS' tab will NOT be shown
                            $scope.sectionTabs = [
                                $scope.rules_tab,
                                options_tab,
                                data_table_tab,
                                events_tab,
                            ];
                        } else {
                            $scope.sectionTabs = [
                                $scope.rules_tab,
                                plots_tab,
                                options_tab,
                                data_table_tab,
                                events_tab,
                            ];
                        }
                    }

                    initializeSectionTabs();

                    $scope.goToRulesTab = function () {
                        $scope.$broadcast('go to tab', 'rules');
                    };

                    $scope.$on(CHART_DISPLAY_EVENTS.SELECT_TAB, function (evt, tabId) {
                        $scope.$broadcast('go to tab', tabId);
                    });

                    $scope.$on(CHART_DISPLAY_EVENTS.SELECT_PREVIOUS_TAB, function () {
                        $scope.$broadcast('go to previous tab');
                    });

                    function updateRuleTabTooltip() {
                        if ($scope.ruleConditionOpened) {
                            if ($scope.sharedChartState.currentTabId === $scope.rules_tab.id) {
                                $scope.rules_tab.showTooltip = true;
                                $scope.rules_tab.warning = false;
                                $scope.rules_tab.tooltip =
                                    'You must complete your alert condition before saving detector.';
                            } else {
                                $scope.rules_tab.showTooltip = true;
                                $scope.rules_tab.warning = true;
                                $scope.rules_tab.tooltip = 'Alert condition not yet completed.';
                            }
                        } else {
                            $scope.rules_tab.showTooltip = false;
                            $scope.rules_tab.warning = false;
                        }
                    }

                    $scope.sharedChartState = {};
                    $scope.$on('tab selected', function (ev, tabId) {
                        $scope.sharedChartState.currentTabId = tabId;

                        updateRuleTabTooltip();

                        if (tabId === plots_tab.id) {
                            $scope.$emit('signalEditorOpened');
                        } else {
                            if (tabId === $scope.rules_tab.id) {
                                // unhighlight in case the was clicked and mouseout never triggered
                                $scope.rules_tab.highlight = false;
                            } else if (tabId === data_table_tab.id) {
                                $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'data');
                            } else if (tabId === events_tab.id) {
                                $scope.$broadcast(
                                    CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED,
                                    'event'
                                );
                            }

                            $scope.$emit('signalEditorClosed');
                            detectorUtils.updateDetectorValidityState(
                                uiModel.rules,
                                uiModel.allPlots
                            );
                        }
                    });

                    $scope.legendOrOptionsTabActive = function () {
                        return (
                            $scope.sharedChartState.currentTabId === data_table_tab.id ||
                            $scope.sharedChartState.currentTabId === events_tab.id ||
                            $scope.sharedChartState.currentTabId === options_tab.id
                        );
                    };

                    $scope.getSignalResolutionPromise = function (plotLabel) {
                        return signalResolution(plotLabel, $scope.model.sf_uiModel.allPlots);
                    };

                    function updatePreviewState() {
                        if ($scope.rulePreview.selectedRule > -1) {
                            const rule =
                                $scope.detector.sf_uiModel.rules[$scope.rulePreview.selectedRule];
                            if (!rule.invalid) {
                                $scope.$broadcast('rule publish preview', rule);
                            }
                        } else {
                            $scope.$broadcast('rule publish preview');
                        }
                    }

                    $scope.rulePreview = {
                        selectedRule: -1,
                    };

                    $scope.toggleSelectedRule = function (index) {
                        userAnalytics.event('click', 'detector-rule-visible-click');
                        if ($scope.rulePreview.selectedRule === index) {
                            $scope.rulePreview.selectedRule = -1;
                        } else {
                            $scope.rulePreview.selectedRule = index;
                        }
                    };

                    if (model.sf_uiModel.allPlots.length > 1 || $scope.isLocked) {
                        $scope.toSelectTab = $scope.rules_tab;
                    }

                    $scope.$watch('rulePreview.selectedRule', updatePreviewState);

                    function updateRules() {
                        model.sf_uiModel.rules.forEach(function (rule) {
                            rule.readable = detectorUtils.getAutoDetectorRuleDescription(
                                rule,
                                model.sf_uiModel.allPlots,
                                true
                            );
                        });
                    }

                    updateRules();
                    $scope.$on('changed plot name', updateRules);

                    $scope.hasUnsavedChanges = model.sf_id === undefined;
                    $scope.fetchActiveAlertsCount = fetchActiveAlertsCount;

                    const ruleDetails = {};

                    function getActiveRules() {
                        const rules =
                            model.sf_modelVersion === 2
                                ? model.sf_uiModel?.rules
                                : $scope.model.sf_rules;
                        if (!rules) return [];

                        return rules.filter(function (rule) {
                            return !rule.invalid && !rule.disabled;
                        });
                    }

                    function getEventType(rule) {
                        const flow2 = detectorUtils.isFlow2($scope.model);
                        if (flow2) {
                            const notificationId = $scope.model.sf_notificationId;
                            if (notificationId && notificationId !== ZERO_ID) {
                                return (
                                    $scope.model.sf_notificationId +
                                    '__' +
                                    $scope.model.sf_id +
                                    '__' +
                                    rule.detectLabel
                                );
                            }
                            return $scope.model.sf_id + '__' + rule.detectLabel;
                        }
                        return rule.eventType;
                    }

                    function fetchActiveAlertsCount(rule) {
                        updateActiveAlerts([rule], false);
                    }

                    function getRuleDetailsId(name) {
                        const ruleNamePrefix =
                            $scope.model.sf_modelVersion === 2 ? $scope.model.sf_id + '__' : '';
                        return ruleNamePrefix + name;
                    }

                    function getRelatedRuleDetails(name) {
                        return (ruleDetails[getRuleDetailsId(name)] = {});
                    }

                    function resetActiveAlertsCount(rules) {
                        rules.forEach((rule) => {
                            ruleDetails[getRuleDetailsId(rule.name)] = {
                                ...ruleDetails[getRuleDetailsId(rule.name)],
                                outdated: true,
                            };
                        });
                    }

                    function updateLoadingState(names, isLoading = true) {
                        names.forEach((ruleName) => {
                            ruleDetails[getRuleDetailsId(ruleName)] = {
                                ...ruleDetails[getRuleDetailsId(ruleName)],
                                isLoadingAlertsCount: isLoading,
                            };
                        });
                    }

                    function processAggregation(aggregation) {
                        const rule = getRelatedRuleDetails(aggregation.name);
                        rule.total = aggregation.count;
                        rule.active = 0;
                        rule.id = aggregation.name;
                        rule.isLoadingAlertsCount = false;
                        rule.lastRefresh = Date.now();

                        if (aggregation.aggregations) {
                            rule.active = aggregation.aggregations.reduce(function (prev, current) {
                                if (alertTypeService.isClearingEvent(current.name)) return prev;
                                return prev + current.count;
                            }, 0);
                        }
                    }

                    function updateActiveAlerts(specificRules = [], autoUpdate = true) {
                        const rules = specificRules.length ? specificRules : getActiveRules();
                        let queryNames;
                        let queryTerm;
                        if ($scope.model.sf_modelVersion === 2) {
                            const ruleNames = rules
                                .filter((rule) => rule.name)
                                .map((rule) => rule.name);
                            if (ruleNames.length) {
                                queryTerm = 'sf_detectLabel';
                                queryNames = ruleNames;
                            }
                        } else {
                            const eventTypes = rules.map((rule) => getEventType(rule));
                            if (eventTypes.length) {
                                queryTerm = 'sf_eventType';
                                queryNames = eventTypes;
                            }
                        }

                        if (queryNames) {
                            // there is a hard-coded limit on BE which is up to 10 aggregations per single request
                            const limitedQueryNames = queryNames.slice(0, 10);
                            updateLoadingState(limitedQueryNames);

                            $http
                                .post(API_URL + '/v2/eventtimeseries/_/aggregate', {
                                    query:
                                        'sf_detectorId:' +
                                        $scope.model.sf_id +
                                        ' AND sf_detectLabel:(' +
                                        sanitizeTerm(limitedQueryNames) +
                                        ' AND (NOT sf_archived:true)' +
                                        ')',
                                    aggregateOnFields: [queryTerm, 'sf_anomalyState'],
                                })
                                .then((res) => res.data)
                                .then(function (res) {
                                    updateLoadingState(limitedQueryNames, false);

                                    if (autoUpdate) {
                                        periodicUpdater = $timeout(
                                            updateActiveAlerts,
                                            UPDATE_INTERVAL
                                        );
                                    } else {
                                        $timeout(
                                            () => resetActiveAlertsCount(rules),
                                            ALERTS_COUNT_EXPIRATION
                                        );
                                    }

                                    limitedQueryNames.forEach((name) => {
                                        const result = res.aggregations?.find(
                                            (aggregation) => aggregation.name === name
                                        );
                                        // if there's no alerts in API response general count is equal 0 w/o aggregations by detectLabel
                                        processAggregation(
                                            result || { count: 0, name, aggregations: [] }
                                        );
                                    });
                                });
                        } else {
                            if (autoUpdate) {
                                periodicUpdater = $timeout(updateActiveAlerts, UPDATE_INTERVAL);
                            } else {
                                $timeout(
                                    () => resetActiveAlertsCount(rules),
                                    ALERTS_COUNT_EXPIRATION
                                );
                            }
                        }
                    }

                    updateActiveAlerts();

                    $scope.getRuleDetails = function (r) {
                        const rules =
                            model.sf_modelVersion === 2 ? model.sf_uiModel?.rules : model.sf_rules;
                        if (!rules) return;

                        if (detectorUtils.isFlow2($scope.model)) {
                            const notificationId = $scope.model.sf_notificationId;
                            if (notificationId && notificationId !== ZERO_ID) {
                                return ruleDetails[
                                    $scope.model.sf_notificationId +
                                        '__' +
                                        $scope.model.sf_id +
                                        '__' +
                                        r.name
                                ];
                            }
                            return ruleDetails[$scope.model.sf_id + '__' + r.name];
                        } else {
                            // This exploits the way that event type names are generated.
                            // This should be more deterministic, but currently eventtypes don't have
                            // a way to reference a rule directly.
                            const rule = model.sf_rules.filter(function (rule) {
                                const eventType = getEventType(rule);
                                return (eventType || '').indexOf('_' + r.uniqueKey + '_') !== -1;
                            })[0];

                            if (!rule) return;

                            return ruleDetails[getEventType(rule)];
                        }
                    };

                    $scope.$on('$destroy', function () {
                        if (periodicUpdater) {
                            $timeout.cancel(periodicUpdater);
                        }
                    });

                    function ensureDetectorPlot(model) {
                        if (!model.sf_id) return;

                        const uiModel = model.sf_uiModel;

                        const hasPlot = uiModel.allPlots.some(function (plot) {
                            if (plot.type === 'event' && plot.name === model.sf_id) {
                                plot.seriesData.detectorQuery = model.sf_detector;
                                return true;
                            } else {
                                return false;
                            }
                        });

                        if (hasPlot) return;

                        const plot = plotUtils.createDetectorPlot(
                            model,
                            chartbuilderUtil.getNextUniqueKey(uiModel, true)
                        );

                        // Insert the plot right before the transient plot
                        uiModel.allPlots.splice(uiModel.allPlots.length - 1, 0, plot);
                    }

                    ensureDetectorPlot(model);

                    $scope.deleteDetectorRule = function (rule) {
                        const index = uiModel.rules.indexOf(rule);
                        uiModel.rules.splice(index, 1);
                        if ($scope.rulePreview.selectedRule === index) {
                            $scope.rulePreview.selectedRule = -1;
                        }

                        if (rule.apmServiceEndpointSelections) {
                            // for apm detector, we create the plots for the service endpoint selections
                            // if the rule is deleted, we also want to remove the plots (related to the selections)
                            const plotIds = [];
                            rule.apmServiceEndpointSelections.forEach((s) =>
                                plotIds.push(...s.plotIds)
                            );
                            uiModel.allPlots = uiModel.allPlots.filter(
                                (p) => !plotIds.includes(p.uniqueKey)
                            );
                        }
                    };

                    $scope.addEmptyDetectorRule = detectorUtils.addEmptyDetectorRule;

                    $scope.cloneDetectorRule = function (original) {
                        const clone = angular.copy(original);
                        clone.uniqueKey = chartbuilderUtil.getNextUniqueKey(uiModel, true);
                        clone.name += ' (Clone)';
                        clone.draft = true;

                        const index = uiModel.rules.indexOf(original);
                        uiModel.rules.splice(index + 1, 0, clone);
                    };

                    $scope.updateModelAndClearDirty = function (editedDetector) {
                        if (editedDetector) {
                            $scope.detector = editedDetector;
                            updateModel();
                            $scope.clearDirty();
                        }
                    };

                    $scope.newRule = function () {
                        $scope.inRuleWizard = true;
                        detectorWizardModal
                            .newRule($scope.model)
                            .then($scope.updateModelAndClearDirty)
                            .finally(() => {
                                $scope.inRuleWizard = false;
                            });
                    };

                    $scope.shouldRenderCharts = () => {
                        return !$scope.inRuleWizard;
                    };

                    function editRuleWizard(rule) {
                        $scope.inRuleWizard = true;
                        detectorWizardModal
                            .editRule($scope.detector, rule)
                            .then($scope.updateModelAndClearDirty)
                            .finally(() => {
                                $scope.inRuleWizard = false;
                            });
                    }

                    $scope.editRuleWizard = editRuleWizard;

                    // only attempt instrumentation if we expect a fetch to occur.
                    if ($scope.model.sf_programText) {
                        const instrumentationTimer = instrumentationService.getInstrumentationTimer(
                            'ui.directive.detector_edit',
                            ['tti', 'fullload'],
                            30000
                        );
                        instrumentationTimer.init();
                        $timeout(function () {
                            instrumentationTimer.report('tti');
                        }, 0);
                        $scope.$on(CHART_DISPLAY_EVENTS.INSTRUMENTATION_FULL_LOAD, function () {
                            instrumentationTimer.report('fullload');
                        });
                    }

                    let currentViewTime = urlOverridesService.getViewTime();
                    let currentTimepicker = urlOverridesService.getGlobalTimePicker();

                    function applyTimePickerUrlParams() {
                        const timepicker = urlOverridesService.getGlobalTimePicker();
                        const newViewTime = urlOverridesService.getViewTime();
                        if (!angular.equals(currentTimepicker, timepicker)) {
                            if (timepicker) {
                                $scope.$broadcast('initializeTimePicker', timepicker);
                            } else {
                                $scope.$broadcast('resetTimePicker');
                            }
                            $scope.$broadcast('preflight trigger');

                            if (newViewTime && !timepicker.relative) {
                                $scope.pinAfterLoad(newViewTime);
                            }
                        }
                        currentTimepicker = timepicker;

                        // may be separate this into its own controller at some point
                        if (newViewTime !== currentViewTime) {
                            currentViewTime = newViewTime;
                            $scope.$broadcast('set chart config override', newViewTime, timepicker);
                        }
                    }

                    $scope.pinAfterLoad = function (time) {
                        $scope.$broadcast(
                            'chart pin after load',
                            time,
                            $scope.sharedChartState.currentTabId === events_tab.id
                        );
                    };

                    if (!$scope.model.sf_uiModel.revisionNumber) {
                        $scope.model.sf_uiModel.revisionNumber = 1;
                    } else {
                        $scope.model.sf_uiModel.revisionNumber++;
                    }

                    $scope.$watch('model.sf_uiModel.revisionNumber', function () {
                        const model = $scope.model;
                        model.sf_uiModel.uiHelperValue = analyticsService.getPublishKey(
                            model.sf_id,
                            model.sf_uiModel.revisionNumber
                        );
                    });

                    $scope.$watch('model.sf_jobResolution', function () {
                        $scope.model.sf_uiModel.forcedResolution = $scope.model.sf_jobResolution;
                    });

                    $scope.seriesMap = {};

                    $scope.updateModelName = function (name) {
                        $scope.model.sf_detector = name;
                    };

                    function linkDetectorToChart(detectorId, chartId) {
                        $log.info('Linking detector', detectorId, 'to chart', chartId);

                        return chartV2Service.get(chartId).then(function (chart) {
                            if (!chart.sf_uiModel.relatedDetectors) {
                                chart.sf_uiModel.relatedDetectors = [];
                            }

                            if (chart.sf_uiModel.relatedDetectors.indexOf(detectorId) === -1) {
                                chart.sf_uiModel.relatedDetectors.push(detectorId);
                            }

                            return chartV2Service.save(chart);
                        });
                    }

                    function validateForSave() {
                        $scope.hilightRuleNameErrors = false;
                        const ruleNames = uiModel.rules.map(function (rule) {
                            return rule.name;
                        });
                        // check for empty rule names
                        const hasEmptyRuleNames = ruleNames.some(function (ruleName) {
                            return !ruleName;
                        });
                        // check for duplicate rule names
                        const duplicateRuleNames = ruleNames.filter(function (ruleName, index) {
                            return ruleNames.indexOf(ruleName) !== index;
                        });
                        if (hasEmptyRuleNames || duplicateRuleNames.length) {
                            $log.error(
                                'detector has empty rule names ',
                                hasEmptyRuleNames,
                                ', duplicate rule names ',
                                duplicateRuleNames
                            );
                            showInvalidRuleNames(hasEmptyRuleNames, duplicateRuleNames);
                            return false;
                        } else {
                            return true;
                        }
                    }

                    function showInvalidRuleNames(hasEmptyRuleNames, duplicateRuleNames) {
                        $scope.hilightRuleNameErrors = true;
                        sfxModal
                            .open({
                                templateUrl: detectorInvalidRuleNamesTemplateUrl,
                                controller: [
                                    '$scope',
                                    'duplicateRuleNames',
                                    'hasEmptyRuleNames',
                                    function ($scope, duplicateRuleNames, hasEmptyRuleNames) {
                                        $scope.duplicateRuleNames = duplicateRuleNames;
                                        $scope.hasEmptyRuleNames = hasEmptyRuleNames;
                                    },
                                ],
                                resolve: {
                                    duplicateRuleNames: function () {
                                        return duplicateRuleNames;
                                    },
                                    hasEmptyRuleNames: function () {
                                        return hasEmptyRuleNames;
                                    },
                                },
                            })
                            .result.finally($scope.goToRulesTab);
                    }

                    function showConfirmationModal() {
                        if (model.sf_id === undefined) {
                            // If this is a new detector and it doesn't have a name, first
                            // ask for a name
                            if (!$scope.model.sf_detector) {
                                return sfxModal.open({
                                    templateUrl: detectorNameModalTemplateUrl,
                                    scope: $scope,
                                    backdrop: 'static',
                                    keyboard: false,
                                }).result;
                            } else {
                                return $q.when();
                            }
                        }

                        return $q.when();
                    }

                    $scope.save = function () {
                        if (!validateForSave()) {
                            return;
                        }
                        return detectorUtils
                            .showConfirmNoNotifications($scope.detector.sf_uiModel)
                            .then(showConfirmationModal)
                            .then(function () {
                                const model = angular.copy($scope.model);
                                const uiModel = model.sf_uiModel;

                                uiModel.rules = uiModel.rules.map((rule) => {
                                    const { draft, ...props } = rule;
                                    if (draft) {
                                        return { ...props };
                                    }

                                    return rule;
                                });

                                if (!model.sf_detector) {
                                    $window.alert('A name is required to create a detector');
                                    return;
                                }

                                const isNew = model.sf_id === undefined;

                                const modalInstance = sfxModal.open({
                                    template: '<div><i class="busy-spinner-light"></i></div>',
                                    windowClass: 'full-screen-busy-spinner',
                                    backdrop: 'static',
                                    keyboard: false,
                                });
                                return detectorUtils
                                    .saveDetector(model)
                                    .then(
                                        function (savedModel) {
                                            $log.info('Saved detector', savedModel);

                                            const newVersion = savedModel.sf_signalflowVersion;
                                            if (newVersion) {
                                                const oldVersion =
                                                    $scope.model.sf_signalflowVersion;
                                                $scope.model.sf_signalflowVersion = newVersion;
                                                if (newVersion !== oldVersion && newVersion === 2) {
                                                    $scope.model.sf_programs = [];
                                                    refreshProgramText();
                                                }
                                            }
                                            if (savedModel.sf_currentJobIds) {
                                                $scope.model.sf_currentJobIds =
                                                    savedModel.sf_currentJobIds;
                                            }
                                            if (savedModel.sf_jobIdsHistory) {
                                                $scope.model.sf_jobIdsHistory =
                                                    savedModel.sf_jobIdsHistory;
                                            }
                                            $scope.model.sf_updatedOnMs = savedModel.sf_updatedOnMs;
                                            ensureDetectorPlot(model);

                                            if (isNew) {
                                                userAnalytics.event('detector', 'create');

                                                $location.search({});

                                                let locationPromise = $q.when();
                                                if (isNew && uiModel.fromChart) {
                                                    locationPromise = linkDetectorToChart(
                                                        savedModel.sf_id,
                                                        uiModel.fromChart
                                                    );
                                                }

                                                locationPromise.then(function () {
                                                    $location.path(
                                                        '/detector/' + savedModel.sf_id + '/edit'
                                                    );
                                                });
                                            }

                                            $scope.clearDirty();

                                            return savedModel;
                                        },
                                        function (errs) {
                                            alertMessageService({
                                                title: 'Error',
                                                messages: [
                                                    'Unable to save detector!',
                                                    errs.data.status + ' : ' + errs.data.message,
                                                ],
                                            });
                                        }
                                    )
                                    .catch(function (e) {
                                        $log.error('Unable to save detector', e);
                                    })
                                    .finally(function () {
                                        modalInstance.close();
                                    });
                            });
                    };

                    $scope.showMenu = function () {
                        return $scope.detector && ($scope.detector.id || $scope.detector.sf_id);
                    };

                    $scope.muteDetector = () => {
                        mutingService.mute($scope.detector.sf_id).then($scope.reloadMutings);
                    };

                    $scope.goBack = () => {
                        $window.history.back();
                    };

                    $scope.deleteDetector = function () {
                        $scope.deleteModalState.onDelete = function () {
                            $timeout(function () {
                                $scope.deleteModalState.isOpen = false;
                                deleteDetector($scope.detector).then(function () {
                                    $location.url($scope.goBack());
                                });
                            }, 0);
                        };

                        const name = $scope.detector.sf_detector || $scope.detector.name;

                        $scope.deleteModalState.title = 'Delete Detector';
                        $scope.deleteModalState.description =
                            'You are about to permanently delete the detector ' +
                            name +
                            '. Any alerts from this detector will stop alerting.';
                        $scope.deleteModalState.isOpen = true;
                    };

                    $scope.teamRelationEditor = function () {
                        return teamLinkingService.editDetector($scope.detector);
                    };

                    $scope.showDetectorInfo = function () {
                        detectorSettingsModal.info($scope.detector);
                    };

                    $scope.showDetectorPermissions = function () {
                        detectorSettingsModal.permissions($scope.detector);
                    };

                    $scope.switchToSignalFlowEditor = () => {
                        ngRoute.history?.replace({
                            pathname: ngRoute.pathname,
                            search: qs.stringify({ detectorSignalFlowEditor: 1 }),
                        });
                        ngRoute.reload();
                    };

                    $scope.showSignalFlow = function () {
                        // we should consider showing the annotation so that user can follow
                        showSignalFlowModal(
                            programTextUtils.getV2ProgramText(
                                $scope.detector.sf_uiModel,
                                false,
                                true,
                                false
                            )
                        );
                    };

                    $scope.clearDirty = function () {
                        // There are watchers on $scope.model which will set this true due to
                        // modifications made to it during a save, this timeout allows us to
                        // set the flag back to false immediately afterwards.
                        // Used to determine new rules
                        $timeout(function () {
                            $scope.hasUnsavedChanges = false;
                            refreshProgramText();
                            updatePreviewState();
                        }, 1000);
                    };

                    metrics.endRouteUi('detector');

                    function refreshProgramText() {
                        if (detectorUtils.isFlow2($scope.model)) {
                            const detectorPlotUniqueKey = plotUtils.getDetectorPlot(
                                $scope.model
                            )?.uniqueKey;
                            const programOptionOverrides =
                                detectorVersionService.getInternalVersion($scope.model) === 2
                                    ? {
                                          includeEvents: true,
                                          plotsToExclude:
                                              detectorPlotUniqueKey || detectorPlotUniqueKey === 0
                                                  ? [detectorPlotUniqueKey]
                                                  : [],
                                      }
                                    : {};
                            programTextUtils.refreshDetectorProgramTextV2(
                                $scope.model,
                                programOptionOverrides
                            );
                        } else {
                            programTextUtils.refreshProgramText($scope.model);
                        }
                    }

                    let refreshProgramTextDebounce;

                    function refreshProgramTextWithDebounce() {
                        if (refreshProgramTextDebounce) {
                            $timeout.cancel(refreshProgramTextDebounce);
                        }
                        refreshProgramTextDebounce = $timeout(refreshProgramText, 500);
                    }

                    $scope.$watch('model.sf_uiModel', function (newUIModel) {
                        uiModel = $scope.uiModel = newUIModel;
                    });

                    $scope.$watch(
                        'uiModel.allPlots',
                        function (nval, oval) {
                            if (angular.equals(nval, oval)) {
                                return;
                            }
                            refreshProgramTextWithDebounce();
                            chartbuilderUtil.createTransientIfNeeded($scope.model.sf_uiModel);
                            detectorUtils.updateDetectorValidityState(
                                uiModel.rules,
                                uiModel.allPlots
                            );
                            const disabled = uiModel.allPlots.length < 2;
                            disabledWhenNoSignalTabs.forEach(function (tab) {
                                tab.disabled = disabled;
                            });
                        },
                        true
                    );

                    $scope.$watch(
                        'uiModel.rules',
                        function () {
                            refreshProgramTextWithDebounce();
                        },
                        true
                    );

                    $scope.$watch('model.sf_jobMaxDelay', function (n) {
                        uiModel.chartconfig.maxDelay = n;
                    });

                    // Update the timezone picker drop down choice
                    $scope.$watch('model.sf_timezone', function (n) {
                        if (!angular.isDefined(n)) {
                            return;
                        }
                        uiModel.chartconfig.timezone = n;
                        $scope.timezone.query = n;
                    });

                    // Set the detector options' timezone
                    $scope.$on('setCalendarWindowTimezoneFromAnalytics', function (evt, data) {
                        $scope.model.sf_timezone = data;
                    });

                    $scope.$watch('model.sf_jobResolution', function (n) {
                        uiModel.chartconfig.forcedResolution = n;
                    });

                    $scope.$watchGroup(
                        ['model.sf_authorizedUserWriters', 'model.sf_authorizedTeamWriters'],
                        updateLocalPermissionState
                    );

                    refreshProgramText();

                    function validPlots() {
                        return plotUtils.hasValidPlots($scope.model);
                    }

                    $scope.allowSave = function () {
                        const flow2 = detectorUtils.isFlow2($scope.model);
                        const rules = $scope.model.sf_uiModel.rules || [];
                        return (
                            $scope.hasWritePermission &&
                            (!flow2 || rules.length) &&
                            !$scope.model.sf_isLocked &&
                            !$scope.ruleConditionOpened &&
                            validPlots() &&
                            $scope.hasUpdateDetectorCapability
                        );
                    };

                    $scope.$on('resendPlotInformation', function () {
                        $scope.processJobMessages(jobFeedback[DETECTOR_CHART_IDENTIFIERS.NATIVE]);
                    });

                    $scope.processJobMessages = function (messages) {
                        const plotKeyToInfoMap = chartbuilderUtil.processJobMessages(
                            $scope.model.sf_uiModel.allPlots,
                            messages
                        );
                        $scope.throttled = chartbuilderUtil.processJobThrottle(
                            plotKeyToInfoMap,
                            $scope.model
                        );
                        $scope.$broadcast('plotTimeSeriesData', plotKeyToInfoMap);
                    };

                    $scope.$watch(
                        `jobFeedback.${DETECTOR_CHART_IDENTIFIERS.NATIVE}`,
                        $scope.processJobMessages
                    );

                    $scope.$on(
                        CHART_DISPLAY_EVENTS.DERIVED_STREAM_INITIATED,
                        function (evobj, jobId) {
                            $scope.jobId = jobId;
                            $scope.$broadcast('plotTimeSeriesData', {});
                        }
                    );

                    $scope.$on('React:$routeUpdate', function () {
                        applyTimePickerUrlParams();
                    });

                    $scope.$watch(
                        'model',
                        function (newObj, oldObj) {
                            if (angular.equals(newObj, oldObj)) return;
                            $scope.hasUnsavedChanges = true;
                        },
                        true
                    );

                    $scope.$on('rule condition finish trigger', function (ev, rule) {
                        $scope.$broadcast('rule condition finish', rule);
                        $scope.ruleConditionOpened = false;
                        updatePreviewState();
                    });

                    $scope.$watch('ruleConditionOpened', updateRuleTabTooltip);

                    $scope.$on('rule condition cancel trigger', function () {
                        $scope.ruleConditionOpened = false;
                        updatePreviewState();
                    });

                    function checkRuleNames() {
                        const duplicates = {};
                        $scope.invalidRuleName = {};
                        const ruleNames = $scope.detector.sf_uiModel.rules.map(function (rule) {
                            return rule.name;
                        });
                        ruleNames.forEach(function (ruleName, index) {
                            if (!duplicates[ruleName] && ruleNames.indexOf(ruleName) !== index) {
                                duplicates[ruleName] = true;
                            }
                            $scope.invalidRuleName[index] = !ruleName || duplicates[ruleName];
                        });
                    }

                    $scope.$on('modified rule name', checkRuleNames);
                    checkRuleNames();

                    $scope.$on('team notification removed', ($event, team) => {
                        teamsToLink = teamsToLink.filter((t) => t.id !== team.id);
                        teamsToUnlink.push(team);
                    });

                    $scope.$on('team notification added', ($event, team) => {
                        teamsToUnlink = teamsToUnlink.filter((t) => t.id !== team.id);
                        teamsToLink.push(team);
                    });
                },
            ],
            link: function ($scope, element) {
                $scope.ruleConditionOpened = false;
                $scope.$on('open rule condition trigger', function (ev, rule) {
                    $scope.$broadcast('open rule condition', rule);
                    $scope.ruleConditionOpened = true;

                    // when rule condition modal open, scroll so top edge of chart show up
                    const chart = angular.element('.chart-builder-full-preview', element);
                    const scrollParent = chart.scrollParent();
                    scrollParent.animate({ scrollTop: scrollParent.scrollTop() - 36 });
                });
            },
        };
    },
]);
