export const APMFilterService = [
    '$log',
    'filterFactory',
    'blockService',
    'signalTypeService',
    function ($log, filterFactory, blockService, signalTypeService) {
        return {
            generateQueryItems,
            generateDataManipulation,
        };

        function generateQueryItems({ environment, customFilters, signalTypeEntity, selection }) {
            const queryItems = generateBaseQueryItems(signalTypeEntity, selection);
            const environmentFilter = filterFactory.FILTER(
                filterFactory.TYPES.VALUE,
                environment,
                false,
                signalTypeService.getAPMDimensions().environment
            );
            queryItems.push(environmentFilter);

            if (Array.isArray(customFilters) && customFilters.length) {
                customFilters.forEach((f) => {
                    const customFilter = filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        f.propertyValue,
                        f.NOT,
                        f.property
                    );
                    queryItems.push(customFilter);
                });
            } else {
                const customFilterNone = filterFactory.FILTER(
                    filterFactory.TYPES.VALUE,
                    '*',
                    true,
                    signalTypeService.getAPMDimensions().dimensionalized
                );
                queryItems.push(customFilterNone);
            }

            return queryItems;
        }

        function generateDataManipulation(type, signalTypeEntity, options) {
            const analyticsFunction = blockService.getByDisplayName(type);
            const groupBy = {
                [signalTypeService.SERVICE_ENDPOINT.name]: [
                    { value: signalTypeService.getAPMDimensions().service },
                    { value: signalTypeService.getAPMDimensions().operation },
                ],
                [signalTypeService.WORKFLOW.name]: [
                    { value: signalTypeService.getAPMDimensions().workflow },
                ],
            };

            return analyticsFunction.asAggregation(groupBy[signalTypeEntity.name], options);
        }

        function generateBaseQueryItems(signalTypeEntity, selection) {
            let queryItems = [
                filterFactory.FILTER(
                    filterFactory.TYPES.VALUE,
                    ['SERVER', 'CONSUMER'],
                    false,
                    signalTypeService.getAPMDimensions().kind
                ),
            ];

            if (signalTypeEntity.name === signalTypeService.SERVICE_ENDPOINT.name) {
                const { service, endpoints } = selection;
                queryItems = [
                    ...queryItems,
                    filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        service,
                        false,
                        signalTypeService.getAPMDimensions().service
                    ),
                    filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        endpoints,
                        false,
                        signalTypeService.getAPMDimensions().operation
                    ),
                ];
            } else if (signalTypeEntity.name === signalTypeService.WORKFLOW.name) {
                const { resource } = selection;
                queryItems = [
                    filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        resource,
                        false,
                        signalTypeService.getAPMDimensions().workflow
                    ),
                ];
            } else {
                $log.error('Signal type not found.');
            }

            return queryItems;
        }
    },
];
