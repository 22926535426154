import templateUrl from './dashboardCrossLinkDefinition.tpl.html';

export default {
    templateUrl,
    bindings: {
        crossLinkToEdit: '<',
        dashboardEditable: '<',
        dashboardObject: '<',
        context: '<',
        missingUpdateDashboardCapability: '<?',
        hasReadGlobalDataLinkCapability: '<',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$scope',
        'crossLinkUtils',
        'CROSS_LINK_TYPES',
        function ($scope, crossLinkUtils, CROSS_LINK_TYPES) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            $ctrl.closeParentModal = closeParentModal;

            $ctrl.allowedTypes = [
                CROSS_LINK_TYPES.INTERNAL_LINK,
                CROSS_LINK_TYPES.EXTERNAL_LINK,
                CROSS_LINK_TYPES.SPLUNK_LINK,
                CROSS_LINK_TYPES.KIBANA_LINK,
                CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK,
                CROSS_LINK_TYPES.APPD_LINK,
            ];

            function $onInit() {
                crossLinkUtils.getGlobalDataLink($ctrl.crossLinkToEdit).then((url) => {
                    $ctrl.globalDataLinks = '#' + url;
                });
            }

            function closeParentModal() {
                $scope.$emit('close modal');
            }
        },
    ],
};
