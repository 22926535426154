import templateUrl from './detectorV2Display.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import qs from 'query-string';
import { omit } from 'lodash';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { isDashboardTimeWindowSelected } from '../../../app/utils/programArgsUtils';

angular
    .module('signalview.detectorV2')
    .controller('v2DetectorDisplayController', [
        '$scope',
        '$timeout',
        'urlOverridesService',
        'eventModal',
        'moment',
        '$location',
        'deleteDetector',
        'currentUser',
        'getNextDetectorDestination',
        'featureEnabled',
        'detectorSettingsModal',
        'teamLinkingService',
        'CHART_DISPLAY_EVENTS',
        'DETECTOR_CHART_IDENTIFIERS',
        'detectorUtils',
        'v2DetectorAPIWrapper',
        'mutingService',
        'hasCapability',
        function (
            $scope,
            $timeout,
            urlOverridesService,
            eventModal,
            moment,
            $location,
            deleteDetector,
            currentUser,
            getNextDetectorDestination,
            featureEnabled,
            detectorSettingsModal,
            teamLinkingService,
            CHART_DISPLAY_EVENTS,
            DETECTOR_CHART_IDENTIFIERS,
            detectorUtils,
            v2DetectorAPIWrapper,
            mutingService,
            hasCapability
        ) {
            const $ctrl = this;

            $ctrl.$onInit = () => {
                const {
                    detectorObject,
                    mutings,
                    updateTitle,
                    incident,
                    userChartPermissions,
                    chartId,
                    canEditInRichEditor,
                } = $ctrl.data;

                const defaultSignalflow = ngRoute.params.signalflow || '';
                $scope.canEditInRichEditor = canEditInRichEditor;

                $scope.deleteModalState = {
                    isOpen: false,
                    onCancel: function () {
                        $timeout(() => {
                            $scope.deleteModalState.isOpen = false;
                        }, 0);
                    },
                };

                $scope.detector =
                    detectorObject && detectorObject.detector
                        ? detectorObject.detector
                        : {
                              // name is important currently for version comprehension
                              name: null,
                              programText: defaultSignalflow,
                          };

                $scope.userChartPermissions = userChartPermissions;
                $scope.fromChartId = chartId;

                $scope.sharedChartState = {};
                const originalDetector = v2DetectorAPIWrapper($scope.detector);
                const startTime = $location.search().startTimeUTC || null;
                const endTime = $location.search().endTimeUTC || null;
                const model = {
                    sf_chart: originalDetector.getName(),
                    sf_detector: originalDetector.getName(),
                    sf_id: originalDetector.getId(),
                    sf_viewProgramText: originalDetector.getSignalFlow() || '',
                    // TODO: We should look to remove this
                    sf_flowVersion: 2,
                    sf_modelVersion: 2,
                    policies: originalDetector.getRules() || [],
                    sf_jobMaxDelay: originalDetector.getMaxDelay() || '',
                    sf_timezone: originalDetector.getTimezone() || '',
                    sf_type: 'Detector',
                    $isOriginallyV2: true,
                    // Note:
                    // 1. We need this here so the detector charts can load
                    // upon initialization
                    // 2. Chart config object here carries information from the detector and
                    // is used to populate the options tab
                    sf_uiModel: {
                        allPlots: [],
                        chartconfig: {
                            range: startTime && endTime ? null : 15 * 60 * 1000,
                            absoluteStart: startTime,
                            absoluteEnd: endTime,
                            showDots: originalDetector.getShowDataMarkers(),
                            eventLines: originalDetector.getShowEventLines(),
                            disableThrottle: originalDetector.getDisableThrottle(),
                        },
                        chartMode: 'graph',
                        chartType: 'line',
                        revisionNumber: 1,
                    },
                };

                hasCapability(Capability.CREATE_DETECTOR).then(
                    (hasCreateDetectorCapability) =>
                        ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
                );

                hasCapability(Capability.CREATE_MUTING_RULE).then(
                    (hasCreateMutingRuleCapability) =>
                        ($scope.hasCreateMutingRuleCapability = hasCreateMutingRuleCapability)
                );

                $scope.readOnlyEnabled = featureEnabled('readOnly');

                // Update model so the chart can load
                let plots;
                let plotLabels;

                if (detectorObject) {
                    plots = detectorObject.plots;
                    plotLabels = detectorObject.plotLabels;
                }

                const plotModel = detectorUtils.getV2DetectorPlotModel(
                    model.sf_detector,
                    plots,
                    plotLabels,
                    $scope.detector.visualizationOptions
                );
                model.sf_uiModel = angular.extend(model.sf_uiModel, plotModel);
                $scope.model = model;

                $scope.$on('v2 signalflow updated', (ev, plotLabels) => {
                    const wrappedDetector = v2DetectorAPIWrapper($scope.detector);
                    wrappedDetector.updatePublishLabelOptions(model.sf_uiModel);
                    detectorUtils
                        .getPlotsFromProgramText($scope.detector.programText)
                        .then((plots) => {
                            // Update model with new plot and plot label information
                            const plotModel = detectorUtils.getV2DetectorPlotModel(
                                $scope.detector.name,
                                plots,
                                plotLabels,
                                $scope.detector.visualizationOptions
                            );
                            $scope.model.sf_uiModel = angular.extend(
                                $scope.model.sf_uiModel,
                                plotModel
                            );
                        });
                });

                let currentViewTime = urlOverridesService.getViewTime();
                let currentTimepicker = urlOverridesService.getGlobalTimePicker();

                if (!$scope.detector || !$scope.detector.id) {
                    updateTitle('New Detector');
                } else {
                    updateTitle('Detector - ' + $scope.detector.name);
                }

                $scope.updateModelName = function (name) {
                    $scope.model.sf_detector = name;
                };

                const pathPromise = currentUser.orgId().then(function (orgId) {
                    return '/alerts/' + orgId;
                });

                pathPromise.then(function (path) {
                    $scope.alertsPath = path;
                });

                $scope.initRange = null;
                $scope.initRange = {};
                if (
                    $scope.detector &&
                    $scope.detector.visualizationOptions &&
                    $scope.detector.visualizationOptions.time
                ) {
                    const time = $scope.detector.visualizationOptions.time;
                    if (time.type === 'relative') {
                        $scope.initRange.range = time.range;
                    } else if (time.type === 'absolute') {
                        $scope.initRange.absoluteStart = time.start;
                        $scope.initRange.absoluteEnd = time.end;
                    }
                } else {
                    $scope.initRange.range = 900000;
                }

                $scope.showMenu = function () {
                    $scope.canEditInRichEditor = !isDashboardTimeWindowSelected(
                        $scope.detector.programText
                    );
                    return $scope.detector && ($scope.detector.id || $scope.detector.sf_id);
                };
                $scope.deleteDetector = function () {
                    $scope.deleteModalState.onDelete = function () {
                        $timeout(function () {
                            deleteDetector($scope.detector).then(function () {
                                $location.url($scope.getNextDestination());
                            });
                        }, 0);
                    };

                    const name = $scope.detector.sf_detector || $scope.detector.name;

                    $scope.deleteModalState.title = 'Delete Detector';
                    $scope.deleteModalState.description =
                        'You are about to permanently delete the detector ' +
                        name +
                        '. Any alerts from this detector will stop alerting.';
                    $scope.callToAction = '';
                    $scope.deleteModalState.isOpen = true;
                };

                $scope.teamRelationEditor = function () {
                    return teamLinkingService.editDetector($scope.detector);
                };

                $scope.showDetectorInfo = function () {
                    detectorSettingsModal.info($scope.detector);
                };

                $scope.switchToRichEditor = () => {
                    ngRoute.history?.replace({
                        pathname: ngRoute.pathname,
                        search: qs.stringify(omit(ngRoute.search, 'detectorSignalFlowEditor')),
                    });
                    ngRoute.reload();
                };

                $scope.showDetectorPermissions = function () {
                    detectorSettingsModal.permissions($scope.detector);
                };

                $scope.$on(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, function (ev, obj) {
                    $scope.isDirty = true;
                    $scope.$broadcast('initializeTimePicker', obj);
                });

                function applyTimePickerUrlParams() {
                    const timepicker = urlOverridesService.getGlobalTimePicker();
                    const newViewTime = urlOverridesService.getViewTime();
                    if (!angular.equals(currentTimepicker, timepicker)) {
                        if (timepicker) {
                            $scope.$broadcast('initializeTimePicker', timepicker);
                        } else {
                            $scope.$broadcast('resetTimePicker');
                        }
                    }
                    currentTimepicker = timepicker;

                    if (newViewTime !== currentViewTime) {
                        currentViewTime = newViewTime;
                        $scope.$broadcast('set chart config override', newViewTime, timepicker);
                    }
                }

                $scope.$on(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, function (ev, obj) {
                    $scope.$broadcast('initializeTimePicker', obj);
                });

                $scope.$on(
                    CHART_DISPLAY_EVENTS.JOB_RESOLUTION_DETERMINED,
                    function (ev, res, identifier) {
                        if (identifier === DETECTOR_CHART_IDENTIFIERS.MAIN) {
                            $scope.viewResolution = res;
                        }
                    }
                );

                $scope.archivedMetricsWarningEntity =
                    $scope.detector.id || $scope.detector.sf_id ? 'detector' : 'newDetector';

                $scope.$on(CHART_DISPLAY_EVENTS.ARCHIVED_METRICS_FOUND, function (event, metrics) {
                    $scope.archivedMetrics = metrics;
                });

                $scope.$on(
                    CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                    function (evt, chartWindowMisaligned, identifier) {
                        if (identifier === 'main') {
                            $scope.misalignedResolution = chartWindowMisaligned;
                        }
                    }
                );

                $scope.$on('React:$routeUpdate', function () {
                    applyTimePickerUrlParams();
                });

                if (incident) {
                    if (incident) {
                        // convert incidentId parameter to eventId and adjust proper timestamp.
                        const timestamp = moment(incident.timestamp);
                        const start = timestamp.clone().subtract(15, 'minute');
                        const end = timestamp.clone().add(15, 'minute');
                        $location.search('incidentId', null);
                        $location.search('startTimeUTC', start.valueOf());
                        $location.search('endTimeUTC', end.valueOf());
                        $location.search('eventId', incident.id);
                        $location.replace();
                    }
                    eventModal(incident);
                }

                $scope.$on(
                    CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED,
                    function (ev, start, end) {
                        $scope.$broadcast('setNewGlobalTimeRange', start, end);
                    }
                );

                $scope.getNextDestination = getNextDetectorDestination(
                    $scope.detector.sf_organizationID,
                    $scope.detector.id
                );

                function initMuting(mutings) {
                    $scope.mutings = mutings || [];
                    $scope.mutingStarted = mutingService.isMutingStarted($scope.mutings);
                    $scope.mutingText = mutingService.getMutingText($scope.mutingStarted);
                }

                initMuting(mutings);

                $scope.reloadMutings = () => {
                    return mutingService.getListForDetector($scope.detector.id).then(initMuting);
                };

                $scope.openMutingModal = (muting) => {
                    mutingService.openMutingModal(muting, []).then($scope.reloadMutings);
                };

                $scope.deleteMuting = (muting) => {
                    $scope.deleteModalState.onDelete = function () {
                        $timeout(function () {
                            $scope.deleteModalState.isOpen = false;
                            mutingService.deleteMuting(muting).then($scope.reloadMutings);
                        }, 0);
                        mutingService.deleteMuting(muting).then($scope.reloadMutings);
                    };

                    $scope.deleteModalState.isOpen = true;
                    $scope.deleteModalState.title = 'Delete Muting Rule';
                    $scope.deleteModalState.description =
                        'You are about to delete this muting rule. Are you sure?';
                    $scope.deleteModalState.callToAction = 'Delete';
                };

                $scope.endMuting = (muting) => {
                    $scope.deleteModalState.onDelete = function () {
                        $timeout(function () {
                            $scope.deleteModalState.isOpen = false;
                            mutingService.endMuting(muting).then($scope.reloadMutings);
                        }, 0);
                    };

                    $scope.deleteModalState.isOpen = true;
                    $scope.deleteModalState.title = 'Resume Notifications';
                    $scope.deleteModalState.description =
                        'You are about to resume notifications. Are you sure?';
                    $scope.deleteModalState.callToAction = 'Resume';
                };

                $scope.muteDetector = () => {
                    mutingService.mute($scope.detector.id).then($scope.reloadMutings);
                };

                $scope.getFormattedMutingDurationAndCreator =
                    mutingService.getFormattedDurationAndCreator;
                $scope.getMutingTooltip = mutingService.getMutingTooltip;
            };
        },
    ])
    .component('detectorSignalFlowEditor', {
        templateUrl,
        controller: 'v2DetectorDisplayController',
        bindings: {
            data: '<',
        },
    });
