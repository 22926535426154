import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledURLTextBox = styled.textarea`
    ${({ invalidLink }) => {
        return invalidLink && 'border: 1px solid #d41f1f !important';
    }}
`;

const AppdUrlOption = ({
    appdUrl,
    onUpdateAppdLink,
    isLinkValid,
    onAppdUrlBoxFocus,
    onAppdUrlBoxBlur,
    target,
    urlErrorMessage,
}) => {
    const [appdLink, setAppdLink] = useState(appdUrl ? appdUrl : '');
    const defaultURL = 'Enter AppDynamics Tier URL';
    const onLinkChange = (e) => {
        setAppdLink(e.target.value);
        onUpdateAppdLink(e.target.value);
    };
    const isDisplayError =
        ((isLinkValid && appdUrl === undefined) || (!isLinkValid && isLinkValid !== undefined)) &&
        urlErrorMessage !== undefined;
    useEffect(() => {
        if (target) {
            setAppdLink(target.url);
        }
    }, [target]);

    return (
        <>
            <StyledURLTextBox
                aria-labelledby="appd-data-link-url"
                className="form-control external-link-template-input sfx-input description-input"
                placeholder={defaultURL}
                required
                rows="5"
                value={appdLink}
                onChange={(e) => onLinkChange(e)}
                invalidLink={isDisplayError}
                onFocus={onAppdUrlBoxFocus}
                onBlur={onAppdUrlBoxBlur}
            />
            {isDisplayError && (
                <div
                    className={`credential-error-message ${
                        !isDisplayError && 'error-on-submit inline-error'
                    }`}
                    role="alert"
                >
                    {urlErrorMessage}
                </div>
            )}
        </>
    );
};

export default AppdUrlOption;
