import templateUrl from './mapChart.tpl.html';
import archivedMetricsIconTemplateUrl from './archivedMetricsWarningIcon.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import {
    getProgramArgsForDashboardInTime,
    isDashboardTimeWindowSelected,
} from '../../utils/programArgsUtils';
import { generateTimeSeriesName } from '@splunk/olly-utilities/lib/Timeseries';

/**
 * Implementation of the Map chart type.
 *
 * @author max
 */
export const mapChart = {
    templateUrl,
    bindings: {
        chartModel: '<',
        colorByMetric: '<',
        colorByValue: '<',
        openHref: '<',
        signalFlow: '<',
        hideTitle: '<',
        isPreview: '<',
        plotDataGeneration: '<',
        jobMessageSummary: '<',
        updateInterval: '<',
        onNewDataProvider: '&',
    },
    controller: [
        '$rootScope',
        '$scope',
        '$timeout',
        '$log',
        'BaseDataProvider',
        'chartDisplayUtils',
        'timepickerUtils',
        'valueFormatter',
        'themeService',
        'murmurHash',
        'signalViewMaps',
        'colorByValueService',
        'colorAccessibilityService',
        'routeParameterService',
        'featureEnabled',
        function (
            $rootScope,
            $scope,
            $timeout,
            $log,
            BaseDataProvider,
            chartDisplayUtils,
            timepickerUtils,
            valueFormatter,
            themeService,
            murmurHash,
            signalViewMaps,
            colorByValueService,
            colorAccessibilityService,
            routeParameterService,
            featureEnabled
        ) {
            const $ctrl = this;
            $ctrl.$onInit = configure;
            $ctrl.$onChanges = changed;
            $ctrl.$onDestroy = destroy;
            $ctrl.map = null;
            $ctrl.tooltip = null;
            $ctrl.entities = {};
            $ctrl.geoJSON = entitiesToGeoJSON();
            $ctrl.lastTimestamp = null;

            $scope.archivedMetricsIconTemplateUrl = archivedMetricsIconTemplateUrl;

            const DARK_THEME = 'mapbox://styles/mapbox/dark-v9';
            const LIGHT_THEME = 'mapbox://styles/mapbox/light-v9';
            function getMapTheme(dark) {
                return dark ? DARK_THEME : LIGHT_THEME;
            }

            let dataProvider;
            let initialized = false;
            let dirtyTimeout = null;
            let unregisterRouteWatchGroup = null;

            function configure() {
                signalViewMaps.get().then(
                    function (libs) {
                        $ctrl.mapboxgl = libs.mapboxgl;
                        $ctrl.geoJSONExtent = libs.geoJSONExtent;
                        initialize();
                    },
                    function (reason) {
                        $log.error(
                            'Libraries required for map chart could not be loaded! ' + reason
                        );
                    }
                );
            }

            function initialize() {
                dataProvider = new BaseDataProvider(callback);
                setupStream();
                $ctrl.onNewDataProvider({ dataProvider });
                unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
                    angular.bind(this, setupStream)
                );

                $timeout(function () {
                    $ctrl.map = new $ctrl.mapboxgl.Map({
                        container: 'map-container-' + $scope.$id,
                        style: getMapTheme(themeService.dark),
                        attributionControl: false,
                        fadeDuration: 150,
                    });

                    $ctrl.map.on('load', function () {
                        $ctrl.tooltip = new $ctrl.mapboxgl.Popup({
                            closeButton: false,
                            closeOnClick: false,
                            className: 'map-tooltip',
                        });

                        // Start centered on SignalFx's San Mateo HQ.
                        $ctrl.map.easeTo({
                            center: [-122.3244, 37.563],
                            zoom: 8,
                            bearing: 0,
                            pitch: 0,
                        });

                        initialized = true;
                    });

                    $ctrl.map.on('style.load', function () {
                        $ctrl.map.addSource('entities', {
                            type: 'geojson',
                            data: $ctrl.geoJSON,
                        });

                        $ctrl.map.addLayer({
                            id: 'markers',
                            type: 'circle',
                            source: 'entities',
                            paint: {
                                'circle-color': ['get', 'color'],
                                'circle-radius': ['get', 'size'],
                            },
                        });

                        $ctrl.map.addLayer({
                            id: 'labels',
                            type: 'symbol',
                            source: 'entities',
                            layout: {
                                'text-field': '{title}',
                                'text-size': 12,
                                'text-offset': [1, 0],
                                'text-anchor': 'left',
                            },
                            paint: {
                                'text-color': themeService.dark ? 'white' : 'black',
                                'text-halo-color': themeService.dark ? 'black' : 'white',
                                'text-halo-width': 1,
                            },
                        });
                    });

                    const interactionHandler = function () {
                        $timeout.cancel(dirtyTimeout);
                        dirtyTimeout = $timeout(function () {
                            dirtyTimeout = null;
                            fitToData();
                        }, 5000);
                    };

                    $ctrl.map.on('move', interactionHandler);
                    $ctrl.map.on('zoom', interactionHandler);
                    $ctrl.map.on('drag', interactionHandler);

                    $ctrl.map.on('mouseenter', 'markers', function (e) {
                        $ctrl.map.getCanvas().style.cursor = 'pointer';

                        const f = e.features[0];
                        const coordinates = f.geometry.coordinates.slice();
                        const entity = $ctrl.entities[f.properties.name];
                        const description = formatEntityDescription(entity);

                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        // Populate the popup and set its coordinates
                        // based on the feature found.
                        $ctrl.tooltip.setLngLat(coordinates).setHTML(description).addTo($ctrl.map);
                    });

                    $ctrl.map.on('mouseleave', 'markers', function () {
                        $ctrl.map.getCanvas().style.cursor = '';
                        $ctrl.tooltip.remove();
                    });
                }, 0);

                // Handle resizing of the chart's context to resize the map properly.
                $scope.$on('context-resize', function () {
                    if (initialized) {
                        $ctrl.map.resize();
                        fitToData();
                    }
                });

                // Update map theme when the app theme changes.
                $rootScope.$on('theme update', function (event, dark) {
                    if (initialized) {
                        $ctrl.map.setStyle(getMapTheme(dark));
                    }
                });
            }

            function setupStream() {
                chartDisplayUtils.updateGlobalTimeRange(
                    $ctrl.chartModel,
                    timepickerUtils.getChartConfigURLTimeParameters()
                );

                const { chartconfig, chartMode } = $ctrl.chartModel.sf_uiModel;
                const rangeParams = chartDisplayUtils.getJobRangeParametersFromConfig(
                    chartconfig,
                    chartMode
                );

                const programArgs = getProgramArgsForDashboardInTime(chartconfig);
                dataProvider.setOffsetByMaxDelay(true);
                dataProvider.setResolution(rangeParams.resolution);
                dataProvider.setHistoryrange(rangeParams.range);
                if (
                    featureEnabled('dashboardTimeWindow') &&
                    isDashboardTimeWindowSelected(chartModel.sf_viewProgramText)
                ) {
                    dataProvider.setProgramArgs(programArgs);
                }
                dataProvider.setStopTime(rangeParams.endAt);
                dataProvider.setFallbackResolutionMs(rangeParams.fallbackResolutionMs);
            }

            function changed(changes) {
                if (!initialized) {
                    return;
                }

                if (changes.updateInterval) {
                    setupStream();
                } else {
                    updateMapSource();
                }
            }

            function destroy() {
                if (unregisterRouteWatchGroup) {
                    unregisterRouteWatchGroup();
                }
                initialized = false;
                $ctrl.map.remove();
                $ctrl.map = null;
            }

            function callback({ type, data }) {
                switch (type) {
                    case 'init':
                        $ctrl.lastTimestamp = null;
                        $ctrl.entities = {};
                        $ctrl.geoJSON = entitiesToGeoJSON();
                        break;
                    case 'data':
                        updateEntities(data);
                        updateMapSource();
                        break;
                    case 'timestampAdvance':
                        $ctrl.lastTimestamp = data;
                        break;
                }
            }

            /**
             * Update the known entities from newly-received data from the job stream.
             *
             * New entities are created if new timeseries appear.
             *
             * TODO(mpetazzoni): handle EXPIRED_TSID messages to garbage-collect entities.
             */
            function updateEntities(data) {
                Object.entries(data).forEach(function ([tsid, point]) {
                    const obj = dataProvider.getMetricMetaData(tsid);
                    const plot = chartDisplayUtils.getPlotObject(
                        obj,
                        $ctrl.chartModel,
                        $ctrl.chartModel.$isOriginallyV2
                    );
                    const name = getEntityName(obj, plot);
                    let entity = $ctrl.entities[name];
                    if (!entity) {
                        entity = {
                            tsid: tsid,
                            metadata: obj,
                            plot: plot,
                            name: name,
                            hash: murmurHash(name),
                            longitude: obj.longitude ? parseFloat(obj.longitude) : null,
                            latitude: obj.latitude ? parseFloat(obj.latitude) : null,
                            timestamp: null,
                            value: null,
                        };
                        $ctrl.entities[name] = entity;
                    }

                    // TODO: make sure we get the point with the latest timestamp.
                    const chartconfig = $ctrl.chartModel.sf_uiModel.chartconfig;
                    if (obj.sf_streamLabel === chartconfig.valueLabel) {
                        entity.timestamp = point.timestamp;
                        entity.value = point.value;
                    } else if (obj.sf_streamLabel === chartconfig.longitudeLabel) {
                        entity.longitude = point.value;
                    } else if (obj.sf_streamLabel === chartconfig.latitudeLabel) {
                        entity.latitude = point.value;
                    }
                });
            }

            /**
             * Refresh the GeoJSON data source from the entities, and re-fit the map if necessary.
             */
            function updateMapSource() {
                if (!initialized) {
                    return;
                }

                $ctrl.geoJSON = entitiesToGeoJSON();
                const source = $ctrl.map.getSource('entities');
                if (source) {
                    source.setData($ctrl.geoJSON);
                    fitToData();
                }
            }

            /**
             * Fits the map to a padded bounding box around the displayed features/entities.
             */
            function fitToData() {
                if (!initialized || dirtyTimeout) {
                    return;
                }

                const features = $ctrl.geoJSON.features;
                if (features.length === 0) {
                    return;
                } else if (features.length === 1) {
                    $ctrl.map.panTo(features[0].geometry.coordinates);
                } else {
                    const extent = $ctrl.geoJSONExtent($ctrl.geoJSON);
                    if (extent) {
                        $ctrl.map.fitBounds(extent, { padding: 25 });
                    }
                }
            }

            /**
             * Builds an identify name for an entity from its metadata object.
             */
            function getEntityName(obj, plot, withMetric) {
                return generateTimeSeriesName(obj, null, !withMetric, withMetric, plot, [
                    'longitude',
                    'latitude',
                ]);
            }

            /**
             * Builds a GeoJSON feature collection from the given map of entities to render.
             */
            function entitiesToGeoJSON() {
                const features = Object.values($ctrl.entities)
                    .filter(function (entity) {
                        // Exclude entities with no valid value or position.
                        // TODO: render entities with no value, but differently?
                        return (
                            entity.longitude !== undefined &&
                            entity.longitude !== null &&
                            entity.latitude !== undefined &&
                            entity.latitude !== null &&
                            entity.value !== undefined &&
                            entity.value !== null
                        );
                    })
                    .map(function (entity) {
                        return {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [entity.longitude, entity.latitude],
                            },
                            properties: {
                                tsid: entity.tsid,
                                name: entity.name,
                                title: entity.name || entity.plot.name,
                                value: entity.value,
                                color: entityToColor(entity),
                                size: 10,
                            },
                        };
                    });
                return { type: 'FeatureCollection', features: features };
            }

            /**
             * Maps an entity to a color based on the chart and plot configuration.
             *
             * This effectively implements color by value, color override and color by dimensions (by hashing the name).
             * @param entity The entity.
             * @returns The hexadecimal color for this entity.
             */
            function entityToColor(entity) {
                const chartconfig = $ctrl.chartModel.sf_uiModel.chartconfig;
                if (chartconfig.colorByValue) {
                    return colorByValueService.getColorForValue(
                        chartconfig.colorByValueScale,
                        entity.value
                    );
                }

                const plotconfig = entity.plot.configuration;
                if (plotconfig && plotconfig.colorOverride) {
                    const color = plotconfig.colorOverride;
                    return (
                        colorAccessibilityService.get().convertPlotColorToAccessible(color) || color
                    );
                }

                const name = getEntityName(
                    entity.metadata,
                    entity.plot,
                    !!chartconfig.colorByMetric
                );
                const colors = colorAccessibilityService.get().getPlotColors();
                return colors[murmurHash(name) % colors.length];
            }

            /**
             * Create the tooltip's content for an entity.
             *
             * @param entity The entity.
             * @returns The HTML contents of the tooltip.
             */
            function formatEntityDescription(entity) {
                if (!entity) {
                    return;
                }

                const name = entity.name || entity.plot.name || entity.plot.seriesData.metric;
                const useKMG2 = $ctrl.chartModel.sf_uiModel.chartconfig.useKMG2;
                const unitType = safeLookup(entity.plot, 'configuration.unitType') || '';
                const value = unitType
                    ? valueFormatter.formatScalingUnit(entity.value, unitType, 7)
                    : valueFormatter.formatValue(entity.value, 7, useKMG2);
                const prefix = safeLookup(entity.plot, 'configuration.prefix') || '';
                const suffix = safeLookup(entity.plot, 'configuration.suffix') || '';

                return (
                    '<strong>' +
                    name +
                    ': </strong>' +
                    name +
                    '<p><div class="point-value">' +
                    '<div class="point-value-prefix">' +
                    prefix +
                    '</div>' +
                    value +
                    '<div class="point-value-suffix">' +
                    suffix +
                    '</div>' +
                    '</div></p>'
                );
            }
        },
    ],
};
