const heatmapGraph = {
    restrict: 'E',
    scope: {
        selectionPosition: '=?',
        detectorMenuOpen: '=?',
        heatmapData: '=',
        tooltipMsgOnNoData: '=?',
        labelMarkup: '=?',
        plotPrefix: '=',
        plotSuffix: '=',
        parentWidth: '=?',
        parentHeight: '=?',
        hideRootLabel: '=?',
        groupPadding: '=?',
        groupNodeClass: '=?',
        disableSelection: '=?',
        ignoreWindowResize: '=?',
        aspectRatio: '=?',
        sortFunction: '=?',
        disableNewNodeTransition: '=?',
        unitPackConstraints: '=?',
        disableAnimations: '=?',
        onNodeClicked: '=?',
        onGroupClicked: '=?',
        renderOnInit: '=?',
    },
    controller: [
        '$scope',
        '$element',
        '_',
        '$compile',
        '$timeout',
        '$log',
        'urlOverridesService',
        'TetherDrop',
        'Tether',
        function (
            $scope,
            $element,
            _,
            $compile,
            $timeout,
            $log,
            urlOverridesService,
            TetherDrop,
            Tether
        ) {
            function getAspectRatio() {
                const groupBy = heatmapData.groupBy();
                return groupBy && groupBy.length ? 'auto' : $scope.aspectRatio || 21 / 9;
            }

            function getTitle() {
                return heatmapData.mode().displayName;
            }

            const heatmapData = $scope.heatmapData;

            $scope.labelMarkup =
                $scope.labelMarkup ||
                function () {
                    return '';
                };

            $scope.thing = {
                detectorMenuState: false,
            };

            function getWidth() {
                if ($scope.parentWidth) return $scope.parentWidth;

                return $element[0].getBoundingClientRect().width - 40;
            }

            function getHeight() {
                return $scope.parentHeight || 300;
            }

            let currentScale = 1;
            const sortFunction =
                $scope.sortFunction ||
                function (a, b) {
                    return a.id.localeCompare(b.id);
                };

            const visualization = groupableHeatmap()
                .container($element[0])
                .width(getWidth())
                .height(getHeight())
                .nodeSize(20)
                .maxScale(4)
                .title(getTitle())
                .aspectRatio(getAspectRatio())
                .sort(sortFunction)
                .labelMarkup($scope.labelMarkup);

            if (angular.isDefined($scope.disableSelection)) {
                visualization.disableSelection($scope.disableSelection);
            }

            if (angular.isDefined($scope.groupPadding)) {
                visualization.groupPadding($scope.groupPadding);
            }

            if (angular.isDefined($scope.groupNodeClass)) {
                visualization.groupNodeClass($scope.groupNodeClass);
            }

            if (angular.isDefined($scope.disableNewNodeTransition)) {
                visualization.disableNewNodeTransition($scope.disableNewNodeTransition);
            }

            if (angular.isDefined($scope.unitPackConstraints)) {
                visualization.unitPackConstraints($scope.unitPackConstraints);
            }

            if (angular.isDefined($scope.onNodeClicked)) {
                // To work with the old version of heatmap component.
                // This will be removed in the next release after groupable-heatmap is updated.
                visualization.on('click', function (node) {
                    $scope.onNodeClicked(node);
                });
                visualization.on('nodeClick', function (node) {
                    $scope.onNodeClicked(node);
                });
            }
            if (angular.isDefined($scope.onGroupClicked)) {
                visualization.on('groupClick', function (node) {
                    $scope.onGroupClicked(node);
                });
            }

            function setHideRootLabel() {
                if (angular.isDefined($scope.hideRootLabel)) {
                    visualization.hideRootLabel($scope.hideRootLabel);
                }
            }

            setHideRootLabel();

            visualization.on('labelEnter', function (selection) {
                selection.each(function (d) {
                    const childScope = $scope.$new();
                    childScope.d = d;
                    childScope.heatmap = heatmapData;
                    $compile(this)(childScope);
                });
            });

            let labelTooltip;
            let tooltipOverDatumId;
            let tooltipTarget;
            let labelTooltipScope;
            $scope.$on('reposition', function () {
                $timeout(function () {
                    Tether.position();
                });
            });

            function showTooltip(data, element, pinned) {
                const tooltipMsgOnNoData =
                    $scope.tooltipMsgOnNoData && `'${$scope.tooltipMsgOnNoData}'`;
                const $labelTooltipContent = angular.element(
                    // declare-used-dependency-to-linter::heatmapLabelTooltip
                    `<heatmap-label-tooltip data="data" heatmap="heatmap" tooltip-msg-on-no-data="${tooltipMsgOnNoData}" prefix="plotPrefix" suffix="plotSuffix"></heatmap-label-tooltip>`
                );
                const labelTooltipContent = $labelTooltipContent[0];

                if (labelTooltipScope) {
                    labelTooltipScope.$destroy();
                    labelTooltipScope = null;
                }

                labelTooltipScope = $scope.$new();
                labelTooltipScope.data = data;
                labelTooltipScope.heatmap = $scope.heatmapData;

                $compile(labelTooltipContent)(labelTooltipScope);
                $scope.$apply();

                if (labelTooltip) {
                    labelTooltip.destroy();
                }

                const classes = 'heatmap-label-tooltip resource-tooltip';

                tooltipTarget = element;

                const dropOptions = {
                    target: tooltipTarget,
                    openOn: '',
                    position: 'right center',
                    content: labelTooltipContent,
                    classPrefix: 'heatmap-label-',
                    classes: classes,
                    tetherOptions: {},
                    constrainToScrollParent: false,
                };

                if (pinned) {
                    dropOptions.constrainToWindow = true;
                    dropOptions.tetherOptions.constraints = [
                        {
                            to: 'window',
                            pin: true,
                        },
                    ];
                }

                labelTooltip = new TetherDrop(dropOptions);

                tooltipOverDatumId = data.id;
                labelTooltip.open();
            }

            // Data point can optionally be provided if there's concern of closing a
            // tooltip of another datapoint
            function hideTooltip(data) {
                if (labelTooltip && (data === undefined || tooltipOverDatumId === data.id)) {
                    labelTooltip.destroy();
                    labelTooltip = null;
                    tooltipOverDatumId = null;
                    tooltipTarget = null;
                }

                if (labelTooltipScope) {
                    labelTooltipScope.$destroy();
                    labelTooltipScope = null;
                }
            }

            visualization.on('mouseEnterNode', function (data, element) {
                heatmapData.highlighted(data);
                showTooltip(data, element);
            });

            visualization.on('mouseLeaveNode', function (data) {
                heatmapData.highlighted(null);
                hideTooltip(data);

                $scope.$apply();
            });

            function updateTooltipPosition() {
                Tether.position();
            }

            $scope.latestHover = null;

            let firstRender = true;
            const update = _.debounce(function () {
                if (destroyed) {
                    return;
                }

                let data;
                if (!heatmapData.hasDataValues()) {
                    $log.debug('Rendering heatmap visualization with no data values.');
                    data = [];
                } else {
                    $log.debug('Rendering heatmap visualization');
                    data = heatmapData.getNodes();

                    if (heatmapData.hideNulls()) {
                        data = data.filter((n) => {
                            return n.value !== null;
                        });
                    }

                    if (heatmapData.hideDeadHosts()) {
                        data = data.filter(function (node) {
                            return !node._isDead;
                        });
                    }
                }

                const doAnimation = !$scope.disableAnimations && !heatmapData.isVeryLarge();

                // Due to it being a performance bottleneck, disable animation when
                // dealing with too many nodes
                if (heatmapData.isVeryLarge()) {
                    if (!$scope.groupPadding) {
                        visualization.groupPadding(function (d) {
                            if (d.depth === 0) {
                                return { top: 130, bottom: 0, left: 0, right: 0 };
                            } else {
                                return { top: 130, bottom: 10, left: 5, right: 15 };
                            }
                        });
                    }
                } else {
                    if (!$scope.groupPadding) {
                        visualization.groupPadding(null);
                    }
                }
                visualization.animationEnabled(doAnimation);
                visualization.disableNewNodeTransition(!doAnimation);

                const viz = visualization
                    .width(getWidth())
                    .height(getHeight())
                    .nodes(data)
                    .structure(getStructure())
                    .color(coloringFunction)
                    .aspectRatio(getAspectRatio())
                    .nodeSize(20)
                    .minGroupWidth(140)
                    .title(getTitle());
                if ($scope.sortFunction) {
                    viz.sort($scope.sortFunction);
                }

                viz.render();

                if (firstRender) {
                    $element.children('svg')?.attr('aria-label', 'Interactive heatmap chart');
                    if (data.length) {
                        firstRender = false;
                        const selectionId = urlOverridesService.getMapSelection();

                        if (selectionId) {
                            try {
                                viz.selectionId(selectionId);
                            } catch (e) {
                                heatmapData.selection(null);
                            }
                        }
                    }
                }

                $timeout(refreshSelection, 100);

                $scope.$digest();
            }, 150);

            function coloringFunction(data) {
                return heatmapData.coloringFunction()(data);
            }

            function getStructure() {
                const mode = heatmapData.mode();

                let structure;
                if (mode.type === 'elemental') {
                    structure = {};
                    let node = structure;
                    let prevNode = node;
                    heatmapData.groupBy().forEach(function (groupBy) {
                        node.key = groupBy;
                        prevNode = node;
                        const child = {};
                        node.children = [child];
                        node = child;
                    });

                    prevNode.drawBoundary = true;
                } else {
                    structure = mode.map.groupingStructure;
                }

                return structure;
            }

            const unbindOnSelectionUpdated = heatmapData.on(
                'selection updated',
                function ($event, selected) {
                    // $timeout drops this call out of the current $digest cycle preventing
                    // errors if $apply is called due to an event triggered by the heatmap
                    // code.
                    if (selected === visualization.selection()) return;

                    $timeout(function () {
                        visualization.selection(selected);
                        let element;

                        if (selected) {
                            element = visualization.datumElement(selected);
                        }

                        onSelectionUpdate(selected, element);
                    });
                }
            );

            function onSelectionUpdate(data, element) {
                if (element) {
                    $scope.selectionPosition = element.getBoundingClientRect();
                    $scope.selectionPosition.scale = currentScale;
                    if (data.sf_key) {
                        showTooltip(data, element, true);
                    }
                } else {
                    $scope.detectorMenuOpen = false;
                    hideTooltip();
                }
            }

            visualization.on('selection', function (data, element) {
                heatmapData.selection(data);

                onSelectionUpdate(data, element);

                $scope.$apply();
            });

            visualization.on('selection-tween', function () {
                updateTooltipPosition();
            });

            $scope.scale = 1;
            visualization.on('scale', function (scale) {
                currentScale = scale;
                $scope.scale = scale;
                $scope.$apply();
            });

            visualization.on(
                'rescaled',
                _.debounce(function (scale) {
                    $scope.$broadcast('rescaled', scale);
                }, 200)
            );

            $scope.$watch('hideRootLabel', setHideRootLabel);

            $scope.$on('detectorMenuToggle', function () {
                //HACK : need this to go AFTER selection changes...
                $timeout(function () {
                    $scope.detectorMenuOpen = !$scope.detectorMenuOpen;
                }, 0);
            });

            $scope.$on('crosslinkMenuToggle', ($event, dropdownMenu) => {
                // Find required translation based on graph scale and apply
                const width = dropdownMenu.width();
                dropdownMenu.css('transform', 'translateX(-' + width * (1 - currentScale) + 'px)');
            });

            const unbindOnNodesUpdated = heatmapData.on('nodes updated', update);
            const unbindOnGroupByUpdated = heatmapData.on('groupBy updated', update);
            const unbindOnColoringFunctionUpdated = heatmapData.on(
                'coloringFunction updated',
                update
            );
            const unbindOnResizeHeatmapUpdated = heatmapData.on('resize heatmap', update);
            const unbindOnHideDeadHostsUpdated = heatmapData.on('hideDeadHosts updated', update);

            let destroyed = false;
            $scope.$on('$destroy', function () {
                destroyed = true;
                hideTooltip();

                unbindOnNodesUpdated();
                unbindOnGroupByUpdated();
                unbindOnColoringFunctionUpdated();
                unbindOnResizeHeatmapUpdated();
                unbindOnSelectionUpdated();
                unbindOnHideDeadHostsUpdated();

                visualization.destroy();
                if (!$scope.ignoreWindowResize) {
                    angular
                        .element('.heatmap-scroll-container')
                        .off('scroll', updateTooltipPosition);
                }
            });

            function refreshSelection() {
                const selected = visualization.selection();
                if (selected) {
                    const element = visualization.datumElement(selected);
                    onSelectionUpdate(selected, element);
                }
            }

            function resized() {
                update();
                $timeout(refreshSelection, 100);
            }

            if (!$scope.ignoreWindowResize) {
                $scope.$on('resize', resized);
                angular.element('.heatmap-scroll-container').on('scroll', updateTooltipPosition);
            }

            if ($scope.renderOnInit) {
                update();
            }
        },
    ],
};

angular.module('signalview.heatmap').directive('heatmapGraph', [
    function () {
        return heatmapGraph;
    },
]);

// eslint-disable-next-line import/no-unused-modules
export { heatmapGraph };
