import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { convertToDbTimestamp } from '../../utils/programArgsUtils';
import { getReservedDimensionKeys } from '@splunk/olly-utilities/lib/Timeseries';

export const ChartExportService = [
    'chartDisplayUtils',
    'HTML2CANVAS',
    'eventService',
    'moment',
    'analyticsService',
    'alertMessageService',
    function (
        chartDisplayUtils,
        html2canvas,
        eventService,
        moment,
        analyticsService,
        alertMessageService
    ) {
        return {
            exportAsCsv,
            downloadAsCSV,
            downloadAsImage,
            exportEventAsJson,
        };

        function exportAsCsv(
            timeStampToTimeSlice,
            labels = {},
            metadataMap = {},
            legendKeys = [],
            plots = {},
            chartName
        ) {
            const timeStamps = Object.keys(timeStampToTimeSlice);

            const systemKeys = getReservedDimensionKeys();
            legendKeys = legendKeys.filter((key) => !systemKeys.includes(key));

            const dataTable = [];
            let numberOfMTS = 0;

            timeStamps.forEach((timeStamp) => {
                const valuesForTimeStamp = timeStampToTimeSlice[timeStamp];

                valuesForTimeStamp.forEach(function (d, idx) {
                    if (!dataTable[idx]) {
                        dataTable[idx] = [d];
                    } else {
                        dataTable[idx].push(d);
                    }
                });

                numberOfMTS = valuesForTimeStamp.length;
            });

            populateUnits(dataTable, labels, plots, numberOfMTS);
            populateDimensions(dataTable, legendKeys, metadataMap, labels, numberOfMTS, plots);
            downloadAsCSV(dataTable, chartName);
        }

        function downloadAsImage(element, chartObj) {
            const scrollParent = element.scrollParent();
            const scrollTop = scrollParent.scrollTop();
            const scrollParentBottom = scrollTop + scrollParent.height();
            const elementBottom = element.offset().top + element.height();
            const generateImage = () => {
                const icons = angular.element('.dashboard-portlet-icons', element);
                const sfxLogo = angular.element('.dashboard-portlet-sfx-logo', element);
                icons.hide();
                sfxLogo.show();
                html2canvas(element[0]).then(
                    function (canvas) {
                        const a = document.getElementById('chart-image-download-link');
                        a.href = canvas.toDataURL('image/png');

                        if (chartObj) {
                            a.download = (chartObj.sf_chart || chartObj.name) + ' chart.png';
                        } else {
                            a.download = 'Chart.png';
                        }

                        a.click();
                        sfxLogo.hide();
                        icons.show();
                    },
                    function () {
                        alertMessageService({
                            title: 'Image Generation Error',
                            messages: [
                                'An error occurred while generating an image to this chart.',
                            ],
                        });
                        sfxLogo.hide();
                        icons.show();
                    }
                );
            };
            const bottomDifferences = elementBottom - scrollParentBottom;
            if (bottomDifferences > 0) {
                scrollParent.animate(
                    { scrollTop: scrollTop + bottomDifferences },
                    {
                        duration: 100,
                        complete: generateImage,
                    }
                );
            } else {
                setTimeout(generateImage);
            }
        }

        function exportEventAsJson(query, program, chartName, to, from) {
            const request = {
                query: query,
                programToQuery: program,
                limit: 1000,
                offset: 0,
            };

            if (to || to === 0) {
                request.endTime = convertToDbTimestamp(to);
            }

            if (from || from === 0) {
                // if no time range is specified, beginning is 900000
                request.startTime = convertToDbTimestamp(from);
            }

            eventService.v2search(request).then(function (results) {
                downloadAsJSON(
                    results,
                    query,
                    chartName,
                    convertToDbTimestamp(from),
                    convertToDbTimestamp(to)
                );
            });
        }

        function populateUnits(dataTable, labels, plots, numberOfMTS) {
            // Column headers
            dataTable[0].unshift('Value Suffix');
            dataTable[0].unshift('Value Prefix');

            // Unit values per time series
            for (let i = 1; i < numberOfMTS; i++) {
                const tsid = labels[i];
                const suffix = safeLookup(plots, tsid + '.configuration.suffix') || '';
                const prefix = safeLookup(plots, tsid + '.configuration.prefix') || '';
                dataTable[i].unshift(suffix, prefix);
            }
        }

        function populateDimensions(
            dataTable,
            legendKeys,
            metadataMap,
            labels,
            numberOfMTS,
            plots
        ) {
            legendKeys.forEach((legendKey) => {
                dataTable[0].unshift(chartDisplayUtils.getAliasedName(legendKey));
            });

            let i = 1; // First item is the timestamp
            while (i < numberOfMTS) {
                // tsid is in the same order in the timeStampToTimeSlice and labels.
                // tsidToIndex maps index to tsid, and the labels are updated to match.
                const tsid = labels[i];
                const metadata = metadataMap[tsid];
                const plot = plots[tsid];

                if (metadata) {
                    legendKeys.forEach((legendKey) => {
                        const val = analyticsService.syntheticIdFilter(
                            metadata[legendKey],
                            plot,
                            metadata
                        );
                        dataTable[i].unshift(val);
                    });
                }
                i += 1;
            }
        }

        function downloadFromUri({ href, download }) {
            const linkElement = document.createElement('a');
            linkElement.href = href;
            linkElement.download = download;
            linkElement.click();
        }

        function downloadAsCSV(dataTable, chartName) {
            const csvData = dataTable.join('\n');
            const href = encodeURI(`data:text/csv;charset=utf-8,${csvData}`);
            const download = getFileName(chartName, 'csv');

            downloadFromUri({ href, download });
        }

        // TODO: remove when confirmed it is not used
        function downloadAsJSON(data, query, chartName, from, to) {
            const timeFormat = 'MM/DD/YYYY hh:mm:ss a';
            const metadata = {
                from: from,
                to: to,
                jsonExported: moment(Date.now()).format(timeFormat),
            };

            const json = {
                metadata,
                result: data,
            };

            const jsonData = encodeURIComponent(JSON.stringify(json));
            const href = `data:text/json;chartset=utf-8,${jsonData}`;
            const download = getFileName(chartName, 'json');

            downloadFromUri({ href, download });
        }

        function getFileName(chartName, extension) {
            if (chartName) {
                return (
                    chartName
                        .replace(/[^\s\w.-]/gi, ' ')
                        .trim()
                        .replace(/\s+/g, '_') + `-data.${extension}`
                );
            } else {
                return `data.${extension}`;
            }
        }
    },
];
