'use strict';

import './settingsModal/_module';

import { DashboardSavedVariablesContext } from '../../../app/dashboard/variables/dashboardSavedVariablesContext.ts';
import { reactNgBridge } from '../../../app/reactAngularBridge/MigratedServiceProxies';
import dashboardMirrorService from './dashboardMirrorService';
import maxDelayOverridePill from './maxDelayOverridePill';
import { NewDashboardService } from './NewDashboardService';
import signalFlowInfoService from './signalFlowInfoService';
import chartAlertMenu from './chartAlertMenu/chartAlertMenu';
import dashboardChartServiceAPM2 from './dashboardChartServiceAPM2';
import dashboardChartServiceProfiling from './dashboardChartServiceProfiling';
import dashboardChartServiceRUM from './dashboardChartServiceRUM';
import dashboardChartWrapper from './chartWrapper/dashboardChartWrapper';
import pointDensityPicker from './pointDensityPicker/pointDensityPicker';
import dashboardSelectorControl from './dashboardSelector/dashboardSelectorControl';
import dashboardSelectorService from './dashboardSelector/dashboardSelectorService';
import sourceFiltersIsolate from './sourceFiltersIsolate';
import dashboardPage from './dashboardPage';
import saveAclDashboardModal from './aclDashboard/saveAclDashboardModal';
import CreateAclDashboard from '../../../app/dashboard/aclDashboard/CreateAclDashboard';
import CreateAclMirror from '../../../app/dashboard/aclDashboard/CreateAclMirror';
import EditAclDashboard from '../../../app/dashboard/aclDashboard/EditAclDashboard';
import aclDashboardPermissions from './aclDashboard/aclDashboardPermissions';
import { react2angular } from 'react2angular';
import CreateDetectorFromChart from './chartAlertMenu/createDetectorFromChart';
import MissingRbacCapabilityMessage from './MissingRbacCapabilityMessage';
import dashboardNavController from './dashboardNavController';
import { dashboardFilter } from '../../../app/dashboard/dashboardFilter/DashboadFilter';
import { DashboardChartSlotComponent } from '../../../common/ui/dashboard/DashboardChartSlot';
import { deleteConfirmationModal } from '../../../common/ui/modals/DeleteConfirmationModal';
import { BellIcon } from '../../../common/ui/icons/BellIcon';
import { DotsThreeHorizontalIcon } from '../../../common/ui/icons/DotsThreeHorizontalIcon';
import { DrilldownButton } from '../../../common/ui/dashboard/DrilldownButton';
import { CopyIcon } from '../../../common/ui/icons/CopyIcon';
import { CopyButton } from '../../../common/ui/dashboard/CopyButton';
import { OptionsButton } from '../../../common/ui/dashboard/OptionsButton';

angular
    .module('signalview.dashboard', [
        'signalview',
        'signalview.dashboard.modal',
        'gridster',
        'signalboost',
        'signalview.timePicker',
        'chartbuilderUtil',
        'sfx.filterControls',
        'signalview.urlOverrides',
        'sfx.data',
        'sfx.ui',
        'sfx.filterControls',
        'signalview.consts',
        'sfUtil',
        'sfx.debug',
        'signalview.eventsPanel',
        'signalview.imageService',
        'signalview.page',
        'signalview.search',
        'signalview.sfGlobalNav',
        'signalview.appNotifications',
        'signalview.recentPages',
        'signalview.variables',
        'signalview.dashboardUtil',
        'signalview.variables',
        'signalview.objectmanager',
        'signalview.snapshotSharing',
        'signalview.utils',
        'signalview.metricsEventsSidebar',
        'signalview.metricsSidebar',
        'signalview.quickAnalytics',
        'signalview.routeParameters',
        'sfx.api.v2',
        'sfx.alerting',
    ])
    .directive('dashboardNavController', dashboardNavController)
    .component('maxDelayOverridePill', maxDelayOverridePill)
    .component('saveAclDashboardModal', saveAclDashboardModal)
    .component('createAclDashboard', react2angular(CreateAclDashboard))
    .component('createAclMirror', react2angular(CreateAclMirror))
    .component('editAclDashboard', react2angular(EditAclDashboard))
    .component('aclDashboardPermissions', aclDashboardPermissions)
    .component('dashboardFilter', dashboardFilter)
    .component('missingRbacCapabilityMessage', react2angular(MissingRbacCapabilityMessage))
    .directive('chartAlertMenu', chartAlertMenu)
    .component('createDetectorFromChart', react2angular(CreateDetectorFromChart))
    .component('dashboardChartSlot', DashboardChartSlotComponent)
    .directive('dashboardChartWrapper', dashboardChartWrapper)
    .directive('pointDensityPicker', pointDensityPicker)
    .directive('sourceFiltersIsolate', sourceFiltersIsolate)
    .controller('dashboardSelectorControl', dashboardSelectorControl)
    .component('dashboardPage', dashboardPage)
    .service('dashboardSelectorService', dashboardSelectorService)
    .service('signalFlowInfoService', signalFlowInfoService)
    .service('dashboardMirrorService', dashboardMirrorService)
    .service('dashboardChartServiceAPM2', dashboardChartServiceAPM2)
    .service('dashboardChartServiceProfiling', dashboardChartServiceProfiling)
    .service('dashboardChartServiceRUM', dashboardChartServiceRUM)
    .service('dashboardSavedVariablesContext', () => new DashboardSavedVariablesContext())
    .constant('newDashboardService', reactNgBridge.ngReactMemo(NewDashboardService))
    .component('deleteConfirmationModal', deleteConfirmationModal)
    .component('bellIcon', react2angular(BellIcon, ['variant', 'alertState']))
    .component('dotsThreeHorizontalIcon', react2angular(DotsThreeHorizontalIcon))
    .component('copyIcon', react2angular(CopyIcon))
    .component('drilldownButton', react2angular(DrilldownButton, ['link']))
    .component('copyButton', react2angular(CopyButton, ['onCopyClick']))
    .component(
        'optionsButton',
        react2angular(OptionsButton, [
            'widget',
            'chartId',
            'snapshot',
            'getOpenUrl',
            'currentModel',
            'hasCreateChartCapability',
            'hasCreateShareableSnapshotCapability',
            'onCopyClick',
            'onShowAdditionalInfoClick',
            'onDownloadClick',
            'onExportAsCsvClick',
            'onExportEventAsJsonClick',
            'troubleshootIndexParams',
            'setTroubleshootIndexParams',
            'profilingIndexParams',
            'setProfilingIndexParams',
            'rumIndexParams',
            'setRumIndexParams',
            'viewOnly',
            'enterDeleteMode',
            'rumEnabled',
            'profilingTroubleshootingEnabled',
            'apm2Enabled',
            'getOpenTitle',
            'clipSize',
            'onShareClick',
        ])
    );
