import templateUrl from './dashboard.tpl.html';
import dashboardSaveAsModalTemplateUrl from './dashboardSaveAsModal.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { isChartDisplayTooType } from '../charting/chart/chartVersionServiceModule';
import { startSpanWithMMS } from '@splunk/olly-tracing/utils';
import { sanitizeChartModel } from '@splunk/olly-services';
import { getProgramArgsForDashboardInTime } from '../utils/programArgsUtils';
import { isSLOChartType } from '../../../common/ui/dashboard/isSLOChartType';

const dashboard = {
    restrict: 'EA',
    scope: {
        data: '=',
        activeDashboardConfig: '=?',
        viewOnly: '=',
        permissionsPromise: '=?',
        filters: '=',
        chartUrlGenerator: '=?',
        disableLazyRender: '=?',
        trackVisited: '=?',
        saveSnapshotEdits: '=?',
        saveFilters: '=?',
        reset: '=?',
        sourceName: '=?',
        sourceFilters: '=?',
        allCharts: '=?',
        filterState: '=?',
        disableVariables: '=?',
        disableUrlOverrides: '=?',
        needsVariableValues: '=?',
        sdVariableValues: '=?',
        snapshot: '=?',
        cancelSelections: '=?',
        isEditable: '=?',
        getFilterOverrides: '=?',
        cloneDashboard: '=',
        eventOverlayParams: '<?',
        openMetricsSidebar: '<?',
        orgOverviewContext: '<?',
        getAppearanceCount: '<?',
        refreshAppearanceCount: '<?',
        triggerFilterReset: '=?',
        groupIsServiceDiscovery: '=?',
        isSidebarDashboard: '<?',
        disableClick: '<?',
        removeChartMargin: '<?',
        allDashboardConfigs: '<?',
        parentSpan: '<?', // optional parentSpan to use for telemetry
        dashboardLoaded: '=?', // optional callback called when the all initially visible charts have loaded
    },
    templateUrl,
    controller: [
        '$scope',
        '$q',
        '$location',
        '$timeout',
        '$window',
        '$log',
        'sfxModal',
        'signalviewMetrics',
        'userAnalytics',
        'urlOverridesService',
        'sourceFilterService',
        'instrumentationService',
        'fullscreen',
        '$interval',
        'recentPagesService',
        'signalviewMetrics',
        'dashboardVariablesService',
        'variableTransferService',
        'featureEnabled',
        'snapshotEditsService',
        'routeParameterService',
        'signalStreamPreRunner',
        'chartDisplayUtils',
        'CHART_DISPLAY_EVENTS',
        'chartVersionService',
        'chartUtils',
        'SAMPLE_CONSTANTS',
        'programTextUtils',
        'dashboardV2Service',
        'dashboardV2Util',
        'permissionsChecker',
        'signalFlowInfoService',
        'zeroStateService',
        'IS_MOBILE',
        'dashboardVariableUtils',
        'dashboardVariableSuggestUtils',
        'dashboardUtil',
        'currentUser',
        'dashboardGroupService',
        'dashboardMirrorService',
        'dashboardSavedVariablesContext',
        'ChartExportService',
        function (
            $scope,
            $q,
            $location,
            $timeout,
            $window,
            $log,
            sfxModal,
            metrics,
            userAnalytics,
            urlOverridesService,
            sourceFilterService,
            instrumentationService,
            fullscreen,
            $interval,
            recentPagesService,
            signalviewMetrics,
            dashboardVariablesService,
            variableTransferService,
            featureEnabled,
            snapshotEditsService,
            routeParameterService,
            signalStreamPreRunner,
            chartDisplayUtils,
            CHART_DISPLAY_EVENTS,
            chartVersionService,
            chartUtils,
            SAMPLE_CONSTANTS,
            programTextUtils,
            dashboardV2Service,
            dashboardV2Util,
            permissionsChecker,
            signalFlowInfoService,
            zeroStateService,
            IS_MOBILE,
            dashboardVariableUtils,
            dashboardVariableSuggestUtils,
            dashboardUtil,
            currentUser,
            dashboardGroupService,
            dashboardMirrorService,
            dashboardSavedVariablesContext,
            chartExportService
        ) {
            $scope.hasGroupWritePermission = false;
            if ($scope.data.group) {
                permissionsChecker
                    .hasDashboardGroupWriteAccess($scope.data.group)
                    .then(function (result) {
                        $scope.hasGroupWritePermission = result;
                    });
            }

            $scope.readOnlyEnabled = featureEnabled('readOnly');
            $scope.dashboardViewsEnabled = featureEnabled('dashboardViews');

            $scope.isSLOChart = (chart) => isSLOChartType(chart.options.type);

            $scope.removeWidget = (widget) => {
                $scope.$emit('removeGridsterItem', {
                    widget,
                    chartId: widget.chartId,
                });
                userAnalytics.event('chart', 'remove');
            };

            $scope.exportToImage = (chartId, isDashboardSlotChart) => {
                if (isDashboardSlotChart) {
                    const element = angular.element(
                        `dashboard-chart-slot:has(> [data-slot-id="chart-${chartId}"])`
                    );
                    const chartObj = $scope.allCharts[chartId];
                    chartExportService.downloadAsImage(element, chartObj);
                }
            };

            $scope.exportToCsv = chartExportService.downloadAsCSV;

            function updateDefaultMessage() {
                // an empty page will not have a model
                $scope.showDefaultMessage =
                    $scope.model && $scope.model.charts && !$scope.model.charts.length;
            }

            zeroStateService.queryForMetrics().then((hasNoMetrics) => {
                $scope.hasNoMetrics = hasNoMetrics;

                if (hasNoMetrics) {
                    $scope.$watch('model.charts.length', updateDefaultMessage);
                    updateDefaultMessage();
                }
            });

            const minDisplayableHeight = 180;
            const defaultWidgetHeight = 220;
            const widgetMargin = !$scope.removeChartMargin ? 14 : 0;
            const fullScreenDashboardReloadInterval = 60 * 60 * 1000; // 1 hour
            let widgetHeight = defaultWidgetHeight;
            let lastMouseMoveTime = $window.Date.now();
            let numReloads = 0;
            let numChartsExpectingLoad = 0;
            let numChartsLoaded = 0;

            /**
             * Instrumented performance using tracing. We leverage the callbacks from the chartdisplay to track:
             * 1. The duration of each chart loading, including backend traceID linkage.
             * 2. The duration of the dashboard load, as defined as when all initial charts have completed. Note
             *    that only the visible charts are loaded initially, so we only track loading of charts until
             *    they "stabilize".
             * Note: Only charts using chartdisplay.js currently have events for starting/loaded in a way
             *       that can be tracked here so if a dashboard only has chartDisplayToo charts don't start a dashboard trace.
             */

            // Dashboard load span

            let dashboardLoadSpan = null;
            let chartSpanMap = null;
            let cancelOpenSpans = () => null;
            if (!$scope.data.charts.every(isChartDisplayTooType)) {
                const dashboardSpanAttributes = {
                    'dashboard.id': $scope.data.dashboard.id,
                    'dashboard.groupId': $scope.data.dashboard.groupId,
                };

                dashboardLoadSpan = startSpanWithMMS(
                    'signalview/Dashboard',
                    'dashboard_load',
                    dashboardSpanAttributes,
                    $scope.parentSpan ?? undefined
                );

                // Chart load spans
                chartSpanMap = new Map();
                $scope.$on(
                    CHART_DISPLAY_EVENTS.INCREMENT_CHART_LOADS_EXPECTED,
                    function (event, chartId, chartModelId) {
                        numChartsExpectingLoad++;
                        if (dashboardLoadSpan.isRecording()) {
                            chartSpanMap.set(
                                chartId,
                                startSpanWithMMS(
                                    'signalview/Dashboard',
                                    'chart_load',
                                    {
                                        'chart.id': chartModelId,
                                    },
                                    dashboardLoadSpan
                                )
                            );
                        }
                        event.stopPropagation();
                    }
                );
                $scope.$on(
                    CHART_DISPLAY_EVENTS.INCREMENT_CHART_LOADS_SUCCEEDED,
                    function (event, chartId, { traceId }) {
                        numChartsLoaded++;
                        const chartSpan = chartSpanMap.get(chartId);
                        if (chartSpan && chartSpan.isRecording()) {
                            if (traceId) {
                                chartSpanMap.get(chartId)?.setAttribute('link.traceId', traceId);
                            }
                            chartSpanMap.get(chartId)?.end();
                        }
                        if (numChartsLoaded === numChartsExpectingLoad) {
                            dashboardLoadSpan.end();
                            if ($scope.dashboardLoaded) {
                                $scope.dashboardLoaded();
                            }
                        }
                        event.stopPropagation();
                    }
                );

                cancelOpenSpans = () => cancelOpenDashboardSpan(dashboardLoadSpan, chartSpanMap);
            }

            function cancelSpanIfOpen(span, spanName) {
                if (span.isRecording()) {
                    span.updateName(`${spanName} (canceled)`);
                    span.end();
                }
            }

            function cancelOpenChartSpans(chartSpans) {
                chartSpans.forEach((span) => cancelSpanIfOpen(span, 'chart_load'));
            }

            function cancelOpenDashboardSpan(dashboardSpan, chartSpans) {
                cancelOpenChartSpans(chartSpans);
                cancelSpanIfOpen(dashboardSpan, 'dashboard_load');
            }

            if (!$scope.snapshot) $scope.snapshot = {};

            if ($scope.snapshot.id) {
                $scope.newChartHref = '#/temp/chart/new?toDashboard=' + $scope.snapshot.id;
            } else if ($scope.data.dashboard && $scope.data.dashboard.id) {
                $scope.newChartHref = '#/chart/new?toDashboard=' + $scope.data.dashboard.id;
            } else {
                $scope.newChartHref = '#/chart/new?';
            }

            $scope.$watch(
                () => ngRoute.params,
                () => {
                    $scope.dashboardParams = dashboardUtil.getDashboardSearchParamsString(
                        ngRoute.params.groupId,
                        ngRoute.params.configId
                    );
                }
            );

            const chartFilterLinks = chartUtils.getChartFiltersLink(null, true);
            $scope.chartFilterLinks = chartFilterLinks ? `&${chartFilterLinks}` : '';
            let scrollElem = null;
            //binding this via angular would call a digest every time the mouse is moved, which
            //is crippling for perf.  bind it manually via jquery
            $window.setTimeout(function () {
                angular.element('#dashboard_' + $scope.$id).on('mousemove', function () {
                    lastMouseMoveTime = $window.Date.now();
                    numReloads = 0;
                });
            });

            function initializeFullScreenReloader() {
                $interval.cancel($scope.fullScreenReloader);
                $scope.fullScreenReloader = $interval(function () {
                    const timeDelta = Date.now() - lastMouseMoveTime;
                    // only do this reload if we're not near the top of the hour
                    const minutes = (Date.now() / 60000) % 60;

                    //FIXME THIS WILL RELOAD PAUSED DASHBOARDS
                    if (
                        timeDelta > fullScreenDashboardReloadInterval &&
                        minutes < 55 &&
                        minutes > 10
                    ) {
                        numReloads++;
                        if (numReloads < 12) {
                            $scope.$broadcast('fullscreen reload charts');
                            signalviewMetrics.incr('ui.dashboard.dashboardReloads');
                            lastMouseMoveTime = $window.Date.now();
                        } else {
                            //if the mouse hasn't moved for over 12 hours, reload the entire page.  this is a hack in a sense because
                            //we are doing it to "fix" a memory leak, but also to ensure HUD/wallTVs have the latest UI code
                            $window.location.reload();
                        }
                    }
                }, fullScreenDashboardReloadInterval / 5);
            }

            let loadAllTimeout;

            function loadAllRemainingCharts() {
                $timeout.cancel(loadAllTimeout);
                // this will cause the below "foldrow" charts in the dashboard portlets to be initialized
                // via an ng-if, which results in them attempting to start their respective jobs and then get
                // fed into the batcher
                $scope.foldRow = 100000;

                //update the batch size to whatever we feel is reasonable to ask analytics after
                //the fold charts have been loaded
            }

            const isSampleDashboard =
                $scope.data.charts &&
                $scope.data.charts.some(function (chart) {
                    return chart.sf_disallowCachedProgram === true;
                });

            $scope.isDiscoveryDashboard =
                $scope.data.dashboard && $scope.data.dashboard.discoveryOptions
                    ? !!$scope.data.dashboard.discoveryOptions.selectors.length
                    : false;

            $scope.model = null;
            $scope.parentPage = null;
            $scope.sourceFilters = [];
            $scope.foldRow = Math.max(1, Math.ceil(($window.innerHeight - 200) / widgetHeight) - 1);
            $scope.filterState = {
                timePickerDirty: false,
                sourceFilterDirty: false,
                variablesDirty: false,
                pointDensityDirty: false,
                eventOverlayDirty: false,
            };
            $scope.allCharts = {};
            $scope.chartDropdownState = {};
            $scope.sourceName = '';
            $scope.metricsQuery = null;
            $scope.sharedChartState = {};
            $scope.minRow = null;
            $scope.maxRow = null;
            $scope.resizeInitialized = false;

            function broadcastResize() {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                recalculateViewableRows();
            }

            function throttledResize() {
                $timeout.cancel($scope.resizeTimeout);
                $scope.resizeTimeout = $timeout(broadcastResize, 300);
            }

            //gridster resized doesn't seem to fire in window resize anymore...
            $scope.$on('gridster-resized', throttledResize);

            let resizeObserver;
            const dashboardElem = angular.element('.dashboard-main')[0];
            if (dashboardElem) {
                resizeObserver = new ResizeObserver(throttledResize);
                resizeObserver.observe(dashboardElem);
            } else {
                // dashboardElem really should exist, but at least don't fail here if it doesn't
                angular.element($window).on('resize', throttledResize);
            }

            const gridsterOptions = {
                margins: [widgetMargin, widgetMargin],
                columns: 12,
                rowHeight: widgetHeight,
                defaultSizeX: 6,
                defaultSizeY: 1,
                max_cols: 12,
                maxSizeY: 3,
                // do not allow gridster to flip into phone mode, coordinates get screwed up
                mobileModeEnabled: false,
                avoid_overlapped_widgets: true,
                resizable: {
                    enabled: false,
                    handles: ['e', 'w', 's', 'se', 'sw'],
                },
                draggable: {
                    enabled: false,
                },
            };

            if ($scope.isSidebarDashboard) {
                gridsterOptions.columns = 1;
                gridsterOptions.defaultSizeX = 1;
                gridsterOptions.max_cols = 1;
            }

            $scope.gridsterOptions = gridsterOptions;

            function updateMinMaxRow() {
                if (!scrollElem) {
                    // in order to allow lazy load to occur during the first digest of dashboard initialization,
                    // we cannot rely on the to-be-created elements.  hardcode for now and potentially use
                    // window size to determine this later
                    $scope.minRow = 0;
                    $scope.maxRow = 2;
                    return;
                }
                const dsa = scrollElem;
                const t = dsa.scrollTop();
                const h = dsa.height();
                const header = 95 + widgetMargin;
                $scope.minRow = Math.floor((t + header) / $scope.gridsterOptions.rowHeight);
                $scope.maxRow = Math.floor((t + header + h) / $scope.gridsterOptions.rowHeight) - 1;
                $scope.$broadcast('dashboardScrolled');
            }

            function recalculateViewableRows() {
                $timeout.cancel($scope.viewportTimeout);
                $scope.viewportTimeout = $timeout(updateMinMaxRow, 50);
            }

            $scope.getViewState = function (opts) {
                // {col, row, width, height}
                if ($scope.disableLazyRender) {
                    return true;
                }

                if ($scope.minRow !== null && $scope.maxRow !== null) {
                    if (
                        (opts.row >= $scope.minRow && opts.row <= $scope.maxRow) ||
                        (opts.row + opts.height >= $scope.minRow && opts.row < $scope.minRow)
                    ) {
                        return true;
                    }
                    return false;
                } else {
                    return false;
                }
            };

            $timeout(
                function () {
                    scrollElem = angular.element('.dashboard-main');
                    if (scrollElem.length === 0) {
                        return;
                    }
                    scrollElem = scrollElem.scrollParent();
                    scrollElem.on('scroll', recalculateViewableRows);
                },
                0,
                false
            );

            $scope.isFullscreen = fullscreen.isFullscreen();
            $scope.$on('fullscreen enabled', function () {
                $scope.isFullscreen = true;
                const chromeSize = 110;
                let screenHeight = null;
                if ($window.screen && $window.screen.height) {
                    screenHeight = $window.screen.height;
                } else {
                    return;
                }
                let maxRow = 1;
                angular.forEach($scope.model.charts, function (chart) {
                    const chartExtent = chart.height + chart.row;
                    if (chartExtent > maxRow) {
                        maxRow = chartExtent;
                    }
                });

                const space = screenHeight - chromeSize;
                let desiredRowcount = maxRow;
                let proposedWidgetHeight = space / desiredRowcount;

                while (proposedWidgetHeight < minDisplayableHeight && desiredRowcount > 2) {
                    desiredRowcount--;
                    proposedWidgetHeight = space / desiredRowcount;
                }
                widgetHeight = proposedWidgetHeight;

                $scope.gridsterOptions.rowHeight = widgetHeight;
                $scope.gridsterOptions.margins = [1, 1];
                $timeout(function () {
                    recalculateViewableRows();
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                }, 2000);
            });

            $scope.$on('fullscreen disabled', function () {
                $scope.isFullscreen = false;
                $scope.gridsterOptions.rowHeight = defaultWidgetHeight;
                $scope.gridsterOptions.margins = [widgetMargin, widgetMargin];
                $timeout(function () {
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                }, 5000);
            });

            const dashboardLoadTimer = instrumentationService.getInstrumentationTimer(
                'ui.directive.dashboard',
                ['tti', 'fullload']
            );
            dashboardLoadTimer.init();

            $log.debug('Loading dashboard');

            function onChartsLoaded() {
                dashboardLoadTimer.report('fullload');
                $log.debug('All dashboard charts have fully loaded');
            }

            function onChartsInitialized() {
                dashboardLoadTimer.report('tti');
                recalculateViewableRows();
                $log.debug('All dashboard charts have initialized');
            }

            function getSavedFilters(model, config) {
                if ($scope.disableVariables) {
                    return dashboardUtil.getSavedFiltersAndIgnoreVariables(model, config);
                } else {
                    return dashboardUtil.getSavedFilters(model, config);
                }
            }

            function callbackError() {
                console.error('A callback was invoked on a job pre-run!');
            }

            function populateJobProgramOpts(chart, jobOpts) {
                chartDisplayUtils.applyUrlStateToModel(chart, $scope.model.filters.variables || []);

                let signalFlowToStream = null;

                let programTextSource = null;
                let throttleValue = 0;
                let throttle = true;
                if (chart.sf_uiModel.chartconfig.disableThrottle) {
                    throttle = false;
                }

                programTextSource = chart;

                programTextUtils.refreshProgramText(programTextSource);

                throttleValue = throttle ? SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE : 0;
                if (
                    !featureEnabled('disableBrowserProtectionMode') &&
                    chartDisplayUtils.isRenderThrottledChartMode(chart.sf_uiModel.chartMode)
                ) {
                    throttleValue = throttle
                        ? SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE
                        : SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE;
                }

                // if we're in v1, then regenerate text to run based on throttle and read-only state
                // if we're in v2, pass thru
                if (!chart.sf_flowVersion) {
                    signalFlowToStream = programTextUtils.getV2ProgramText(
                        programTextSource.sf_uiModel,
                        true,
                        false,
                        []
                    );
                } else {
                    signalFlowToStream = programTextSource.sf_viewProgramText;
                }

                if (!signalFlowToStream) {
                    return;
                }

                let maxDelay;
                if ($scope.model.maxDelayOverride === null) {
                    // This indicates 'No override', so we defer to the chart settings
                    maxDelay =
                        parseInt(safeLookup(chart, 'sf_uiModel.chartconfig.maxDelay'), 10) || null;
                } else {
                    maxDelay = $scope.model.maxDelayOverride;
                }

                const timezone = safeLookup(chart, 'sf_uiModel.chartconfig.timezone') || null;

                const chartConfig = safeLookup(chart, 'sf_uiModel.chartconfig') || null;

                const jobRangeParameters = chartDisplayUtils.getJobRangeParameters(chart);

                angular.extend(jobOpts, {
                    signalFlowText: signalFlowToStream,
                    resolution: jobRangeParameters.resolution,
                    historyrange: jobRangeParameters.range,
                    stopTime: jobRangeParameters.endAt,
                    resolutionAdjustable: urlOverridesService.getResolutionAdjustable(),
                    maxDelayMs: maxDelay,
                    timezone: timezone,
                    offsetByMaxDelay: true,
                    disableAllEventPublishes: true,
                    programArgs: getProgramArgsForDashboardInTime(chartConfig),
                });

                if (!$scope.snapshot || !$scope.snapshot.id) {
                    angular.extend(jobOpts, {
                        computingFor: chart.sf_id,
                        traceContext: { chartId: chart.sf_id },
                    });
                }

                //this skips zeroes, but its ok, as zero is an invalid resolution.
                if (jobRangeParameters.fallbackResolutionMs) {
                    jobOpts.fallbackResolutionMs = jobRangeParameters.fallbackResolutionMs;
                }

                if (throttleValue) {
                    jobOpts.sampleSize = throttleValue;
                }

                if (featureEnabled('cacheJobsForCharts')) {
                    jobOpts.useCache = true;
                }

                //fixme
                const chartVersion = chartVersionService.getVersion(chart || {});
                if (chartVersion === 2) {
                    // not checking for detectors since we don't use variables/filters there
                    const variables = $scope.model.filters.variables || [];
                    const filters = chartUtils
                        .getChartOverrides(
                            variables.filter((f) => {
                                return !f.replaceOnly;
                            })
                        )
                        .map(function (filt) {
                            return {
                                property: filt.key,
                                propertyValue: filt.value,
                                applyIfExists: filt.applyIfExists,
                                NOT: filt.not,
                            };
                        })
                        .concat(
                            ($scope.getFilterOverrides ? $scope.getFilterOverrides() : []) || []
                        );

                    const replaceOnlyFilters = chartUtils
                        .getChartOverridesCustom(
                            variables.filter((f) => {
                                return f.replaceOnly;
                            }),
                            []
                        )
                        .map(function (filt) {
                            return {
                                property: filt.key,
                                propertyValue: filt.value,
                                applyIfExists: filt.applyIfExists,
                                NOT: filt.not,
                            };
                        });

                    const filterOnlyStr =
                        sourceFilterService.translateSourceFilterObjectsToFilterBlock(
                            replaceOnlyFilters
                        );
                    jobOpts.filter =
                        sourceFilterService.translateSourceFilterObjectsToFilterBlock(filters);
                    jobOpts.replaceOnlyFilter = filterOnlyStr;
                }

                if (chart && chart.sf_uiModel.chartMode === 'heatmap') {
                    jobOpts.bulk = true;
                    jobOpts.withDerivedMetadata = true;
                    jobOpts.streamStartCallback = function () {};
                }
            }

            function preRunJob(chart) {
                if ($scope.getFilterOverrides) {
                    return;
                }
                let chartToExec;
                if (chartVersionService.getVersion(chart || {}) === 2) {
                    chartToExec = chartDisplayUtils.createConformingModelFromV2(chart);
                } else {
                    chartToExec = angular.copy(chart);
                }

                const jobOpts = {
                    callback: callbackError,
                    eventCallback: callbackError,
                    streamStartCallback: callbackError,
                    onFeedback: callbackError,
                    metaDataUpdated: callbackError,
                    onStreamError: callbackError,
                };

                try {
                    populateJobProgramOpts(chartToExec, jobOpts);
                } catch (e) {
                    $log.warn('An error occurred while generating pre-runner program text : ' + e);
                }
                if (!jobOpts.signalFlowText) {
                    return;
                }
                signalStreamPreRunner.prerun(jobOpts);
            }

            function mapOverrideValue(variable) {
                if (angular.isArray(variable.value) && variable.value.length === 0) {
                    variable.value = '';
                }
                return variable;
            }

            function initialize(data) {
                $scope.initializedData = true;
                if (!data.group && !data.dashboard && !$scope.snapshot.id) {
                    $log.error('Could not find the dashboard to be displayed.  Bailing out!');
                    $location.path('/dashboards');
                    return;
                }

                $scope.parentPage = data.group || {};
                $scope.isEmptyPage = $scope.parentPage?.dashboards?.length === 0;

                if (data.dashboard) {
                    if (data.dashboard.id || $scope.snapshot.id) {
                        $scope.model = data.dashboard;
                        $scope.configId = data.configId;
                    }

                    if (!$scope.activeDashboardConfig) {
                        $scope.activeDashboardConfig = dashboardUtil.getConfig(
                            $scope.parentPage.dashboardConfigs || [],
                            $scope.configId
                        );
                    }

                    // we want to map variable override values of [] to '' for display
                    // to prevent empty pill box from appearing
                    if (
                        $scope.activeDashboardConfig &&
                        $scope.activeDashboardConfig.filtersOverride
                    ) {
                        const variables = $scope.activeDashboardConfig.filtersOverride.variables;
                        if (variables) {
                            $scope.activeDashboardConfig.filtersOverride.variables =
                                variables.map(mapOverrideValue);
                        }
                    }

                    if (data.dashboard.charts && data.dashboard.charts.length) {
                        const charts = data.dashboard.charts;

                        let foldCharts = 0;

                        charts.forEach(function (chart) {
                            if (chart.row <= $scope.foldRow) {
                                foldCharts++;
                            }
                        });

                        $scope.numFoldCharts = foldCharts;
                    }

                    setUpEventOverlays(data);
                    dashboardSavedVariablesContext.setSavedVariables(
                        data.dashboard.filters?.variables || []
                    );

                    if (data.charts && data.charts.length) {
                        if (isSampleDashboard) {
                            userAnalytics.event('sample-dashboard', 'open');
                        }

                        setUpFilters(data);
                    }

                    const chartIdsAboveFold = [];
                    // track the number of v2 charts we expect to get data early for as well as prepopulate
                    // the signalflow meta-information we will need.
                    // once v2 migration is complete we wont need to check versions.
                    const signalFlowAboveFold = [];
                    data.dashboard.charts.forEach(function (chartDef) {
                        if (chartDef.row < 3) {
                            chartIdsAboveFold.push(chartDef.chartId);
                        }
                    });

                    data.charts
                        // SLO charts should be skipped
                        .filter((chart) => !isSLOChartType(chart.options.type))
                        .forEach(function (chart) {
                            const chartId = chart.sf_id || chart.id;
                            if (chartIdsAboveFold.indexOf(chartId) !== -1) {
                                preRunJob(chart, data);
                                if (chart.id && chart.programText) {
                                    signalFlowAboveFold.push(chart.programText);
                                }
                            }
                        });
                    signalFlowInfoService.prePopulate(signalFlowAboveFold);
                }

                if ($scope.trackVisited && !$scope.snapshot.id) {
                    if ($location.path().match(/\/mypage|\/page\//)) {
                        const groupId = data.group.id || data.group.sf_id;
                        const toChange =
                            data.dashboard && data.dashboard.id
                                ? '/dashboard/' + data.dashboard.id
                                : '/page/' + groupId;
                        if (toChange !== $location.path()) {
                            $location.path(toChange);
                        }
                    }

                    if ($scope.snapshot.id) {
                        currentUser.orgId().then((orgId) => {
                            recentPagesService.setSnapshotVisited(orgId, {
                                snapshotId: $scope.snapshot.id,
                            });
                        });
                    } else if (data.dashboard) {
                        currentUser.orgId().then((orgId) => {
                            recentPagesService.setDashboardVisited(orgId, {
                                groupId: data.group?.id || data.dashboard.groupId,
                                dashboardId: data.dashboard.id,
                                configId: $scope.configId,
                                nameOverride: ($scope.activeDashboardConfig || {}).nameOverride,
                            });
                        });
                    }
                }

                data.charts.forEach(function (chart) {
                    // this should not happen unless chart was created outside of ui
                    const currentIndex = chart.sf_id || chart.id || Date.now();
                    // V2 charts are wrapped in a layer to provide the chartIndex for
                    // backwards compatibility
                    if (!chart.sf_type && chart.chart) {
                        chart = chart.chart;
                    }

                    $scope.allCharts[currentIndex] = sanitizeChartModel(chart);
                });

                // When referencing the dashboard as a component from the olly React
                // code (like <Dashboard...>), $scope.allCharts gets reset to undefined
                // when the parent component gets rerendered (due to a digest cycle being
                // triggered). Keep a copy of allCharts to mitigate that issue.
                $scope.allChartsCopy = $scope.allCharts;

                // Tracking logic for determining when charts are initialized and loaded
                // for the first time load of the dashboard
                if ($scope.model) {
                    const chartCount = $scope.model.charts.length;
                    if (chartCount === 0) {
                        // If there are no charts on the dashboard, it qualifies as having all
                        // charts initialized and loaded
                        onChartsInitialized();
                        onChartsLoaded();
                    } else {
                        let loaded = 0,
                            initialized = 0;
                        $scope.chartFullLoad = function () {
                            loaded++;
                            if (loaded === chartCount) onChartsLoaded();
                        };

                        $scope.chartInitialize = function () {
                            initialized++;
                            if (initialized === chartCount) onChartsInitialized();
                        };
                    }
                }
            }

            function setUpEventOverlays(data) {
                const eventOverlays = data.dashboard.eventOverlays;

                if (eventOverlays) {
                    eventOverlays.forEach((eventOverlay) => {
                        eventOverlay.sources = eventOverlay.sources.map((source) => {
                            return {
                                property: source.property,
                                propertyValue: source.value,
                                value: source.value,
                                applyIfExists: source.applyIfExists,
                                NOT: source.not,
                            };
                        });
                    });
                }
            }

            function resetFilterState() {
                $scope.filterState.sourceFilterDirty = false;
                $scope.filterState.timePickerDirty = false;
                $scope.filterState.variablesDirty = false;
                $scope.filterState.pointDensityDirty = false;
                $scope.filterState.eventOverlayDirty = false;
            }

            function hasVariablesChanged(variableOverrides, savedVariables) {
                savedVariables = savedVariables || [];
                if (!variableOverrides || variableOverrides.length === 0) {
                    return false;
                }
                for (let i = 0; i < savedVariables.length; i++) {
                    const savedVariable = savedVariables[i];
                    const variableOverride = dashboardVariableUtils.getVariableByProperty(
                        variableOverrides,
                        savedVariable.property
                    );
                    if (
                        !variableOverride ||
                        !dashboardVariableUtils.semanticOverrideEqualityCheck(
                            savedVariable.value,
                            variableOverride.value
                        )
                    ) {
                        return true;
                    }
                }
                return false;
            }

            function isSourcesDirty(sourcesOverride, savedSources) {
                // Empty list for saved filters is same as no override
                if (!sourcesOverride && savedSources && !savedSources.length) {
                    return false;
                } else {
                    return !angular.equals(sourcesOverride, savedSources);
                }
            }

            function isVariablesDirty(variablesOverride, savedVariables) {
                let sdVariableValuesChanged = false;
                if ($scope.sdVariableValues) {
                    sdVariableValuesChanged =
                        $scope.sdVariableValues &&
                        hasVariablesChanged(variablesOverride, $scope.sdVariableValues);
                }
                return (
                    hasVariablesChanged(variablesOverride, savedVariables) ||
                    sdVariableValuesChanged
                );
            }

            function standardizeDashboardVariable(variable) {
                const val = dashboardVariableUtils.normalizeVariableValue(
                    dashboardVariableUtils.getVariableDefaultValue(variable)
                );
                return {
                    alias: variable.alias,
                    variable: variable.alias,
                    property: variable.property,
                    applyIfExists: variable.applyIfExists,
                    value: val,
                };
            }

            function setUpFilters(data) {
                const savedFilters = getSavedFilters(
                    dashboardV2Service.apiDashboardToUI(data.dashboard),
                    $scope.activeDashboardConfig
                );

                let savedVariables = null;
                if (savedFilters.variables && savedFilters.variables.length) {
                    savedVariables = savedFilters.variables.map(standardizeDashboardVariable);

                    savedFilters.variables = savedVariables;
                } else if (savedFilters.variables && savedFilters.variables.length === 0) {
                    // In views, no variables may be empty arrays instead of nulls
                    savedFilters.variables = null;
                }

                // gets filter/variable/time picker values from query string, if there are any
                let sourceOverride = urlOverridesService.getSourceFilterOverrideList();
                let variablesOverride = dashboardVariablesService.getVariablesUrlOverrideAsModel();
                let timePickerOverride = urlOverridesService.getGlobalTimePicker();
                let pointDensityOverride = urlOverridesService.getPointDensity();

                const currentFilters = {
                    sources: sourceOverride,
                    variables: variablesOverride,
                    time: timePickerOverride,
                    density: pointDensityOverride,
                };

                if (
                    dashboardUtil.setFilterOverrides(
                        savedFilters,
                        currentFilters,
                        $scope.sdVariableValues
                    )
                ) {
                    $location.replace();
                    sourceOverride = urlOverridesService.getSourceFilterOverrideList();
                    variablesOverride = dashboardVariablesService.getVariablesUrlOverrideAsModel();
                    timePickerOverride = urlOverridesService.getGlobalTimePicker();
                    pointDensityOverride = urlOverridesService.getPointDensity();
                }

                $scope.filterState.timePickerDirty = !angular.equals(
                    timePickerOverride,
                    savedFilters.time
                );

                if (timePickerOverride) {
                    $scope.$emit(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, timePickerOverride);
                }

                if (sourceOverride && sourceOverride.length) {
                    const sourceOverrideString = urlOverridesService.getSourceOverride();
                    $scope.sourceFilters =
                        sourceFilterService.getSourceFilters(sourceOverrideString);
                } else {
                    $scope.sourceFilters = [];
                }

                $scope.filterState.sourceFilterDirty = isSourcesDirty(
                    sourceOverride,
                    savedFilters.sources
                );
                $scope.filterState.variablesDirty = isVariablesDirty(
                    variablesOverride,
                    savedVariables
                );
                $scope.filterState.pointDensityDirty = !angular.equals(
                    pointDensityOverride,
                    savedFilters.density
                );
            }

            $scope.reset = function () {
                // clear held state about variables and the urls themselves
                variableTransferService.reset();
                urlOverridesService.clearAllNonLocationUrlParams();

                // set up the defaults
                urlOverridesService.setSelectedEventOverlays($scope.model.selectedEventOverlays);
                dashboardUtil.resetFilterOverrides(
                    getSavedFilters($scope.model, $scope.activeDashboardConfig)
                );
                resetFilterState();
            };

            function mapEmptyStringToEmptyArray(sourceFilters) {
                if (!sourceFilters) {
                    return sourceFilters;
                }

                return sourceFilters.map((filter) => {
                    if (filter.value === '') {
                        filter.value = [];
                    }
                    return filter;
                });
            }

            function updateConfigFilters(oldConfig, oldDash) {
                if (!$scope.activeDashboardConfig.filtersOverride) {
                    $scope.activeDashboardConfig.filtersOverride = {};
                }

                let configFilters = mapEmptyStringToEmptyArray(
                    urlOverridesService.getSourceFilterOverrideList()
                );
                // If the parent dashboard has filters defined, but the current context does not define any filter
                // the system should assume user intent is to clear the dashboard filters as an override. This
                // effectively neans the only way to revert back to inheriting from a parent dashboard once a
                // config-level override has been applied is to go through the modal and explicitly unchecking the
                // override checkbox for filters.
                if (
                    oldDash.filters.sources &&
                    oldDash.filters.sources.length &&
                    configFilters === null
                ) {
                    configFilters = [];
                }
                $scope.activeDashboardConfig.filtersOverride.sources = configFilters;

                // Note that it is possible for a variable to be created and already have an override
                // if a variable on a property was deleted, then a new one on the same property is
                // created again. This is because we do not want group updates to propagate from a
                // dashboard action, which would be required if we were to update all mirrors' configs
                // when a dashboard variable was deleted. This assumption is safe as long as we assume
                // a dashboard variable can be uniquely identified (in the context of a dashboard) by
                // its property
                if (needToCreateVariableOverrides(oldDash)) {
                    $scope.activeDashboardConfig.filtersOverride.variables =
                        createMissingVariableOverrides(oldDash.filters.variables);
                }

                $scope.activeDashboardConfig.filtersOverride.variables =
                    dashboardVariablesService.applyVariableOverridesToVariableModel(
                        $scope.activeDashboardConfig.filtersOverride.variables || []
                    );

                oldDash = updateDashboardOnlyOverrides(oldDash);

                oldConfig.filtersOverride = $scope.activeDashboardConfig.filtersOverride;

                let dashboardUpdatePromise;
                if ($scope.isEditable) {
                    dashboardUpdatePromise = dashboardV2Service.update(oldDash);
                } else {
                    dashboardUpdatePromise = $q.when();
                }

                if ($scope.hasGroupWritePermission && !$scope.groupIsServiceDiscovery) {
                    dashboardUpdatePromise = dashboardUpdatePromise.then(() => {
                        return dashboardGroupService.updateDashboardConfig(
                            $scope.parentPage.id,
                            oldConfig
                        );
                    });
                }

                return dashboardUpdatePromise;
            }

            function needToCreateVariableOverrides(dashboard) {
                if (
                    !(
                        dashboard.filters &&
                        dashboard.filters.variables &&
                        dashboard.filters.variables.length
                    )
                ) {
                    return false;
                }

                const variableOverrides =
                    $scope.activeDashboardConfig.filtersOverride.variables || [];
                return (
                    $scope.filterState.variablesDirty &&
                    !dashboard.filters.variables.every((variable) => {
                        return variableOverrides.some((variableOverride) => {
                            return variable.property === variableOverride.property;
                        });
                    })
                );
            }

            function createMissingVariableOverrides(variables) {
                const currentVariableOverrides =
                    $scope.activeDashboardConfig.filtersOverride.variables || [];

                variables.forEach((variable) => {
                    const hasOverride = currentVariableOverrides.some((variableOverride) => {
                        return variable.property === variableOverride.property;
                    });
                    if (!hasOverride) {
                        currentVariableOverrides.push(
                            dashboardUtil.createNewDashboardVariableOverride({
                                property: variable.property,
                                value: variable.value,
                            })
                        );
                    }
                });

                return currentVariableOverrides;
            }

            function updateDashboardFilters(oldDash) {
                if (!$scope.model.filters) {
                    $scope.model.filters = {};
                }

                $scope.model.filters.sources = urlOverridesService.getSourceFilterOverrideList();
                $scope.model.filters.time = urlOverridesService.getGlobalTimePicker();
                $scope.model.filters.density = urlOverridesService.getPointDensity();

                oldDash = updateDashboardOnlyOverrides(oldDash);

                oldDash.filters = $scope.model.filters;
                return dashboardV2Service.update(oldDash);
            }

            function updateDashboardOnlyOverrides(oldDash) {
                if (!$scope.dashboardViewsEnabled || $scope.getAppearanceCount() === 1) {
                    $scope.model.filters.variables =
                        dashboardVariablesService.applyVariableOverridesToVariableModel(
                            $scope.model.filters.variables || []
                        );
                    oldDash.filters.variables = $scope.model.filters.variables;
                }

                $scope.model.selectedEventOverlays = urlOverridesService.getSelectedEventOverlays();
                oldDash.selectedEventOverlays = $scope.model.selectedEventOverlays;

                oldDash.filters.time = urlOverridesService.getGlobalTimePicker();
                oldDash.filters.density = urlOverridesService.getPointDensity() || 0;
                return oldDash;
            }

            $scope.saveFilters = function () {
                const appearanceCount = $scope.refreshAppearanceCount();
                const isMirror = dashboardMirrorService.isDashboardMirror($scope.model.id);
                /*
                 * This is to ensure that we have the most up to date count of appearances for this
                 * dashboard, since the location to save filters is dependent on this information
                 */
                return $q.all({ appearanceCount, isMirror }).then(function ({ isMirror }) {
                    let filteredObjectPromise;
                    if ($scope.snapshot.id) {
                        filteredObjectPromise = $q.when($scope.model).then(() => {
                            $scope.model.filters = {
                                sources: urlOverridesService.getSourceFilterOverrideList(),
                                variables:
                                    dashboardVariablesService.applyVariableOverridesToVariableModel(
                                        $scope.model.filters.variables || []
                                    ),
                                time: urlOverridesService.getGlobalTimePicker(),
                                density: urlOverridesService.getPointDensity(),
                            };
                            $scope.model.selectedEventOverlays =
                                urlOverridesService.getSelectedEventOverlays();
                            return $scope.saveSnapshotEdits(true);
                        });
                    } else if ($scope.dashboardViewsEnabled && isMirror) {
                        /*
                         * In this case, it is possible that overrides for the config object will be set
                         * rather than their counterparts on the dashboard. Here we check what if any
                         * changes to the config are appropriate and save them.
                         */
                        filteredObjectPromise = dashboardGroupService
                            .getDashboardConfig($scope.parentPage.id, $scope.configId)
                            .then((config) => {
                                return dashboardV2Service.get($scope.model.id).then((dashboard) => {
                                    return updateConfigFilters(config, dashboard);
                                });
                            });
                    } else {
                        // If it is not a views org, or the dashboard only appears once, save to the dashboard
                        filteredObjectPromise = dashboardV2Service
                            .get($scope.model.id)
                            .then(updateDashboardFilters);
                    }

                    return filteredObjectPromise
                        .then(function (patched) {
                            $log.info('Saved filters.', patched);
                            const variableResetCandidates = (
                                safeLookup(
                                    $scope,
                                    'activeDashboardConfig.filtersOverride.variables'
                                ) || []
                            )
                                .filter((e) => !e.value || !e.value.length)
                                .map((e) => e.property);
                            $scope.activeDashboardConfig = patched;
                            clearEmptyVariablesByName(variableResetCandidates);
                        })
                        .catch(function (e) {
                            if (isLockedError(e)) {
                                showDashboardSaveAsModal();
                            } else {
                                $log.error('Failed saving filters.', e);
                                $window.alert(
                                    'There was a problem saving filters. Try again later.'
                                );
                            }
                        });
                });
            };

            function clearEmptyVariablesByName(candidateProperties) {
                // clear empty variables (if they were formerly overridden in the config) so we can pick
                // up the underlying dashboard's configuration instead of defining the override to be empty.
                let variables = dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
                variables = variables.filter((variable) => {
                    return (
                        variable.value.length > 0 && candidateProperties.includes(variable.property)
                    );
                });
                dashboardVariablesService.setVariablesOverride(variables);
            }

            $scope.triggerFilterReset = (newConfig) => {
                $scope.activeDashboardConfig = newConfig;
                setUpFilters($scope.data);
            };

            $scope.saveSnapshotEdits = function (saveFilters) {
                const ignoreSavedFilters = !saveFilters;
                return snapshotEditsService
                    .saveDashboardEdits(
                        $scope.model,
                        dashboardUtil.getAllChartModels($scope.allCharts),
                        true,
                        $scope.snapshot,
                        ignoreSavedFilters
                    )
                    .then(function (saved) {
                        $scope.snapshot.id = saved.id;
                        return $scope.model;
                    });
            };

            function showDashboardSaveAsModal(title) {
                return sfxModal.open({
                    templateUrl: dashboardSaveAsModalTemplateUrl,
                    controller: 'dashboardSaveAsModal',
                    resolve: {
                        params: function () {
                            return {
                                dashboard: $scope.model,
                                cloneDashboard: $scope.cloneDashboard,
                                title: title,
                            };
                        },
                        orgId: [
                            'currentUser',
                            function (currentUser) {
                                return currentUser.orgId();
                            },
                        ],
                    },
                    backdrop: 'static',
                    keyboard: false,
                }).result;
            }

            function applyOverrideUrlParams() {
                let timepicker = urlOverridesService.getGlobalTimePicker();
                let sourceOverride = urlOverridesService.getSourceFilterOverrideList();
                let variablesOverride = dashboardVariablesService.getVariablesUrlOverrideAsModel();
                let pointDensityOverride = urlOverridesService.getPointDensity();

                const savedFilters = getSavedFilters($scope.model, $scope.activeDashboardConfig);
                let savedVariables = null;
                if (savedFilters.variables && savedFilters.variables.length) {
                    savedVariables = savedFilters.variables.map(standardizeDashboardVariable);
                }

                $scope.filterState.timePickerDirty = !angular.equals(timepicker, savedFilters.time);
                $scope.filterState.sourceFilterDirty = isSourcesDirty(
                    sourceOverride,
                    savedFilters.sources
                );
                $scope.filterState.variablesDirty = isVariablesDirty(
                    variablesOverride,
                    savedVariables
                );
                $scope.filterState.pointDensityDirty = !angular.equals(
                    pointDensityOverride,
                    savedFilters.density
                );

                if (!timepicker) timepicker = {};
                if (!sourceOverride) sourceOverride = [];
                if (!variablesOverride) variablesOverride = [];
                if (!pointDensityOverride) pointDensityOverride = null;

                if (sourceOverride && sourceOverride.length) {
                    const sourceOverrideString = urlOverridesService.getSourceOverride();
                    $scope.sourceFilters =
                        sourceFilterService.getSourceFilters(sourceOverrideString);
                } else {
                    $scope.sourceFilters = [];
                }

                const data = {
                    sources: $scope.sourceFilters,
                    variables: variablesOverride,
                    density: pointDensityOverride,
                };

                timepicker.store = {};

                $scope.$emit(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, timepicker);

                angular.extend(data, timepicker.store);

                const isAbsolute = 'startUTC' in data;

                // always reset since source filter application is only additive, and charts don't
                // remove filters unless resetting it.
                data.reset = true;

                if (!isAbsolute) {
                    initializeFullScreenReloader();
                } else {
                    $interval.cancel($scope.fullScreenReloader);
                }

                $scope.$emit('broadcast url overrides', data);
            }

            const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                [
                    'sources[]',
                    'variables[]',
                    'density',
                    'startTime',
                    'endTime',
                    'startTimeUTC',
                    'endTimeUTC',
                    'resolutionAdjustable',
                ],
                function () {
                    if ($scope.disableUrlOverrides) {
                        return;
                    }
                    applyOverrideUrlParams();
                }
            );

            $scope.$on('removeGridsterItem', function (ev, toRemove) {
                delete $scope.allCharts[toRemove.chartId];
                $scope.model.charts.splice($scope.model.charts.indexOf(toRemove.widget), 1);
                if (toRemove.chartId) {
                    // wait for chart removal before reserialize
                    // otherwise the reserialize may happen before the chart is removed + membership link removed,
                    // leaving behind an orphan chart
                    removeChart(toRemove.chartId).then(reserializeDataAndSave);
                } else {
                    $timeout(reserializeDataAndSave, 0);
                }
            });

            $scope.$on('removeAllCharts', function () {
                // Only allow bulk deletion of charts in snapshot
                if (!$scope.snapshot.id) {
                    return;
                }
                $scope.model.charts = [];
                $scope.allCharts = {};
                // Force digest to occur and trigger any relevant watches. Gridster
                // needs to make some updates when the widgets change. Without the
                // timeout, the charts are not getting removed from the snapshot.
                $timeout($scope.saveSnapshotEdits, 0);
            });

            $scope.$on('updateSnapShotV2Chart', function (ev, chart) {
                if ($scope.allCharts[chart.id]) {
                    $scope.allCharts[chart.id] = chart;
                }
            });

            $scope.broadcastMouseUp = function (event) {
                $scope.$broadcast('dashboardMouseUp', event);
            };

            $scope.showGlobalTimePicker = true;

            $q.when($scope.permissionsPromise).then(function () {
                if ($scope.viewOnly) {
                    $scope.disableSave = true;
                } else {
                    // Give the dashboard a good amount of time to finish setting up before proceeding with gridster
                    // initialization.  note that resize elements are always present and usable, however the dependent
                    // broadcasts that trigger on resize complete do not work until the configs are applied, which
                    // is costly and deferred.
                    $timeout(function lazyLoadResizableAndDraggable() {
                        const resizeableConfig = {
                            enabled: canChangeLayout(),
                            stop: function () {
                                broadcastResize();
                                alertParentOfDragAction();
                                reserializeDataAndSave();
                            },
                        };

                        const draggableConfig = {
                            enabled: canChangeLayout(),
                            handle: '.chart-drag-handle',
                            stop: function onDragStop() {
                                alertParentOfDragAction();
                                reserializeDataAndSave();
                            },
                        };

                        $scope.gridsterOptions.resizable = resizeableConfig;
                        $scope.gridsterOptions.draggable = draggableConfig;
                        $scope.resizeInitialized = true;
                    }, 5000);
                }
            });

            function alertParentOfDragAction() {
                $scope.$emit('dashboard layout changed');
            }

            $scope.getCurrentQuery = function () {
                return chartUtils.getChartSignalFlow($scope.allCharts || $scope.allChartsCopy);
            };

            function canChangeLayout() {
                return !!$scope.isEditable && !IS_MOBILE;
            }

            function updateGridsterConfig() {
                $scope.gridsterOptions.draggable.enabled = canChangeLayout();
                $scope.gridsterOptions.resizable.enabled = canChangeLayout();
            }

            function reserializeDataAndSave() {
                if (isSampleDashboard) {
                    userAnalytics.event('sample-dashboard', 'save');
                }

                dashboardUtil.reserializeData($scope.model);

                if ($scope.snapshot.id) {
                    return $scope.saveSnapshotEdits();
                }

                dashboardV2Service
                    .update($scope.model)
                    .then(function (savedModel) {
                        $scope.model.lastUpdated = savedModel.lastUpdated;
                    })
                    .catch((e) => {
                        if (isLockedError(e)) {
                            showDashboardSaveAsModal();
                        }
                    });
            }

            function isLockedError(error) {
                return (
                    error.data &&
                    error.data.message &&
                    error.data.message.includes('locked and cannot be modified')
                );
            }

            $scope.isEmptyDashboard = () => {
                return $scope.allCharts && Object.keys($scope.allCharts).length === 0;
            };

            function removeChart(chartId) {
                if ($scope.snapshot.id) {
                    return $q.when();
                }
                return dashboardV2Util.deleteChart($scope.model, chartId);
            }

            $scope.$watch('isEditable', updateGridsterConfig);
            $scope.cancelSelections = function () {
                $scope.$broadcast('cancel selections');
            };

            $scope.$on('load all remaining charts', loadAllRemainingCharts);

            $scope.$on('showChartsVerticalLines', function (evt, timestamp) {
                $scope.sharedChartState.mouseHoverTimestamp = timestamp;
                $scope.sharedChartState.verticalLineTimestamp = timestamp;
            });

            $scope.$on('hideChartsVerticalLines', function () {
                $scope.sharedChartState.mouseHoverTimestamp = null;
                $scope.sharedChartState.verticalLineTimestamp = null;
            });

            $scope.$on('$destroy', function () {
                cancelOpenSpans();
                $interval.cancel($scope.fullScreenReloader);
                angular.element('#dashboard_' + $scope.$id).off('mousemove');
                if (resizeObserver) {
                    resizeObserver.disconnect();
                } else {
                    angular.element($window).off('resize', throttledResize);
                }
                unregisterRouteWatchGroup();
            });

            initializeFullScreenReloader();

            function setDefaultVariables() {
                const dashboard = $scope.data.dashboard || {};
                const isSD =
                    dashboard.discoveryOptions &&
                    dashboard.discoveryOptions.selectors &&
                    dashboard.discoveryOptions.selectors.length;
                const variables = (dashboard.filters || {}).variables || [];
                if (isSD && variables.length && !$scope.disableVariables) {
                    const needsValues = variables.filter(function (variable) {
                        return variable.required && !variable.value;
                    }).length;
                    return dashboardVariableSuggestUtils
                        .getRequiredVariables(dashboard, $scope.data.charts || [])
                        .then(function (values) {
                            return { values: values, needsValues: needsValues };
                        })
                        .catch(function (e) {
                            $log.error('Error finding variables', e);
                            return { values: [], needsValues: needsValues };
                        });
                } else {
                    return $q.when({ values: [], needsValues: false });
                }
            }

            setDefaultVariables().then(function (newOverrides) {
                if (newOverrides.values.length) {
                    $scope.sdVariableValues = newOverrides.values.map((valueStr) => {
                        return dashboardVariableUtils.getVariableFromStr(valueStr);
                    });
                } else {
                    $scope.needsVariableValues = newOverrides.needsValues;
                }
                initialize($scope.data);
                recalculateViewableRows();
                metrics.endRouteUi('dashboard');
            });

            updateMinMaxRow();
        },
    ],
};

angular.module('signalview.dashboard').directive('dashboard', [
    function () {
        return dashboard;
    },
]);

// eslint-disable-next-line import/no-unused-modules
export { dashboard };
