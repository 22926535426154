import styled from 'styled-components';

export const StyledSelectorContainer = styled.div`
    display: ${({ isLoading }) => (isLoading ? 'flex' : 'block')};
`;

export const StyledSelecter = styled.div`
    padding-right: 35px !important;
    color: rgba(0, 0, 0, 0.65);
    box-shadow: none;

    &:hover {
        background-color: rgba(0, 0, 0, 0.07);
        color: #2c2c2c;
    }
    &:focus-within {
        outline: 2px solid #0264d7;
        box-shadow: 0 0 0 2px #f9f9f9, 0 0 0 5px rgba(2, 100, 215, 0.7) !important;
    }
    ${({ colorScheme }) =>
        colorScheme === 'dark' && 'background-color: #444 !important; color: #ccc'};
`;

export const StyledMenu = styled.div`
    max-height: 150px;
    overflow: auto;
    z-index: 1010;
`;
export const StyledSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border: 2px solid transparent; /* Default border for the wrap */
  border-radius: 4px;
  transition: border-color 0.3s ease;
  margin: 7px 9px 3px 9px !important
  &:focus {
    border-color: blue;
  };
`;

export const StyledSearchInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    padding: 2px !important;
    font-size: 11px !important;
    margin-left: 2px;
    background-color: ${({ colorScheme }) => (colorScheme === 'dark' ? '#27292e' : 'none')};
    color: ${({ colorScheme }) => (colorScheme === 'dark' ? 'hsla(0,0%,100%,.7)' : 'none')};
`;

export const StyledIconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 2px !important;
`;

export const StyledMenuWrapper = styled.div`
    border: ${({ colorScheme }) => (colorScheme === 'dark' ? 'none' : '1px solid #c7c7c7')};
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.03), 0 2px 23px rgba(0, 0, 0, 0.05),
        0 9px 107px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color: ${({ colorScheme }) => (colorScheme === 'dark' ? '#27292e' : 'none')};
    color: ${({ colorScheme }) => (colorScheme === 'dark' ? 'hsla(0,0%,100%,.7)' : 'none')};
    position: absolute;
    z-index: 1000;
    background-color: ${({ colorScheme }) => (colorScheme === 'dark' ? '#27292e' : '#fff')};
`;
