import detectorInfoPane from './detectorInfoPane';
import detectorPermissions from './detectorPermissions';
import detectorSettingsModal from './detectorSettingsModal';

import detectorV1New from './detectorV1New';
import detectorV1Edit from './detectorV1Edit';
import signalTypeService from './signalTypeService';

angular
    .module('signalview.detector', [
        'signalview.chartbuilder',
        'sfx.api.v2',
        'sfx.ui',
        'sfx.data',
        'sfx.util',
        'signalboost',
        'signalview.sfGlobalNav',
        'signalview.detector.notification',
        'signalview.detector.rule',
        'signalview.detector.signal',
        'signalview.chartdisplay.shared',
        'signalview.snapshot',
        'signalview-utils.functions',
        'signalview-utils.signalflowv2',
        'signalflowv2',
        'signalview.userAnalytics',
    ])
    .component('detectorInfoPane', detectorInfoPane)
    .component('detectorPermissions', detectorPermissions)
    .component('detectorV1New', detectorV1New)
    .component('detectorV1Edit', detectorV1Edit)
    .service('detectorSettingsModal', detectorSettingsModal)
    .service('signalTypeService', signalTypeService)
    .run([
        '$window',
        'moment',
        function ($window, moment) {
            function getStringFromSafeString(val) {
                if (angular.isDefined(val)) {
                    return val.toString();
                }
                return val;
            }
            function equals(lvalueIn, rvalueIn) {
                const lvalue = getStringFromSafeString(lvalueIn);
                const rvalue = getStringFromSafeString(rvalueIn);
                if (lvalue === rvalue) {
                    return true;
                }
                const quotedLv = "'" + lvalue + "'";
                const quotedRv = "'" + rvalue + "'";
                return lvalue === quotedRv || quotedLv === rvalue;
            }
            // for testing validation only
            const helperFn = function () {
                return '';
            };
            ['emitter', 'forEachEntry', 'htmlNewLine', 'notEmpty'].forEach(function (helper) {
                $window.Handlebars.registerHelper(helper, helperFn);
            });

            // real helper
            $window.Handlebars.registerHelper('eq', function (lvalue, rvalue, options) {
                if (arguments.length >= 3 && equals(lvalue, rvalue)) {
                    return options.fn(this);
                }
                return options.inverse(this);
            });
            $window.Handlebars.registerHelper('neq', function (lvalue, rvalue, options) {
                if (arguments.length >= 3 && !equals(lvalue, rvalue)) {
                    return options.fn(this);
                }
                return options.inverse(this);
            });
            $window.Handlebars.registerHelper('is_empty', function (lvalueIn, options) {
                const lvalue = getStringFromSafeString(lvalueIn);
                if (!angular.isDefined(lvalue) || lvalue === 'None') {
                    return options.fn(this);
                }
                try {
                    const arr = JSON.parse(lvalue);
                    if (angular.isArray(arr) && !arr.length) {
                        return options.fn(this);
                    }
                } catch (e) {
                    // not json
                }
                return options.inverse(this);
            });
            $window.Handlebars.registerHelper('dateTimeFormat', function (time, options) {
                if (time === null) {
                    return null;
                }

                const format = options.hash['format'];
                switch (format) {
                    case 'full':
                        return moment(time).format('MM/DD/YYYY hh:mm:ss a');
                    case 'iso':
                    default:
                        return time;
                }
            });
        },
    ])
    .constant('PACKAGE_INFO', {
        package: 'signalfx',
        version: '0.0.1',
    });
