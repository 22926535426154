import templateUrl from './metricsCatalogResults.tpl.html';
import tooltipTemplateUrl from '../tooltips/metricTooltip.tpl.html';
import metricDescriptionTemplateUrl from './metricDescription.tpl.html';
import metricsResultsSummaryTemplateUrl from './metricsResultsSummary.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default {
    templateUrl,
    bindings: {
        results: '<',
        terms: '<',
        filters: '<',
        addFilter: '<',
        isActiveMetricsOnly: '<',
    },
    controller: [
        'sfxModal',
        '$q',
        'chartUrlUtils',
        'metricsCatalogSearchUtil',
        'metricService',
        'hasCapability',
        function (
            sfxModal,
            $q,
            chartUrlUtils,
            metricsCatalogSearchUtil,
            metricService,
            hasCapability
        ) {
            const $ctrl = this;
            const metricProperties = {};
            $ctrl.hasUpdateMetricCapability = false;
            $ctrl.maxResultsReached = false;
            $ctrl.includedFilters = [];
            $ctrl.excludedFilters = [];

            hasCapability(Capability.UPDATE_METRIC).then(
                (hasUpdateMetricCapability) =>
                    ($ctrl.hasUpdateMetricCapability = hasUpdateMetricCapability)
            );

            // Lifecyle functions
            $ctrl.$onInit = $onInit;

            // Bound functions
            $ctrl.getChartUrlForMetric = getChartUrlForMetric;
            $ctrl.editMetricDescription = editMetricDescription;
            $ctrl.isArchived = isArchived;
            $ctrl.getTooltip = getTooltip;

            $ctrl.tooltipTemplateUrl = tooltipTemplateUrl;
            $ctrl.metricsResultsSummaryTemplateUrl = metricsResultsSummaryTemplateUrl;

            $ctrl.toggleMetricExpand = toggleMetricExpand;

            $ctrl.getProperties = getPropertiesForMetric;

            function $onInit() {
                metricsCatalogSearchUtil.addMetricResultMetadata($ctrl.results, $ctrl.terms);
                $ctrl.resultsCapped = metricsCatalogSearchUtil.areMetricResultsCapped(
                    $ctrl.results
                );
                processFilters();
            }

            // determine whether the metric is an archived metrics
            // this is calculated in metricsCatalogSearchUtil.js
            function isArchived(metric) {
                return metric.isArchived;
            }

            function getTooltip(metric) {
                if (isArchived(metric)) {
                    return {
                        showDescription: false,
                        getFilterString: null,
                        onClickActionText: 'Information is not available for archived metric.',
                        showMetricLabels: false,
                    };
                } else {
                    return {
                        showDescription: false,
                        getFilterString: getFilterString,
                        onClickActionText: 'Click metric name to view in chart',
                        showMetricLabels: true,
                    };
                }
            }

            function processFilters() {
                $ctrl.filters.forEach((filter) => {
                    if (filter.NOT) {
                        $ctrl.excludedFilters.push(filter.value.substring(1)); //remove ! symbol
                    } else {
                        $ctrl.includedFilters.push(filter.value);
                    }
                });
            }

            function getFilterString(filter) {
                return filter.value;
            }

            function getChartUrlForMetric(metric) {
                // create a list with suggested filters and search filters
                const suggestedFilters = metric.filters.map((filter) => filter.value);
                const searchBarFilters = $ctrl.filters.map((filter) => filter.value);
                const filtersList = suggestedFilters.concat(searchBarFilters);

                const metricFilter = `sf_metric:${metric.name}`;
                filtersList.push(metricFilter);

                return chartUrlUtils.getNewChartURLWithFilters(filtersList);
            }

            function getPropertiesForMetric(metric, filters) {
                if (!!metricProperties[metric]) {
                    return $q.when(metricProperties[metric]);
                }
                return metricService
                    .getProperties(metric, filters, $ctrl.isActiveMetricsOnly)
                    .then((result) => {
                        const properties = result.data;
                        metricProperties[metric] = properties;
                        return properties;
                    });
            }

            function editMetricDescription(metric) {
                const modalParams = {
                    templateUrl: metricDescriptionTemplateUrl,
                    controller: 'metricDescriptionController',
                    resolve: {
                        metricObj: () => metric,
                    },
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false,
                };

                return sfxModal.open(modalParams).result.then((result) => {
                    //update description for metric being displayed
                    metric.description = result.description;
                }, angular.noop);
            }

            function toggleMetricExpand(metric) {
                metric.expanded = !metric.expanded;
            }
        },
    ],
};
