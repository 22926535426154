import objectNameTemplateUrl from './objectName.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import { generateTimeSeriesName } from '@splunk/olly-utilities/lib/Timeseries';

angular
    .module('signalview.objectmanager')
    .constant('canAddToFilter', ['Metric', 'Dimension', 'Property', 'Topic', 'Tag'])
    .directive('objectName', [
        '$window',
        '$log',
        '$location',
        '$timeout',
        'sfxModal',
        'detectorService',
        'dashboardCloner',
        'ES_INDEXING_DELAY',
        'signalboost',
        'METRIC_TYPE_CONVERSION',
        'getLinkForDocument',
        'v2ObjectService',
        'config',
        'pageService',
        'sfObjInfo',
        'canAddToFilter',
        'deleteDetector',
        'dashboardV2Util',
        'dashboardV2Service',
        'isEphemeralService',
        'dashboardSettingsModal',
        'urlOverridesService',
        'chartUrlUtils',
        'SYSTEM_USER_ID',
        function (
            $window,
            $log,
            $location,
            $timeout,
            sfxModal,
            detectorService,
            dashboardCloner,
            ES_INDEXING_DELAY,
            signalboost,
            METRIC_TYPE_CONVERSION,
            getLinkForDocument,
            v2Object,
            config,
            pageService,
            sfObjInfo,
            canAddToFilter,
            deleteDetector,
            dashboardV2Util,
            dashboardV2Service,
            isEphemeralService,
            dashboardSettingsModal,
            urlOverridesService,
            chartUrlUtils,
            SYSTEM_USER_ID
        ) {
            return {
                restrict: 'AE',
                scope: {
                    object: '=',
                    filters: '=?',
                    isNewCatalog: '=',
                    inViewAll: '=',
                    viewMode: '=',
                    archivedMetric: '=',
                },
                templateUrl: objectNameTemplateUrl,
                link: function ($scope) {
                    $scope.objname = '';
                    const isSuperPowers = config('superpower.unreleasedFeatures');
                    $scope.isServiceDiscovery =
                        ($scope.object.sf_creator === SYSTEM_USER_ID ||
                            $scope.object.creator === SYSTEM_USER_ID) &&
                        !isSuperPowers;

                    $scope.deleteModalState = {
                        isOpen: false,
                        onCancel: function () {
                            $timeout(function () {
                                $scope.deleteModalState.isOpen = false;
                            }, 0);
                        },
                    };

                    function removeSearchSelection() {
                        $location.search('select', null);
                        ngRoute.reload();
                    }

                    $scope.$on('pageDeleted', removeSearchSelection);

                    $scope.deleteDetector = function () {
                        $scope.deleteModalState.isOpen = true;
                        $scope.deleteModalState.isLoading = true;

                        // refetch the detector so we can get the right version of it(v1, v2)
                        detectorService
                            .get($scope.object.sf_id || $scope.object.id)
                            .then(function (detector) {
                                $scope.deleteModalState.onDelete = function () {
                                    $timeout(function () {
                                        $scope.deleteModalState.isOpen = false;
                                        deleteDetector(detector).then(function (deleted) {
                                            if (deleted === true) {
                                                $location.search({
                                                    selectedKeyValue: 'sf_type:Detector',
                                                });
                                            }
                                        });
                                    }, 0);
                                };

                                $timeout(function () {
                                    const name = detector.sf_detector || detector.name;

                                    scope.deleteModalState.title = 'Delete Detector';
                                    $scope.deleteModalState.description =
                                        'You are about to permanently delete the detector ' +
                                        name +
                                        '. Any alerts from this detector will stop alerting.';
                                    $scope.deleteModalState.isLoading = false;
                                });
                            });
                    };

                    $scope.cloneDashboard = function () {
                        const dashboard = $scope.object;
                        dashboardV2Util
                            .getDashboardAndParentPage(dashboard.sf_id)
                            .then(({ dashboard, page }) => {
                                dashboardCloner(
                                    dashboard,
                                    null, // chart models (fetched by cloner if not provided)
                                    $scope.snapshot,
                                    page, // default target
                                    null // routeChangeCallback (idfk)
                                );
                            });
                    };

                    $scope.showDashboardInfo = function () {
                        dashboardV2Service.get($scope.object.sf_id).then(function (dashboard) {
                            dashboardSettingsModal.info({
                                dashboard: dashboard,
                            });
                        });
                    };

                    function isInFilter(object) {
                        if (!$scope.filters || !object.sf_key) return false;

                        return $scope.filters.some(function (filter) {
                            let filterProperty = filter.property;

                            if (filterProperty === 'sf_tags') filterProperty = 'sf_tag';

                            if (filterProperty === 'sf_key' || filterProperty === 'sf_type') {
                                return filter.propertyValue === object.sf_topic;
                            } else {
                                return (
                                    filterProperty === object.sf_key[0] &&
                                    filter.propertyValue === object[filterProperty]
                                );
                            }
                        });
                    }

                    $scope.viewAll = function () {
                        if ($scope.object.isProperty || $scope.object.isDimension) {
                            $location.search('topicFilterKey', $scope.object.sf_topic);
                        } else if ($scope.object.isType) {
                            $location.search('topicFilterType', $scope.object.sf_topic);
                        }
                    };

                    $scope.addToFilter = function () {
                        const object = $scope.object;

                        let key = object.sf_key[0];
                        let query;

                        if (object.sf_type === 'Topic') {
                            query = 'sf_key:' + object.sf_topic;
                        } else {
                            // Rewrite 'sf_tag' to 'sf_tags' to match against their special
                            // list property on corresponding objects
                            if (key === 'sf_tag') key = 'sf_tags';

                            query = key + ':' + object[key];
                        }

                        const sources = urlOverridesService.getSourceOverride() || [];
                        sources.push(query);
                        urlOverridesService.clearTopicFilter();
                        urlOverridesService.setSourceOverride(sources);
                    };

                    function getChartLink(object) {
                        const hasMetricFilter =
                            $scope.filters &&
                            $scope.filters.some(function (filter) {
                                return filter.property === 'sf_metric';
                            });

                        if (
                            !hasMetricFilter &&
                            !(object.sf_type === 'MetricTimeSeries' || object.sf_type === 'Metric')
                        ) {
                            return '';
                        }

                        const filters = [];
                        if ($scope.filters) {
                            $scope.filters.forEach(function (filter) {
                                if (
                                    filter.type === 'property' &&
                                    filter.property &&
                                    filter.propertyValue
                                ) {
                                    filters.push(filter.property + ':' + filter.propertyValue);
                                }
                            });
                        }

                        if (object) {
                            object.sf_key.forEach(function (key) {
                                filters.push(key + ':' + object[key]);
                            });
                        }

                        return chartUrlUtils.getNewChartURLWithFilters(filters);
                    }

                    $scope.getMtsName = function getMtsName(obj) {
                        const metricTypeToDisplay = METRIC_TYPE_CONVERSION.nativeToDisplay;

                        let name = generateTimeSeriesName(obj, null, true);
                        name += ' | ' + obj.sf_metric;
                        name += ' | ' + metricTypeToDisplay[obj.sf_metricType].replace(' ', '');

                        return name;
                    };

                    const readableNames = {
                        Page: 'Dashboard Group',
                        Metrics: 'sf_metric',
                    };

                    $scope.getReadableName = function (name) {
                        const readableName = readableNames[name];

                        if (readableName) {
                            return readableName;
                        } else {
                            return name;
                        }
                    };

                    $scope.editPageName = function () {
                        pageService.editPageName($scope.object, $scope).then((pg) => {
                            angular.extend($scope.object, pg);
                        });
                    };
                    $scope.showInfo = function () {
                        sfObjInfo.showObjectInfo($scope.object);
                    };

                    function getName(object) {
                        if (object.sf_type === 'MetricTimeSeries') {
                            return $scope.getMtsName(object);
                        } else if (object.sf_type === 'EventType') {
                            return decorateNameWithKey('sf_eventType', object.sf_eventType);
                        } else if (
                            object.sf_type === 'Dimension' ||
                            object.sf_type === 'Property'
                        ) {
                            const key = object.sf_key[0];
                            return decorateNameWithKey(key, object[key]);
                        } else if (object.sf_type === 'Topic') {
                            return $scope.getReadableName(object.sf_key[0]);
                        } else if (v2Object.isV2Object(object)) {
                            return object.name;
                        } else {
                            const key = 'sf_' + object.sf_type.toLowerCase();
                            const value = object[key] || '';
                            return decorateNameWithKey(key, value);
                        }
                    }

                    function decorateNameWithKey(key, value) {
                        return `${key}: ${value}`;
                    }

                    function setViewInChart() {
                        $scope.viewInChart = null;
                        if (!$scope.viewMode) {
                            if ($scope.object.sf_type === 'MetricTimeSeries') {
                                $scope.viewInChart = { type: 'mts' };
                            } else if ($scope.object.sf_type === 'Metric') {
                                $scope.viewInChart = { type: 'metric' };
                            }
                        }
                    }

                    function updateIsEphemeral(object) {
                        if (config('superpower.unreleasedFeatures')) {
                            if (object.sf_type === 'Topic' && object.sf_topic) {
                                $scope.isEphemeral = false;
                                $scope.isDieQuickly = false;
                                isEphemeralService(object.sf_topic).then(function (result) {
                                    $scope.isEphemeral = result.isEphemeral;
                                    $scope.isDieQuickly = result.isDieQuickly;
                                });
                            } else {
                                $scope.isEphemeral = object.sf_isEphemeral;
                                $scope.isDieQuickly = object.sf_isDieQuickly;
                            }
                        }
                    }

                    $scope.$watch('object', function (object) {
                        $scope.objname = getName(object);
                        $scope.link = getLinkForDocument(object);
                        $scope.chartLink = getChartLink(object);
                        $scope.isInFilter = isInFilter(object);
                        if (object.sf_type === 'Topic') {
                            $scope.canAddToFilter = object.isDimension;
                        } else {
                            $scope.canAddToFilter = canAddToFilter.indexOf(object.sf_type) !== -1;
                        }
                        updateIsEphemeral(object);
                        setViewInChart();
                    });

                    $scope.$watch('filters', function () {
                        const object = $scope.object;
                        if (!object) return;

                        $scope.chartLink = getChartLink(object);
                        $scope.isInFilter = isInFilter(object);
                    });
                },
            };
        },
    ]);
