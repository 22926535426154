import React, { useCallback } from 'react';
import { react2angular } from 'react2angular';
import {
    ArchivedMetricsWarningBar,
    EntityType,
} from '@splunk/olly-imm/build/archived-metrics/ArchivedMetricsWarningBar';
import { ArchivedMetrics } from '@splunk/olly-imm/build/archived-metrics/ArchivedMetrics';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';
import type { UserAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalytics';

type ArchivedMetricsWarningBarProps = {
    entityType: EntityType;
    archivedMetrics: ArchivedMetrics;
    userAnalytics: ReturnType<UserAnalytics>;
};

const entityCategoryMap: Record<string, string> = {
    chart: 'Chart',
    newChart: 'Chart',
    dashboard: 'Dashboard',
    detector: 'Detector',
    newDetector: 'Detector',
    metricMetadata: 'Metric_Metadata',
};

export const ArchivedMetricsWarning = ({
    entityType,
    archivedMetrics,
    userAnalytics,
}: ArchivedMetricsWarningBarProps): JSX.Element => {
    const themeKey = useLegacyThemeServiceKey();

    const onPresented = useCallback(
        (entityType: string) => {
            userAnalytics.event(
                `Data_View/${entityCategoryMap[entityType]}`,
                'Presented',
                entityType,
                'Archived_Metrics/Warning_Banner'
            );
        },
        [userAnalytics]
    );

    return (
        <ThemeProvider colorScheme={themeKey}>
            <ArchivedMetricsWarningBar
                entityType={entityType}
                archivedMetrics={archivedMetrics}
                onPresented={onPresented}
            ></ArchivedMetricsWarningBar>
        </ThemeProvider>
    );
};

export const archivedMetricsWarningBar = react2angular(
    ArchivedMetricsWarning,
    ['entityType', 'archivedMetrics'],
    ['userAnalytics']
);
