import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import SquaresLayeredPlus from '@splunk/react-icons/SquaresLayeredPlus';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledSquaresLayeredPlus = styled(SquaresLayeredPlus)`
    width: 20px;
    height: 20px;
    color: ${variables.contentColorDefault};
`;

export const CopyIcon: React.FC = () => {
    const themeKey = useLegacyThemeServiceKey();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledSquaresLayeredPlus />
        </ThemeProvider>
    );
};
