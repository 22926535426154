import { useState, useEffect, useCallback } from 'react';
import { useGetInferredServiceValues } from './useFetchInferredServicesData';
/**
 * This hook is used for AppD Show On selector component
 * @returns It will return the list of inferred service
 */
const useFetchAppDShowOnServices = () => {
    const [services, setServices] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { getInferredServiceValues } = useGetInferredServiceValues();

    const fetchInferServiceList = useCallback(async () => {
        const ONE_HOUR_MILLIS = 1000 * 60 * 60;
        const LOOKBACK_8D = ONE_HOUR_MILLIS * 24 * 8;
        const queryFilters = [
            {
                tagName: '_sf_inferredServiceType',
                values: ['_sf_service'],
            },
        ];

        setLoading(true);
        getInferredServiceValues({
            tag: 'sf_service',
            filters: {
                tags: queryFilters,
            },
            timeRange: { lookbackMillis: LOOKBACK_8D },
        })
            .then((res) => {
                const inferredServiceArray = getInferredServiceArray(res);
                setServices(inferredServiceArray);
                setLoading(false);
            })
            .catch((err) => {
                if (err.message) {
                    console.log(`HTTP error! status: ${err.status}`);
                }
            });
    }, [getInferredServiceValues]);

    useEffect(() => {
        const abortController = new AbortController();
        fetchInferServiceList();
        return () => abortController.abort();
    }, [fetchInferServiceList]);

    return { isLoading, services };
};

export default useFetchAppDShowOnServices;

const getInferredServiceArray = (response) => {
    if (!response) return [];
    const inferredServices = [];
    response.forEach((serviceObj) => {
        const { tags } = serviceObj;
        tags.forEach((tag) => {
            const { value } = tag;
            if (value) {
                inferredServices.push(value);
            }
        });
    });

    return inferredServices;
};
