import styled from 'styled-components';
import { variables } from '@splunk/themes';
import React, { ReactNode, useContext } from 'react';
import { SignalviewTopNavContext } from '../TopNavContextProvider';
import { PageHeader, PageHeaderProps } from '@splunk/olly-common';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../common/theme/ThemeProvider';

/**
 *  Override .sf-bootstrap styles
 */
export const StyledPageHeaderWrapper = styled.div`
    h1 {
        color: ${variables.contentColorActive};
        font-size: ${variables.fontSizeXXLarge};
        font-weight: ${variables.fontWeightBold};
        margin: 0;
        padding: 0;
    }

    a:not([disabled], [aria-disabled='true']) {
        :focus:not(:hover) {
            color: ${variables.contentColorActive};
        }
        color: ${variables.contentColorActive};
    }
`;

export type PageHeaderWrapperProps = Pick<PageHeaderProps, 'description' | 'navigation'> & {
    rightContent?: ReactNode;
    titleContent?: ReactNode;
};

export const PageHeaderWrapper = ({
    description,
    navigation: customNavigation,
    rightContent,
    titleContent,
}: PageHeaderWrapperProps): React.JSX.Element => {
    const themeKey = useLegacyThemeServiceKey();
    const { navigation } = useContext(SignalviewTopNavContext);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledPageHeaderWrapper>
                <PageHeader
                    description={description}
                    navigation={customNavigation ?? navigation}
                    rightContent={rightContent}
                    titleContent={titleContent}
                />
            </StyledPageHeaderWrapper>
        </ThemeProvider>
    );
};
