export default [
    'currentUser',
    'traceSuggestionService',
    function (currentUser, traceSuggestionService) {
        const APM_ENVIRONMENT_PROPERTY = 'sf_lastViewedAPM2Environment';

        return {
            getEnvironmentInfo,
        };

        async function getEnvironmentInfo() {
            const environments = await traceSuggestionService.getEnvironments();
            const preferredEnv = await getPreferredEnvironment(environments);

            markCurrentEnvironmentInPrefs(preferredEnv);
            return { environments, current: preferredEnv };
        }

        // Updates last viewed environment in user preferences in case the resolved
        // environment does not match the current environment
        function markCurrentEnvironmentInPrefs(environment) {
            return currentUser.orgPreferences().then((prefs) => {
                if (prefs[APM_ENVIRONMENT_PROPERTY] === environment) {
                    return;
                }

                return currentUser.updateOrgPreferences({
                    sf_id: prefs.sf_id,
                    [APM_ENVIRONMENT_PROPERTY]: environment,
                });
            });
        }

        async function getPreferredEnvironment(environments) {
            const orgPreferences = await currentUser.orgPreferences();

            const savedPreferredEnv = orgPreferences[APM_ENVIRONMENT_PROPERTY];

            const hasAvailableSavedPreferredEnv =
                !!savedPreferredEnv && environments.includes(savedPreferredEnv);
            return hasAvailableSavedPreferredEnv ? savedPreferredEnv : environments[0];
        }
    },
];
