import templateUrl from './orgCrossLinkDefinition.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../app/routing/ngRoute';
import * as Sitemaps from '../../../../app/routing/Sitemaps';

export default {
    templateUrl,
    bindings: {
        editable: '<',
    },
    controller: [
        'CROSS_LINK_TYPES',
        'featureEnabled',
        '$location',
        'hasCapability',
        'blockingLoadService',
        function (CROSS_LINK_TYPES, featureEnabled, $location, hasCapability, blockingLoadService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.isLoading = true;
            $ctrl.magneticPageHeader = featureEnabled('magneticPageHeader');
            $ctrl.headerNavigation = [
                {
                    id: Sitemaps.OrganizationCrossLink.map.id,
                    label: Sitemaps.OrganizationCrossLink.map.label,
                },
            ];

            function $onInit() {
                const spinner = blockingLoadService();
                hasCapability(Capability.READ_GLOBAL_DATA_LINK).then(
                    (hasReadGlobalDataLinkCapability) => {
                        if (!hasReadGlobalDataLinkCapability) {
                            ngRoute?.history?.replace('/forbidden');
                        } else {
                            $ctrl.isLoading = false;
                        }

                        if (spinner) {
                            spinner.close();
                        }
                    }
                );
            }
            const { propertyName, propertyValue } = $location.search();

            $ctrl.crossLinkToEdit = { propertyName, propertyValue };

            $ctrl.allowedTypes = [
                CROSS_LINK_TYPES.INTERNAL_LINK,
                CROSS_LINK_TYPES.EXTERNAL_LINK,
                CROSS_LINK_TYPES.SPLUNK_LINK,
                CROSS_LINK_TYPES.KIBANA_LINK,
                CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK,
                CROSS_LINK_TYPES.APPD_LINK,
            ];
        },
    ],
};
