import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector.wizard').factory('apmChartToDetectorTranslator', [
    'DETECTOR_TYPES',
    'APM_METRIC_TYPE',
    'dashboardVariablesService',
    'ServiceEndpointSelection',
    'BusinessWorkflowSelection',
    'urlOverridesService',
    'featureEnabled',
    'signalflowV2Service',
    'signalTypeService',
    function (
        DETECTOR_TYPES,
        APM_METRIC_TYPE,
        dashboardVariablesService,
        ServiceEndpointSelection,
        BusinessWorkflowSelection,
        urlOverridesService,
        featureEnabled,
        signalflowV2Service,
        signalTypeService
    ) {
        const { APM_V2, INFRASTRUCTURE } = DETECTOR_TYPES;

        const BACKEND_APM_METRIC_TYPE_MAPPING = {
            SERVICE: {
                ERROR_RATE: APM_METRIC_TYPE.SERVICE_ERRORS,
                LATENCY: APM_METRIC_TYPE.SERVICE_LATENCY,
            },
            WORKFLOW: {
                ERROR_RATE: APM_METRIC_TYPE.WORKFLOW_ERROR_RATE,
                LATENCY: APM_METRIC_TYPE.WORKFLOW_LATENCY,
            },
            UNKNOWN: {
                UNKNOWN: null,
            },
        };
        const BACKEND_DETECTOR_VERSION_MAPPING = {
            APM2: APM_V2,
            INFRA: INFRASTRUCTURE,
            UNKNOWN: null,
        };

        const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');

        return {
            getSignalTypeSelection,
            getEnvironmentSelection,
            getApmFiltersFromSourcesOverride,
            getDetectorRecommendation,
        };

        // return a service/endpoint or business/workflow selection based on apmMetricType
        function getSignalTypeSelection(filterAlias, apmMetricType) {
            if (signalTypeService.SERVICE_ENDPOINT['apmMetricGroup'].includes(apmMetricType)) {
                return getServiceEndpointSelection(filterAlias);
            } else if (
                isApm2WorkflowsEnabled &&
                signalTypeService.WORKFLOW['apmMetricGroup'].includes(apmMetricType)
            ) {
                return getBusinessWorkflowSelection(filterAlias);
            }
        }

        // return a serviceEndpointSelection based on filterAlias and variables override
        function getServiceEndpointSelection(filterAlias) {
            const overrideSelection = getSelectionFromVariablesOverride(
                signalTypeService.SERVICE_ENDPOINT.name
            );
            const defaultSelection = getSelectionFromFilterAlias(
                filterAlias,
                signalTypeService.SERVICE_ENDPOINT.name
            );

            const service = overrideSelection.service || defaultSelection.service;
            if (service) {
                const endpoints = overrideSelection.endpoints ||
                    defaultSelection.endpoints || ['*'];
                return new ServiceEndpointSelection(service, endpoints);
            }
        }

        // return a businessWorkflowSelection based on filterAlias and variables override
        function getBusinessWorkflowSelection(filterAlias) {
            const overrideSelection = getSelectionFromVariablesOverride(
                signalTypeService.WORKFLOW.name
            );
            const defaultSelection = getSelectionFromFilterAlias(
                filterAlias,
                signalTypeService.WORKFLOW.name
            );

            const resource = overrideSelection.resource || defaultSelection.resource;
            if (resource) {
                return new BusinessWorkflowSelection(resource);
            }
        }

        /**
         * retrieve sources override filters from url and return only the valid apm filters
         * @returns {array} an array of apm filters
         */
        function getApmFiltersFromSourcesOverride() {
            const sourceFilterOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
            return sourceFilterOverrides.filter(isValidApmFilter.bind(null));
        }

        function getDetectorRecommendation(chartProgramText) {
            return signalflowV2Service
                .fetchDetectorTypeRecommendation(chartProgramText)
                .then((response) => ({
                    detectorType: response.type
                        ? BACKEND_DETECTOR_VERSION_MAPPING[response.type]
                        : undefined,
                    apmMetricType:
                        response.entity && response.flavor
                            ? BACKEND_APM_METRIC_TYPE_MAPPING[response.entity][response.flavor]
                            : undefined,
                }));
        }

        function getSelectionFromVariablesOverride(signalTypeName) {
            const variablesOverride =
                dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];

            if (signalTypeName === signalTypeService.SERVICE_ENDPOINT.name) {
                let service;
                let endpoints;
                const serviceFilterProp = signalTypeService.getAPMDimensions().service;
                const operationFilterProp = signalTypeService.getAPMDimensions().operation;
                const serviceVariable = variablesOverride.find(
                    (v) => v.property === serviceFilterProp
                );

                if (serviceVariable && serviceVariable.value && serviceVariable.value.length) {
                    // for now, we decided to just use the first one as service suggestion
                    service = serviceVariable.value[0];
                }

                const endpointVariable = variablesOverride.find(
                    (v) => v.property === operationFilterProp
                );
                if (endpointVariable) endpoints = endpointVariable.value;

                return { service, endpoints };
            } else if (
                isApm2WorkflowsEnabled &&
                signalTypeName === signalTypeService.WORKFLOW.name
            ) {
                let resource;
                const workflowFilterProp = signalTypeService.getAPMDimensions().workflow;
                const workflowVariable = variablesOverride.find(
                    (v) => v.property === workflowFilterProp
                );

                if (workflowVariable && workflowVariable.value && workflowVariable.value.length) {
                    resource = workflowVariable.value[0];
                }

                return { resource };
            }
        }

        // get service/endpoints or business/workflows selection from filterAlias
        function getSelectionFromFilterAlias(filterAlias, signalTypeName) {
            if (signalTypeName === signalTypeService.SERVICE_ENDPOINT.name) {
                let service;
                let endpoints;
                const serviceFilterProp = signalTypeService.getAPMDimensions().service;
                const operationFilterProp = signalTypeService.getAPMDimensions().operation;

                // get endpoints selection from filterAlias
                const serviceFilterAlias = (filterAlias || []).find(
                    (f) => f.property === serviceFilterProp
                );
                if (serviceFilterAlias) {
                    service = serviceFilterAlias.value;

                    // get endpoints selection from filterAlias
                    endpoints = filterAlias
                        .filter((f) => f.property === operationFilterProp)
                        .map((f) => f.value);
                    if (endpoints.length === 0) endpoints.push('*');
                }
                return { service, endpoints };
            } else if (
                isApm2WorkflowsEnabled &&
                signalTypeName === signalTypeService.WORKFLOW.name
            ) {
                let resource;
                const workflowFilterProp = signalTypeService.getAPMDimensions().workflow;
                const workflowFilterAlias = (filterAlias || []).find(
                    (f) => f.property === workflowFilterProp
                );
                if (workflowFilterAlias) {
                    resource = workflowFilterAlias.value;
                }
                return { resource };
            }
        }

        function getEnvironmentSelection(filterAlias, chart) {
            const environmentFilterProp = signalTypeService.getAPMDimensions().environment;

            const variablesOverrides =
                dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
            const environmentVariableOverride = variablesOverrides.find(
                (v) => v.property === environmentFilterProp
            );
            if (environmentVariableOverride && environmentVariableOverride.value) {
                return getSingleValue(environmentVariableOverride.value);
            }

            const sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
            const environmentSourceOverride = sourceOverrides.find(
                (v) => v.property === environmentFilterProp
            );
            if (environmentSourceOverride && environmentSourceOverride.value) {
                return getSingleValue(environmentSourceOverride.value);
            }

            const environmentFilterAlias = (filterAlias || []).find(
                (f) => f.property === environmentFilterProp
            );
            if (environmentFilterAlias) {
                return getSingleValue(environmentFilterAlias.value);
            }

            const queryItems = safeLookup(chart, 'sf_uiModel.allPlots.0.queryItems') || [];
            const environmentQueryItem = queryItems.find(
                (f) => f.property === environmentFilterProp
            );
            if (isNonEmptyFilter(environmentQueryItem)) {
                return getSingleValue(environmentQueryItem.propertyValue);
            }

            return null;
        }

        /**
         * the 'service', 'operation', 'workflow' and 'kind' property are invalid apm filter
         * because they're set/controlled by different selector in apmSignalEditor.js
         * @param {object} filter - the filter object
         * @returns {boolean} true if filter is a valid apm filter, otherwise false
         */
        function isValidApmFilter(filter) {
            return (
                filter &&
                filter.type === 'property' &&
                filter.property !== signalTypeService.getAPMDimensions().service &&
                filter.property !== signalTypeService.getAPMDimensions().operation &&
                filter.property !== signalTypeService.getAPMDimensions().kind &&
                filter.property !== signalTypeService.getAPMDimensions().environment &&
                filter.property !== signalTypeService.getAPMDimensions().workflow
            );
        }

        function isNonEmptyFilter(filter) {
            if (filter === undefined || filter === null) {
                return false;
            }

            if (typeof filter.propertyValue === 'string' && filter.propertyValue.length > 0) {
                return true;
            }

            if (Array.isArray(filter.propertyValue) && filter.propertyValue.length > 0) {
                return true;
            }

            return false;
        }

        function getSingleValue(valueOrArray) {
            if (valueOrArray === undefined || valueOrArray === null) {
                return null;
            }

            if (Array.isArray(valueOrArray)) {
                return valueOrArray[0];
            }

            return valueOrArray;
        }
    },
]);
