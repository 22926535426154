import templateUrl from './detectorWizardFunctions.tpl.html';

/**
 * The functions picker component allows user to select an alert condition.
 * Functions list display varies by basic/advanced view settings.
 */
angular.module('signalview.detector.wizard').component('detectorWizardFunctionsPicker', {
    bindings: {
        categories: '<',
        canSelect: '<',
        compoundSelected: '<',
        currentSelection: '<',
        done: '&',
        isNewRule: '<',
        onFunctionSelect: '&',
        proceed: '&',
        selectedDetectorType: '<',
        selectedApmMetricType: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        '$timeout',
        'APM_METRIC_TYPE',
        'DETECTOR_TYPES',
        function ($scope, $timeout, APM_METRIC_TYPE, DETECTOR_TYPES) {
            const ctrl = this;

            let timeoutVar;

            // lifecycle
            ctrl.$onInit = $onInit;
            ctrl.$onChanges = $onChanges;

            function $onInit() {
                // set selected function to what already exists in the rule, could be undefined
                $scope.selectedFunction = ctrl.categories[ctrl.currentSelection];

                if (!ctrl.compoundSelected) {
                    initHoveredFunction();
                }

                setIsSupportedForAllCategories();
            }

            function $onChanges(changesObj) {
                const {
                    selectedDetectorType: selectedDetectorTypeChange,
                    selectedApmMetricType: selectedApmMetricTypeChange,
                } = changesObj;

                if (
                    (selectedDetectorTypeChange && selectedDetectorTypeChange.currentValue) ||
                    (selectedApmMetricTypeChange && selectedApmMetricTypeChange.currentValue)
                ) {
                    setIsSupportedForAllCategories();
                }
            }

            function initHoveredFunction() {
                const isApmDetector = ctrl.selectedDetectorType === DETECTOR_TYPES.APM_V2;

                if (!isApmDetector) {
                    // for infra detector, just pick the first category
                    $scope.hoveredFunction = ctrl.categories[0];
                    return;
                }

                // for apm detector, pick the first category with the selected metric type
                for (const category of ctrl.categories) {
                    if (
                        category.path.type === DETECTOR_TYPES.APM_V2 &&
                        category.path.metricType === ctrl.selectedApmMetricType
                    ) {
                        $scope.hoveredFunction = category;
                        break;
                    }
                }
            }

            // set hovered function to what user hovers over
            $scope.setHoveredFunction = function (fn) {
                if (ctrl.compoundSelected) {
                    return;
                }
                $timeout.cancel(timeoutVar);
                $scope.hoveredFunction = fn;
            };

            $scope.clearHoveredFunctionOnTimeout = function () {
                $timeout.cancel(timeoutVar);
                timeoutVar = $timeout(function () {
                    $scope.hoveredFunction = null;
                }, 50);
            };

            // select this alert condition and call binding callback
            $scope.chooseFunction = function (index, event) {
                if (
                    !ctrl.canSelect ||
                    (ctrl.compoundSelected && index !== ctrl.categories.length - 1)
                ) {
                    return;
                }
                $scope.selectedFunction = ctrl.categories[index];
                ctrl.onFunctionSelect({ index: index, event: event });
            };

            // go through all categories and set the isSupported property (for each category)
            function setIsSupportedForAllCategories() {
                if (!ctrl.categories) return;

                ctrl.categories.forEach(
                    (category) => (category.isSupported = isSupportedCategory(category))
                );
            }

            // check if the category is supported by selected type of detector
            // return true ONLY if the category is supported
            function isSupportedCategory(category) {
                const hasType = !!(category && category.path && category.path.type);
                // just return true if type is NOT defined
                if (!hasType) return true;

                const hasMetricType = !!(category && category.path && category.path.metricType);

                // check if the selected type matches the path type
                const selectedType = ctrl.selectedDetectorType || DETECTOR_TYPES.INFRASTRUCTURE;
                const isTypeMatched = selectedType === category.path.type;

                if (!hasMetricType) {
                    // just return type match result if metric type is NOT defined
                    return isTypeMatched;
                } else {
                    // check if the selected metric type matches the path metric type
                    const selectedMetricType =
                        ctrl.selectedApmMetricType || APM_METRIC_TYPE.SERVICE_ERRORS;
                    const isMetricTypeMatched = selectedMetricType === category.path.metricType;

                    return isTypeMatched && isMetricTypeMatched;
                }
            }
        },
    ],
});
