import React from 'react';
import Tooltip from '@splunk/react-ui/Tooltip';

export const DashboardNavHeaderStatus = (): React.JSX.Element => {
    return (
        <Tooltip content="To modify this dashboard, you must first create a copy by using save as in the actions menu.">
            <div className="read-only-pill read-only-pill-header sf-pill-spacing sf-pill sf-vertical header-pill">
                read-only
            </div>
        </Tooltip>
    );
};
