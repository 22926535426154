import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default [
    '$q',
    '_',
    'METRIC_TYPE_CONVERSION',
    'METRICS_CATALOG_CONSTANTS',
    'rollupsFromMetricType',
    'metricService',
    'suggestAPIService',
    'termsMatchService',
    'catalogMetricDocumentation',
    'userAnalytics',
    function (
        $q,
        _,
        METRIC_TYPE_CONVERSION,
        METRICS_CATALOG_CONSTANTS,
        rollupsFromMetricType,
        metricService,
        suggestAPIService,
        termsMatchService,
        catalogMetricDocumentation,
        userAnalytics
    ) {
        const WHITE_SPACE_REGEX = /\s+/;
        const WILDCARD_CHARS = ['?', '*'];
        const NOT_CHAR = '!';
        const TYPEAHEAD_DROP_DOWN_LIMIT = 100;
        const IS_ACTIVE_FILTER = 'sf_isActive:true';
        const FILTER_TYPE = {
            PROPERTY_VALUE: { delimiter: ':' },
            METRIC: { delimiter: '.' },
        };
        const PARTIAL_METRIC_COUNT_LIMIT = 10000;

        const GA_EVENT_CATEGORY = METRICS_CATALOG_CONSTANTS.GOOGLE_ANALYTICS_EVENT_CATEGORY;

        return {
            WILDCARD_CHARS,
            FILTER_TYPE,
            parseSearchText,
            getSanitizedTokens,
            getSourceFilterFromString,
            fetchSuggestions,
            fetchMetricGroupSuggestions,
            addMetricResultMetadata,
            areMetricResultsCapped,
            sendGAEvent,
            getExtraSearchTermCount,
            fetchProcessedMetricAndFacetResults,
        };

        function parseSearchText(searchText) {
            if (_.isEmpty(searchText)) {
                return '';
            }

            const terms = getSanitizedTokens(searchText);
            const query = terms.join(' ');
            return query;
        }

        function checkForWildcard(str) {
            return (str.match(/\*/) || []).length === 1;
        }

        function getSanitizedTokens(query) {
            query = sanitizeQuery(query);
            return query.split(WHITE_SPACE_REGEX).filter((token) => token.length);
        }

        function sanitizeQuery(query) {
            WILDCARD_CHARS.forEach((ch) => {
                query = query.replace(ch, ' ');
            });
            return query.trim();
        }

        /**
         * Utility method to centralize calls to fetch metrics, properties, and values (suggest,
         * facets, and custom categories by org).
         *
         * We are centralizing this as meatballs endpoint (metricfinder) returns all the data in a
         * single call - this ensures that we only call metricfinder upon new queries and filters as
         * metricfinder is a very expensive operation.
         */
        function fetchProcessedMetricAndFacetResults(query, filters, activeOnly) {
            return metricService.metricfinder(query, filters, activeOnly).then((response) => {
                // Process the both the regular metrics and the archived metrics
                const metricResults = Array.isArray(response?.metrics?.metricResults)
                    ? response.metrics.metricResults
                    : [];
                const archivedMetricResults = Array.isArray(
                    response?.archivedMetrics?.archivedMetricResults
                )
                    ? response.archivedMetrics.archivedMetricResults
                    : [];

                const processedMetricsSearchResults = processMetricsSearchResults(
                    metricResults,
                    filters,
                    activeOnly,
                    false
                );
                const processedArchivedMetricsSearchResults = processMetricsSearchResults(
                    archivedMetricResults,
                    filters,
                    activeOnly,
                    true
                );

                // It will return a list of metrics. Each metric will have isArchived property
                response.metrics = [
                    ...processedMetricsSearchResults,
                    ...processedArchivedMetricsSearchResults,
                ];
                return response;
            });
        }

        function processMetricsSearchResults(results, metricFilters, activeOnly, isArchived) {
            return results.map((metric) => {
                const filters = [];
                for (const property in metric.topHit) {
                    const value = '' + metric.topHit[property]; // Make sure it's a string
                    filters.push({
                        value: property + FILTER_TYPE.PROPERTY_VALUE.delimiter + value,
                        name: property,
                        currentValue: value,
                    });
                }

                const metricObj = {
                    name: metric.value,
                    description: metric.description,
                    metricType: METRIC_TYPE_CONVERSION.nativeToDisplay[metric.metricType],
                    rollupType: rollupsFromMetricType.getRollupDisplayName(metric.rollupType),
                    createdDate: metric.createdDate,
                    extraLoading: false,
                    count: metric.count,
                    filters,
                    isArchived,
                };

                let extraMetricInfoPromise;

                function fetchExtraInfo() {
                    // if there's already a promise in flight, don't do anything else.
                    if (extraMetricInfoPromise) {
                        return;
                    }
                    // mark things as loading
                    metricObj.extraLoading = true;
                    extraMetricInfoPromise = metricService.fetchExtraMetricInfo(
                        metric.value,
                        metricFilters,
                        activeOnly
                    );

                    extraMetricInfoPromise
                        .then((response) => {
                            metricObj.mtsCount =
                                response.count > PARTIAL_METRIC_COUNT_LIMIT
                                    ? PARTIAL_METRIC_COUNT_LIMIT
                                    : response.count;
                            metricObj.isPartialCount = response.isPartialCount;
                        }, angular.noop)
                        .finally(() => {
                            // when done (or failed) loading, just mark loading as false
                            metricObj.extraLoading = false;
                        });
                }

                metricObj.fetchExtraInfo = fetchExtraInfo;

                return metricObj;
            });
        }

        function getSourceFilterFromString(sourceFilterString) {
            const kv = sourceFilterString.split(':');
            const property = kv[0].replace(/^!/, '');
            const not = kv[0].match(/^!/);

            return {
                property: property,
                propertyValue: kv.slice(1).join(':'),
                value: sourceFilterString,
                NOT: !!not,
            };
        }

        function isActiveFilter(isActive) {
            return isActive ? IS_ACTIVE_FILTER : null;
        }

        function fetchSuggestions(propertyField, partialInput, isActive) {
            // if partial input has a wildcard, allow wildcard selection
            if (checkForWildcard(partialInput)) {
                return $q.when([partialInput]);
            }
            const property =
                propertyField.charAt(0) === NOT_CHAR ? propertyField.substring(1) : propertyField;
            return suggestAPIService.getSignalFlowSuggest({
                programs: [],
                property: property,
                partialInput,
                limit: TYPEAHEAD_DROP_DOWN_LIMIT,
                additionalFilters: [],
                additionalReplaceOnlyFilters: [],
                extraQuery: isActiveFilter(isActive),
            });
        }

        function fetchMetricGroupSuggestions(partialInput, isActive) {
            const additionalQuery = isActiveFilter(isActive);
            return suggestAPIService
                .getMetricGroupSuggest({
                    programs: [],
                    property: null,
                    partialInput,
                    limit: TYPEAHEAD_DROP_DOWN_LIMIT,
                    additionalFilters: [],
                    additionalReplaceOnlyFilters: [],
                    additionalQuery,
                })
                .then((result) => {
                    // pad all results with metric delimiter to inform user of token suggestion
                    return result.map((result) => {
                        return FILTER_TYPE.METRIC.delimiter + result;
                    });
                });
        }

        function addMetricResultMetadata(metrics, terms) {
            const termsLowerCased = terms.map((term) => term.toLocaleLowerCase());

            metrics.forEach((metric) => {
                metric.nameParts = termsMatchService.splitStringByTerms(
                    metric.name,
                    termsLowerCased
                );

                metric.filters.forEach((filter) => {
                    filter.nameParts = termsMatchService.splitStringByTerms(
                        filter.value,
                        termsLowerCased
                    );
                });

                catalogMetricDocumentation
                    .getMetricDocumentation(metric.name)
                    .then(function (metadata) {
                        metric.builtinDescription = safeLookup(metadata, 'yaml.brief');
                    });
            });
        }

        function areMetricResultsCapped(results) {
            return results && results.length >= 100;
        }

        function sendGAEvent(eventName, value) {
            userAnalytics.event(GA_EVENT_CATEGORY, eventName, value);
        }

        function getExtraSearchTermCount(currentCount) {
            return currentCount - METRICS_CATALOG_CONSTANTS.MAX_SEARCH_TERMS;
        }
    },
];
