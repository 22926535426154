import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './detectorWizardSignal.tpl.html';
import detectorSettingsPanelTemplateUrl from '../../../detectorSettingsPanel.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

/**
 * The signal picker component allows user to search and select a target signal to monitor.
 * There are 3 views in this component.
 * 1. Metrics Picker (typically found in dashboard/chart sidebars) - this is the basic view for a new rule that has no signal
 * 2. Drop down list of signals - this is the basic view for an existing rule,
 *                                shows list of signals from which user can pick the target
 * 3. Plot editor - this is the advanced view for signal curation and selection
 *
 * All changes to signals(plots) added/removed/modified are passed along to the parent controller through the bindings.
 */
angular.module('signalview.detector.wizard').component('detectorWizardSignalPicker', {
    bindings: {
        rule: '<',
        model: '<',
        preSelectedSignal: '<',
        numSources: '<',
        isNewRule: '<',
        isFlow2: '<',
        compoundConditionsEnabled: '<',
        compoundSelected: '<',
        onSignalUpdate: '&',
        onSignalSelect: '&',
        onSelectTab: '&',
        onConfigChange: '&',
        proceed: '&',
        done: '&',
        // APM related
        apmFilters: '<',
        selectedDetectorType: '<',
        selectedApmPercentile: '<',
        selectedApmMetricType: '<',
        selectedEnvironment: '<',
        selectedServiceEndpoints: '<',
        selectedBusinessWorkflows: '<',
        selectedApmFilters: '<',
        onSelectApmMetricType: '<',
        onSelectApmEnvironment: '<',
        onSelectApmServiceEndpoint: '<',
        onSelectApmBusinessWorkflow: '<',
        onSelectApmFilters: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        '$q',
        '$timeout',
        '$element',
        'chartbuilderUtil',
        'plotUtils',
        '_',
        'userAnalytics',
        'DETECTOR_TYPES',
        'featureEnabled',
        'signalTypeService',
        function (
            $scope,
            $q,
            $timeout,
            $element,
            chartbuilderUtil,
            plotUtils,
            _,
            userAnalytics,
            DETECTOR_TYPES,
            featureEnabled,
            signalTypeService
        ) {
            this.detectorSettingsPanelTemplateUrl = detectorSettingsPanelTemplateUrl;

            let watchers = [];
            const ctrl = this;

            ctrl.$onInit = $onInit;
            ctrl.$onChanges = $onChanges;

            Object.defineProperty(ctrl, 'isApmDetector', {
                get: () => ctrl.selectedDetectorType === DETECTOR_TYPES.APM_V2,
            });

            const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');

            // The timezone picker component is used a templateUrl through ng-include
            // so, use $scope instead of ctrl.
            $scope.timezone = {
                callback: (toSave) => {
                    $scope.model.sf_timezone = toSave;
                    userAnalytics.event('timezone-changed', 'v1-detector-options' + toSave);
                },
            };

            // call parent binding when user picks a signal to monitor
            function updateWithSelectedSignal() {
                if (
                    !$scope.selectedSignal.plot ||
                    ctrl.preSelectedSignal ===
                        plotUtils.getLetterFromUniqueKey($scope.selectedSignal.plot.uniqueKey)
                ) {
                    return;
                }
                ctrl.onSignalSelect({
                    plot: $scope.selectedSignal.plot,
                });
            }

            // update plot definitions so we can show a drop down list of signals for basic view of presaved rule
            function updateDropdownPlotsList() {
                if ($scope.selectedSignal.plot) {
                    $scope.selectedLabel = plotUtils.getLetterFromUniqueKey(
                        $scope.selectedSignal.plot.uniqueKey
                    );
                }
                const plots = $scope.model.sf_uiModel.allPlots
                    .filter(isPlotType)
                    .map(function (plot) {
                        return {
                            invisible: plot.invisible,
                            label: plotUtils.getLetterFromUniqueKey(plot.uniqueKey),
                            name: plot.name,
                            type: plot.type,
                        };
                    });
                $scope.plotsDefinition = {
                    dataType: {
                        values: plots,
                    },
                };
            }

            function isPlotType(plot) {
                return (plot.type === 'plot' || plot.type === 'ratio') && !plot.transient;
            }

            // initialize plots and variables for local view - if there are 2 or more plots, we disable basic view for a new rule
            function initializePlotsForLocalView() {
                const uiModel = $scope.model.sf_uiModel;
                let plotIndex = 0;
                let hasAnalytics = false;
                const numPlots = uiModel.allPlots.filter(function (plot, index) {
                    const isPlot = isPlotType(plot);
                    if (isPlot) {
                        plotIndex = index;
                    }
                    hasAnalytics = hasAnalytics || (plot.dataManipulations || []).length;
                    return isPlot;
                }).length;
                // simple plot mode is where there is one or less plots
                $scope.isSimplePlot = numPlots <= 1 && !hasAnalytics;
                // when there are no plots, copy a transient model into the ui model
                chartbuilderUtil.createTransientIfNeeded(uiModel);

                // if rule has a pre selected signal, set that as the active signal in the UI
                if (ctrl.preSelectedSignal) {
                    const targetPlotKey = plotUtils.getUniqueKeyFromLetter(ctrl.preSelectedSignal);
                    uiModel.allPlots.some(function (plot, index) {
                        if (targetPlotKey === plot.uniqueKey) {
                            plotIndex = index;
                            $scope.selectedSignal.plot = plot;
                        }
                    });
                }
                $scope.plot = $scope.model.sf_uiModel.allPlots[plotIndex];
                if (!$scope.selectedSignal.plot && !$scope.plot.transient) {
                    $scope.selectedSignal.plot = $scope.plot;
                }
                updateWithSelectedSignal();
                updateDropdownPlotsList();
            }

            // reset local model values and variable settings, based on passed in model
            function copyPlotInfoToLocalModel(model) {
                $scope.model = angular.copy(model);
                // mark this as new if detector has no id and no plots
                // sometimes detector with no id could have come from a chart, that is - create detector from chart
                $scope.isNew =
                    !$scope.model.sf_id &&
                    !$scope.model.sf_uiModel.allPlots.some(
                        (plot) => plot.type === 'plot' && !plot.invisible && !plot.transient
                    );
                initializePlotsForLocalView();
            }

            function moveDataTableIntoContainer() {
                angular
                    .element('.detector-wizard-data-table')
                    .removeClass('show-off-detector-wizard-screen')
                    .detach()
                    .appendTo(angular.element('.legend-data-table-parent'));
            }

            function resetTabState(tabId) {
                if (tabId === 'data') {
                    $scope.selectTab($scope.sectionTabs[$scope.tabIndices.data]);
                } else {
                    $scope.selectTab($scope.sectionTabs[$scope.tabIndices.plots]);
                }
                moveDataTableIntoContainer();
            }

            function onUpdateTabStage(tabId, stage) {
                if (stage === 'signal') {
                    resetTabState(tabId);
                }
            }

            function $onInit() {
                $scope.sharedChartState = {};
                $scope.autoFocus = true;
                $scope.showFilterInput = false;
                $scope.objectType = ['MetricTimeSeries'];
                $scope.selectedSignal = {};
                $scope.timezone.query = ctrl.model.sf_uiModel.chartconfig.timezone || '';

                if (ctrl.model.sf_uiModel.allPlots) {
                    setCompoundConditionAvailable();
                }

                function tabEnabled() {
                    return $scope.model.sf_uiModel.allPlots.length > 1;
                }

                $scope.sectionTabs = [
                    {
                        id: 'plots',
                        name: 'Signals',
                        enabled: function () {
                            return true;
                        },
                    },
                    {
                        id: 'options',
                        name: 'Options',
                        enabled: tabEnabled,
                    },
                    {
                        id: 'data',
                        name: 'Data Table',
                        enabled: tabEnabled,
                    },
                ];

                $scope.tabIndices = {};
                $scope.sectionTabs.forEach(function (tab, index) {
                    $scope.tabIndices[tab.id] = index;
                });

                copyPlotInfoToLocalModel(ctrl.model);

                // when selected signal changes, update using parent bindings in controller
                watchers.forEach((w) => w());
                watchers = [];

                watchers.push(
                    $scope.$watch('selectedSignal.plot', function (nval, oval) {
                        if (nval && !angular.equals(nval, oval)) {
                            updateWithSelectedSignal();
                        }
                    })
                );

                // when local plots change, update with parent bindings
                watchers.push(
                    $scope.$watch(
                        'model.sf_uiModel.allPlots',
                        function (nval, oval) {
                            $scope.showFilterInput = false;
                            if (oval && !angular.equals(nval, oval)) {
                                // keep current selected signal up-to-date since you can use plot editor to change the signal/filters
                                if ($scope.selectedSignal.plot) {
                                    $scope.selectedSignal.plot = nval.find(
                                        (plot) =>
                                            plot.uniqueKey === $scope.selectedSignal.plot.uniqueKey
                                    );
                                }
                                if (!$scope.selectedSignal.plot) {
                                    $scope.selectedSignal = {};
                                }
                                initializePlotsForLocalView();
                                ctrl.onSignalUpdate({
                                    plots: $scope.model.sf_uiModel.allPlots,
                                    uniqueKey: $scope.model.sf_uiModel.currentUniqueKey,
                                });
                                if ($scope.selectedSignal.plot) {
                                    ctrl.onSignalSelect({
                                        plot: $scope.selectedSignal.plot,
                                    });
                                }
                                setCompoundConditionAvailable();
                            }
                        },
                        true
                    )
                );

                watchers.push(
                    $scope.$watch(
                        'model.sf_uiModel.chartconfig',
                        function (nval, oval) {
                            if (oval && !angular.equals(nval, oval)) {
                                const config = _.pickBy(nval, function (v, k) {
                                    return !angular.equals(oval[k], v);
                                });
                                ctrl.onConfigChange({
                                    config: config,
                                });
                            }
                        },
                        true
                    )
                );
                watchers.push(
                    $scope.$watch(
                        'model.sf_jobMaxDelay',
                        function (nval, oval) {
                            if (!angular.equals(nval, oval)) {
                                ctrl.onConfigChange({
                                    maxDelay: nval,
                                });
                            }
                        },
                        true
                    )
                );

                watchers.push(
                    $scope.$watch(
                        'model.sf_timezone',
                        function (nval, oval) {
                            if (!angular.equals(nval, oval)) {
                                ctrl.onConfigChange({
                                    timezone: nval,
                                });
                                // Update chart config for detector as well so that the timezone
                                // can trickle down to the analytics functions for the rules
                                $scope.model.sf_uiModel.chartconfig.timezone = nval;
                                $scope.timezone.query = nval;
                            }
                        },
                        true
                    )
                );

                watchers.push(
                    $scope.$on('detector wizard selected stage', function (ev, stage) {
                        onUpdateTabStage('', stage);
                    })
                );
                watchers.push(
                    $scope.$on('go to tab', function (ev, tabId, stage) {
                        onUpdateTabStage(tabId, stage);
                    })
                );

                // Set the detector options' timezone
                $scope.$on('setCalendarWindowTimezoneFromAnalytics', function (evt, data) {
                    $scope.model.sf_timezone = data;
                });

                resetTabState();
            }

            function setCompoundConditionAvailable() {
                $scope.compoundConditionAvailable =
                    ctrl.compoundConditionsEnabled &&
                    ctrl.model.sf_uiModel.allPlots.filter((plot) => plot.type === 'plot').length >
                        2;
            }

            function $onChanges(changesObj) {
                const {
                    selectedDetectorType: selectedDetectorTypeChange,
                    preSelectedSignal: preSelectedSignalChange,
                } = changesObj;

                if (selectedDetectorTypeChange && selectedDetectorTypeChange.currentValue) {
                    if (!$scope.selectedSignal) $scope.selectedSignal = {};
                    copyPlotInfoToLocalModel(ctrl.model);
                }

                if (preSelectedSignalChange && $scope.model) {
                    initializePlotsForLocalView();
                }
            }

            $scope.selectTab = function (tab) {
                $scope.selectedTab = tab;
                ctrl.onSelectTab({ tab: tab });
            };

            // when user picks a signal from the metrics picker, we create
            // a corresponding plot model in the detector's plots
            // and call functions to set this plot as pre selected signal
            $scope.onSelect = function (item, selectedFilters) {
                const newFilters = (selectedFilters || []).map(function (signalFilter) {
                    return {
                        type: 'property',
                        property: signalFilter.name,
                        propertyValue: signalFilter.currentValue,
                    };
                });
                // copy filters into already existing filters since user may have added more filters
                $scope.plot.queryItems = _.uniq(
                    $scope.plot.queryItems.concat(newFilters),
                    false,
                    function (signalFilter) {
                        return signalFilter.property;
                    }
                );
                $scope.plot.seriesData.metric = item[0].value;
                $scope.plot.name = item[0].value;
                $scope.plot.transient = false;
                $scope.selectedSignal.plot = $scope.plot;
                updateWithSelectedSignal();
                updateDropdownPlotsList();
                return $q.when();
            };

            $scope.onClearFilter = function (name, value) {
                $scope.plot.queryItems = $scope.plot.queryItems.filter(function (signalFilter) {
                    return !(
                        signalFilter.property === name && signalFilter.propertyValue === value
                    );
                });
            };

            // in metrics picker, user can click on a signal again to deselect it
            $scope.onDeselect = function () {
                $scope.selectedSignal = {};
            };

            // used for toggling filter input box in metrics picker basic view - filters pane
            $scope.toggleFilterInput = function () {
                $scope.showFilterInput = !$scope.showFilterInput;
                if ($scope.showFilterInput) {
                    $timeout(function () {
                        angular.element('.in-place-source-edit input', $element).focus();
                    }, 0);
                }
            };

            // query for metrics picker
            $scope.getCurrentQuery = function () {
                let query = '';
                if ($scope.selectedSignal.plot) {
                    query =
                        'sf_metric:' + sanitizeTerm($scope.selectedSignal.plot.seriesData.metric);
                }
                return query;
            };

            // When user picks a signal from drop down list (basic view of presaved rule), update with parent binding
            $scope.selectExistingSignal = function (value, valid) {
                if (!valid) {
                    return;
                }
                const uniqueKey = plotUtils.getUniqueKeyFromLetter(value);
                $scope.selectedSignal.plot = $scope.model.sf_uiModel.allPlots.find(
                    (plot) => plot.uniqueKey === uniqueKey
                );
                updateWithSelectedSignal();
            };

            $scope.disableProceedButton = () => {
                if ($scope.isApmDetector) {
                    let selections, hasCompletedSelection;
                    const currentMetricType = ctrl.selectedApmMetricType || '';

                    if (
                        signalTypeService.SERVICE_ENDPOINT['apmMetricGroup'].includes(
                            currentMetricType
                        )
                    ) {
                        selections = safeLookup(ctrl, 'rule.apmServiceEndpointSelections') || [];
                        hasCompletedSelection = selections.some(
                            (s) => s._service !== '' && s._endpoints.length !== 0
                        );
                        return !hasCompletedSelection;
                    } else if (
                        isApm2WorkflowsEnabled &&
                        signalTypeService.WORKFLOW['apmMetricGroup'].includes(currentMetricType)
                    ) {
                        selections = safeLookup(ctrl, 'rule.apmBusinessWorkflowSelections') || [];
                        hasCompletedSelection = selections.some((s) => s._resource !== '');
                    }
                    return !hasCompletedSelection;
                } else {
                    return !$scope.selectedSignal.plot;
                }
            };

            $scope.altMode = false;

            $scope.$on(
                'plot alt mode trigger',
                function (evt, altModeDisplayedKey, altModeOn = false) {
                    $scope.altMode = altModeOn;
                    $scope.$broadcast('plot alt mode apply', altModeDisplayedKey, $scope.altMode);
                }
            );
        },
    ],
});
