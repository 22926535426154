// The intent of this module is that it holds the collection of components
// and services that we expect to be used throughout the app, as opposed to
// those components which we expect to be used solely in the main tracing
// module.

import './businessWorkflow/module.js';
import './serviceEndpoint/module.js';

import environmentResolver from './environmentResolver';
import traceSuggestionService from './traceSuggestionService';

const APM_METRIC_TYPE = {
    SERVICE_ERRORS: 'errors',
    SERVICE_LATENCY: 'latency',
    WORKFLOW_ERROR_RATE: 'workflow_errors',
    WORKFLOW_LATENCY: 'workflow_latency',
};

angular
    .module('signalview.common.tracing', [
        'd3',

        'signalview.detector.signal',
        // sub-modules
        'signalview.common.tracing.businessWorkflow',
        'signalview.common.tracing.serviceEndpoint',
    ])

    // constants
    .constant('APM_METRIC_TYPE', APM_METRIC_TYPE)

    // services
    .service('environmentResolver', environmentResolver)
    .service('traceSuggestionService', traceSuggestionService);
