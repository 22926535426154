import CHART_VALUE_UNITS from '../../../../common/ui/formatting/chartValueUnits';
import { scaleToBestUnit } from '../../../../common/ui/formatting/scalingUnitServiceModule';
import { SCALING_UNIT_TYPES } from '../../../../common/ui/formatting/scalingUnitTypes';

// TODO: respect 'Use IEC units' (KMB vs KMG2) chart settings for displaying
// values in the legend and tooltip.

const MAX_CHARS_FOR_FORMATTED_NUMBER = 20;

const api = {
    formatValue,
    formatScalingUnit,
};

function getUnitTruncatedNumber(value, charLimit, useKMG2) {
    const unit = getLargestRelevantUnit(value, useKMG2);
    let unitValue = value / unit.mod;

    const decimalIndex = unitValue.toString().indexOf('.');

    if (decimalIndex !== -1) {
        if (decimalIndex < charLimit) {
            const fixedAt = Math.min(
                MAX_CHARS_FOR_FORMATTED_NUMBER,
                Math.max(0, charLimit - decimalIndex)
            );

            unitValue = trimDecimalZeroes(unitValue.toFixed(fixedAt));
        } else {
            unitValue = Math.round(unitValue);
        }
    }

    return unitValue + unit.descriptor;
}

function needsUnitTruncation(value, charLimit) {
    const decimalIdx = value.toString().indexOf('.');
    return decimalIdx === -1 || decimalIdx > charLimit;
}

// returns the largest unit for which value * unit.mod >= 1
function getLargestRelevantUnit(value, useKMG2) {
    const units = useKMG2 ? CHART_VALUE_UNITS.kmg2 : CHART_VALUE_UNITS.kmb;
    let unitIndex;

    for (let x = units.length - 1; x > -1; x--) {
        if (value / units[x].mod >= 1) {
            unitIndex = x;
            break;
        }
    }

    return units[unitIndex];
}

function scaleValue(value, charLimit, unitName) {
    const scaleResult = scaleToBestUnit(value, unitName);
    const unit = scaleResult.unit;
    const scaledValue = scaleResult.value;

    return { value: scaledValue, unit };
}

function formatDecimal(value, charLimit) {
    if (value.toString().indexOf('.')) {
        // if number is a decimal, then either convert to exponential notation
        // or format the decimal.
        if (needsExponentialNotation(value, charLimit)) {
            return convertToExponentialNotation(value, charLimit);
        } else {
            return formatBasicDecimal(value, charLimit);
        }
    }

    return value.toString();
}

function formatBasicDecimal(scaledValue, charLimit) {
    const unitsLeft = charLimit;
    const decimalIndex = scaledValue.toString().indexOf('.');

    // don't mess with non-decimals here.
    if (decimalIndex === -1) {
        return scaledValue;
    }

    if (decimalIndex < unitsLeft) {
        const fixAt = Math.min(
            MAX_CHARS_FOR_FORMATTED_NUMBER,
            Math.max(0, charLimit - decimalIndex)
        );

        // fix at the appropriate length and drop insignificant digits after decimal
        scaledValue = trimDecimalZeroes(scaledValue.toFixed(fixAt));
    } else {
        // round down if rounded number is already over charLimit
        scaledValue = Math.round(scaledValue).toString();
    }

    return scaledValue.toString();
}

function trimDecimalZeroes(value) {
    const numStr = value.toString();

    if (numStr.indexOf('.') === -1) {
        return numStr;
    }

    const chars = numStr.split('');
    let i = numStr.length - 1;

    while (i >= 0) {
        if (numStr[i] !== '0') {
            break;
        }
        i--;
    }

    if (numStr[i] === '.') {
        i--;
    }

    return chars.slice(0, i + 1).join('');
}

function needsExponentialNotation(number, charLimit) {
    if (number > 1) {
        return false;
    }

    // even if we cant fit the whole fractional value, if it possesses enough significant digits
    // to be displayed in standard non exponential format, then do that instead and truncate.
    const minStandardFormatSigFigs = 3;
    // minimumNonExponential is gauranteed to allow for fractions with three digits,
    // since exponential notation is three digits anyway.
    const minimumNonExponential = Math.pow(
        10,
        -1 * Math.max(2, charLimit - minStandardFormatSigFigs)
    );

    return number < minimumNonExponential;
}

function convertToExponentialNotation(value, charLimit) {
    const targetPrecision = Math.min(Math.max(1, charLimit - 3), MAX_CHARS_FOR_FORMATTED_NUMBER);

    return parseFloat(value.toPrecision(targetPrecision)).toExponential(
        Math.min(Math.max(targetPrecision - 1, 0), 20)
    );
}

function addCommasToNumber(num) {
    let numStr = num.toString();
    const decOrUnitMatch = numStr.match(/[\.a-zA-Z]/);
    let searchBegin = decOrUnitMatch ? decOrUnitMatch[1] - 3 : numStr.length - 3;

    while (searchBegin > 0) {
        numStr = numStr.substring(0, searchBegin) + ',' + numStr.substring(searchBegin);
        searchBegin -= 3;
    }

    return numStr;
}

// returns the closest fit that can be made without truncating with a unit
// or using scientific notation.
function getTentativeValue(val, charLimit) {
    if (val < 1) {
        if (!needsExponentialNotation(val, charLimit)) {
            const numDecimalPlaces = Math.min(
                Math.max(charLimit, 1),
                MAX_CHARS_FOR_FORMATTED_NUMBER
            );

            return val.toFixed(numDecimalPlaces);
        }
    } else if (val < 1000) {
        // calculate the number of characters available to the decimal places and
        // format accordingly
        const remainingCharacters = Math.min(
            MAX_CHARS_FOR_FORMATTED_NUMBER,
            charLimit - Math.floor(val).toString().length
        );

        return Math.floor(val) === val
            ? val.toFixed(0)
            : val.toFixed(Math.max(0, remainingCharacters));
    }

    return val.toString();
}

function formatValue(inputVal, charLimit = 6, useKMG2 = false) {
    // direct returns for edge cases
    if (inputVal === 0) {
        return '0';
    }
    if (isNaN(inputVal) || inputVal === null) {
        return '-';
    }
    // this fixes floating-point precision errors in JavaScript
    // for example when dygraph calculates values for Y-Axis based on numbers between 0.012 and 0.212
    // it invokes this function with these values - [0, 0.005, 0.009999999999999998, 0.015, 0.020]
    // we want to keep it consistent and display 0.010 instead of 0.009999999999999998 or 1e-2
    const val = parseFloat(inputVal.toPrecision(15));

    const sign = val < 0 ? '-' : '';
    const unsignedValue = Math.abs(val);
    let tentativeValue = getTentativeValue(unsignedValue, charLimit);

    if (
        tentativeValue.length <= charLimit ||
        (unsignedValue < 1 && tentativeValue.length - 2 <= charLimit)
    ) {
        // if the full length fits (roughly), then format it and return
        return sign + addCommasToNumber(tentativeValue);
    }

    if (unsignedValue >= 1000) {
        if (needsUnitTruncation(unsignedValue, charLimit)) {
            // if its a reasonably large number, use the formatted KMB/KMG2 result to summarize it
            tentativeValue = getUnitTruncatedNumber(unsignedValue, charLimit, useKMG2);
        } else {
            // if the length of value is less than the limit, there is no need to truncate;
            // format the current number and return
            const decimalIndex = unsignedValue.toString().indexOf('.');
            const fixedAtLimit = Math.round(unsignedValue).toFixed(charLimit - decimalIndex);

            tentativeValue = trimDecimalZeroes(fixedAtLimit);
        }
    } else if (unsignedValue < 1) {
        // ifs its a small number, then use exponential if the original had too many 0 decimal places

        // at least 1, at most 20
        // targetPrecision is a value that we believe we can call toPrecision with based
        // on available space and specified precision maximums
        // exponentialRepresentation is attempting to truncate the float value to fit within
        // the bounds of the specified display parameters
        const targetPrecision = Math.min(Math.max(1, charLimit - 3), 20);

        tentativeValue = parseFloat(unsignedValue.toPrecision(targetPrecision)).toExponential(
            Math.min(Math.max(targetPrecision - 1, 0), 20)
        );
    }

    return sign + addCommasToNumber(tentativeValue);
}

function formatScalingUnit(value, scalingUnit, charLimit = 6) {
    // direct returns for edge cases
    if (value === 0) {
        return '0';
    }
    if (isNaN(value) || value === null) {
        return '-';
    }

    const sign = value < 0 ? '-' : '';
    const unsignedValue = Math.abs(value);
    const scaled = scaleValue(unsignedValue, charLimit, scalingUnit);
    const resultUnit = scaled.unit;
    let finalValue = scaled.value;

    if (
        !resultUnit.nextLarger &&
        Math.round(finalValue).toString().length > Math.max(charLimit, 5)
    ) {
        // in case there is no larger unit and the value is still longer than the
        // min of charLimit and the minimum amount of characters needed to display
        // the value in exponential notation then use exponential notation
        finalValue = convertToExponentialNotation(finalValue, charLimit);
    } else if (finalValue.toString().indexOf('.') !== -1) {
        // else if it has a decimal, then format it normally.
        finalValue = formatDecimal(finalValue, charLimit - resultUnit.abbreviation.length);
    }

    return sign + finalValue + resultUnit.abbreviation;
}

// expose convenience functions bound to individual units,
// e.g. formatNanoseconds, formatYottabits, etc.
addNamedScalingUnitFunctionsToApi(api);

function addNamedScalingUnitFunctionsToApi(api) {
    Object.values(SCALING_UNIT_TYPES).forEach((scalingUnit) => {
        api[`format${scalingUnit}s`] = (value, charLimit) => {
            return formatScalingUnit(value, scalingUnit, charLimit);
        };
    });
}

export default api;
