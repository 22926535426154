import uiBootstrap from 'angular-ui-bootstrap';
import { reactNgBridge } from '../../../app/reactAngularBridge/MigratedServiceProxies';
import { CreateContentService } from '../../../common/CreateContentService';

import globalNavHomeButton from './globalNavHomeButton.js';
import globalNavService from './globalNavService.js';
import sfNavMenuBarInput from './sfNavMenuBarInput.js';
import globalActions from './globalActions/globalActions.js';
import globalActionsWrapper from './globalActions/globalActionsWrapper.js';
import aboutUs from './aboutUs.js';
import { withNg2React } from '../../../common/util/Ng2React';
import { ngRoute } from '../../../app/routing/ngRoute';
import { react2angular } from 'react2angular';
import { GlobalActionPasteCharts } from '../../../common/ui/dashboard/GlobalActionPasteCharts';

export const globalNavProps = ['hideAll', 'object', 'snapshot', 'dashboardState', 'dashboardGroup'];

export const isSnapshotEdit = () => {
    const lastRoute = ngRoute.getLastRoute();
    const currentRoute = ngRoute.getRoute();
    const samePath = lastRoute.matchedPath === currentRoute.matchedPath;
    const fromSnapshot = lastRoute.params.snapshotID;
    const toSnapshot = currentRoute.params.snapshotID;
    return samePath && fromSnapshot && toSnapshot;
};

export const defaultActionState = {
    dashboardGroup: null,
    dashboardState: {
        //Clipboard bookKeeping
        copyInProgress: 0,
        pastingInProgress: false,
        isInDashboardPage: false,
    },
    snapshot: null,
    hideAll: false,
    object: null,
};

// eslint-disable-next-line import/no-unused-modules
export const createContentService = reactNgBridge.ngReactMemo(CreateContentService);

angular
    .module('signalview.sfGlobalNav', [
        'sfx.security',
        uiBootstrap,
        'sfx.data',
        'signalview.imageService',
        'signalview.recentPages',
        'signalview.appNotifications',
        'signalview.orgNotifications',
        'signalview.themeService',
        'signalview.catalog',
        'support.supportService',
        'signalview.heatmap',
    ])
    .constant('globalNavProps', globalNavProps)
    .component('globalNavHomeButton', globalNavHomeButton)
    .component('globalActions', globalActions)
    .component('globalActionsWrapper', globalActionsWrapper)
    .directive('sfNavMenuBarInput', sfNavMenuBarInput)
    .service('globalNavService', globalNavService)
    .component(
        'globalActionPasteCharts',
        react2angular(GlobalActionPasteCharts, [
            'onClick',
            'pastingInProgress',
            'isInDashboardPage',
            'isDashboardGroupEmpty',
            'isReadOnly',
            'chartClips',
        ])
    )
    .constant('createContentService', createContentService)
    .component('aboutUs', aboutUs);

const GlobalActionsWrapperReact = withNg2React({
    name: 'GlobalActionsWrapper',
    definition: globalActionsWrapper,
});

// eslint-disable-next-line import/no-unused-modules
export { GlobalActionsWrapperReact };
