import templateUrl from './detectorWizardRecipients.tpl.html';

/**
 * The recipients editor component shows the rule notification widget
 * and allows user to update notifications for the rule.
 */
angular.module('signalview.detector.wizard').component('detectorWizardRecipientsEditor', {
    bindings: {
        detector: '<',
        rule: '<',
        isNewRule: '<',
        onRecipientsSelect: '&',
        proceed: '&',
        done: '&',
    },
    templateUrl,
    controller: [
        '$scope',
        'featureEnabled',
        function ($scope, featureEnabled) {
            let previousWatch;
            const ctrl = this;
            ctrl.$onInit = $onInit;

            function $onInit() {
                $scope.onReminderNotificationChange = function (reminderNotification) {
                    ctrl.rule.reminderNotification = reminderNotification;
                    $scope.$applyAsync();
                };
                // copy notifications to local variable
                $scope.recipients = angular.copy(ctrl.rule.notifications || []);
                $scope.isReminderNotificationEnabled = featureEnabled(
                    'detectorsRepeatedNotificationReminderOldWizard'
                );
                // pass modified notifications to parent binding
                if (previousWatch) {
                    previousWatch();
                }
                previousWatch = $scope.$watch(
                    'recipients',
                    function (nval, oval) {
                        if (nval && !angular.equals(nval, oval)) {
                            ctrl.onRecipientsSelect({ recipients: $scope.recipients });
                        }
                        if (nval.length === 0 && $scope.isReminderNotificationEnabled) {
                            ctrl.rule.reminderNotification = undefined;
                        }
                    },
                    true
                );
            }
        },
    ],
});
