import { react2angular } from 'react2angular';
import { DashboardNavPageHeaderWrapper } from './DashboardNavPageHeaderWrapper';

angular
    .module('signalview.dashboardNavPageHeaderWrapper', [])
    .component(
        'dashboardNavPageHeaderWrapper',
        react2angular(DashboardNavPageHeaderWrapper, [
            'actions',
            'description',
            'model',
            'navigation',
            'onTrackClick',
            'showActions',
            'showStatus',
        ])
    );
