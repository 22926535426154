import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import templateUrl from './chartAlertMenu.tpl.html';
import dashboardAlertNewFromDetectorMenuTemplateUrl from './dashboardAlertNewFromDetectorMenu.tpl.html';
import { DETECTOR_FROM_CHART_MODAL_ID_NAMESPACE } from './createDetectorFromChart';
import {
    DASHBOARD_WINDOW_DEFAULT_TIME,
    convertDashboardWinToMovingWin,
    isDashboardTimeWindowSelected,
} from '../../../app/utils/programArgsUtils';
import { convertMSToStringWithSingleUnit } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default [
    '$location',
    '$log',
    'alertDataService',
    'alertingService',
    'chartDisplayUtils',
    'chartV2Utils',
    'detectorUtils',
    'detectorWizardModal',
    'featureEnabled',
    'relatedDetectorService',
    'urlOverridesService',
    'chartUtils',
    'hasCapability',
    function (
        $location,
        $log,
        alertDataService,
        alertingService,
        chartDisplayUtils,
        chartV2Utils,
        detectorUtils,
        detectorWizardModal,
        featureEnabled,
        relatedDetectorService,
        urlOverridesService,
        chartUtils,
        hasCapability
    ) {
        return {
            templateUrl,
            restrict: 'EA',
            scope: {
                detectorAlertStates: '=',
                alerts: '<',
                right: '=?',
                onChange: '=',
                trackClickPrefix: '@',
                chart: '=?',
                filterAlias: '=?',
                editable: '<?',
                convertible: '<?',
                onLinkDetector: '=?',
                dashboardIndex: '@',
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.detectorFromChartProps = { modalId: undefined, chartId: '' };
                    $scope.dashboardAlertNewFromDetectorMenuTemplateUrl =
                        dashboardAlertNewFromDetectorMenuTemplateUrl;

                    $scope.showAlertSubmenu = function (event, index) {
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            document
                                .getElementById(`alert-submenu-${index}`)
                                .classList.add('subMenuDisplay');
                        }
                    };

                    $scope.hideAlertSubmenu = function (index) {
                        if (document.getElementById(`alert-submenu-${index}`)) {
                            document
                                .getElementById(`alert-submenu-${index}`)
                                .classList.remove('subMenuDisplay');
                        }
                    };

                    $scope.$watch('detectorAlertStates', function (detectorAlertStates) {
                        $scope.isDetectorIdLinked = {};
                        $scope.numHiddenAlerts = 0;

                        if (!detectorAlertStates) return;

                        const linkedDetectorIds = detectorAlertStates.allDetectors.map(
                            (detectorAlertState) => detectorAlertState.id
                        );

                        linkedDetectorIds.forEach((detectorId) => {
                            $scope.isDetectorIdLinked[detectorId] = true;
                        });

                        $scope.viewAllAlertsUrl =
                            '#/alerts?sources[]=sf_detectorId:[' +
                            linkedDetectorIds.map((id) => `"${id}"`).join(',') +
                            ']';
                    });

                    hasCapability(Capability.CREATE_DETECTOR).then(
                        (hasCreateDetectorCapability) =>
                            ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
                    );

                    hasCapability(Capability.UPDATE_DETECTOR).then(
                        (hasUpdateDetectorCapability) =>
                            ($scope.hasUpdateDetectorCapability = hasUpdateDetectorCapability)
                    );

                    hasCapability(Capability.UPDATE_CHART).then(
                        (hasUpdateChartCapability) =>
                            ($scope.hasUpdateChartCapability = hasUpdateChartCapability)
                    );

                    $scope.openIncidentModal = function (alert) {
                        return alertingService.openIncidentModal(alert);
                    };

                    $scope.editLinkToChart = function (detectorId) {
                        urlOverridesService.setHighlightDetectorPlot(detectorId);
                        $location.path('/chart/' + $scope.chart.sf_id);
                    };

                    $scope.subscribeToDetectorById = function (detectorId) {
                        relatedDetectorService
                            .subscribeToDetectorById(detectorId)
                            .then($scope.onChange);
                    };

                    $scope.subscribeToDetector = function (detector) {
                        detector.subscribe().then($scope.onChange);
                    };

                    $scope.resetModalId = function () {
                        $scope.detectorFromChartProps = { modalId: undefined, chartId: '' };
                    };

                    $scope.newDetectorFromChartFallback = function (rejectionReason) {
                        $log.log('Unable to open detector in DetectorWizard', rejectionReason);
                        if ($scope.chart) {
                            openDetectorWizard($scope.chart);
                        }
                    };

                    $scope.newDetectorFromChartClicked = function (chart) {
                        if (
                            featureEnabled('linkingDetectors') &&
                            (!$scope.editable || !$scope.hasUpdateChartCapability)
                        ) {
                            detectorUtils.showFailedDetectorLinkingWarning().then(() => {
                                openDetectorWizard(chart);
                            });
                        } else if (isDashboardTimeWindowSelected(chart.sf_viewProgramText)) {
                            detectorUtils.showDetectorWarningForDashboardTimeWindow().then(() => {
                                openDetectorWizard(chart);
                            });
                        } else {
                            openDetectorWizard(chart);
                        }
                    };

                    $scope.initDetectorWizardFromChart = () => {
                        const modalId = _.uniqueId(DETECTOR_FROM_CHART_MODAL_ID_NAMESPACE);

                        if (
                            featureEnabled('linkingDetectors') &&
                            (!$scope.editable || !$scope.hasUpdateChartCapability)
                        ) {
                            detectorUtils.showFailedDetectorLinkingWarning().then(() => {
                                $scope.detectorFromChartProps = {
                                    modalId,
                                    chartId: '',
                                };
                            });
                        } else if (isDashboardTimeWindowSelected($scope.chart.sf_viewProgramText)) {
                            detectorUtils.showDetectorWarningForDashboardTimeWindow().then(() => {
                                $scope.detectorFromChartProps = {
                                    modalId,
                                    chartId: $scope.chart.sf_id,
                                };
                            });
                        } else {
                            $scope.detectorFromChartProps = {
                                modalId,
                                chartId: $scope.chart.sf_id,
                            };
                        }
                    };

                    $scope.displayAlertSummary = function (alertSummary) {
                        if (alertSummary.alertCount === 1) {
                            alertDataService
                                .getDetectorAlerts(alertSummary.id)
                                .then((detectorAlertsBySeverity) => {
                                    if (
                                        !detectorAlertsBySeverity ||
                                        !detectorAlertsBySeverity.alertsBySeverity
                                    ) {
                                        $log.error(
                                            "Couldn't retrieve detector information from the server"
                                        );
                                        return;
                                    }
                                    const alertsBySeverity =
                                        detectorAlertsBySeverity.alertsBySeverity;
                                    const incidentId =
                                        Object.values(alertsBySeverity)[0][0].incidentId;
                                    $scope.openIncidentModal({ incidentId });
                                });
                        } else {
                            $location.url('/alerts?sources[]=sf_detectorId:' + alertSummary.id);
                        }
                    };

                    $scope.getFilters = function () {
                        const sourceOverride = urlOverridesService.getSourceOverride();
                        const overrides = chartUtils.getChartOverridesCustom(
                            $scope.filterAlias,
                            sourceOverride
                        );
                        return overrides
                            .filter((filter) => !!filter.value)
                            .map((filter) => ({
                                dimension: filter.key,
                                values: Array.isArray(filter.value) ? filter.value : [filter.value],
                            }));
                    };

                    function handleDashboardTimeWindowForDetectors(sourceChart) {
                        let absDashboardCurrentTime = DASHBOARD_WINDOW_DEFAULT_TIME;
                        const globalTime = urlOverridesService.getGlobalTimeAbsolute();
                        const dashboardTime = globalTime?.end - globalTime?.start;
                        if (dashboardTime) {
                            absDashboardCurrentTime =
                                convertMSToStringWithSingleUnit(dashboardTime);
                        }

                        sourceChart.sf_uiModel.allPlots.forEach((plot) => {
                            plot.dataManipulations = convertDashboardWinToMovingWin(
                                plot.dataManipulations,
                                absDashboardCurrentTime
                            );
                            plot.name = plot.name?.replace(
                                /Dashboard window/gi,
                                absDashboardCurrentTime
                            );
                        });
                    }

                    function openDetectorWizard(chart) {
                        const filterAlias = $scope.filterAlias || [];
                        const sourceChart = angular.copy(chart);
                        chartV2Utils.forcePublishNormalization(sourceChart);
                        chartDisplayUtils.applyUrlStateToModel(sourceChart, filterAlias);
                        if (isDashboardTimeWindowSelected(chart.sf_viewProgramText)) {
                            handleDashboardTimeWindowForDetectors(sourceChart);
                        }
                        detectorWizardModal.newDetector(
                            sourceChart,
                            filterAlias,
                            $scope.editable,
                            $scope.convertible
                        );
                    }
                },
            ],
        };
    },
];
