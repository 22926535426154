import React from 'react';
import Dropdown from '@splunk/react-ui/Dropdown';
import Menu from '@splunk/react-ui/Menu';
import Button from '@splunk/react-ui/Button';
import DotsThreeVertical from '@splunk/react-icons/DotsThreeVertical';
import styled from 'styled-components';

const { Item } = Menu;

const StyledMenu = styled(Menu)`
    width: 200px;
`;

export type DashboardNavHeaderActionsProps = {
    actions: {
        deleteDashboardGroup: () => void;
        deleteDashboardGroupEnabled: () => boolean;
        exportEnabled: () => boolean;
        exportGroup: () => void;
        hasDeleteDashboardGroupCapability: boolean;
        isTeamsLinksDisabled: () => boolean;
        showPageInfo: () => void;
        showPagePermissions: () => boolean;
        showPagePermissionsLink: () => boolean;
        showDeleteDashboardGroup: () => boolean;
        showTeamsLinksModal: () => void;
    };
    onTrackClick: (value: string) => void;
};

export const DashboardNavHeaderActions = ({
    actions: {
        deleteDashboardGroup,
        deleteDashboardGroupEnabled,
        exportEnabled,
        exportGroup,
        hasDeleteDashboardGroupCapability,
        isTeamsLinksDisabled,
        showDeleteDashboardGroup,
        showPageInfo,
        showPagePermissions,
        showPagePermissionsLink,
        showTeamsLinksModal,
    },
    onTrackClick,
}: DashboardNavHeaderActionsProps): React.JSX.Element => {
    const Toggle = (
        <div>
            <Button appearance="subtle" icon={<DotsThreeVertical />} />
        </div>
    );

    return (
        <Dropdown toggle={Toggle}>
            <StyledMenu>
                <Item
                    onClick={(): void => {
                        showPageInfo();
                        onTrackClick('dashboard-group-info');
                    }}
                >
                    Info
                </Item>
                {showPagePermissionsLink() && (
                    <Item
                        onClick={(): void => {
                            showPagePermissions();
                            onTrackClick('dashboard-group-permissions');
                        }}
                    >
                        Permissions
                    </Item>
                )}
                <Item
                    disabled={isTeamsLinksDisabled()}
                    onClick={(): void => {
                        showTeamsLinksModal();
                        onTrackClick('dashboard-group-link-to-team');
                    }}
                >
                    Links to teams
                </Item>
                {exportEnabled() && (
                    <Item
                        onClick={(): void => {
                            exportGroup();
                            onTrackClick('dashboard-group-export');
                        }}
                    >
                        Export
                    </Item>
                )}
                {showDeleteDashboardGroup() && hasDeleteDashboardGroupCapability && (
                    <Item
                        disabled={!deleteDashboardGroupEnabled()}
                        onClick={(): void => {
                            deleteDashboardGroup();
                            onTrackClick('delete-dashboard-group');
                        }}
                    >
                        Delete
                    </Item>
                )}
            </StyledMenu>
        </Dropdown>
    );
};
