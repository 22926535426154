import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import removedDetectorsStore from '../../../../app/alerts/common/removedDetectorsStore.ts';
import resolvedAlertsStore from '../../../../app/alerts/common/resolvedAlertsStore';
import { Monitoring } from '../../../../app/routing/Sitemaps';
import { DETECTOR_ORIGINS_LENGTH } from '../../../../common/ui/detectors/DetectorListFilters';
import { DetectorOrigin } from '../../../../common/ui/detectors/DetectorOriginLabels';

export const AlertDataController = [
    '$scope',
    'featureEnabled',
    'sourceFilterService',
    'urlOverridesService',
    'signalboost',
    '$interval',
    '$timeout',
    'getAlertList',
    'applyAlertListState',
    '$log',
    '$location',
    '$q',
    'getAlertAggregation',
    'ETSAlertService',
    'zeroStateService',
    'eventAlertService',
    'alertListDetectorCache',
    'routeParameterService',
    'title',
    'traceSuggestionService',
    'writepermissionsPermissionsChecker',
    'apmQueryService',
    'SPLUNK_APM_PRODUCT_NAME',
    'DetectorV2SearchService',
    'hasCapability',
    function (
        $scope,
        featureEnabled,
        sourceFilterService,
        urlOverridesService,
        signalboost,
        $interval,
        $timeout,
        getAlertList,
        applyAlertListState,
        $log,
        $location,
        $q,
        getAlertAggregation,
        ETSAlertService,
        zeroStateService,
        eventAlertService,
        alertListDetectorCache,
        routeParameterService,
        title,
        traceSuggestionService,
        writepermissionsPermissionsChecker,
        apmQueryService,
        SPLUNK_APM_PRODUCT_NAME,
        DetectorV2SearchService,
        hasCapability
    ) {
        const pageSize = 50;
        const dataFetchThrottleMs = 100;
        const maxPropertySuggestions = 100;
        const eventRefreshInterval = 60 * 1000;

        const ANY_ENV = `Any ${featureEnabled('apm2') ? 'Environment' : 'Cluster'}`;

        $scope.propertyMap = {};
        $scope.scrollCheck = 0;
        $scope.detectorCache = alertListDetectorCache.getCache();
        $scope.zeroStateService = zeroStateService;

        zeroStateService.queryForDetectors();

        $scope.detectorMode = false;
        $scope.onlyShowMutedResults = urlOverridesService.getMuted() || false;

        $scope.apm2Enabled = featureEnabled('apm2');
        $scope.isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');
        $scope.isResolveAlertEnabled = featureEnabled('resolveAlert');
        $scope.apmFiltersLabel = `${SPLUNK_APM_PRODUCT_NAME} Filters`;

        $scope.resetEventData = resetEventData;
        $scope.checkSingle = checkSingle;
        $scope.getCurrentGrouping = getCurrentGrouping;
        $scope.getNewData = getNewData;
        $scope.refreshEventDetails = refreshEventDetails;
        $scope.getNextDataSet = getNextDataSet;
        $scope.getDetectorStream = getDetectorStream;
        $scope.refreshEventQuery = refreshEventQuery;
        $scope.refreshEventData = refreshEventData;
        $scope.getPropertySuggestions = getPropertySuggestions;
        $scope.onGroupingSelected = onGroupingSelected;
        $scope.removeGrouping = removeGrouping;
        $scope.applyOverrideUrlParams = applyOverrideUrlParams;
        $scope.onSelectApmEnvironment = onSelectApmEnvironment;
        $scope.isShowOnlyMutedVisible = true;
        $scope.isShowOnlyMutedEnabled = true;

        hasCapability(Capability.CREATE_DETECTOR).then(
            (hasCreateDetectorCapability) =>
                ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
        );

        if ($scope.isApmEnabled) {
            initializeApmEnvPicker();
        }

        $scope.selectedDetectorOrigins = urlOverridesService.getDetectorOrigins();
        $scope.onFilterSelect = (selectedDetectorOrigins, showOnlyMuted) => {
            $scope.selectedDetectorOrigins = selectedDetectorOrigins;
            $scope.onlyShowMutedResults = showOnlyMuted;
            updateMutedQuery();
            updateDetectorOriginsQuery();
            $scope.$apply();
        };

        if ($location.path().indexOf('/logs/') === 0) {
            // logs is a prototype feature behind the flag.
            $scope.selectedTab = 'logs';
            $scope.eventFetchService = eventAlertService.elog;
            title.updateTitle('Event - Logs');
        } else if ($location.path().indexOf('/bounces/') === 0) {
            $scope.selectedTab = 'bounces';
            $scope.eventFetchService = eventAlertService.bounce;
            title.updateTitle('Event - Bounces');
        } else {
            if ($location.path().indexOf('/detectors/') === 0) {
                $scope.selectedTab = 'detectors';
                $scope.detectorMode = true;
                title.updateTitle('Detectors');
            } else {
                $scope.selectedTab = 'alerts';
                title.updateTitle('Active Alerts');
            }
            $scope.eventFetchService = ETSAlertService;
        }

        $scope.$parent.setHierarchicalNav(Monitoring.name, Monitoring.IDs[$scope.selectedTab]);

        setShowOnlyMutedVisibility();

        let reloadList = true;
        $scope.tentativeQueryText = '';

        if ($scope.eventFetchService.showTimePicker) {
            // if we have time picker and it changes, reload.
            const currentTime = urlOverridesService.getGlobalTimePicker();
            $scope.$on('React:$routeUpdate', function () {
                if (!angular.equals(currentTime, urlOverridesService.getGlobalTimePicker())) {
                    $scope.refreshEventData();
                }
            });
        }

        let requestTrackCount = 0;
        let originalList = null;
        let originalMutings = null;

        $scope.eventFilters = [];

        const sourceOverride = urlOverridesService.getSourceOverride();
        if (sourceOverride) {
            $scope.eventFilters = sourceFilterService.getSourceFilters(sourceOverride);
        }
        $scope.eventQuery = null;
        $scope.eventSeverityFilter = null;
        $scope.eventdata = {};

        $scope.$on('reload event list', reloadEventList);

        $scope.$on('severity clicked', function (ev, filters) {
            // clear everything and add the one they select.
            $scope.eventFilters = [];
            angular.forEach(filters, setFilter);
        });
        $scope.$on('severity panel clicked', function (ev, severity) {
            // behave like a radio button, if it's already there with that value, removes it
            // else, set it to that value.
            const index = getFilterIndex($scope.eventFetchService.severityProperty, severity);
            if (index !== -1) {
                $scope.eventFilters.splice(index, 1);
            } else {
                setFilter(severity, $scope.eventFetchService.severityProperty);
            }
        });

        routeParameterService.registerRouteWatch('filterByTeam', $scope.refreshEventQuery);

        $scope.$watch('eventFilters', $scope.refreshEventQuery, true);

        $scope.$watch('eventQuery', $scope.refreshEventData);

        $scope.$watch('eventSeverityFilter', reloadEventList);

        $scope.$watch('selectedDetectorOrigins', $scope.refreshEventData);

        $scope.$watchGroup(
            [
                'serviceEndpointQuery',
                'businessWorkflowQuery',
                'environmentQuery',
                'onlyShowMutedResults',
            ],
            $scope.refreshEventData
        );

        $scope.$watchCollection('grouping', $scope.refreshEventData);

        $scope.$watchGroup(
            ['grouping', 'eventFilters', 'eventSeverityFilter'],
            setShowOnlyMutedEnabled
        );

        $scope.$watchCollection('grouping', function (g) {
            urlOverridesService.setGroupBy(g);
        });

        $scope.$watchGroup(['grouping', 'eventQuery'], function () {
            $scope.scrollCheck++;
        });

        let debounceTextType = null;
        $scope.$watch('tentativeQueryText', function (nval, oval) {
            if (nval === oval) {
                return;
            }
            $timeout.cancel(debounceTextType);
            debounceTextType = $timeout(function () {
                $scope.scrollCheck++;
                $scope.refreshEventData();
            }, 400);
        });

        $scope.$on('$destroy', function () {
            $interval.cancel($scope.dataRefresher);
        });

        $scope.$on('React:$routeUpdate', function () {
            $scope.applyOverrideUrlParams();
        });

        $scope.applyOverrideUrlParams();
        $scope.resetEventData();
        $scope.refreshEventQuery();
        if (!$scope.detectorMode) {
            $scope.dataRefresher = $interval($scope.getDetectorStream, eventRefreshInterval);
        }

        function setShowOnlyMutedVisibility() {
            // Show the muted checkbox for detectors and alerts tabs
            $scope.isShowOnlyMutedVisible =
                ($scope.detectorMode || $scope.selectedTab === 'alerts') &&
                !zeroStateService.hasNoDetectors;
        }

        function setShowOnlyMutedEnabled() {
            // On the detector tab disable the show only muted option when grouping or filtering

            let isShowOnlyMutedEnabled = true;

            if (
                !$scope.isShowOnlyMutedVisible ||
                ($scope.detectorMode &&
                    !(
                        !$scope.grouping.length &&
                        !$scope.eventSeverityFilter &&
                        !$scope.eventFilters.length
                    ))
            ) {
                isShowOnlyMutedEnabled = false;
            }
            if ($scope.isShowOnlyMutedEnabled !== isShowOnlyMutedEnabled) {
                $scope.isShowOnlyMutedEnabled = isShowOnlyMutedEnabled;
                $scope.onlyShowMutedResults = false;
            }
        }

        function resetEventData() {
            $scope.tsidToMetadata = {};
            $scope.valueArray = [];
            $scope.eventDetails = null;
            $scope.eventdata = null;
        }

        function checkSingle(key, value) {
            return value > 1;
        }

        function getCurrentGrouping() {
            return $scope.grouping || [];
        }

        function getTentativeQueryString() {
            return $scope.tentativeQueryText ? sanitizeTerm($scope.tentativeQueryText) + '*' : '';
        }

        function getBaseAlertQuery() {
            const queryString = getTentativeQueryString();
            const queryList = $scope.eventQuery ? [$scope.eventQuery] : [];

            if (queryString) {
                const expectedGrouping = $scope.detectorMode
                    ? $scope.grouping.concat(['sf_detectorId'])
                    : $scope.grouping;
                const val =
                    ($scope.eventFetchService.hasLowerCaseIndex ? '.lowercase' : '') +
                    ':*' +
                    ($scope.eventFetchService.hasLowerCaseIndex
                        ? queryString.toLowerCase()
                        : queryString);
                const searchQuery = $scope.eventFetchService.defaultSearchFields
                    .concat(expectedGrouping)
                    .map((key) => key + val)
                    .join(' OR ');
                if (searchQuery) queryList.push(searchQuery);
            }

            if ($scope.isApmEnabled) {
                queryList.push(apmQueryService.getEnvironmentAlertsQuery($scope.environmentQuery));
                queryList.push(
                    apmQueryService.getServiceEndpointAlertsQuery($scope.serviceEndpointQuery)
                );
            }

            if ($scope.isApm2WorkflowsEnabled) {
                queryList.push(
                    apmQueryService.getBusinessWorkflowAlertsQuery($scope.businessWorkflowQuery)
                );
            }

            if ($scope.onlyShowMutedResults) queryList.push('sf_isMuted :true');

            if (
                $scope.selectedDetectorOrigins.length &&
                $scope.selectedDetectorOrigins.length !== DETECTOR_ORIGINS_LENGTH
            ) {
                const detectorOriginQuery = $scope.selectedDetectorOrigins.reduce(
                    (result, detectorOrigin, index) => {
                        return (
                            result +
                            (detectorOrigin === DetectorOrigin.STANDARD
                                ? `(${detectorOrigin} OR NOT _exists_:sf_eventDetectorOrigin)`
                                : `${detectorOrigin}`) +
                            (index === $scope.selectedDetectorOrigins.length - 1 ? `` : ` OR `)
                        );
                    },
                    ''
                );
                queryList.push(`sf_eventDetectorOrigin: (${detectorOriginQuery})`);
            }

            if ($scope.selectedTab === 'detectors') {
                queryList.push('NOT sf_eventDetectorOrigin:SLO');
            }

            return queryList
                .filter(Boolean)
                .map((query) => `(${query})`)
                .join(' AND ');
        }

        function getNewData() {
            const query = getBaseAlertQuery();
            getAlertAggregation($scope.eventFetchService, query).then(
                (result) => ($scope.eventdata = result)
            );
            $scope.refreshEventDetails();
        }

        function excludeRemovedDetectors(detectors) {
            const ids = removedDetectorsStore.getIds();
            return detectors.filter(
                (item) => !ids.includes(item.key) && !(item.parent && ids.includes(item.parent.key))
            );
        }

        function excludeResolvedAlerts(alerts) {
            const ids = resolvedAlertsStore.getIds();
            return alerts.filter((alert) => !ids.includes(alert.item?.original?.sf_incidentId));
        }

        function refreshEventDetails() {
            requestTrackCount++;
            const currentRequest = requestTrackCount;
            $scope.loading = true;
            const queryString = getTentativeQueryString();
            const requestPromise = {};

            if (
                $scope.detectorMode &&
                !$scope.grouping.length &&
                !$scope.eventSeverityFilter &&
                !$scope.eventFilters.length
            ) {
                const filterByTeamId = urlOverridesService.getFilterByTeam();
                const requestParams = {
                    name: '*' + queryString,
                    limit: $scope.maxResult,
                    offset: 0,
                    orderBy: 'name',
                    muted: $scope.onlyShowMutedResults ? true : undefined,
                };

                if ($scope.selectedDetectorOrigins.length) {
                    requestParams.detectorOrigins = $scope.selectedDetectorOrigins;
                }

                const sourceSelectors = apmQueryService.getSourceSelectors(
                    urlOverridesService.getEnvironment(),
                    $scope.isApmEnabled ? urlOverridesService.getServiceEndpoints() : [],
                    $scope.isApm2WorkflowsEnabled ? urlOverridesService.getBusinessWorkflows() : []
                );

                if (filterByTeamId) {
                    requestParams.teamId = filterByTeamId;
                }

                requestParams.sourceSelectors = sourceSelectors;

                // in detector mode, we'll just paginate the rest of the detector here after first page (which is that group by thingy)
                requestPromise.detectors = DetectorV2SearchService.search(requestParams).then(
                    (result) => result.rs
                );
            }

            if (
                !$scope.eventDetails ||
                (!$scope.grouping.length && !$scope.detectorMode) ||
                reloadList
            ) {
                const expectedGrouping = $scope.detectorMode
                    ? $scope.grouping.concat(['sf_detectorId'])
                    : $scope.grouping;

                const query = getBaseAlertQuery();
                requestPromise.alerts = getAlertList(
                    $scope.eventFetchService,
                    query,
                    $scope.maxResult,
                    $scope.tentativeQueryText,
                    expectedGrouping,
                    $scope.eventFetchService.severityProperty,
                    $scope.eventSeverityFilter
                );
                reloadList = false;
            }
            $q.all(requestPromise).then(
                function (result) {
                    if (result.alerts) {
                        if (currentRequest !== requestTrackCount) {
                            $log.info('Throw away stale alert response');
                            return;
                        }
                        originalMutings = result.alerts.groupedExtras;
                        const ids = Object.keys(result.alerts.detectorIdToName);

                        // update our local state with all detectors seen in this aggregation.
                        if (ids.length > 0) {
                            DetectorV2SearchService.search({
                                detectorIds: ids,
                                limit: ids.length,
                            }).then(function (result) {
                                cacheDetectors(result.rs);
                            });
                        }
                        $scope.eventDetails = applyAlertListState(
                            result.alerts,
                            $scope.eventDetails
                        );
                        originalList = $scope.eventDetails.result.slice(); // a shallow copy so that we can keep appending full detector result if needed.
                    }

                    if (result.detectors) {
                        cacheDetectors(result.detectors);
                        $scope.eventDetails.result = originalList.concat(
                            result.detectors
                                .filter(function (detector) {
                                    return !$scope.eventDetails.detectorIdToName[detector.sf_id];
                                })
                                .map(function (detector) {
                                    const result = {
                                        level: 0,
                                        key: detector.sf_id,
                                        display: detector.sf_detector,
                                        by: 'sf_detectorId',
                                        track: detector.sf_id,
                                        child: [],
                                        parent: null,
                                        severity: {},
                                        extra: (originalMutings[detector.sf_id] || []).filter(
                                            function (v) {
                                                // 3 (properties that we ignored, _not, _or, _original and level always have offset starting from 0 so we do +1)
                                                // Do angular.copy to get rid of keys like $$hashKey
                                                return (
                                                    Object.keys(angular.copy(v)).length === 3 + 1 &&
                                                    angular.equals({}, v._not)
                                                );
                                            }
                                        ),
                                    };
                                    result.started = result.extra.some((v) => {
                                        return v._original.started;
                                    });

                                    return result;
                                })
                        );

                        if ($scope.onlyShowMutedResults) {
                            $scope.eventDetails.result = $scope.eventDetails.result.filter(
                                (event) =>
                                    (!event.parent && event.extra && event.extra.length) ||
                                    event.parent
                            );
                        }
                    }

                    $scope.eventDetails.result = excludeRemovedDetectors(
                        $scope.eventDetails.result
                    );

                    if ($scope.isResolveAlertEnabled) {
                        $scope.eventDetails.result = excludeResolvedAlerts(
                            $scope.eventDetails.result
                        );
                    }

                    $scope.dataReady = true;
                    $scope.loading = false;
                },
                function (error) {
                    $log.error('Failed loading alerts list', error);
                    $scope.loading = false;
                }
            );
        }

        function cacheDetectors(detectors) {
            detectors.forEach((detector) => {
                return setWritePermissions(detector).then((detector) =>
                    alertListDetectorCache.put(detector)
                );
            });
        }

        function setWritePermissions(value) {
            return writepermissionsPermissionsChecker
                .hasWritePermissions(value)
                .then(function (hasWritePermission) {
                    value.hasWritePermission = hasWritePermission;
                    return value;
                });
        }

        function getNextDataSet() {
            if ($scope.grouping.length) {
                return;
            }
            $scope.maxResult += pageSize;
            $scope.refreshEventDetails();
        }

        function getFilterIndex(key, val) {
            let filterIndex = -1;
            const searchKey = key + ':' + (val || '');
            $scope.eventFilters.forEach(function (filtertag, index) {
                if (
                    filtertag.query.indexOf(searchKey) === 0 ||
                    filtertag.query.indexOf('NOT ' + searchKey) === 0
                ) {
                    filterIndex = index;
                }
            });
            return filterIndex;
        }

        function setFilter(val, key) {
            let not = false;
            if (val === 'n/a') {
                val = '*';
                not = true;
            }
            const filterIndex = getFilterIndex(key);
            const query = (not ? 'NOT ' : '') + key + ':' + val;
            const filterItem = {
                NOT: not,
                iconClass: 'icon-properties',
                property: key,
                query: query,
                type: 'property',
                value: key + ':' + val,
                propertyValue: val,
            };

            if (filterIndex > -1) {
                $scope.eventFilters[filterIndex] = filterItem;
            } else {
                $scope.eventFilters.push(filterItem);
            }
        }

        function getDetectorStream() {
            $timeout.cancel($scope.debounceDataFetch);
            $scope.debounceDataFetch = $timeout(function () {
                return $scope.getNewData();
            }, dataFetchThrottleMs);
        }

        function refreshEventQuery() {
            // special case severity and not include it in general filters, we'll use this to filter
            // data later.
            $scope.eventSeverityFilter = null;
            const effectiveFilters = $scope.eventFilters.filter(function (filter) {
                if (filter.property === $scope.eventFetchService.severityProperty) {
                    // take value side of the filter query to ensure 'OR' queries are formated properly
                    const queryValueIndex = filter.query.indexOf(':') + 1;
                    $scope.eventSeverityFilter = filter.query.substring(queryValueIndex);
                    return false;
                }
                return true;
            });

            const suffix = sourceFilterService.translateSourceFilterObjects(effectiveFilters);
            const queryList = [];
            const baseQuery = $scope.eventFetchService.getBaseEventQuery();

            if (suffix) {
                queryList.push(suffix);
            }
            if (baseQuery) {
                queryList.push(baseQuery);
            }

            const filterByTeam = urlOverridesService.getFilterByTeam();
            if (filterByTeam) {
                const sanitizedTeamId = sanitizeTerm(filterByTeam);
                queryList.push(`sf_propagatedTeams:${sanitizedTeamId}`);
            }

            $scope.eventQuery = queryList
                .map(function (query) {
                    return '(' + query + ')';
                })
                .join(' AND ');
        }

        function refreshEventData() {
            //this needs to page or stream
            $scope.maxResult = pageSize;
            $scope.eventDetails = null;
            $scope.getDetectorStream();
        }

        function reloadEventList() {
            reloadList = true;
            $scope.getDetectorStream();
        }

        function getPropertySuggestions(p) {
            return signalboost.autosuggest
                .getPropertySuggestions({
                    query: $scope.eventQuery,
                    partialInput: p,
                    limit: maxPropertySuggestions,
                    types: $scope.eventFetchService.objectTypes,
                })
                .finally(() => {
                    $scope.$applyAsync();
                });
        }

        function onGroupingSelected(v) {
            $scope.grouping.push(v);
            $scope.typeaheadgrouping = '';
        }

        function removeGrouping(item) {
            $scope.grouping.splice($scope.grouping.indexOf(item), 1);
        }

        function applyOverrideUrlParams() {
            const sourceOverride = urlOverridesService.getSourceOverride() || [];
            $scope.eventFilters = sourceFilterService.getSourceFilters(sourceOverride);
            $scope.grouping = urlOverridesService.getGroupBy() || [];

            updateMutedQuery();
            updateDetectorOriginsQuery();
            updateServiceEndpointQuery();
            updateBusinessWorkflowQuery();
            updateEnvironmentQuery();
        }

        function updateDetectorOriginsQuery() {
            const detectorOrigins = $scope.selectedDetectorOrigins;
            if (!detectorOrigins || detectorOrigins.length === 0) {
                return;
            }
            urlOverridesService.setDetectorOrigins($scope.selectedDetectorOrigins);
        }

        function updateMutedQuery() {
            urlOverridesService.setMuted($scope.onlyShowMutedResults);
        }

        function updateServiceEndpointQuery() {
            if (!$scope.isApmEnabled) {
                return;
            }

            const serviceEndpoints = urlOverridesService.getServiceEndpoints();
            if (!serviceEndpoints || serviceEndpoints.length === 0) {
                $scope.serviceEndpointQuery = null;
                return;
            }

            $scope.serviceEndpointQuery = [];
            serviceEndpoints.forEach((serviceEndpoint) => {
                const service = sanitizeTerm(serviceEndpoint.service);
                const operations = serviceEndpoint.endpoints.map((e) => sanitizeTerm(e));
                $scope.serviceEndpointQuery.push({ service: service, operations: operations });
            });
        }

        function updateBusinessWorkflowQuery() {
            if (!$scope.isApm2WorkflowsEnabled) {
                return;
            }

            const businessWorkflows = urlOverridesService.getBusinessWorkflows();
            if (!businessWorkflows || businessWorkflows.length === 0) {
                $scope.businessWorkflowQuery = null;
                return;
            }
            $scope.businessWorkflowQuery = [];
            businessWorkflows.forEach((businessWorkflow) => {
                const resource = sanitizeTerm(businessWorkflow.resource);
                $scope.businessWorkflowQuery.push({ resource: resource });
            });
        }

        function updateEnvironmentQuery() {
            if (!$scope.isApmEnabled) {
                return;
            }

            const environment = urlOverridesService.getEnvironment();
            if (environment && environment !== ANY_ENV) {
                $scope.environmentQuery = sanitizeTerm(environment);
            } else {
                $scope.environmentQuery = null;
            }
        }

        function initializeApmEnvPicker() {
            traceSuggestionService.getEnvironments().then((suggestions) => {
                $scope.apmEnvOptions = [ANY_ENV, ...suggestions];
            });

            $scope.selectedApmEnvironment = urlOverridesService.getEnvironment();
            $scope.selectedApmEnvironmentOption = $scope.selectedApmEnvironment || ANY_ENV;
        }

        function onSelectApmEnvironment(environment) {
            if (!environment) return;

            if (environment === ANY_ENV) {
                $scope.selectedApmEnvironment = null;
                urlOverridesService.clearEnvironment();
            } else {
                $scope.selectedApmEnvironment = environment;
                urlOverridesService.setEnvironment(environment);
            }
        }
    },
];
