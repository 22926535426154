import templateUrl from './organization.tpl.html';
import OrganizationController from './organization';

export const organizationPage = {
    bindings: {
        setHierarchicalNav: '<',
    },
    templateUrl,
    controller: OrganizationController,
};
