/* global optionApm1 */

import { AccessControlPermissionTypes } from '@splunk/olly-services';
import templateUrl from './styleguide.tpl.html';
import alertEventModalStyleGuideTemplateUrl from './alertEventModalV2StyleGuide.html';
import accessControlStyleGuideTemplateUrl from './accessControlStyleGuide.html';
import { accessControlMock } from '../../../common/ui/accessControl/accessControlMock';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import accessControlHelper from '../../../common/ui/accessControl/accessControlHelper';
import serviceEndpointSelectorGuideTemplateUrl from './serviceEndpointSelectorGuide.html';

export const styleGuideController = [
    '$scope',
    'themeService',
    '$window',
    '$timeout',
    '_',
    'PRODUCT_NAME',
    'DETECTOR_TYPES',
    function ($scope, themeService, $window, $timeout, _, DETECTOR_TYPES, PRODUCT_NAME) {
        const availableThemes = themeService.getThemes();

        $scope.PRODUCT_NAME = PRODUCT_NAME;
        $scope.theme = themeService;
        $scope.dark = themeService.dark;
        $scope.window = $window;
        $scope.delayedAction = (path, value, rootItm = $scope, delay = 4000) => {
            $timeout(() => {
                const pathObject = _.get(rootItm, path);
                if (_.isFunction(pathObject)) {
                    pathObject.apply(rootItm, _.isArray(value) ? value : [value]);
                } else {
                    _.set(rootItm, path, value);
                }
            }, delay);
        };

        $scope.$watch('dark', function (nval) {
            themeService.setTheme(nval ? availableThemes.DARK_THEME : availableThemes.LIGHT_THEME);
        });

        $scope.getValueForProp = function () {
            return null;
        };

        const dropdownSelectOptions = [
            { displayName: 'abc', value: '1' },
            { displayName: 'def', value: '2' },
            { displayName: 'ghi', value: '3' },
        ];
        $scope.dropdownSelect = {
            options: dropdownSelectOptions,
            defaultOption: dropdownSelectOptions[0],
            callback: function (o) {
                $window.alert('callback called with ' + JSON.stringify(o));
            },
        };

        $scope.sampleParameters = {
            stream: {
                displayName: 'Stream',
                description: 'stream',
                dataType: {
                    type: 'Stream',
                    values: [
                        { label: 'A', type: 'plot', name: 'demo.trans.latency' },
                        { label: 'B', type: 'plot', name: 'demo.trans.count' },
                        { label: 'C', type: 'ratio', name: 'A/B' },
                    ],
                },
            },
            timespan: {
                displayName: 'Timespan',
                defaultValue: '5m',
                description: 'window whose behavior is being tested for abnormality',
                dataType: { type: 'TimeSpan' },
            },
            groupBy: {
                displayName: 'Group By',
                defaultValue: null,
                description: 'attribute by which to group population members before calculating',
                dataType: { type: 'GroupBy' },
            },
            percent: {
                displayName: 'Percent',
                defaultValue: 0.1,
                description: 'percent of life left in you',
                dataType: { type: 'Percent' },
            },
            number: {
                displayName: 'Number',
                defaultValue: 2.5,
                description:
                    'number of standard deviations different from historical mean required to clear',
                dataType: { type: 'Number' },
            },
            lasting: {
                displayName: 'Lasting',
                defaultValue: { duration: '5m', percent: 1.0 },
                description: '(duration, percent of duration) associated with fire threshold',
                dataType: { type: 'Lasting' },
            },
            string: {
                displayName: 'Dropdown',
                defaultValue: 'above',
                dataType: {
                    type: 'String',
                    values: [
                        { value: 'above', displayName: 'Above' },
                        { value: 'below', displayName: 'Below' },
                        { value: 'out_of_band', displayName: 'Out of Band' },
                    ],
                },
                description:
                    'specifies whether detect fires when signal is above, below, or out-of-band',
            },
        };

        // generate alert event modal v2 variations
        const severity = ['Critical', 'Major', 'Minor', 'Warning', 'Info'];

        const variations = ($scope.sampleParameters.alertEventModalV2Header = []);
        severity.forEach((severity) => {
            [true, false].forEach((isTriggering) => {
                // triggering event may be either active or not, depending on what happened after it
                [true, false].forEach((isActive) => {
                    // alert can't be active at the time of an event clearing it
                    if (!isTriggering && isActive) {
                        return;
                    }

                    [true, false].forEach((isMuted) => {
                        variations.push({
                            title: 'This is rule title',
                            severity,
                            eventState: isTriggering ? 'anomalous' : 'ok',
                            isMuted,
                            isActive,
                        });
                    });
                });
            });
        });

        $scope.dumpVariation = function (input) {
            try {
                return JSON.stringify(input);
            } catch (e) {}
        };

        const userId = accessControlMock.getMockCurrentUserId();
        const orgId = accessControlMock.getMockCurrentOrgId();
        const initialAcls = [
            accessControlHelper.getDefaultAcl(orgId, userId, AccessControlPermissionTypes.PUBLIC),
            accessControlHelper.getDefaultAcl(
                orgId,
                userId,
                AccessControlPermissionTypes.RESTRICTED_WRITE
            ),
            [accessControlMock.getMockAclUser(true), accessControlMock.getMockAclUser(true, 4)],
        ];
        const initialPermissionType = accessControlHelper.detectPermissionType(initialAcls[2]);

        $scope.sampleParameters.accessControl = [
            {
                label: 'Example 1: initially public access to dashboard group',
                userId,
                orgId,
                objectType: AccessControlObjectType.DASHBOARD_GROUP,
                onChange: (newPermissions) => {
                    $scope.sampleParameters.accessControl[0].permissions = newPermissions;
                },
                principalsDataRepository: accessControlMock.principalsDataRepository,
                permissions: { acl: initialAcls[0] },
            },
            {
                label: 'Example 2: initially restricted write access to dashboard group',
                userId,
                orgId,
                objectType: AccessControlObjectType.DASHBOARD_GROUP,
                onChange: (newPermissions) => {
                    $scope.sampleParameters.accessControl[1].permissions = newPermissions;
                },
                principalsDataRepository: accessControlMock.principalsDataRepository,
                permissions: { acl: initialAcls[1] },
            },
            {
                label: 'Example 3: initially restricted read and write access to dashboard group',
                userId,
                orgId,
                objectType: AccessControlObjectType.DASHBOARD_GROUP,
                onChange: (newPermissions, newPermissionType, isDefault) => {
                    // revert initial setup
                    if (isDefault && newPermissionType === initialPermissionType) {
                        $scope.sampleParameters.accessControl[2].permissions = {
                            permissions: initialAcls[2],
                        };
                    } else {
                        $scope.sampleParameters.accessControl[2].permissions = newPermissions;
                    }
                },
                principalsDataRepository: accessControlMock.principalsDataRepository,
                permissions: { acl: initialAcls[2] },
            },
        ];

        $scope.DETECTOR_TYPES = DETECTOR_TYPES;
    },
];

export const styleguide = {
    templateUrl: templateUrl,
    controller: 'StyleGuideController',
};

export const alertEventModalStyleGuide = {
    templateUrl: alertEventModalStyleGuideTemplateUrl,
    controller: 'StyleGuideController',
};

export const accessControlStyleGuide = {
    templateUrl: accessControlStyleGuideTemplateUrl,
    controller: 'StyleGuideController',
};

export const serviceEndpointSelectorStyleGuide = {
    templateUrl: serviceEndpointSelectorGuideTemplateUrl,
    controller: [
        '$scope',
        'DETECTOR_TYPES',
        'APM_METRIC_TYPE',
        'ServiceEndpointSelection',
        'SPLUNK_APM_PRODUCT_NAME',
        function (
            $scope,
            DETECTOR_TYPES,
            APM_METRIC_TYPE,
            ServiceEndpointSelection,
            SPLUNK_APM_PRODUCT_NAME
        ) {
            const { APM_V2 } = DETECTOR_TYPES;
            const optionApm2 = { displayName: SPLUNK_APM_PRODUCT_NAME, value: APM_V2 };

            $scope.apmTypes = [optionApm2];
            $scope.selectedApmType = optionApm1;
            $scope.handleSelectedApmTypeChanged = (newType) => {
                $scope.selectedApmType = newType;
            };

            $scope.selectedApmFilters = [];
            $scope.selectedApmMetricType = APM_METRIC_TYPE.SERVICE_ERRORS;
            $scope.selectedEnvironment = null;
            $scope.selectedApmPercentile = 0;
            $scope.selectedServiceEndpoints = [new ServiceEndpointSelection()];

            $scope.detector = {
                sf_uiModel: {},
            };
            $scope.rule = {};

            $scope.handleSelectApmEnvironment = (environment) => {
                $scope.selectedEnvironment = environment;
            };

            $scope.handleSelectApmFilters = (filters) => {
                $scope.selectedApmFilters = filters;
            };

            $scope.handleSelectApmMetricType = (metric) => {
                $scope.selectedApmMetricType = metric;
            };

            $scope.handleSelectApmServiceEndpoint = (endpoints) => {
                $scope.selectedServiceEndpoints = endpoints;
            };
        },
    ],
};
