import {
    DetectorModelConversionError,
    DetectorModelConversionNoMatchingRuleError,
    DetectorModelConversionSignalBoostValidationError,
} from '../../../../app/detector/detectorModelConversionErrors';
import { sanitizePublishLabelOptions } from '@splunk/olly-services';
import { CHART_TYPE, DETECTOR_TYPE } from '../../../common/data/apiv2/getOrgScopeErrorHandler';

angular.module('signalview.detectorV2').factory('v2DetectorResolverUtil', [
    '$q',
    '$log',
    'eventService',
    'processNotificationRecipient',
    'getOrgScopeErrorHandler',
    'signalboostUtil',
    'chartDisplayUtils',
    'chartV2Service',
    'mutingService',
    'detectorUtils',
    'detectorV2Converter',
    'ruleToSignalflowV2',
    'detectorv2Service',
    function (
        $q,
        $log,
        eventService,
        processNotificationRecipient,
        getOrgScopeErrorHandler,
        signalboostUtil,
        chartDisplayUtils,
        chartV2Service,
        mutingService,
        detectorUtils,
        detectorV2Converter,
        ruleToSignalflowV2,
        detectorv2Service
    ) {
        const getDetector = (detectorId) => {
            return detectorv2Service
                .get(detectorId, getOrgScopeErrorHandler(DETECTOR_TYPE))
                .then((detector) => {
                    /*
                     * Sanitize the detector model on load if necessary.
                     *
                     * KISS-166, KISS-468: Ensure that publishLabelOptions are cleaned up by calling
                     * sanitizePublishLabelOptions(). Among other things, this ensures the proper spelling of units is
                     * used if detectors have been saved with the incorrect spelling.
                     */
                    if (
                        detector.visualizationOptions &&
                        detector.visualizationOptions.publishLabelOptions
                    ) {
                        detector.visualizationOptions.publishLabelOptions =
                            sanitizePublishLabelOptions(
                                detector.visualizationOptions.publishLabelOptions
                            );
                    }
                    return detector;
                });
        };

        const newDetector = (chartId) => {
            if (chartId) {
                return chartV2Service
                    .get(chartId, getOrgScopeErrorHandler(CHART_TYPE))
                    .then(function (chart) {
                        return signalboostUtil.initializeOrgForObj(chart);
                    })
                    .then(function (data) {
                        // When cloning from a chart - the chart will not have a detect block so add it to force the user to add one
                        let programTextWithDetect = data.programText;
                        if (data.programText.indexOf('detect(') === -1) {
                            programTextWithDetect =
                                data.programText + '\r\ndetect( [put your detect condition here] )';
                        }

                        return $q.when({
                            name: data.name + ' detector',
                            programText: programTextWithDetect,
                            timezone: data.options.programOptions.timezone,
                        });
                    });
            } else {
                return $q.when(null);
            }
        };

        const convertToUIModel = (detector) => {
            return detectorUtils
                .getSignalFlowModel(detector.programText)
                .then((signalFlowModel) => {
                    // get sf_uiModel
                    const sf_uiModel = detectorUtils.getV2DetectorUIModel(
                        detector,
                        signalFlowModel
                    );

                    // convert v2 model into v1
                    const v1Detector = detectorV2Converter.v2ModelToV1Model(detector, sf_uiModel);

                    return v1Detector;
                });
        };

        // Will attempt to convert to UIModel and return the modified detector with
        // 'rich' as the editor version. If there's a recoverable error, will return
        // the passed in detector (unmodified) with 'signalflow' as the editor version
        // instead.
        const setupDetectorModelForEditor = (detector) => {
            return convertToUIModel(detector)
                .then((detector) => {
                    return { detector, editor: 'rich' };
                })
                .catch((err) => {
                    const isKnownError =
                        err instanceof DetectorModelConversionNoMatchingRuleError ||
                        err instanceof DetectorModelConversionSignalBoostValidationError ||
                        err instanceof DetectorModelConversionError;

                    if (!isKnownError) {
                        $log.error(err.message, err);
                    }

                    return { detector, editor: 'signalflow' };
                });
        };

        const getPlots = (programText) => {
            return $q.all({
                plotLabels: chartDisplayUtils.getLabelInformation(programText),
                plots: detectorUtils.getPlotsFromProgramText(programText),
            });
        };

        const getDetectorNotifications = (rules) => {
            let notificationRecipients = [];
            rules.forEach((rule) => {
                notificationRecipients = notificationRecipients.concat(rule.notifications);
            });
            return $q.when(
                // This is used to obtain the notification's display names
                // It modifies the notification object in place and passed
                // around as part of the $scope object
                processNotificationRecipient.getDisplayDetails(notificationRecipients)
            );
        };

        const getMutings = (detectorId) => {
            return $q.when(
                mutingService
                    .getListForDetector(detectorId)
                    .then((resp) => resp)
                    .catch(function () {
                        return [];
                    })
            );
        };

        const getIncident = (incidentId) => {
            let incident;
            if (incidentId) {
                incident = eventService
                    .search({
                        query: 'incidentId:"' + incidentId + '"',
                        limit: 1,
                        offset: 0,
                    })
                    .then(function (data) {
                        if (data && data.length) {
                            return data[0];
                        }
                    });
            } else {
                incident = $q.when();
            }
            return incident;
        };

        const getDetectorRichViewData = (detector) =>
            $q.all({
                detector: signalboostUtil.initializeOrgForObj(detector),
                notifications: getDetectorNotifications(detector.sf_uiModel.rules || []),
                mutings: getMutings(detector.sf_id),
            });

        const getDetectorSignalflowViewData = (detector) =>
            $q.all({
                detector: signalboostUtil.initializeOrgForObj(detector),
                plotsData: getPlots(detector.programText),
                notifications: getDetectorNotifications(detector.rules),
                mutings: getMutings(detector.id),
            });

        const extendV2DetectorBasedEvent = (event) => {
            if (event.properties.sfui_incidentInformation) {
                return Promise.resolve(event);
            }

            return (
                eventService
                    .getEventProgramText(event)
                    .then((programText) => detectorUtils.getSignalFlowModel(programText))
                    .then((signalFlowModel) => {
                        const detectLabel = event.metadata.sf_detectLabel;
                        const severity = event.metadata.sf_severity;
                        const fakeDetectorModel = { rules: [{ detectLabel, severity }] };

                        return detectorUtils.getV2DetectorUIModel(
                            fakeDetectorModel,
                            signalFlowModel,
                            (rule) => rule.name === detectLabel
                        );
                    })
                    .then((model) => {
                        const eventRule = model.rules[0];
                        const annotations = ruleToSignalflowV2.generateCompoundEventAnnotations(
                            eventRule.conditions || [eventRule]
                        );
                        const incidentInfo = annotations.find(
                            (item) => item.key === 'sfui_incidentInformation'
                        );
                        // events from function type detectors should have only readableCondition in sfui_incidentInformation
                        if (incidentInfo && eventRule.function) {
                            const incidentInfoJSON = JSON.parse(incidentInfo.value).map(
                                (incidentInfoItem) => ({
                                    readableCondition: incidentInfoItem.readableCondition,
                                    builtInFunction: eventRule.module,
                                })
                            );

                            return { value: JSON.stringify(incidentInfoJSON) };
                        }

                        return incidentInfo;
                    })
                    .then((incidentInfo) => {
                        if (incidentInfo) {
                            event.properties.sfui_incidentInformation = incidentInfo.value;
                        }
                        return event;
                    })
                    // return input event on error
                    .catch((e) => {
                        $log.warn('Unable to extend V2 detector based event', e);
                        return event;
                    })
            );
        };

        return {
            getDetector,
            getIncident,
            getPlots,
            getDetectorNotifications,
            getMutings,
            getDetectorRichViewData,
            getDetectorSignalflowViewData,
            newDetector,
            convertToUIModel,
            extendV2DetectorBasedEvent,
            setupDetectorModelForEditor,
        };
    },
]);
