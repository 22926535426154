import templateUrl from './organizationInfo.tpl.html';

export const organizationInfo = {
    templateUrl,
    bindings: {
        org: '<',
        editable: '<',
        hasInfraEntitlement: '<',
        setHierarchicalNav: '<',
    },
    controller: [
        '$scope',
        '$rootScope',
        'organizationDataService',
        'sidebarService',
        'subscriptionTypeUtil',
        'urlOverridesService',
        'title',
        'featureEnabled',
        'themeService',
        function (
            $scope,
            $rootScope,
            organizationDataService,
            sidebarService,
            subscriptionTypeUtil,
            urlOverridesService,
            title,
            featureEnabled,
            themeService
        ) {
            const ctrl = this;
            ctrl.hasWritePermissions = featureEnabled('writepermissions');

            ctrl.dataService = organizationDataService;
            ctrl.sidebarService = sidebarService;
            ctrl.$onInit = $onInit;
            ctrl.$onDestroy = $onDestroy;
            $scope.$on('org member removed from current org', function () {
                $scope.$broadcast('catalog sidebar reload');
            });

            $rootScope.$on('theme update', function () {
                $scope.themeKey = themeService.getColorScheme();
            });

            $scope.$on('React:$routeUpdate', updateTitle);

            $scope.themeKey = themeService.getColorScheme();

            function $onInit() {
                initializeSection();
                updateTitle();
                sidebarService.showSidebar = true;
                ctrl.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(
                    ctrl.org.accountSubscriptionType
                );
            }

            function $onDestroy() {
                this.setHierarchicalNav();
            }

            function getSelectedSectionName() {
                const selection = urlOverridesService.getCatalogSelection();
                const section = selection ? selection.value : ctrl.section;
                return organizationDataService.getSectionName(section);
            }

            function updateTitle() {
                const displayTitle = getSelectedSectionName();
                if (displayTitle) {
                    title.updateTitle(displayTitle);
                }
            }

            // determines section if no value is set un query params
            // non-admins default to members and admins defualt to overview.
            function initializeSection() {
                if (!ctrl.editable) {
                    ctrl.section = 'members';
                } else {
                    ctrl.section = 'overview';
                }
            }
        },
    ],
};
