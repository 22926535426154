import React from 'react';
import {
    ApolloClient,
    ApolloProvider,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { isNumber, memoize, noop } from 'lodash';
import { createTracingLink, tracingLinkFetch } from '@splunk/olly-tracing/apollo';
import { serializeBaggage } from '@splunk/olly-tracing/utils';
import { RetryLink } from '@apollo/client/link/retry';
import { API_URL } from '../../common/consts';

function getClient(auth, uri, handleRedirectToError) {
    /**
     * Adds the current auth token to request headers
     */
    const authTokenLink = setContext((_, { headers }) => {
        const authToken = auth.authToken();
        return {
            headers: {
                ...headers,
                [auth.HEADER]: authToken,
            },
        };
    });

    /**
     * Handle an unauthorized / forbidden response.
     */
    const unauthorizedLink = onError(({ networkError }) => {
        const networkStatusCode =
            networkError && isServerError(networkError) && networkError.statusCode;
        if (networkStatusCode === 403) {
            handleRedirectToError();
        }
        if (networkStatusCode === 401) {
            auth.clearAuth();
            location.reload();
        }
    });

    const authLink = authTokenLink.concat(unauthorizedLink);

    return new ApolloClient({
        cache,
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
        link: ApolloLink.from([
            authLink,
            qsLink(uri),
            createCustomBaggageLink(),
            createTracingLink('apm/GraphqlClient', () => ({ 'ui.page': computeUIPageName() })),
            timeoutRetryLink(),
            httpLink,
        ]),
    });
}

const isServerError = (error) => {
    return isNumber(error.statusCode);
};

const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        Query: {
            // reference: https://www.apollographql.com/docs/react/caching/cache-field-behavior/
            fields: {
                // for the `edge` and `node` fields from the root Query type, resolve cache overwrites by performing a merge of the data
                edge: {
                    merge(existing, incoming, { mergeObjects }) {
                        return mergeObjects(existing, incoming);
                    },
                },
                node: {
                    merge(existing, incoming, { mergeObjects }) {
                        return mergeObjects(existing, incoming);
                    },
                },
                // for the `edges` and `nodes` fields from the root Query type, resolve cache overwrites by replacing the original entry with the new one
                edges: {
                    merge: false,
                },
                nodes: {
                    merge: false,
                },
            },
        },
    },
});

const httpLink = createHttpLink({ fetch: tracingLinkFetch });
const qsLink = memoize(
    (uri) =>
        new ApolloLink((operation, forward) => {
            operation.setContext({
                uri: `${uri}?op=${operation.operationName}`,
            });
            return forward(operation);
        })
);

const routeNamespaces = ['apm', 'rum', 'infra', 'logs'];

export const computeUIPageName = () => {
    const route = window.location.hash.replace(/^#\//, '').replace(/\?.*$/, '');
    const routeSegments = route.split('/');
    if (routeSegments.length === 1) {
        return '/' + routeSegments[0];
    }
    if (routeNamespaces.includes(routeSegments[0])) {
        return (
            '/' +
            [routeSegments[0], routeSegments[1], ...routeSegments.slice(2).map(() => ':id')].join(
                '/'
            )
        );
    }
    return '/' + [routeSegments[0], ...routeSegments.slice(1).map(() => ':id')].join('/');
};

export const createCustomBaggageLink = () =>
    setContext((_, prevContext) => ({
        headers: {
            ...prevContext.headers,
            baggage: serializeBaggage({
                'ui.page': computeUIPageName(),
            }),
        },
    }));

export const timeoutRetryLink = (delayIncrement = 5000) => {
    /**
     * RetryLink configuration to do an exponential backoff on network errors, using the given delay increment (default 5 seconds),
     * with a maximum of 5 attempts, and using jitter to spread out retries for concurrent requests.
     *
     * See https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
     */
    const retryLink = new RetryLink({
        delay: {
            initial: delayIncrement,
            jitter: true,
        },
        attempts: {
            max: 5,
        },
    });

    // combine these links so that the bubbled-up timeout errors are then handled by the RetryLink
    // (the order is reversed because errors travel backward through the link chain)
    return retryLink;
};

// instantiate the client at runtime because we need the auth context.
export const ApolloContextProvider = (props) => {
    const apmUri = process.env.GRAPHQL_URL || API_URL + '/v2/apm/graphql';
    //noop function is used instead of redirecting to error page as the goal is to fail silently if graphql calls fail
    return (
        <ApolloProvider client={getClient(props.auth, apmUri, noop)}>
            {props.children}
        </ApolloProvider>
    );
};

export default ApolloContextProvider;
