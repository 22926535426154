import { CurrentUserServiceMethods } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStore';
import { UserAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalytics';

import debug from 'debug';
import { LocationDescriptorObject } from 'history';
import qs from 'query-string';
import { InstrumentedServiceClient } from '@splunk/olly-services';
import { isWorkspace, NewDashboardService } from '../legacy/app/dashboard/NewDashboardService';
import nameDescriptionTemplateUrl from '../legacy/app/editors/namedescription.tpl.html';
import { ES_INDEXING_DELAY } from '../legacy/common/consts';
import { ShareableSnapshotService } from '../legacy/common/data/apiv2/shareableSnapshotService';
import { Clipboard } from '@splunk/olly-services/lib/services/Clipboard';
import { DefaultGlobalActionState as GlobalActionsState } from '@splunk/olly-services/lib/services/GlobalNav/GlobalNavUpdateStore';
import type { History } from 'history';
import { omit } from 'lodash';
import { FeatureFlags } from './util/FeatureFlags';

const serviceDebugger = debug('signalview').extend('createContentService');
const log = serviceDebugger.extend('log');
const logError = serviceDebugger.extend('error');

type Deps = {
    dashboardUtil: any;
    currentUser: CurrentUserServiceMethods;
    httpClient: ReturnType<InstrumentedServiceClient>;
    featureFlags: FeatureFlags;
    sfxModal: any;
    userAnalytics: ReturnType<UserAnalytics>;
    detectorWizardModal: any;
};

// Used to be imported from @splunk/olly-utilities/lib/types/utils
// but it is not exported from the lib anymore.
// It is fairly unique to import this type too.
type Falsy = false | 0 | '' | null | undefined;

const joinSearchStrings = (...strings: (string | Falsy)[]): string =>
    strings.filter((s) => s).join('&');

function appendSearchStringToBaseURL(baseURL: string | undefined, searchString: string): string {
    if (!baseURL?.includes('?')) {
        return `${baseURL}?${searchString}`;
    }
    if (baseURL?.endsWith('?')) {
        return `${baseURL}${searchString}`;
    }
    return `${baseURL}&${searchString}`;
}

export class CreateContentService {
    private deps: Deps;

    public static $inject: Array<keyof Deps> = [
        'dashboardUtil',
        'currentUser',
        'httpClient',
        'featureFlags',
        'sfxModal',
        'userAnalytics',
        'detectorWizardModal',
    ];

    private shareableSnapshotService: ShareableSnapshotService;
    private newDashboardService: NewDashboardService;

    constructor(
        dashboardUtil: any,
        currentUser: CurrentUserServiceMethods,
        httpClient: ReturnType<InstrumentedServiceClient>,
        featureFlags: FeatureFlags,
        sfxModal: any,
        userAnalytics: ReturnType<UserAnalytics>,
        detectorWizardModal: any
    ) {
        this.deps = {
            dashboardUtil,
            currentUser,
            httpClient,
            featureFlags,
            sfxModal,
            userAnalytics,
            detectorWizardModal,
        };

        this.shareableSnapshotService = new ShareableSnapshotService(currentUser, httpClient);
        this.newDashboardService = new NewDashboardService(currentUser, httpClient);
    }

    private onDashboardGroupCreated(page: any, history: History): Promise<void> {
        this.deps.userAnalytics.event('page', 'create');

        return new Promise((resolve) =>
            setTimeout(() => {
                let dashboardId;

                if (page.sf_dashboardConfigs && page.sf_dashboardConfigs.length) {
                    const config = page.sf_dashboardConfigs[0];
                    this.deps.dashboardUtil.addDashboardSearchParams({
                        groupId: page.sf_id,
                        configId: config.configId,
                    });

                    dashboardId = config.dashboardId;
                } else {
                    dashboardId = page.sf_dashboards[0];
                }

                history.push({
                    pathname: `/dashboard/${dashboardId}`,
                    search: '',
                });

                resolve();
            }, ES_INDEXING_DELAY)
        );
    }

    private async createACLDashboardGroup(history: History): Promise<void> {
        await this.deps.sfxModal
            .open({
                // declare-used-dependency-to-linter::createAclDashboardGroupModal
                component: 'createAclDashboardGroupModal',
            })
            .result.then((result: any) =>
                this.onDashboardGroupCreated(result.dashboardGroup, history)
            )
            .catch((error: string) => {
                if (error !== 'cancel') logError(error);
            });
    }

    private async createNonACLDashboardGroup(history: History): Promise<void> {
        await this.deps.sfxModal
            .open({
                templateUrl: nameDescriptionTemplateUrl,
                controller: 'NameDescriptionController',
                windowClass: 'name-description-modal',
                resolve: {
                    params: function () {
                        return {
                            obj: { sf_type: 'Page' },
                            label: 'Dashboard Group',
                        };
                    },
                    defaultWriters: this.deps.currentUser.defaultWritePermissions(),
                },
            })
            .result.then((result: any) => this.onDashboardGroupCreated(result, history))
            .catch((error: string) => logError(error));
    }

    private generateDefaultChartLink(
        context: GlobalActionsState,
        history: History
    ): LocationDescriptorObject {
        if (context.snapshot?.id) {
            return {
                pathname: '/temp/chart/new',
                search: '?toDashboard=' + context.snapshot.id,
            };
        }

        const editableDashboardParam =
            context.dashboardState &&
            (context.dashboardState.isInDashboardPage ||
                context.dashboardState.isInDashboardChart) &&
            !context.dashboardState.readOnly &&
            `toDashboard=${context.dashboardState.dashboardID}`;

        const { groupId, configId } = qs.parse(history.location.search || '');
        const dashboardGroupParams = this.deps.dashboardUtil.getDashboardSearchParamsString(
            groupId,
            configId
        );

        return {
            pathname: '/chart/new',
            search: `?${joinSearchStrings(editableDashboardParam, dashboardGroupParams)}`,
        };
    }

    public generateNewGraphChart(context: GlobalActionsState, history: History): void {
        const defaultNewChartLink = this.generateDefaultChartLink(context, history);

        const additionalGraphChartSearch = joinSearchStrings(
            'mode=graph',
            this.deps.dashboardUtil.getTimePickerParamsString()
        );
        const search = appendSearchStringToBaseURL(
            defaultNewChartLink.search,
            additionalGraphChartSearch
        );

        history.push({
            pathname: defaultNewChartLink.pathname,
            search,
        });
    }

    public generateNewTextNote(context: GlobalActionsState, history: History): void {
        const defaultNewChartLink = this.generateDefaultChartLink(context, history);
        const search = appendSearchStringToBaseURL(defaultNewChartLink.search, 'mode=text');

        history.push({
            pathname: defaultNewChartLink.pathname,
            search,
        });
    }

    public async generateNewDashboard(history: History): Promise<void> {
        const { sf_newDashboard: existingNewDashboardId } =
            await this.deps.currentUser.orgPreferences();
        let existingNewDashboardSnapshot;
        if (existingNewDashboardId) {
            try {
                existingNewDashboardSnapshot = await this.shareableSnapshotService.get(
                    existingNewDashboardId
                );
            } catch {
                log('No existing snapshot found or snapshot expired. Creating a new dashboard');
            }
        }
        const newDashboard =
            existingNewDashboardSnapshot || (await this.deps.dashboardUtil.createNewDashboard());

        const pathname = `/temp/dashboard/${newDashboard.id}`;

        history.push({
            pathname,
        });
    }

    public async pasteChartsToDashboard(
        context: GlobalActionsState,
        clipboard: Clipboard
    ): Promise<void> {
        const chartModels = clipboard.getClips().map((model) => omit(model, ['id', 'sf_id']));

        if (
            context.dashboardState.isInDashboardPage &&
            !context.dashboardState.readOnly &&
            chartModels.length > 0
        ) {
            const workspace = await isWorkspace(context.snapshot);
            context.dashboardState.pastingInProgress = true;
            await this.deps.dashboardUtil.addChartsFromModels(
                context.object,
                context.dashboardState.allCharts,
                workspace,
                chartModels,
                context.dashboardState.saveWorkspace
            );
            clipboard.clearClips();
            context.dashboardState.pastingInProgress = false;
        }
    }

    public async generateDashboardGroup(history: History): Promise<void> {
        return this.deps.featureFlags.accessControl
            ? this.createACLDashboardGroup(history)
            : this.createNonACLDashboardGroup(history);
    }

    public generateDetector(): void {
        this.deps.detectorWizardModal.newDetector();
    }

    public createApmDetector(): void {
        this.deps.detectorWizardModal.newApmDetector();
    }

    public getNewDashboardMenuText(clipboard: Clipboard): string {
        let suffix = '';
        const chartClips = clipboard.getClips();

        if (this.newDashboardService.hasNewCharts) {
            suffix = ' (Unsaved)';
        } else if (chartClips.length) {
            suffix = ` with ${chartClips.length} copied chart`;
            if (chartClips.length > 1) {
                suffix += 's';
            }
        }
        return `Dashboard${suffix}`;
    }

    public static isNewEmptyDashboard(context?: GlobalActionsState): boolean {
        return !!context && isWorkspace(context.snapshot) && !context.object?.charts?.length;
    }
}
