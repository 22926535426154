import customCategoryEditor from './customCategoryEditor.tpl.html';
import { MetricFinder as sitemap } from '../../../app/routing/Sitemaps';

export default [
    '$log',
    '$scope',
    '$timeout',
    'featureEnabled',
    'sfxModal',
    'METRIC_CATALOG_EVENTS',
    'METRICS_CATALOG_CONSTANTS',
    'metricsCatalogSearchUtil',
    'routeParameterService',
    'urlOverridesService',
    'promiseGenerationManager',
    'PRODUCT_NAME',
    function (
        $log,
        $scope,
        $timeout,
        featureEnabled,
        sfxModal,
        METRIC_CATALOG_EVENTS,
        METRICS_CATALOG_CONSTANTS,
        metricsCatalogSearchUtil,
        routeParameterService,
        urlOverridesService,
        promiseGenerationManager,
        PRODUCT_NAME
    ) {
        $scope.PRODUCT_NAME = PRODUCT_NAME;
        $scope.PAGE_TYPE = {
            BROWSE: 0,
            SEARCHING: 1,
            RESULTS: 2,
        };

        $scope.INPUT_CLASS = 'metric-search-input';
        $scope.pageType = $scope.PAGE_TYPE.BROWSE;
        $scope.isActiveMetricsOnly = true;
        $scope.isSearchDisabled = false;
        $scope.searchText = '';
        $scope.searchFilters = [];
        $scope.catalogConstants = METRICS_CATALOG_CONSTANTS;
        $scope.currentNumOfSearchTerms = 0;

        // Bound functions
        $scope.searchMetrics = searchMetrics;
        $scope.editCustomCategories = editCustomCategories;
        $scope.updateSearchTermsAndSearch = updateSearchTermsAndSearch;
        $scope.addFilter = addFilter;
        // flag passed down to facet to indicate a re-fetch or refresh of facet data
        $scope.loading = true;
        $scope.filters = [];
        $scope.addFilterAndSearch = addFilterAndSearch;
        $scope.updateFilterAt = updateFilterAt;
        $scope.removeFilterAt = removeFilterAt;
        $scope.focusInput = focusInput;
        $scope.getExtraSearchTermCount = metricsCatalogSearchUtil.getExtraSearchTermCount;
        $scope.clearFilters = clearFilters;
        $scope.hasFilters = hasFilters;
        $scope.magneticPageHeader = featureEnabled('magneticPageHeader');
        $scope.headerNavigation = [{ id: sitemap.map.id, label: sitemap.map.label }];

        const $ctrl = this;
        $ctrl.$onInit = initialize;
        $ctrl.$onDestroy = onDestroy;

        const fetchMetricsAndFacets = promiseGenerationManager(
            metricsCatalogSearchUtil.fetchProcessedMetricAndFacetResults,
            0
        );

        updatePage();

        const unregisterRouteWatch = routeParameterService.registerRouteWatchGroup(
            ['sources[]', 'query', 'includeInactive'],
            updatePage
        );

        $scope.$on('metric finder search terms', (evt, searchTermsCount) => {
            $scope.isSearchDisabled = searchTermsCount > $scope.catalogConstants.MAX_SEARCH_TERMS;
            $scope.currentNumOfSearchTerms = searchTermsCount;
        });

        $scope.$on('$destroy', unregisterRouteWatch);

        function initialize() {
            $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.metricFinder);
        }

        function onDestroy() {
            $ctrl.setHierarchicalNav();
        }

        function isValidSearch(query, filters) {
            // No blank searches allowed
            return query || (filters && filters.length);
        }

        function onSuggestionsAndFacetsFetched(response, query, filters, isActiveMetricsOnly) {
            const metrics = response.metrics;

            if (metrics.length === 0) {
                $scope.$broadcast(METRIC_CATALOG_EVENTS.EMPTY_METRIC_RESULTS);
            }

            metricsCatalogSearchUtil.sendGAEvent('catalog-search-results-count', metrics.length);

            $scope.searchResults = metrics;
            // Wait for children to initialize before broadcasting the event
            $timeout(() => {
                // Get relevant properties and dimensions
                $scope.$broadcast(
                    METRIC_CATALOG_EVENTS.REFRESH_FACETS,
                    query,
                    filters,
                    isActiveMetricsOnly,
                    response.facets,
                    response.customCategories
                );
            }, 0);
            $scope.loading = false;
        }

        function onSuggestionsAndFacetsFetchError(err) {
            $log.error('Unable to get metric suggestions and facets', err);
            $scope.loading = false;
            $scope.$broadcast(METRIC_CATALOG_EVENTS.EMPTY_METRIC_RESULTS);
        }

        function editCustomCategories() {
            const modalParams = {
                templateUrl: customCategoryEditor,
                controller: 'customCategoryEditorController',
                size: 'lg',
                backdrop: true,
                keyboard: true,
            };

            sfxModal.open(modalParams).result.then((rs) => {
                if (!rs) {
                    return;
                }
                $scope.$broadcast('updateToCustomCategory');
            });
        }

        function completeSearch() {
            $scope.pageType = $scope.PAGE_TYPE.RESULTS;
        }

        function updatePage() {
            let query = urlOverridesService.getQueryParam() || '';
            const terms = metricsCatalogSearchUtil.getSanitizedTokens(query);
            const filters = urlOverridesService.getSourceOverride() || [];
            const includeInactive = urlOverridesService.getIncludeInactive();

            $scope.searchText = query;
            query = metricsCatalogSearchUtil.parseSearchText(query);

            $scope.searchFilters = filters.map((filter) =>
                metricsCatalogSearchUtil.getSourceFilterFromString(filter)
            );
            $scope.searchTerms = terms;
            $scope.isActiveMetricsOnly = !includeInactive;

            if (!isValidSearch(query, filters)) {
                // Show browse page instead of attempting to run search
                $scope.pageType = $scope.PAGE_TYPE.BROWSE;
                return;
            }

            $scope.pageType = $scope.PAGE_TYPE.SEARCHING;
            $scope.searchResults = [];
            $scope.loading = true;
            // tell facet to clear its current results so loading is proper
            $scope.$broadcast(METRIC_CATALOG_EVENTS.FACETS_REFRESH_START);

            metricsCatalogSearchUtil.sendGAEvent('catalog-search-terms-count', terms.length);
            metricsCatalogSearchUtil.sendGAEvent('catalog-search-filters-count', filters.length);

            if ($scope.isActiveMetricsOnly) {
                metricsCatalogSearchUtil.sendGAEvent('catalog-search-active-metrics-only');
            } else {
                metricsCatalogSearchUtil.sendGAEvent('catalog-search-include-inactive-metrics');
            }

            // Get metrics
            fetchMetricsAndFacets(query, filters, $scope.isActiveMetricsOnly)
                .then(function (response) {
                    return onSuggestionsAndFacetsFetched(
                        response,
                        query,
                        filters,
                        $scope.isActiveMetricsOnly
                    );
                }, onSuggestionsAndFacetsFetchError)
                .finally(completeSearch);
        }

        function searchMetrics() {
            if ($scope.isSearchDisabled) {
                return;
            }

            const filters = $scope.searchFilters.map((filter) => filter.value);
            const query = metricsCatalogSearchUtil.parseSearchText($scope.searchText);

            if (!isValidSearch(query, filters)) {
                // No-op
                return;
            }

            // Update URL params, which will cause the search to get run via routeParameterService watcher above
            urlOverridesService.setSourceOverride(filters);
            urlOverridesService.setQueryParam(query);
            urlOverridesService.setIncludeInactive(!$scope.isActiveMetricsOnly);
        }

        function updateSearchTermsAndSearch(searchText) {
            $scope.searchText = searchText;
            searchMetrics();
        }

        function addFilter(filter) {
            $scope.searchFilters.push(metricsCatalogSearchUtil.getSourceFilterFromString(filter));
        }

        function addFilterAndSearch(filter) {
            addFilter(filter);
            searchMetrics();
        }

        function focusInput() {
            const inputElement = angular.element(`.${$scope.INPUT_CLASS}:visible`)[0];
            inputElement.focus();
        }

        function updateFilterAt(index, filter) {
            $scope.searchFilters[index] = filter;
            searchMetrics();
        }

        function removeFilterAt(index) {
            $scope.searchFilters.splice(index, 1);
            searchMetrics();
        }

        function clearFilters() {
            $scope.searchText = '';
            $scope.searchFilters = [];
            urlOverridesService.setSourceOverride([]);
            urlOverridesService.setQueryParam('');
            urlOverridesService.setIncludeInactive(!$scope.isActiveMetricsOnly);
        }

        function hasFilters() {
            return Boolean(
                urlOverridesService.getQueryParam() ||
                    ($scope.searchFilters && $scope.searchFilters.length)
            );
        }
    },
];
