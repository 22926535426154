import { ngRoute } from '../../../app/routing/ngRoute';
import { angularifyUri } from '../../../common/urlparams/urlAngularify';
import { modifyTemplateData } from '@splunk/olly-services';
import mustache from 'mustache';

export const NAVIGATOR_VIEW_TYPE_INSTANCE = 'INSTANCE';
export const NAVIGATOR_VIEW_TYPE_AGGREGATE = 'AGGREGATE';
export const CUSTOM_ORGANIZATION_SCOPE_ENTITY = 'ORG_ENTITY';
export const DESTINATION_TYPE_NAVIGATOR = 'navigator';
export const K8S_NAV_NODE_IDENTIFIER = 'k8s.node.name';
export const K8S_NAV_POD_IDENTIFIER = 'k8s.pod.name';
export const K8S_NAV_CONTAINER_IDENTIFIER = 'container.id';
export const K8S_NODES_MAP_NAV_CODE = 'k8snodes';
export const K8S_PODS_MAP_NAV_CODE = 'k8spods';
export const K8S_CONTAINERS_MAP_NAV_CODE = 'k8scontainers';
const MUSTACHE_TEMPLATE_VAR_REGEX = /\{\{(.*?)\}\}/g;
export default [
    'currentUser',
    'urlOverridesService',
    'CROSS_LINK_TYPES',
    'featureEnabled',
    '_',
    'dashboardVariablesService',
    'dashboardSavedVariablesContext',
    function (
        currentUser,
        urlOverridesService,
        CROSS_LINK_TYPES,
        featureEnabled,
        _,
        dashboardVariablesService,
        dashboardSavedVariablesContext
    ) {
        const SYNTHETIC_ID_PATTERN = /SYNTHETIC_ID:/;

        return {
            cleanObject,
            cleanCrossLink,
            getGlobalDataLink,
            getRedirectHref,
            getCrossLinkTitle,
            getDefaultNavigatorCrossLink,
            getTargetFromDashboard,
            getTargetUrl,
            getTriggerString,
            indexByTypeAndTrigger,
            objectIsCrossLinkable,
            getContextualizedCrossLinks,
            isValidTarget,
            stringifyParamValue,
            isTemplateResolvable,
            resolveTemplate,
            isK8sMapNavigator,
        };

        /*
      Returns a copy of the provided link with only the properties that
      are expected by the api.
     */
        function cleanCrossLink(link) {
            const { id, propertyName, propertyValue, contextId, targets } = link;
            const cleanedTargets = targets.map((target) => {
                if (target.type === CROSS_LINK_TYPES.INTERNAL_LINK) {
                    return _.pick(target, [
                        'dashboardId',
                        'dashboardGroupId',
                        'isDefault',
                        'type',
                        'name',
                        'conditions',
                    ]);
                } else {
                    return cleanObject(target);
                }
            });

            const cleanLink = {
                contextId,
                propertyName,
                propertyValue: propertyValue === '*' ? null : propertyValue,
                targets: cleanedTargets,
            };

            if (id && !id.match(SYNTHETIC_ID_PATTERN)) {
                cleanLink.id = id;
            }

            return cleanLink;
        }

        function cleanObject(obj) {
            return _.omitBy(obj, (val, key) => key.includes('$') || key.startsWith('_'));
        }

        function isValidTarget(target) {
            let valid = target && CROSS_LINK_TYPES[target.type];

            if (valid && target.type === CROSS_LINK_TYPES.INTERNAL_LINK) {
                valid = target.dashboardId && target.dashboardGroupId && target.dashboardName;
            }

            if (valid && target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                valid = target.navigatorId && target.navigatorName && target.navigatorView;
            }

            if (
                valid &&
                target.type !== CROSS_LINK_TYPES.INTERNAL_LINK &&
                target.type !== CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK
            ) {
                valid = !_.isEmpty(target.url);
            }

            return valid;
        }

        /*
      Converts dashboard into cross link target, with helper fields attached
      (e.g. name and type). When relying on objects generated in this way,
      cross links must be cleaned before save/update
     */
        function getTargetFromDashboard(dashboard, isDefault = false) {
            return {
                isDefault,
                dashboardId: dashboard.id,
                dashboardGroupId: dashboard.groupId,
                dashboardName: dashboard.name,
                dashboardGroupName: dashboard.groupName,
                type: CROSS_LINK_TYPES.INTERNAL_LINK,
            };
        }

        function getTargetUrl(target) {
            if (target.type === CROSS_LINK_TYPES.INTERNAL_LINK) {
                return `#/dashboard/${target.dashboardId}`;
            } else if (target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                return `#/infra/entity/${target.navigatorId}`;
            }
        }

        function getTriggerString(propertyName, propertyValue) {
            return `${propertyName || '*'}:${propertyValue || '*'}`;
        }

        function getEmptyLink(propertyName = null, propertyValue = null, aliases = null) {
            return {
                propertyName,
                propertyValue,
                targets: [],
                aliases,
            };
        }

        function indexByTypeAndTrigger(crossLinks) {
            const map = {};
            map[CROSS_LINK_TYPES.INTERNAL_LINK] = {};
            map[CROSS_LINK_TYPES.SPLUNK_LINK] = {};
            map[CROSS_LINK_TYPES.EXTERNAL_LINK] = {};
            map[CROSS_LINK_TYPES.KIBANA_LINK] = {};
            map[CROSS_LINK_TYPES.APPD_LINK] = {};

            crossLinks.forEach((link) => {
                let propertyNames;
                if (featureEnabled('mappingService') && link.aliases) {
                    propertyNames = link.aliases[link.propertyName];
                } else {
                    propertyNames = [link.propertyName];
                }

                propertyNames.forEach((propertyName) => {
                    const { propertyValue } = link;
                    const key = getTriggerString(propertyName, propertyValue);

                    link.targets.forEach((target) => {
                        let targetType = target.type;
                        // for internal navigator targets, add them to the same list of internal (dashboard) links. We want to process
                        // (sort/default/etc) all internal targets together
                        if (targetType === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                            targetType = CROSS_LINK_TYPES.INTERNAL_LINK;
                        }
                        const typeMap = map[targetType];
                        if (!typeMap[key]) {
                            typeMap[key] = getEmptyLink(propertyName, propertyValue, link.aliases);
                        }

                        typeMap[key].targets.push(target);
                    });
                });
            });

            _.each(map[CROSS_LINK_TYPES.INTERNAL_LINK], (link) => {
                link.targets.forEach((target) => {
                    target.relativeUrl = getTargetUrl(target);
                    if (target.isDefault && isValidTarget(target)) {
                        link.defaultLink = target;
                    } else if (!link.defaultLink && isValidTarget(target)) {
                        link.defaultLink = target;
                    }
                });
            });

            return map;
        }

        function resolveTemplate(propertyIdentifierTemplate, contextData) {
            let resolvedTemplate = propertyIdentifierTemplate;
            const { idTemplateModified, modifiedData, someKeyHasData } = modifyTemplateData(
                propertyIdentifierTemplate,
                contextData
            );
            if (someKeyHasData) {
                resolvedTemplate = mustache.render(idTemplateModified, modifiedData);
            }
            return resolvedTemplate;
        }

        /**
         * Check if the (one or more) template variables in the given template can all be completely resolved within the supplied contextData.
         * Note we can't rely on resolveTemplate to inform us of full resolution, as in the event that at lest one
         * variable can be resolved, it will fill that one and replace others that don't resolve with empty
         * string. So we need to first explicitly check that all variables in the template can be resolved.
         *
         * @param propertyIdentifierTemplate
         * @param contextData
         * @returns {boolean}
         */
        function isTemplateResolvable(propertyIdentifierTemplate, contextData) {
            const regex = new RegExp(MUSTACHE_TEMPLATE_VAR_REGEX);

            let match;
            let templateVariable;
            let allTemplateVariablesResolvable = true;
            while (
                allTemplateVariablesResolvable &&
                (match = regex.exec(propertyIdentifierTemplate)) !== null
            ) {
                templateVariable = match[1];
                allTemplateVariablesResolvable = !!contextData[templateVariable];
            }
            return allTemplateVariablesResolvable;
        }

        function getContextualizedCrossLinks(globalLinks, contextualLinks) {
            if (contextualLinks) {
                contextualLinks.forEach((contextLink) => {
                    const { propertyName, propertyValue } = contextLink;
                    const key = getTriggerString(propertyName, propertyValue);

                    contextLink.targets.forEach((target) => {
                        let targetType = target.type;
                        // for internal navigator targets, add them to the same list of internal (dashboard) links. We want to process
                        // (sort/default/etc) all internal targets together
                        if (targetType === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                            targetType = CROSS_LINK_TYPES.INTERNAL_LINK;
                        }

                        const typeMap = globalLinks[targetType];
                        if (!typeMap[key]) {
                            typeMap[key] = getEmptyLink(
                                propertyName,
                                propertyValue,
                                contextLink.aliases
                            );
                        }

                        // Contextual Link gets the priority to default
                        if (target.isDefault && isValidTarget(target)) {
                            typeMap[key].targets.forEach((target) => (target.isDefault = false));
                            typeMap[key].defaultLink = target;
                        }
                        if (target) {
                            target.isLocal = true;
                        }

                        typeMap[key].targets.push(target);
                    });
                });
            }

            return globalLinks;
        }

        function getDefaultNavigatorCrossLink(defaultLink) {
            let defaultNavigatorCode;
            if (defaultLink.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                defaultNavigatorCode =
                    defaultLink.navigatorCode || CUSTOM_ORGANIZATION_SCOPE_ENTITY;
            }
            return defaultNavigatorCode;
        }

        function getCrossLinkTitle(defaultLink) {
            let title;
            if (defaultLink.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                title = defaultLink.navigatorName || '';
            } else {
                title = `${defaultLink.dashboardName} (${defaultLink.dashboardGroupName})`;
            }
            return title;
        }

        function getRedirectHrefForDashboard(link, value, target) {
            const dashboardViewsEnabled = featureEnabled('dashboardViews');
            const linkParams = [];

            // the only values carried from the url are variables (if they match variables
            // on the target) and time picker
            const variables = dashboardVariablesService.applyVariableOverridesToVariableModel(
                dashboardSavedVariablesContext.getSavedVariables()
            );
            const filters = urlOverridesService.getSourceFilterOverrideList();
            const time = urlOverridesService.getGlobalTimePicker();

            if (variables) {
                variables.forEach((variable) => {
                    if (variable.value) {
                        const val = `${variable.alias}=${variable.property}:${stringifyParamValue(
                            variable.value
                        )}`;
                        linkParams.push(`variables[]=${encodeURIComponent(val)}`);
                    }
                });
            }

            if (filters) {
                filters.forEach((filter) => {
                    const val = `${filter.NOT ? '!' : ''}${filter.property}:${stringifyParamValue(
                        filter.value
                    )}`;
                    linkParams.push(`sources[]=${encodeURIComponent(val)}`);
                });
            }

            if (time && time.start) {
                if (time.relative) {
                    linkParams.push('startTime=' + time.start + '&endTime=' + time.end);
                } else {
                    linkParams.push('startTimeUTC=' + time.start + '&endTimeUTC=' + time.end);
                }
            }

            if (link && !link.propertyValue) {
                const valuesToCrossLink =
                    featureEnabled('mappingService') &&
                    link.aliases &&
                    link.aliases[link.propertyName]
                        ? link.aliases[link.propertyName]
                        : [link.propertyName];
                valuesToCrossLink.forEach((property) => {
                    const variableVal = `${property}:${value}`;
                    linkParams.push(`crossLinkSelection=${encodeURIComponent(variableVal)}`);
                });
            }

            let href = '#/crosslink';

            if (dashboardViewsEnabled) {
                linkParams.push(`toDashboardGroupId=${target.dashboardGroupId}`);
            }

            href += `/${target.dashboardId}`;

            if (ngRoute.params.dashboardID) {
                if (dashboardViewsEnabled && ngRoute.params.groupId) {
                    linkParams.push(`fromDashboardGroupId=${ngRoute.params.groupId}`);
                }
                href += `/${ngRoute.params.dashboardID}`;
            }

            if (linkParams.length) {
                href += '?' + linkParams.join('&');
            }

            return angularifyUri(href);
        }

        function getRedirectHrefForNavigator(link, value, target) {
            const linkParams = [];

            linkParams.push(`destinationType=${DESTINATION_TYPE_NAVIGATOR}`);

            // the only values carried from the url are variables (if they match variables
            // on the target) and time picker
            const filters = urlOverridesService.getSourceFilterOverrideList() || [];
            const time = urlOverridesService.getGlobalTimePicker();

            if (filters?.length > 0) {
                filters.forEach((filter) => {
                    const val = `${filter.NOT ? '!' : ''}${filter.property}:${stringifyParamValue(
                        filter.value
                    )}`;
                    linkParams.push(`sources[]=${encodeURIComponent(val)}`);
                });
            }

            // handle unique K8s map link parameters if linking to a K8S map navigator
            if (isK8sMapNavigator(target.navigatorCode)) {
                linkParams.push('navigatorContext=k8snodes');
                // add parent pod, node, cluster params (if not already there)
                addRequiredK8sArguments(target, filters, linkParams);
            }

            if (time && time.start) {
                if (time.relative) {
                    linkParams.push('startTime=' + time.start + '&endTime=' + time.end);
                } else {
                    linkParams.push('startTimeUTC=' + time.start + '&endTimeUTC=' + time.end);
                }
            }

            if (target.navigatorView) {
                linkParams.push('toNavigatorView=' + target.navigatorView);
            }
            // single instances view uses mapSelection
            if (!!target.navigatorPropertyIdentifierTemplateResolved) {
                linkParams.push(
                    `mapSelection=${encodeURIComponent(
                        target.navigatorPropertyIdentifierTemplateResolved
                    )}`
                );
            }

            // aggregate view passes crossLinkSelection variables (this is the property(s) + value that the datalink showed on,
            // which will get turned into filter (source) parameters)
            if (target.navigatorView !== NAVIGATOR_VIEW_TYPE_INSTANCE) {
                if (link && !link.propertyValue) {
                    const valuesToCrossLink =
                        featureEnabled('mappingService') &&
                        link.aliases &&
                        link.aliases[link.propertyName]
                            ? link.aliases[link.propertyName]
                            : [link.propertyName];
                    valuesToCrossLink.forEach((property) => {
                        const variableVal = `${property}:${value}`;
                        linkParams.push(`crossLinkSelection=${encodeURIComponent(variableVal)}`);
                    });
                }
            }

            let href = '#/crosslink';

            href += `/${target.navigatorId}`;
            linkParams.push(`toNavigatorId=${target.navigatorId}`);
            if (target.navigatorCode) {
                linkParams.push(`toNavigatorCode=${target.navigatorCode}`);
            }

            if (linkParams.length) {
                href += '?' + linkParams.join('&');
            }

            return angularifyUri(href);
        }

        function getRedirectHref(link, value, target) {
            if (target?.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                return getRedirectHrefForNavigator(link, value, target);
            } else {
                return getRedirectHrefForDashboard(link, value, target);
            }
        }

        function stringifyParamValue(value) {
            if (Array.isArray(value)) {
                return JSON.stringify(value);
            }
            return value;
        }

        function getGlobalDataLink(crossLink) {
            return currentUser.orgId().then((orgId) => {
                let URL = `/organization/${orgId}?selectedKeyValue=sf_section:globaldatalinks`;
                if (crossLink && crossLink.propertyName) {
                    URL += `&propertyName=${crossLink.propertyName}`;
                }
                if (crossLink && crossLink.propertyValue) {
                    URL += `&propertyValue=${crossLink.propertyValue}`;
                }

                return URL;
            });
        }

        function objectIsCrossLinkable(object) {
            return (
                object.sf_type === 'Dimension' ||
                object.sf_type === 'Topic' ||
                object.sf_type === 'Property'
            );
        }

        function isK8sMapNavigator(navigatorCode) {
            if (!navigatorCode) {
                return false;
            }
            return (
                navigatorCode === K8S_NODES_MAP_NAV_CODE ||
                navigatorCode === K8S_PODS_MAP_NAV_CODE ||
                navigatorCode === K8S_CONTAINERS_MAP_NAV_CODE
            );
        }

        function addK8sBreadcrumbAndFilters(target, k8sProp, breadcrumbs, filters, linkParams) {
            if (!!target.requiredContext[k8sProp]) {
                breadcrumbs.push(k8sProp);

                // add to filters if not already there
                const exists = filters.findIndex((filter) => filter.property === k8sProp) > -1;
                if (!exists) {
                    const val = `${k8sProp}:${stringifyParamValue(
                        target.requiredContext[k8sProp]
                    )}`;
                    linkParams.push(`sources[]=${encodeURIComponent(val)}`);
                }
            }
        }

        /**
         * Add any parent k8s hierarchical properties as filters and breadcrumbs
         * @param target
         * @param filters
         * @param linkParams
         */
        function addRequiredK8sArguments(target, filters, linkParams) {
            const breadcrumbs = [];

            const isK8sContainerNav = target.navigatorCode === K8S_CONTAINERS_MAP_NAV_CODE;
            const isK8sPodsNav = target.navigatorCode === K8S_PODS_MAP_NAV_CODE;
            const isK8sNodeNav = target.navigatorCode === K8S_NODES_MAP_NAV_CODE;
            if (isK8sNodeNav) {
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.cluster.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
            } else if (isK8sPodsNav) {
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.cluster.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.node.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
            } else if (isK8sContainerNav) {
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.cluster.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.node.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
                addK8sBreadcrumbAndFilters(
                    target,
                    'k8s.pod.name',
                    breadcrumbs,
                    filters,
                    linkParams
                );
            }

            if (breadcrumbs.length > 0) {
                const breadcrumbStr = breadcrumbs
                    .map(
                        (breadcrumb) =>
                            `${encodeURIComponent('"')}${encodeURIComponent(
                                breadcrumb
                            )}${encodeURIComponent('"')}`
                    )
                    .join(',' + encodeURIComponent(' '));

                linkParams.push(
                    `breadcrumbFilters=${encodeURIComponent(
                        '['
                    )}${breadcrumbStr}${encodeURIComponent(']')}`
                );
            }
        }
    },
];
