import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import Bell from '@splunk/react-icons/Bell';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

type BellProps = {
    variant: 'filled' | 'outlined';
    alertState?: 'critical' | 'major' | 'minor' | 'warning' | 'info' | 'normal';
};

const colorMap = {
    critical: '#ea1849',
    major: '#ff7e00',
    minor: '#e4ec00',
    warning: '#af71a0',
    info: '#b7c4ca',
    normal: '#94d545',
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getIconColor = (props: { variant: 'filled' | 'outlined'; color?: string }) =>
    props.color || variables.contentColorDefault;

const StyledBell = styled(Bell)`
    width: 20px;
    height: 20px;
    color: ${getIconColor};
`;

export const BellIcon: React.FC<BellProps> = ({ variant, alertState }) => {
    const themeKey = useLegacyThemeServiceKey();
    const color = alertState && colorMap[alertState];

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledBell variant={variant} {...{ color }} />
        </ThemeProvider>
    );
};
