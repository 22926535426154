import React from 'react';
import SeverityInformation from '@splunk/react-icons/SeverityInformation';
import styled from 'styled-components';
import ArrowSquareTopRightInset from '@splunk/react-icons/ArrowSquareTopRightInset';
import Link from '@splunk/react-ui/Link';

const StyledSeverityInfo = styled(SeverityInformation)`
    margin-right: 13px;
    height: 20px;
`;
const AppdTerraform = () => {
    const helpLink =
        'https://docs.splunk.com/observability/en/apm/apm-data-links/apm-datalinks-terraform/apm-create-data-links-terraform-batch.html';
    return (
        <div>
            <StyledSeverityInfo variant="filled" />
            <span>
                If you want to batch create data links, use our custom data links script.{' '}
                <Link to={helpLink} target={'_blank'}>
                    View the custom data link script documentation
                    <ArrowSquareTopRightInset />
                </Link>
            </span>
        </div>
    );
};

export default AppdTerraform;
