import { AngularInjector } from '../../../../common/AngularUtils';

OrganizationPreferenceService.useInstance = () =>
    AngularInjector.useInjectedClass(OrganizationPreferenceService);
OrganizationPreferenceService.$inject = ['organizationService'];
export default function OrganizationPreferenceService(organizationService) {
    this.get = () => {
        return organizationService.getOrgSettings();
    };

    this.update = (orgPreferenceId, orgPreferences) => {
        const properties = {};
        if ('restrictTeamManagement' in orgPreferences) {
            properties['sf_restrictTeamManagement'] = orgPreferences.restrictTeamManagement;
        }
        if ('alertAutoClearDuration' in orgPreferences) {
            properties['sf_alertAutoClearDuration'] = orgPreferences.alertAutoClearDuration;
        }
        if ('defaultTokenExpiration' in orgPreferences) {
            properties['sf_defaultTokenLifespanMs'] = orgPreferences.defaultTokenExpiration;
        }
        if ('relatedContentLogsDisabled' in orgPreferences) {
            properties['sf_relatedContentLogsDisabled'] = orgPreferences.relatedContentLogsDisabled;
        }
        if ('aiAssistant' in orgPreferences) {
            properties['sf_ai_assistant_enabled'] = orgPreferences.aiAssistant;
        }
        if ('aiAssistantShareData' in orgPreferences) {
            properties['sf_ai_assistant_customer_data_usage_enabled'] =
                orgPreferences.aiAssistantShareData;
        }
        return organizationService.updateOrgSettings(orgPreferenceId, properties);
    };
}
