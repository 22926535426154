export const APMQueryService = [
    'signalTypeService',
    function (signalTypeService) {
        return {
            getSourceSelectors,
            getEnvironmentDetectorsQuery,
            getEnvironmentAlertsQuery,
            getServiceEndpointDetectorsQuery,
            getServiceEndpointAlertsQuery,
            getBusinessWorkflowAlertsQuery,
            getBusinessWorkflowDetectorsQuery,
        };

        function isDefinedAndNotWildcard(value) {
            return typeof value === 'string' && value !== '' && value !== '*';
        }

        function newSourceSelector(key, value) {
            return [`${key}:"${value}"`];
        }

        function getSourceSelectors(env = null, serviceEndpoints = [], businessWorkflow = []) {
            let sourceSelectors = [];

            if (env) {
                sourceSelectors.push(
                    newSourceSelector(signalTypeService.getAPMDimensions().environment, env)
                );
            } else {
                sourceSelectors.push([]);
            }

            if (Array.isArray(businessWorkflow)) {
                const newItems = businessWorkflow
                    .map((item) => item.resource)
                    .filter(isDefinedAndNotWildcard)
                    .map((resource) =>
                        newSourceSelector(signalTypeService.getAPMDimensions().workflow, resource)
                    );

                sourceSelectors = extendSourceSelectors(sourceSelectors, newItems);
            }

            if (Array.isArray(serviceEndpoints)) {
                const newItems = [];
                serviceEndpoints.forEach((item) => {
                    newItems.push(
                        ...item.endpoints.map((endpoint) => {
                            const singleSourceSelector = [];

                            if (isDefinedAndNotWildcard(item.service)) {
                                singleSourceSelector.push(
                                    ...newSourceSelector(
                                        signalTypeService.getAPMDimensions().service,
                                        item.service
                                    )
                                );
                            }

                            if (isDefinedAndNotWildcard(endpoint)) {
                                singleSourceSelector.push(
                                    ...newSourceSelector(
                                        signalTypeService.getAPMDimensions().operation,
                                        endpoint
                                    )
                                );
                            }

                            return singleSourceSelector;
                        })
                    );
                });

                sourceSelectors = extendSourceSelectors(sourceSelectors, newItems);
            }

            return sourceSelectors;
        }

        function extendSourceSelectors(sourceSelectors, newItems) {
            if (!Array.isArray(newItems) || !newItems.length) {
                return sourceSelectors;
            }

            const extended = [];
            sourceSelectors.forEach((singleSelector) => {
                newItems.forEach((item) => {
                    extended.push([...singleSelector, ...item]);
                });
            });

            return extended;
        }

        /**
         * Returns a Lucene query to filter queries for active alerts in the selected APM environment.
         */
        function getEnvironmentAlertsQuery(environmentQuery = null) {
            if (environmentQuery === null) {
                return null;
            }

            return (
                '(' +
                signalTypeService.getAPMDimensions().environment +
                ':' +
                environmentQuery +
                ')'
            );
        }

        /**
         * Returns a Lucene query to filter queries for detectors to the selected APM environment.
         */
        function getEnvironmentDetectorsQuery(environmentQuery = null) {
            if (environmentQuery === null) {
                return null;
            }

            return (
                '(sf_sourceSelectors:"' +
                signalTypeService.getAPMDimensions().environment +
                ':\\"' +
                environmentQuery +
                '\\"")'
            );
        }

        /**
         * Returns a Lucene query to filter queries for active alerts on the selected service endpoints.
         */
        function getServiceEndpointAlertsQuery(serviceEndpointQuery) {
            if (!Array.isArray(serviceEndpointQuery)) {
                return null;
            }

            return serviceEndpointQuery
                .map((p) => {
                    return (
                        '(' +
                        signalTypeService.getAPMDimensions().service +
                        ':' +
                        p.service +
                        ' AND ' +
                        signalTypeService.getAPMDimensions().operation +
                        ':(' +
                        p.operations.join(' OR ') +
                        '))'
                    );
                })
                .join(' OR ');
        }

        /**
         * Returns a Lucene query to filter queries for active alerts on the selected business workflow.
         */
        function getBusinessWorkflowAlertsQuery(businessWorkflowQuery) {
            if (!Array.isArray(businessWorkflowQuery)) {
                return null;
            }

            return businessWorkflowQuery
                .map((p) => {
                    return (
                        '(' + signalTypeService.getAPMDimensions().workflow + ':' + p.resource + ')'
                    );
                })
                .join(' OR ');
        }

        /**
         * Returns a Lucene query to filter queries for detectors related to the selected service endpoints.
         */
        function getServiceEndpointDetectorsQuery(serviceEndpointQuery) {
            if (!Array.isArray(serviceEndpointQuery)) {
                return null;
            }

            return serviceEndpointQuery
                .map((p) => {
                    return (
                        '(sf_sourceSelectors:"' +
                        signalTypeService.getAPMDimensions().service +
                        ':\\"' +
                        p.service +
                        '\\"" AND (' +
                        p.operations
                            .map(
                                (o) =>
                                    'sf_sourceSelectors:"' +
                                    signalTypeService.getAPMDimensions().operation +
                                    ':\\"' +
                                    o +
                                    '\\""'
                            )
                            .join(' OR ') +
                        '))'
                    );
                })
                .join(' OR ');
        }

        /**
         * Returns a Lucene query to filter queries for detectors related to the selected business workflows.
         */
        function getBusinessWorkflowDetectorsQuery(businessWorkflowQuery) {
            if (!Array.isArray(businessWorkflowQuery)) {
                return null;
            }

            return businessWorkflowQuery
                .map((p) => {
                    return (
                        '(sf_sourceSelectors:"' +
                        signalTypeService.getAPMDimensions().workflow +
                        ':\\"' +
                        p.resource +
                        '\\"")'
                    );
                })
                .join(' OR ');
        }
    },
];
