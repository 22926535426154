import { useApolloClient, gql } from '@apollo/client';
import { useCallback } from 'react';

const GET_INFERRED_SERVICES = gql`
    query GetInferredServices(
        $tag: String!
        $allowedMatches: [String]
        $limit: Int
        $timeRange: TimeRangeInput
        $filters: FilterInput
    ) {
        tagValues(
            groupbys: [{ tagName: $tag, limit: $limit, allowedMatches: $allowedMatches }]
            timeRange: $timeRange
            filters: $filters
        ) {
            tags {
                value
            }
        }
    }
`;

export const useGetInferredServiceValues = () => {
    const client = useApolloClient();
    const getInferredServiceValues = useCallback(
        (variables, context = {}) => {
            return client
                .query({ query: GET_INFERRED_SERVICES, variables, context })
                .then((data) => data.data)
                .then((data) => (data ? data.tagValues : null));
        },
        [client]
    );

    return { getInferredServiceValues };
};
